// @flow

import links from '_common/routes/urls';
import ACL from '_common/stores/aclStore';
import AuthStore from '_common/stores/authStore';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { includes } from 'lodash';

import { Header, GlobalPublishButton } from '_common/components';
import Merchants from 'merchants/routes';
import Settings from 'settings/routes';
import Users from 'users/routes';
import InfoPanel, { InfoPanelColors } from '_common/components/InfoPanel';
import TrackingPage from 'tracking/pages/TrackingPage';
import StatisticsPage from 'statistics/pages/StatisticsPage';
import { EditButton } from '_common/components/EditButton/elements';
import commonActions from '_common/actions';
import { WhiteLabelServices } from '_common/whitelabelConfig';
import UIStore from '_common/stores/uiStore';
import userPilot from '_common/utils/userPilot';

type Props = RouteComponentProps & {
  aclStore: ACL,
  authStore: AuthStore,
  uiStore: UIStore,
};

type RouteType = {
  path: string,
  component: React.ComponentType<any>,
};

@inject('aclStore', 'authStore', 'uiStore')
@observer
class Main extends React.Component<Props> {

  componentDidMount() {
    const {
      uiStore: { editingCompanyByAdmin },
    } = this.props;
    // case for direct access 'settings' and 'users' pages
    if (
      editingCompanyByAdmin ||
      this.checkIfConfigsRequired() ||
      includes(this.props.location.pathname, 'users')
    ) {
      commonActions.getDashboardConfigs(editingCompanyByAdmin);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
      uiStore: { editingCompanyByAdmin },
    } = this.props;
    const previousLocation = prevProps.location.pathname;

    if (pathname !== previousLocation) {
      userPilot.onPageChange();
      // case for redirects, from login, dashboard, merchants, etc
      if (this.checkIfConfigsRequired()) {
        commonActions.getDashboardConfigs(editingCompanyByAdmin);
      }
    }
  }
  /**
   * Get only accessible routes for current user.
   */
  getAvailableRoutes = () => {
    const {
      authStore: { isMerchantUser },
      aclStore: { canAccessUrl },
      uiStore: { editingCompanyByAdmin },
    } = this.props;

    if (editingCompanyByAdmin) {
      return [...Merchants, ...Users.edit, ...Settings.edit];
    }

    return [
      ...Merchants,
      ...Users.regular,
      ...WhiteLabelServices.getWhitelabelRoutes(
        commonActions.getIsAdminUser().isAdmin
      ),
      ...Settings.regular,
    ].filter((route: RouteType) => canAccessUrl(route.path, !!isMerchantUser));
  };

  handleExitEditingMode = () => {
    const {
      uiStore: { setEditingCompanyByAdmin },
      history: { push },
    } = this.props;
    push(links.overview);
    setEditingCompanyByAdmin();
  };

  checkIfConfigsRequired = () =>
    includes(this.props.location.pathname, 'settings');

  get pagesWithPublish() {
    return [
      links.editReturnDetails,
      links.editReturnPolicies,
      links.editBranding,
      links.editPaymentOptionsAndReasonCodes,
    ];
  }

  renderActionButton = () => {
    return (
      <EditButton onClick={this.handleExitEditingMode}>
        Exit without saving
      </EditButton>
    );
  };

  render() {
    const {
      uiStore: {
        editingCompanyByAdmin,
        editingCompanyNameByAdmin,
        isEditingModeRecoveredFromStorage,
        applicationNotification,
      },
    } = this.props;

    /** means here page opens after refresh after edit mode */
    const redirectLink = isEditingModeRecoveredFromStorage
      ? links.overview
      : links.notFound;

    return (
      <React.Fragment>
        <Header />
        {editingCompanyByAdmin && (
          <InfoPanel
            color={InfoPanelColors.ERROR}
            message={`You are in edit mode for ${editingCompanyNameByAdmin}`}
            actionButton={this.renderActionButton()}
          />
        )}
        {applicationNotification && (
          <InfoPanel
            color={InfoPanelColors.SUCCESS}
            message={applicationNotification}
          />
        )}
        <GlobalPublishButton enabledPagesList={this.pagesWithPublish} />
        <Switch>
          <Redirect from={links.dashboardMerchant} to={links.returnDetails} />
          <Redirect from={links.dashboardRoot} to={links.editReturnDetails} />
          {this.getAvailableRoutes().map(
            (route: { path: string, component: any }) => (
              <Route exact key={route.path} {...route} />
            )
          )}

          <Route exact path={links.merchantTracking} component={TrackingPage} />
          <Route exact path={links.adminTracking} component={TrackingPage} />
          <Route
            exact
            path={links.merchantStatistics}
            component={StatisticsPage}
          />
          <Route
            exact
            path={links.adminStatistics}
            component={StatisticsPage}
          />
          <Redirect to={redirectLink} />
        </Switch>
      </React.Fragment>
    );
  }

}

export default Main;
