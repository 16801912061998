import getCompany from './getCompany';
import updateCompany from './updateCompany';
import changeCompanyModel from './changeCompanyModel';
import changePaymentType from './changePaymentType';
import changeReasonCodes from './changeReasonCodes';

export default {
  getCompany,
  updateCompany,
  changeCompanyModel,
  changePaymentType,
  changeReasonCodes,
};
