// @flow

import stores from 'stores';
import _map from 'lodash/map';
import * as React from 'react';
import MenuSection from './MenuSection';
import links from '_common/routes/urls';
import { MenuLink, Text, Wrapper, StyledLink } from './elements';
import { WhiteLabelUi } from '_common/whitelabelConfig';
import commonActions from '_common/actions';

type MenuLinkInfo = {
  acl: any[],
  hideOnMerchantView?: boolean,
  hideOnRootView?: boolean,
  component: React.Node,
};

const getAdvancedSettingsLinks = (company: string) => [
  {
    href: `/${company}/settings/return-details`,
    title: 'Details',
  },
  {
    href: `/${company}/settings/branding`,
    title: 'Branding',
  },
  {
    href: `/${company}/settings/payment-reason-codes`,
    title: WhiteLabelUi.pages.paymentOptionsAndReasonCodes.pageTitle,
  },
  {
    href: `/${company}/settings/return-policies`,
    title: 'Return policies',
  },
  {
    href: `/${company}/settings/communications`,
    title: 'Communications',
  },
];

// const getDashboardLink = (company: string) => {
//   return `/${company}/dashboard`;
// };

const getUsersLink = (company: string, hideUsersRootRoute: boolean) => {
  return hideUsersRootRoute ? `/${company}/users` : `/users`;
};

const logout = () => {
  if (stores.uiStore.hasUnsavedChanges) {
    window.onbeforeunload = null;
    const confirm = window.confirm('Changes you made may not be saved.');
    if (confirm) {
      commonActions.logout();
    }
  } else {
    commonActions.logout();
  }
};

const merchantsLinks = [
  { href: links.overview, title: 'Overview' },
  { href: links.requests, title: 'Requests' },
];

const getMenuLinks = (company: string) => {
  const hideUsersOnRoot = WhiteLabelUi.common.hideUsersListOnRoot(
    commonActions.getIsAdminUser().isAdmin
  );
  const menuLinks: MenuLinkInfo[] = [
    {
      acl: [],
      hideOnRootView: true,
      component: (
        <MenuLink disabled>
          <Text>Home</Text>
        </MenuLink>
        // <StyledLink disabled to={`/${company}/home`}>
        //   <MenuLink>
        //     <Text>Home</Text>
        //   </MenuLink>
        // </StyledLink>
      ),
    },
    {
      acl: [],
      hideOnRootView: true,
      component: (
        <MenuSection
          title="Settings"
          links={getAdvancedSettingsLinks(company)}
        />
      ),
    },
    {
      acl: [],
      hideOnMerchantView: true,
      component: <MenuSection title="Merchants" links={merchantsLinks} />,
    },
    {
      acl: [],
      hideOnMerchantView: true,
      component: (
        <StyledLink to={links.adminStatistics}>
          <MenuLink>
            <Text>Statistics</Text>
          </MenuLink>
        </StyledLink>
      ),
    },
    {
      acl: [],
      hideOnRootView: true,
      component: (
        <StyledLink to={`/${company}/statistics`}>
          <MenuLink>
            <Text>Statistics</Text>
          </MenuLink>
        </StyledLink>
      ),
    },
    {
      acl: [],
      hideOnRootView: hideUsersOnRoot,
      hideOnMerchantView: true,
      component: (
        <StyledLink to={getUsersLink(company, hideUsersOnRoot)}>
          <MenuLink>
            <Text>Users</Text>
          </MenuLink>
        </StyledLink>
      ),
    },
    {
      acl: [],
      hideOnMerchantView: true,
      component: (
        <StyledLink to={links.adminTracking}>
          <MenuLink>
            <Text>Tracking</Text>
          </MenuLink>
        </StyledLink>
      ),
    },
    {
      acl: [],
      hideOnRootView: true,
      component: (
        <StyledLink to={`/${company}/tracking`}>
          <MenuLink withoutBottomBorder>
            <Text>Tracking</Text>
          </MenuLink>
        </StyledLink>
      ),
    },
    {
      acl: [],
      hideOnMerchantView: true,
      component: (
        <MenuLink withoutBottomBorder onClick={logout}>
          <Text>Logout</Text>
        </MenuLink>
      ),
    },
  ];

  const editingModeLinks = [
    {
      acl: [],
      component: (
        <MenuLink disabled>
          <Text>Home</Text>
        </MenuLink>
        // <StyledLink to="/dashboard">
        //   <MenuLink>
        //     <Text>Home</Text>
        //   </MenuLink>
        // </StyledLink>
      ),
    },
    {
      acl: [],
      component: (
        <MenuSection
          title="Settings"
          links={[
            {
              href: `/settings/return-details`,
              title: 'Details',
            },
            {
              href: `/settings/branding`,
              title: 'Branding',
            },
            {
              href: `/settings/payment-reason-codes`,
              title: WhiteLabelUi.pages.paymentOptionsAndReasonCodes.pageTitle,
            },
            {
              href: `/settings/return-policies`,
              title: 'Return policies',
            },
            {
              href: `/settings/communications`,
              title: 'Communications',
            },
          ]}
        />
      ),
    },
    // {
    //   acl: [],
    //   hideOnRootView: true,
    //   component: (
    //     <StyledLink to="/users">
    //       <MenuLink withoutBottomBorder>
    //         <Text>Users</Text>
    //       </MenuLink>
    //     </StyledLink>
    //   ),
    // },
  ];

  return { menuLinks, editingModeLinks };
};

const Item = (props: any) => {
  return props.children;
};

const getAvailableLinks = company => {
  const isMerchant = stores.authStore.getIsMerchantUser();
  const { editingCompanyByAdmin } = stores.uiStore;

  if (editingCompanyByAdmin) {
    return getMenuLinks(editingCompanyByAdmin).editingModeLinks;
  }

  return getMenuLinks(company).menuLinks.filter(link =>
    isMerchant ? !!!link.hideOnMerchantView : !!!link.hideOnRootView
  );
};

class Menu extends React.Component<{}, {}> {

  render() {
    if (!stores.authStore.loggedUser.organisationId) {
      return null;
    }

    return (
      <Wrapper>
        {_map(
          getAvailableLinks(stores.authStore.loggedUser.organisationId),
          (v, k) => {
            const ItemJSX = <Item key={k}>{v.component}</Item>;

            if (
              v.acl.length &&
              v.acl.every(acl => stores.aclStore.canAccessResource(acl))
            ) {
              return ItemJSX;
            } else if (!v.acl.length) {
              return ItemJSX;
            }

            return null;
          }
        )}
      </Wrapper>
    );
  }

}

export default Menu;
