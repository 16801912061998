import styled from 'styled-components';
import { Button as StyledButton, Select as StyledSelect } from 'antd';
import { Icon } from '_common/components';

export const Root = styled.div`
  height: 100px;
  background-color: ${({ accentColor }) => accentColor || '#000'};
  color: #fff;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  padding: 0 260px;
  display: flex;
  align-items: center;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 60px;
  padding: 8px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: 18px;
`;

export const Button = styled(StyledButton)`
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;

  &:disabled {
    background-color: #c7c7cc !important;
  }

  &.ant-btn,
  &.ant-btn:hover {
    background-color: ${({ accentcolor }) => accentcolor || '#4a90e2'};
    color: #fff;
  }

  &.ant-btn {
    font-size: 18px;
    line-height: 20px;
    border: none;
    padding: 10px 33px;
    height: 42px;
  }
`;

export const Input = styled.input`
  height: 42px;
  flex-grow: 1;
  font-size: 18px;
  font-family: Arial, sans-serif;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  padding-left: 20px;
  min-width: 100px;
  color: #222222;
  &::placeholder {
    color: #222222;
  }
`;

export const Select = styled(StyledSelect)`
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #282d32;

  &.ant-select {
    color: #282d32;
    margin: 0 8px;
    width: 186px;
    flex-shrink: 0;
    border-left: 1px solid #d8d8d8;

    .ant-select-selection {
      height: 42px;
      border: none;

      .ant-select-selection__rendered {
        line-height: 42px;
      }
    }
  }
`;

export const LabelWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

export const SelectLabel = styled.div`
  position: absolute;
  top: 0;
  right: 190px;
`;

export const GeoIcon = styled(Icon)`
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  color: #f5222d;
`;

export const ClearButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: rgb(199, 199, 204);
  text-decoration: underline;
  margin-right: 10px;
  margin-bottom: 4px;
`;
