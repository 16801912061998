// @flow
/* eslint camelcase: 0 */
import React, { Component } from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

import { Label, Input } from '_common/components';
import {
  DetailsPanelSection,
  PanelRow,
  PanelLeftSide,
  PanelRightSide,
  PanelIcon,
} from '_common/components/CommonStyledElements';
import {
  ReturnUrlWrapper,
  Link,
  FlexibleTextLabel,
  FieldsWrapper,
} from './elements';
import { detailsMainIcon } from 'assets';
import { CONSUMER_URL } from '_common/constants/appConfig';
import commonActions from '_common/actions';
import { COMPANY_NAME_LABELS } from '_common/constants/common';
import { FIELD_NAMES } from '../../_constants';
import {
  requiredFieldRule,
  chargeAccountFieldRule,
} from '_common/utils/formUtils';
import {
  validateOnSpecialCharacters,
  validatePhoneNumber,
} from '_common/utils';
import { VALIDATION_FIELDS_TYPE } from '_common/constants/validation';
import { convertToBackendModel, recalculatePrice } from '../../_utils';
import {
  addRequiredFieldsForPatch,
  extractValueFromModel,
} from '_common/utils/directoryUtils';
import Amplitude from '_common/utils/amplitude';
import AuthStore from '_common/stores/authStore';
import { CompanyModelStore, UIStore } from 'stores';

type Props = {
  authStore: AuthStore,
  companyModelStore: CompanyModelStore,
  uiStore: UIStore,
  form: Object,
  onSave: () => void,
  onChange: () => void,
  companyModel: Object,
};

@observer
class MerchantDetails extends Component<Props> {

  /** Contains array on nested forms. */
  controlledForms = [];

  getCompanyUrl = () => {
    const {
      uiStore: { editingCompanyByAdmin },
      authStore: {
        loggedUser: { organisationId },
      },
    } = this.props;
    const companyNameInUrl = editingCompanyByAdmin || organisationId;
    return `${CONSUMER_URL}/${companyNameInUrl}`;
  };

  handleSave = () => {
    const {
      form: { validateFieldsAndScroll },
      companyModelStore: { companyModel, companyModelUpdates },
    } = this.props;

    /** force validation in all nested forms. */
    let isControlledFormsValid = true;
    this.controlledForms.map(form =>
      form.validateFields(err => {
        if (err) {
          isControlledFormsValid = false;
        }
      })
    );

    validateFieldsAndScroll({ scroll: { offsetTop: 100 } }, err => {
      if (!isControlledFormsValid || err || isEmpty(companyModelUpdates)) {
        return;
      }

      this.props.onSave(
        convertToBackendModel(
          recalculatePrice(
            addRequiredFieldsForPatch(companyModel, companyModelUpdates)
          )
        )
      );
    });
  };

  onBlurHandler = (field_name: string) => () => {
    Amplitude.logEvent('field_change', {
      page_name: 'Return details',
      field_name,
    });
    this.handleSave();
  };

  getTradingNameElement = () => {
    const {
      form: { getFieldDecorator },
      companyModelStore: {
        companyModel: { companyName },
      },
    } = this.props;
    const { isMerchant } = commonActions.getIsMerchantUser();
    return isMerchant ? (
      <>
        <Label>{COMPANY_NAME_LABELS.COMPANY_NAME}</Label>
        <FlexibleTextLabel>{companyName}</FlexibleTextLabel>
      </>
    ) : (
      <Form.Item>
        <div>
          <Label htmlFor={FIELD_NAMES.COMPANY_NAME}>
            {COMPANY_NAME_LABELS.COMPANY_NAME}
          </Label>
          {getFieldDecorator(FIELD_NAMES.COMPANY_NAME, {
            rules: [
              requiredFieldRule,
              {
                validator: (
                  rule: any,
                  value: string,
                  callback: (error?: Error) => void
                ) => {
                  validateOnSpecialCharacters(
                    rule,
                    value,
                    callback,
                    VALIDATION_FIELDS_TYPE.companyName
                  );
                },
              },
            ],
          })(
            <Input
              disabled={isMerchant}
              style={{ width: 360 }}
              onBlur={this.onBlurHandler('Company trading name')}
              id={FIELD_NAMES.COMPANY_NAME}
              placeholder={'Add company trading name'}
            />
          )}
        </div>
      </Form.Item>
    );
  };
  render() {
    const companyUrl = this.getCompanyUrl();
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { isMerchant } = commonActions.getIsMerchantUser();
    return (
      <>
        <DetailsPanelSection marginBottom={30}>
          <PanelRow>
            <PanelLeftSide flex={1} paddingTop={isMerchant ? 20 : 0}>
              <PanelIcon image={detailsMainIcon} width={72} height={66} />
              <FieldsWrapper>
                {this.getTradingNameElement()}
                <ReturnUrlWrapper isMerchant={isMerchant}>
                  <Label htmlFor="returnsUrl">Your returns URL</Label>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    id="returnsUrl"
                    href={companyUrl}
                  >
                    {companyUrl}
                  </Link>
                </ReturnUrlWrapper>
              </FieldsWrapper>
            </PanelLeftSide>
            <PanelRightSide flex={1}>
              <div>
                <Form.Item>
                  <div>
                    <Label htmlFor={FIELD_NAMES.LOCATION_ID}>
                      Merchant location ID*
                    </Label>
                    {getFieldDecorator(FIELD_NAMES.LOCATION_ID, {
                      validateTrigger: 'onKeyUp',
                      rules: [
                        {
                          ...requiredFieldRule,
                          ...(!isMerchant && {
                            message:
                              'This is an admin mandatory field. Please contact Australia Post.',
                          }),
                        },
                        {
                          pattern: /^[a-z0-9]{3}$|^[a-z0-9]{5}$/i,
                          message: 'Please enter a valid merchant location ID',
                        },
                        {
                          validator: (
                            rule: any,
                            value: string,
                            callback: (error?: Error) => void
                          ) => {
                            validateOnSpecialCharacters(rule, value, callback);
                          },
                        },
                      ],
                    })(
                      <Input
                        disabled={isMerchant}
                        style={{ width: 360 }}
                        id={FIELD_NAMES.LOCATION_ID}
                        placeholder={'Add merchant location ID'}
                        onBlur={this.onBlurHandler('Merchant location ID')}
                      />
                    )}
                  </div>
                </Form.Item>
                <Form.Item>
                  <div>
                    <Label htmlFor={FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT}>
                      Merchant charge account*
                    </Label>
                    {getFieldDecorator(FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT, {
                      validateTrigger: 'onKeyUp',
                      rules: [
                        {
                          ...requiredFieldRule,
                          ...(!isMerchant && {
                            message:
                              'This is an admin mandatory field. Please contact Australia Post.',
                          }),
                        },
                        chargeAccountFieldRule,
                        {
                          validator: (
                            rule: any,
                            value: string,
                            callback: (error?: Error) => void
                          ) => {
                            validateOnSpecialCharacters(rule, value, callback);
                          },
                        },
                      ],
                    })(
                      <Input
                        disabled={isMerchant}
                        style={{ width: 360 }}
                        id={FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT}
                        placeholder={'1234567'}
                        onBlur={this.onBlurHandler('Merchant charge account')}
                      />
                    )}
                  </div>
                </Form.Item>
                <Form.Item>
                  <div>
                    <Label htmlFor={FIELD_NAMES.PHONE_NUMBER}>
                      Phone number{isMerchant ? '*' : ''}
                    </Label>
                    {getFieldDecorator(FIELD_NAMES.PHONE_NUMBER, {
                      validateTrigger: 'onBlur',
                      rules: [
                        isMerchant && requiredFieldRule,
                        { validator: validatePhoneNumber },
                        {
                          validator: (
                            rule: any,
                            value: string,
                            callback: (error?: Error) => void
                          ) => {
                            validateOnSpecialCharacters(rule, value, callback);
                          },
                        },
                      ],
                    })(
                      <Input
                        style={{ width: 360 }}
                        onBlur={this.onBlurHandler('Phone number')}
                        id={FIELD_NAMES.PHONE_NUMBER}
                        placeholder={'Add company phone number'}
                      />
                    )}
                  </div>
                </Form.Item>
              </div>
            </PanelRightSide>
          </PanelRow>
        </DetailsPanelSection>
      </>
    );
  }

}

function mapPropsToFields({ companyModel }: Props) {
  return Object.values(FIELD_NAMES).reduce((acc, fieldName) => {
    acc[fieldName] = Form.createFormField({
      value: extractValueFromModel({
        model: companyModel,
        fieldName,
        toStringFields: [FIELD_NAMES.PRICING_TIER],
      }),
    });
    return acc;
  }, {});
}

export default compose(
  inject('companyModelStore', 'uiStore', 'authStore'),
  Form.create({
    mapPropsToFields,
    onValuesChange(props: Props, values: MerchantDetails) {
      props.onChange(values);
    },
  })
)(MerchantDetails);
