// @flow
import { apiClient, pureClient } from '_common/api/clients/clients';
import { ROOT_ORG_ID } from '_common/constants/appConfig';
import { CancelToken } from 'axios';
import { MAX_STORES, MAX_DISTANCE } from 'locate/utils/locationUtils';
import { WhiteLabelUi } from '_common/whitelabelConfig';

const getGeoQueryStores = async (
  config,
  cancelToken: CancelToken
): Promise<any> => {
  const { lat, lng, locationTypes, distance, extendedHours } = config;
  const { distanceUnit } = WhiteLabelUi.pages.locate;
  const distanceToUse = distance || MAX_DISTANCE;
  const response = await apiClient.get(
    `/v3/stores/latitude/${lat}/longitude/${lng}?distance=${distanceToUse}&unit=${distanceUnit}&limit=${MAX_STORES}&companyId=${ROOT_ORG_ID}&storesFeed=true&locationTypes=${locationTypes}&services=RETURNS&includeOpeningHours=true&extendedHours=${extendedHours}`,
    { cancelToken }
  );
  return response.data.resources;
};

type ReverseGeocodeConfig = {
  lat: number,
  lng: number,
  key: string,
};
const getReverseGeocode = async ({ lat, lng, key }: ReverseGeocodeConfig) => {
  const res = await pureClient.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}`
  );
  return res.data;
};

export default {
  getGeoQueryStores,
  getReverseGeocode,
};
