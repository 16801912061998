// @flow
/* eslint camelcase: 0 */

import staffService from '_common/services/staffService';
import commonActions from '_common/actions';
import {
  extractError,
  isIdempotentErrors,
  toWebSafeFormat,
} from '_common/utils';
import type { MerchantAssets, TMerchant } from 'merchants/models';
import { action, computed, observable, ObservableMap, runInAction } from 'mobx';
import type {
  Applicant,
  RequestsApiResponse,
} from '../services/merchantsService';
import merchantsService from '../services/merchantsService';
import companyModelService from '_common/services/companyModelService';
import assetManagementService from '_common/services/assetManagement';
import { ManualErrors } from '_common/constants/apiErrorResponces';
import { VALIDATION_ERRORS } from '_common/constants/validation';
import { ROOT_ORG_ID } from '_common/constants/appConfig';
import { get, includes, merge } from 'lodash';
import {
  WhiteLabelConstants,
  WhiteLabelServices,
  WhiteLabelStores,
  WhiteLabelUi,
} from '_common/whitelabelConfig';
import Amplitude from '_common/utils/amplitude';
import type { EmailConfig } from '_common/services/emailService';
import emailService from '_common/services/emailService';

const initialFilterValues = {
  companyId: '',
  searchValue: '',
  predicate: WhiteLabelUi.pages.overview.searchFields[0].value,
};

export type ResetPasswordConfig = {
  organisationId: string,
  token: string,
  password: string,
  requestId: string,
};

export type ApproveRequestConfig = {
  request: RequestsApiResponse,
  initialConfig: Object,
};

class MerchantsStore {

  ITEMS_PER_PAGE = 10;
  FIRST_PAGE = 1;

  @observable
  pendingRequests: RequestsApiResponse[] = [];

  @observable
  isLoading: boolean = false;

  @observable
  isAccessRequested: boolean = false;

  @observable
  isCollectionsWidgetSubmitted: boolean = false;

  @observable
  isReturnsPortalSubmitted: boolean = false;

  @observable
  editingInOverviewCompanyId: string | null = null;

  @observable
  editingInOverviewCompanyConfig: Object | null = null;

  @observable
  editingMerchantRequestId: string | null = null;

  @observable
  error: string = '';

  @observable
  merchantsRegistry: ObservableMap<string, TMerchant> = observable.map();

  @observable
  merchantsAssetsRegistry: ObservableMap<
    string,
    MerchantAssets
  > = observable.map();

  @observable
  filters: any = initialFilterValues;

  @observable
  currentPaginationPage = 1;

  getStateForDebug = () => ({
    pendingRequests: this.pendingRequests,
    isLoading: this.isLoading,
    isAccessRequested: this.isAccessRequested,
    error: this.error,
    merchantsRegistry: this.merchantsRegistry,
    merchantsAssetsRegistry: this.merchantsAssetsRegistry,
    filters: this.filters,
    currentPaginationPage: this.currentPaginationPage,
  });

  @computed
  get merchants(): Array<TMerchant> {
    const merchantsData = [...this.merchantsRegistry.values()];
    return merchantsData.sort((a, b) =>
      a.companyName.toUpperCase() > b.companyName.toUpperCase() ? 1 : -1
    );
  }

  @action
  getEntitiesByPagination = <T>(entitiesArray: Array<T>): Array<T> => {
    if (this.currentPaginationPage === this.FIRST_PAGE) {
      return entitiesArray.slice(0, this.ITEMS_PER_PAGE);
    }

    return entitiesArray.slice(
      (this.currentPaginationPage - 1) * this.ITEMS_PER_PAGE,
      this.currentPaginationPage * this.ITEMS_PER_PAGE
    );
  };

  @computed
  get filteredMerchants(): Array<TMerchant> {
    if (this.filters.companyId) {
      return [this.merchantsRegistry.get(this.filters.companyId)];
    }
    return this.merchants.filter((merchant: TMerchant) => {
      const searchProperty = get(merchant, this.filters.predicate);
      return (
        searchProperty &&
        searchProperty
          .toLowerCase()
          .indexOf(this.filters.searchValue.toLowerCase()) !== -1
      );
    });
  }

  @computed
  get merchantsAsOptions(): Array<{ label: string, value: string }> {
    if (!this.filters.searchValue) return [];

    return this.filteredMerchants.map((merchant: TMerchant, index) => {
      const searchProperty = get(merchant, this.filters.predicate);
      const label = includes(this.filters.predicate, 'companyName')
        ? searchProperty
        : `${searchProperty} (${merchant.companyName})`;

      return {
        label,
        value: `${get(merchant, this.filters.predicate)}#${index}`,
        name: merchant.companyId,
      };
    });
  }

  @action
  setPaginationPage = (page: number) => {
    this.currentPaginationPage = page;
  };

  checkErrorForExpiredToken = error => {
    if (!error.response || error.response.status !== 400) return false;
    return error.response.data.errors.some(
      (err: { message: string }) =>
        err.message === 'Error verifying password reset token'
    );
  };

  @action
  staffUpdatePassword = async ({
    organisationId,
    password,
    requestId,
    token,
  }: ResetPasswordConfig) => {
    this.isLoading = true;
    this.error = '';

    try {
      await staffService.staffUpdatePassword(organisationId, token, password);
      await merchantsService.updateToOnBoarded(requestId);
    } catch (e) {
      console.error('resetPassword Error:', e);
      runInAction('resetPassword:finally', () => {
        this.error = this.checkErrorForExpiredToken(e)
          ? VALIDATION_ERRORS.expiredToken
          : 'Error while reset password...';
      });
    } finally {
      runInAction('resetPassword:finally', () => {
        this.isLoading = false;
      });
    }
  };

  @action
  sendEmailWithPasswordResetToken = async (
    organisationId: string,
    email: string
  ) => {
    try {
      const redirectUrl =
        organisationId === ROOT_ORG_ID
          ? `${window.location.origin}/login`
          : `${window.location.origin}/${organisationId}/login`;

      /** trigger password reset */
      await staffService.staffResetPasswordByEmail(
        organisationId,
        email,
        redirectUrl
      );
      return Promise.resolve();
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      console.error('sendEmailWithPasswordResetToken Error:', e);
      return Promise.reject(extractError(e));
    }
  };

  @action
  confirmUpdatingPassword = async (organisationId, token, password) => {
    return staffService.staffUpdatePassword(organisationId, token, password);
  };

  @action
  signUpMerchant = async (body: Applicant) => {
    this.isLoading = true;
    this.error = '';

    try {
      const {
        customersToUse,
        collectionWidget,
      } = WhiteLabelStores.merchantsStore.getSignUpFlowConfig(body);
      if (customersToUse) await merchantsService.createPreRegRequest(body);

      runInAction(() => {
        this.isAccessRequested = true;
        if (collectionWidget) this.isCollectionsWidgetSubmitted = true;
        if (customersToUse) this.isReturnsPortalSubmitted = true;
      });
    } catch (e) {
      runInAction(() => {
        this.error = extractError(e);
        this.isAccessRequested = false;
      });

      return Promise.reject(e);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  setFiltersValue = (value: string, companyId?: string) => {
    this.filters.searchValue = includes(value, '#')
      ? value.split('#')[0]
      : value;
    this.filters.companyId = companyId;
  };

  @action
  clearFilters = () => {
    this.currentPaginationPage = this.FIRST_PAGE;
    this.filters = initialFilterValues;
  };

  @action
  loadSubCompanies = async (companyId: string) => {
    this.isLoading = true;
    try {
      const merchants = await companyModelService.getSubCompanies(companyId);
      runInAction('loadSubCompanies::success', () => {
        this.merchantsRegistry.clear();
        merchants.forEach((merchant: TMerchant) =>
          this.merchantsRegistry.set(merchant.companyId, merchant)
        );
      });
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      console.error('loadSubCompanies', e);
    } finally {
      runInAction('loadSubCompanies::finally', () => {
        this.isLoading = false;
      });
    }
  };

  @action
  fetchRequests = async () => {
    this.isLoading = true;

    try {
      const requests = await merchantsService.getAllRequests();
      const predicate =
        requests[0] && requests[0].ExpirationTime
          ? 'ExpirationTime'
          : 'createdAt';

      // reorder
      runInAction(() => {
        this.isLoading = false;
        this.pendingRequests = requests.sort(
          (a, b) => b[predicate] - a[predicate]
        );
      });
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        commonActions.setApplicationErrorMessage(extractError(e));
        console.error(e);
      });
    }
  };

  @action
  approveRequest = (approveRequestConfig: ApproveRequestConfig) => {
    return new Promise(async (resolve, reject) => {
      const {
        request: {
          requestId,
          applicant: { companyName, emailAddress },
        },
      } = approveRequestConfig;
      const companyId = toWebSafeFormat(companyName);

      try {
        // Create user.
        await merchantsService.merchantSignUpRequest(
          WhiteLabelStores.merchantsStore.getBodyMerchantSignUpRequestConfig(
            approveRequestConfig
          )
        );

        // Force token refresh to get up-to-date organisation scopes.
        await commonActions.forceTokenRefresh();

        // Create assets configs for company.
        await commonActions.assetsActions.createAssetConfig(companyId, {}); // todo: create assets actions
        await commonActions.assetsActions.publishAsset(companyId, '1.0');

        // Change user status to "APPROVED".
        await merchantsService.approveRequest(requestId);

        Amplitude.logWithoutCheck('request_approved', {
          page_name: 'Merchant Requests',
          user_email: emailAddress,
        });
        return resolve();
      } catch (error) {
        console.error(error);
        const extractedError = extractError(error);
        return reject(
          isIdempotentErrors([{ message: extractedError }])
            ? ManualErrors.EMAIL_USED
            : extractedError
        );
      }
    });
  };

  @action
  removeRequestFromPending(requestID: string) {
    this.pendingRequests = this.pendingRequests.filter(
      request => request.requestId !== requestID
    );
  }

  @action
  fetchRequest = async (requestID: string) => {
    return merchantsService.getRequest(requestID);
  };

  @action
  rejectRequest = async (emailConfig, requestID: string) => {
    try {
      await merchantsService.rejectRequest(requestID);

      await WhiteLabelServices.sendMerchantRejectEmail(emailConfig);
      Amplitude.logWithoutCheck('request_ignored', {
        page_name: 'Merchant Requests',
        user_email: emailConfig.emailAddress,
      });
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      console.error(e);
    }

    runInAction(() => {
      this.pendingRequests = this.pendingRequests.filter(
        request => request.requestId !== requestID
      );
    });
  };

  @action
  getAssetsConfigForMerchant = async (organisationId: string) => {
    if (!organisationId || this.merchantsAssetsRegistry.has(organisationId)) {
      return;
    }

    try {
      const config = await assetManagementService.getConfig(organisationId);
      runInAction(() => {
        this.merchantsAssetsRegistry.set(organisationId, config);
      });
    } catch (e) {
      // console.error('getAssetsConfigForMerchant :: error', e);
    }
  };

  @action
  invalidateOrganisationConfig = (organisationId: string) => {
    if (this.merchantsAssetsRegistry.has(organisationId)) {
      this.merchantsAssetsRegistry.delete(organisationId);
    }
  };

  @action
  setEditingInOverviewCompanyId = (organisationId: null | string) => {
    if (
      organisationId === null ||
      this.editingInOverviewCompanyId === organisationId
    ) {
      this.editingInOverviewCompanyId = null;
    } else {
      this.editingInOverviewCompanyId = organisationId;
    }
  };

  @action
  setEditingInOverviewCompanyConfig = (config: Object) => {
    this.editingInOverviewCompanyConfig = merge(
      { ...this.editingInOverviewCompanyConfig },
      config
    );
  };

  @action
  setEditingMerchantRequestId = (requestId: null | string) => {
    if (requestId === null || this.editingMerchantRequestId === requestId) {
      this.editingMerchantRequestId = null;
    } else {
      this.editingMerchantRequestId = requestId;
    }
  };

  @action
  updateMerchantConfigAndRefresh = async (
    companyId: string,
    updatedConfig: Object
  ) => {
    try {
      const responseWithUpdates = await commonActions.companyActions.updateCompany(
        companyId,
        updatedConfig
      );
      runInAction(() => {
        this.merchantsRegistry.set(companyId, responseWithUpdates);
        this.editingInOverviewCompanyConfig = null;
        this.editingInOverviewCompanyId = null;
        commonActions.setApplicationNotification(
          `Saved changes to ${responseWithUpdates.companyName}`
        );
      });
      return Promise.resolve();
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      return Promise.reject(e);
    }
  };

  deleteMerchant = async (companyId: string) => {
    try {
      await companyModelService.updateCompany(companyId, {
        products: {
          [WhiteLabelConstants.PRODUCT_NAME]: {
            enabled: false,
            consumerUrlLive: false,
          },
        },
      });

      /**
       * Function returns callback to caller - it needed to trigger removing from store.
       * Need to show on UI success message for 3 sec after deleting.
       * */
      return Promise.resolve(() => {
        runInAction(() => {
          this.merchantsRegistry.delete(companyId);
          this.merchantsAssetsRegistry.delete(companyId);
        });
      });
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      console.error(e);
      return Promise.reject(e);
    }
  };

  @action
  resendActivationEmail = async (emailConfig: EmailConfig) => {
    try {
      await emailService.sendEmail(emailConfig);
      return Promise.resolve();
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      console.error(e);
      return Promise.reject(e);
    }
  };

  @action
  setSearchPredicate = (predicate: string) => {
    this.filters = { ...initialFilterValues, predicate };
  };

}

export default MerchantsStore;
