import {
  InfoPanelWrapper,
  CloseButton,
  TextMessage,
  InfoPanelWrapperPad,
} from './elements';
import React from 'react';

type Props = {
  children: Object,
  onClick: () => void,
};

const InfoPanel = ({ children, onClick }: Props) => {
  const handleClick = () => onClick();

  return (
    <div>
      <InfoPanelWrapperPad onClick={handleClick} />
      <InfoPanelWrapper>
        <TextMessage>{children}</TextMessage>
        <CloseButton onClick={handleClick} />
      </InfoPanelWrapper>
    </div>
  );
};

export default InfoPanel;
