import styled from 'styled-components';
import { closeIcon } from 'assets';

export const InfoPanelWrapperPad = styled.div`
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
`;

export const InfoPanelWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  height: auto;
  max-width: 460px;
  width: 90%;
  background-color: #fff1f0;
  display: block;
  justify-content: space-between;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #ffa39e;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;

export const TextMessage = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #231f20;
`;

export const CloseButton = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin: 0;
  background: url(${closeIcon}) center center / 14px 14px no-repeat;
  border-style: none;
  position: absolute;
  top: 0;
  right: 0;
`;
