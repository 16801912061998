export const VALIDATION_ERRORS = {
  invalidPassword:
    'Password must be 8+ characters, with 1 capital and 1 number',
  notMatchPasswords: 'Set password and confirm password do not match',
  expiredToken:
    'Your activation link has expired. Please request a new one by clicking this link',
};

export const VALIDATION_FIELDS_TYPE = {
  companyName: 'company-name',
};
