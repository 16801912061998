//@flow
/* eslint camelcase: 0 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Menu, TextSection } from '_common/components';
import {
  Page,
  Content,
  PageHeader,
  PageTitle,
  Panel,
  PanelIcon,
  PanelSectionNoWrap,
} from '_common/components/CommonStyledElements';
import { emailsIcon } from 'assets';
import EmailsList from 'settings/communications/components/EmailsList/EmailsList';
import amplitude from '_common/utils/amplitude';
import { UIStore } from 'stores';
import { WhiteLabelUi } from '_common/whitelabelConfig';

type Props = RouteComponentProps & {
  uiStore: UIStore,
};

@inject('uiStore')
@observer
class Communications extends Component<Props> {

  componentDidMount() {
    amplitude.logEvent('page_open', { page_name: 'Communications' });
  }

  render() {
    const {
      uiStore: { editingCompanyByAdmin },
    } = this.props;
    const {
      communicationTitleText,
    } = WhiteLabelUi.pages.settingsAndCommunications;
    return (
      <Page>
        <Menu />
        <Content editingCompanyByAdmin={editingCompanyByAdmin}>
          <PageHeader>
            <PageTitle>Communications</PageTitle>
          </PageHeader>
          <Panel>
            <PanelSectionNoWrap>
              <PanelIcon image={emailsIcon} width={54} height={54} />
              <TextSection title="Preview emails">
                {communicationTitleText}
              </TextSection>
              <EmailsList />
            </PanelSectionNoWrap>
          </Panel>
        </Content>
      </Page>
    );
  }

}

export default Communications;
