import React from 'react';
import { Tooltip } from 'antd';
import styled, { AnyStyledComponent, css } from 'styled-components';

export const StyledTooltip: AnyStyledComponent = styled(props => {
  return <Tooltip {...props} overlayClassName={props.className} />;
})`
  & {
    position: relative;
    width: 100%;

    .ant-tooltip-inner {
      background-color: #fff;
      color: #222;
      font-size: 12px;
      padding: 12px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-content {
      position: relative;
      left: -70px;
      width: ${({ tooltipWidth }) => tooltipWidth}px;
    }
  }
`;

export const TooltipIcon = styled.div`
  width: 24px;
  height: 24px;
  border: solid 1px #222222;
  border-radius: 50%;
  background-color: #ffffff;
  font-family: ${props => props.theme.fonts.book};
  font-size: 11px;
  color: #222222;
  text-align: center;
  line-height: 24px;
  position: absolute;
  z-index: 100;
  right: ${({ outboundToRight }) => (outboundToRight ? '-32px' : '10px')};
  top: 50%;
  transform: translateY(-50%);
  ${({ beforeInput }) =>
    beforeInput &&
    css`
      right: initial;
      left: -50px;
    `}
`;

export const TooltipWrapper = styled.div`
  position: relative;
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  ${({ drawBorderLeft }) =>
    drawBorderLeft &&
    css`
      &:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #efefef;
        position: absolute;
        top: 0;
        left: -14px;
      }
    `}
`;
