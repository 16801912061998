// @flow
import styled from 'styled-components';
import { StyledInput } from '_common/components/Input/elements';

export const WebsiteWrapper = styled.div`
  display: flex;
  align-items: center;
  ${StyledInput} {
    @media only screen and (max-width: 1440px) {
      width: ${({ width = 300 }) => `${width}px !important`};
    }
  }
  & .ant-form-item {
    margin-bottom: 0;
    & .ant-form-explain {
      max-width: 300px;
    }
  }
`;
