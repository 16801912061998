// @flow

import React, { Component } from 'react';
import { UIStore } from 'stores';
import Logo from '../Logo/index';
import { Wrapper, ErrorMessage } from './elements';
import { inject, observer } from 'mobx-react';
import UserProfileTab from '_common/components/UserProfileTab';
import commonActions from '_common/actions';

type Props = {
  uiStore: UIStore,
};

@inject('uiStore')
@observer
class Header extends Component<Props, {}> {

  getUserProfileTab = () => {
    const {
      uiStore: { editingCompanyByAdmin },
    } = this.props;
    if (editingCompanyByAdmin || !commonActions.getIsAdminUser().isAdmin) {
      return <UserProfileTab />;
    }
  };

  render() {
    const {
      uiStore: { applicationErrorMessage },
    } = this.props;

    return (
      <Wrapper>
        <Logo />
        {applicationErrorMessage && (
          <ErrorMessage>{applicationErrorMessage}</ErrorMessage>
        )}
        {this.getUserProfileTab()}
      </Wrapper>
    );
  }

}

export default Header;
