// @flow

import { Input } from '_common/components';
import { Form } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  LoginInputLabel,
  LoginTitle,
  LoginButtonLarge,
  Wrapper,
  ResetPasswordContent,
} from '../elements/Login';
import { Description } from '../elements/ForgotPassword';
import { FormContainerFlexed } from 'login/pages/elements/Login';
import { FormComponentProps } from 'antd/lib/form';
import LoginCard from 'login/pages/components/LoginCard';
import merchantActions from 'merchants/actions';
import MerchantsStore from 'merchants/stores/merchantsStore';
import commonActions from '_common/actions';
import { validateOnSpecialCharacters } from '_common/utils';

type State = {
  isPasswordChangeRequested: boolean,
};

type Props = FormComponentProps & {
  merchantStore: MerchantsStore,
};

@inject('merchantStore')
@observer
class ForgotPassword extends Component<Props, State> {

  state = {
    isPasswordChangeRequested: false,
  };

  async componentDidMount() {
    await commonActions.appLogin();
  }

  /**
   * Handle resetting password.
   */
  submit = (e: Event) => {
    e.preventDefault();
    const {
      form: { validateFields, setFields },
      match: {
        params: { company },
      },
    } = this.props;

    validateFields(async (err, { email }) => {
      if (!err) {
        merchantActions
          .sendEmailWithPasswordResetToken(company, email)
          .then(() => {
            this.setState({ isPasswordChangeRequested: true });
          })
          .catch(error => {
            setFields({
              email: {
                value: email,
                errors: [new Error(error)],
              },
            });
          });
      }
    });
  };

  renderLoginForm = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <FormContainerFlexed>
        <LoginTitle align="left">Forgot password</LoginTitle>
        <Description>
          Enter your email address to reset your password. We'll send you a
          re-set link
        </Description>
        <Form onSubmit={this.submit}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: 'Please enter a valid email address. ',
                },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <div>
                <LoginInputLabel htmlFor="emailId">Email</LoginInputLabel>
                <Input
                  type="text"
                  id="emailId"
                  placeholder="Enter your email"
                />
              </div>
            )}
          </Form.Item>
          <LoginButtonLarge htmlType="submit">
            Request new password
          </LoginButtonLarge>
        </Form>
      </FormContainerFlexed>
    );
  };

  render() {
    const { isPasswordChangeRequested } = this.state;
    return (
      <Wrapper>
        {isPasswordChangeRequested ? (
          <LoginCard>
            <ResetPasswordContent>
              Password change requested.
            </ResetPasswordContent>
            <ResetPasswordContent>
              You will receive an email if account exists.
            </ResetPasswordContent>
          </LoginCard>
        ) : (
          this.renderLoginForm()
        )}
      </Wrapper>
    );
  }

}

export default compose(Form.create())(ForgotPassword);
