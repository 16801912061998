import styled from 'styled-components';

export const SelectButton = styled.button`
  width: 160px;
  height: 42px;
  border-radius: 21px;
  border: solid 1px #979797;
  background-color: transparent;
  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;
  color: #222222;
  cursor: pointer;
  align-self: center;
  outline: none;
`;

export const UploadFileWrapper = styled.div`
  align-self: center;
  position: relative;
  display: flex;

  & input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  transition: opacity 0.5s ease-out;
  cursor: pointer;

  &:hover > button {
    cursor: pointer;
    border: 1px solid #222222;
  }
`;

export const SelectedFile = styled.div``;

export const Info = styled.div`
  position: absolute;
  bottom: -30px;
  width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  right: 0;
  text-align: right;
`;

export const ErrorMessage = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 11px;
  color: #ff3654;
`;
