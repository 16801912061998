import styled, { css } from 'styled-components';

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.51);

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const Card = styled.div`
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #979797;
  background-color: #f4f4f5;

  min-width: 411px;
  min-height: 255px;

  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.div`
  background: url(${({ icon }) => icon}) no-repeat center;
  min-width: 50px;
  min-height: 50px;
  margin-bottom: 10px;
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  color: #222222;
`;
