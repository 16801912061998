// @flow

import stores from 'stores';
import links from '_common/routes/urls';
import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { getMerchantFromUrl } from '_common/utils';

/** Extra protection layer while accessing private route. */
const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const renderComponent = (props: any) => {
    /** token is valid. */
    if (!!stores.authStore.isTokenValid()) {
      return <Component {...props} />;
    }

    console.error('PrivateRoute :: token invalid.');

    /** Need to check for merchant name in URL, to make redirect for that route if needed. */
    const merchantName = getMerchantFromUrl();
    if (merchantName) {
      return (
        <Redirect to={links.merchantLogin.replace(':company', merchantName)} />
      );
    }
    return <Redirect to={links.root} />;
  };

  return <Route exact {...rest} render={renderComponent} />;
};

export default PrivateRoute;
