// @flow

import type { AssetManagementConfig } from '_common/services/assetManagement';
import stores from 'stores';
import { ROOT_ORG_ID } from '_common/constants/appConfig';

const updateAssetConfig = async (
  config: AssetManagementConfig,
  companyIdFromUrl: string
) => {
  let organisationId;
  const { organisationId: storedOrganisationId } = stores.authStore.loggedUser;

  /** Company id could be forced from args. */
  if (storedOrganisationId === ROOT_ORG_ID && companyIdFromUrl) {
    organisationId = companyIdFromUrl;
  } else {
    organisationId = storedOrganisationId;
  }
  return stores.assetManagementStore.updateAssetConfig(organisationId, config);
};

export default updateAssetConfig;
