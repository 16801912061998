import styled from 'styled-components';
import { ausPostLogo } from '../_assets';

export const AusPostLogo = styled.div`
  width: 287px;
  height: 40px;
  background: url(${ausPostLogo});
  background-size: cover;
  align-self: flex-end;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid #c1c1c1;
  & > .ant-row {
    width: 100%;
    margin-bottom: 0;
  }
`;
