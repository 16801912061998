//@flow

interface IUser {
  userId: string;
  organisationId?: string;
  organisationIds?: string[];
}

class UserPilotAnalytics {

  onPageChange() {
    window.userpilot && window.userpilot.reload();
  }

  identifyUser({ userId, organisationId, organisationIds }: IUser) {
    if (!window.userpilot) {
      console.error('Userpilot err:: script not found!');
      return;
    }
    if (!userId) {
      console.error('Userpilot err:: Unexpected user object!');
      return;
    }
    window.userpilot.identify(userId, {
      name: organisationId || organisationIds,
      email: userId,
    });
  }

}

export default new UserPilotAnalytics();
