//@flow
/* eslint camelcase: 0 */
import React from 'react';
import { Form } from 'antd';
import { FormWrapper, InputWrapper, LeftBorder, StyledInput } from './elements';
import { SectionsWrapper } from '../common/elements';
import {
  InputTooltip,
  Label,
  Menu,
  Switch,
  TextSection,
} from '_common/components';
import { get } from 'lodash';
import {
  extractError,
  validateFieldName,
  validateOnSpecialCharacters,
  validateRegex,
} from '_common/utils';
import {
  WhiteLabelConstants,
  WhiteLabelUi,
  withWhitelabelProps,
} from '_common/whitelabelConfig';
import {
  Content,
  HeaderAbsoluteErrorMessage,
  Page,
  PageHeader,
  PageTitle,
  Panel,
  PanelIcon,
  PanelSectionNoWrap,
} from '_common/components/CommonStyledElements';
import {
  calendarLightIcon,
  phoneIcon,
  shoppingCartIcon,
  structureIcon,
  termsIcon,
} from 'assets';
import { compose } from 'recompose';
import { Prompt } from 'react-router';
import { IS_DEVELOPMENT } from '_common/constants/appConfig';
import { inject, observer } from 'mobx-react';
import { CompanyModelStore, PublishStore, UIStore } from 'stores';
import Amplitude from '_common/utils/amplitude';
import commonActions from '_common/actions';
import { addRequiredFieldsForPatch } from '_common/utils/directoryUtils';
import { TermsInput } from 'settings/branding/components/elements';
import { extractFieldValue } from '_common/utils/companyUtils';
import { TrackingBlocks } from '_common/stores/uiStore';
import { TrackableChanges } from '_common/stores/companyModelStore';

const { PRODUCT_NAME } = WhiteLabelConstants;
export const RETURNS_FORM_FIELD_NAMES = {
  PURCHASE_DATE_VALIDATION_SWITCH: `products.${PRODUCT_NAME}.returnFormFields.purchaseDate`,
  PURCHASE_DATE_WITHIN_POLICY: `products.${PRODUCT_NAME}.purchaseWarrantyPeriodDays`,
  ORDER_ID_REGEX: `products.${PRODUCT_NAME}.returnReasons.validationRegex`,
  RAN_REGEX: `products.${PRODUCT_NAME}.returnReasons.RANValidationRegex`,
  RAN: `products.${PRODUCT_NAME}.returnFormFields.RAN`,
  RAN_EDIT_HEADER: `products.${PRODUCT_NAME}.returnFormFields.RANFieldName`,
  REASON_COMMENTS: `products.${PRODUCT_NAME}.returnFormFields.reasonComments`,
  REASON_COMMENTS_FIELD_NAME: `products.${PRODUCT_NAME}.returnFormFields.reasonCommentsFieldName`,
  REASON_COMMENTS_MANDATORY: `products.${PRODUCT_NAME}.returnFormFields.reasonCommentsMandatory`,
  PHONE_NUMBER: `products.${PRODUCT_NAME}.returnFormFields.phoneNumber`,
  IS_INTEGRATED: `products.${PRODUCT_NAME}.isIntegratedJourney`,
  ORDER_ID_SWITCH: `products.${PRODUCT_NAME}.returnFormFields.orderId`,
  ORDER_ON_LABEL: `products.${PRODUCT_NAME}.showOrderIdOnLabel`,
  TERMS_AND_CONDITIONS: `products.${PRODUCT_NAME}.termsAndConditionsUrl`,
};

const rmaRule = {
  validator: (rule: any, value: string, callback: (error?: Error) => void) => {
    if (/^\d+$/.test(value)) {
      return callback();
    }
    const error = new Error('Please enter a number of days in the box above');
    callback(error);
  },
};

const renderElementWithTooltip = element => (
  <InputTooltip
    trigger={['focus', 'hover']}
    title={
      'A regular expression or regex is a sequence of characters that define a search pattern. Speak to your technical team to get your regex.'
    }
    placement={'left'}
    beforeInput
    drawBorderLeft
  >
    {element}
  </InputTooltip>
);

const renderElementWithBorderLeft = (element, marginRight) => (
  <>
    <LeftBorder marginRight={marginRight} />
    {element}
  </>
);

type Props = {
  form: Object,
  companyModelStore: CompanyModelStore,
  publishStore: PublishStore,
  uiStore: UIStore,
};

@inject('companyModelStore', 'uiStore', 'publishStore')
@observer
class ReturnPolicies extends React.Component<Props> {

  static AMPLITUDE_CONFIG = [
    {
      amplitudeName: 'Purchase date',
      fields: [
        RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_VALIDATION_SWITCH,
        RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_WITHIN_POLICY,
      ],
    },
    {
      amplitudeName: 'Order ID',
      fields: [
        RETURNS_FORM_FIELD_NAMES.ORDER_ID_SWITCH,
        RETURNS_FORM_FIELD_NAMES.ORDER_ID_REGEX,
        RETURNS_FORM_FIELD_NAMES.ORDER_ON_LABEL,
      ],
    },
    {
      amplitudeName: 'Return authorisation number',
      fields: [
        RETURNS_FORM_FIELD_NAMES.RAN,
        RETURNS_FORM_FIELD_NAMES.RAN_REGEX,
        RETURNS_FORM_FIELD_NAMES.RAN_EDIT_HEADER,
      ],
    },
    {
      amplitudeName: 'Phone number',
      fields: [RETURNS_FORM_FIELD_NAMES.PHONE_NUMBER],
    },
    {
      amplitudeName: 'Terms & Conditions',
      fields: [RETURNS_FORM_FIELD_NAMES.TERMS_AND_CONDITIONS],
    },
  ];

  state = {
    hasUnpublishedChanges: false,
  };

  handlePublish = async () => {
    const {
      form: { validateFieldsAndScroll },
      companyModelStore: { companyModel, companyModelUpdates },
      uiStore: { editingCompanyByAdmin },
      match: {
        params: { company },
      },
      publishStore: {
        isPublished,
        setIsLoading,
        setIsPublished,
        publishStart,
        showPublishNotification,
      },
    } = this.props;

    if (isPublished) {
      return;
    }

    validateFieldsAndScroll(async err => {
      if (err) {
        return;
      }

      publishStart();
      try {
        const { updatedConfig } = addRequiredFieldsForPatch(
          companyModel,
          companyModelUpdates
        );

        /** BE requires fields
         "required": [
         "purchaseDate",
         "phoneNumber",
         "orderId",
         "reasonComments",
         "RAN"
         ],
         */
        const oldReturnFormFields = get(
          companyModel,
          `products.${PRODUCT_NAME}.returnFormFields`
        );
        updatedConfig.products[PRODUCT_NAME].returnFormFields = {
          ...oldReturnFormFields,
          ...updatedConfig.products[PRODUCT_NAME].returnFormFields,
        };
        updatedConfig.products[PRODUCT_NAME].returnReasons = {
          enabled: get(
            companyModel,
            `products.${PRODUCT_NAME}.returnReasons.enabled`
          ), // Check that if copy-paste
          ...updatedConfig.products[PRODUCT_NAME].returnReasons,
        };

        commonActions.getChanges([TrackingBlocks.ReturnPolicies]);

        await commonActions.companyActions.updateCompany(
          editingCompanyByAdmin || company,
          updatedConfig
        );

        Amplitude.logFieldsChanges({
          pageName: 'Return policies',
          model: companyModelUpdates,
          config: ReturnPolicies.AMPLITUDE_CONFIG,
        });

        showPublishNotification('isPublished');
        setIsPublished(true);
        this.setState({ hasUnpublishedChanges: false });
      } catch (e) {
        commonActions.setApplicationErrorMessage(extractError(e));
        console.error('catch err :: handlePublish', e);
      } finally {
        setIsPublished(true);
        setIsLoading(false);
      }
    });
  };

  async componentDidMount() {
    this.props.publishStore.setCurrentPublish(this.handlePublish);

    // TODO: to generic
    Amplitude.logEvent('page_open', { page_name: 'Return policies' });
  }

  updateCompanyModel = (path, updatedValue) => {
    const sectionName = ReturnPolicies.AMPLITUDE_CONFIG.find(el =>
      el.fields.includes(path)
    );
    commonActions.trackChange(
      TrackingBlocks.ReturnPolicies,
      `${TrackableChanges.RETURN_POLICIES}${
        sectionName ? ` - ${sectionName.amplitudeName}` : ''
      }`
    );
    commonActions.companyActions.changeCompanyModel({
      path,
      singleValue: updatedValue,
    });
    this.setState({ hasUnpublishedChanges: true });
  };

  handleSwitcherChange = (path: string) => (updatedValue: boolean) => {
    this.updateCompanyModel(path, updatedValue);
  };

  handleInputChange = (path, typeToConvert) => ({ target: { value } }) => {
    if (typeToConvert) {
      value = typeToConvert(value);
    }

    if (path === RETURNS_FORM_FIELD_NAMES.TERMS_AND_CONDITIONS) {
      value = value.trim() || null;
    }
    this.updateCompanyModel(path, value);
    this.setState({ hasUnpublishedChanges: true });
  };

  handleBlur = path => ({ target: { value } }) => {
    if (!value || this.props.form.getFieldError(path)) {
      return;
    }

    const updatedValue = value.trim();
    this.props.form.setFieldsValue({ [path]: updatedValue });
    this.updateCompanyModel(path, updatedValue);
  };

  renderDateValidationGroup = () => {
    const {
      form: { getFieldDecorator },
      companyModelStore: { companyModel },
    } = this.props;

    return (
      <Form.Item>
        <InputWrapper>
          <Label
            fontType="book"
            htmlFor={RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_WITHIN_POLICY}
            paddingLeft
            fontSize={12}
          >
            Purchase date within policy (days)
          </Label>
          {renderElementWithBorderLeft(
            getFieldDecorator(
              RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_WITHIN_POLICY,
              {
                rules: [rmaRule],
                initialValue: get(
                  companyModel,
                  RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_WITHIN_POLICY
                ),
              }
            )(
              <StyledInput
                id={RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_WITHIN_POLICY}
                width={56}
                height={30}
                onChange={this.handleInputChange(
                  RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_WITHIN_POLICY,
                  Number
                )}
              />
            ),
            -20
          )}
        </InputWrapper>
      </Form.Item>
    );
  };

  renderRanGroup = () => {
    const {
      form: { getFieldDecorator },
      companyModelStore: { companyModel },
    } = this.props;

    const ranPlaceholder = get(
      WhiteLabelUi,
      'pages.paymentAndOrderDetails.ranPlaceholder'
    );
    return (
      <>
        <Form.Item>
          <InputWrapper>
            <Label
              fontType="book"
              htmlFor={RETURNS_FORM_FIELD_NAMES.RAN_REGEX}
              paddingLeft
            >
              RAN Regex
            </Label>
            {renderElementWithTooltip(
              getFieldDecorator(RETURNS_FORM_FIELD_NAMES.RAN_REGEX, {
                initialValue: get(
                  companyModel,
                  RETURNS_FORM_FIELD_NAMES.RAN_REGEX
                ),
                rules: [{ validator: validateRegex }],
              })(
                <StyledInput
                  width={120}
                  height={42}
                  id={RETURNS_FORM_FIELD_NAMES.RAN_REGEX}
                  placeholder="[\s\S]{1,50}"
                  onChange={this.handleInputChange(
                    RETURNS_FORM_FIELD_NAMES.RAN_REGEX
                  )}
                />
              )
            )}
          </InputWrapper>
        </Form.Item>
        <Form.Item>
          <InputWrapper>
            <Label
              fontType="book"
              htmlFor={RETURNS_FORM_FIELD_NAMES.RAN_EDIT_HEADER}
              paddingLeft
            >
              Edit header
            </Label>
            {renderElementWithBorderLeft(
              getFieldDecorator(RETURNS_FORM_FIELD_NAMES.RAN_EDIT_HEADER, {
                validateTrigger: 'onKeyUp',
                rules: [{ validator: validateFieldName }],
                initialValue: get(
                  companyModel,
                  RETURNS_FORM_FIELD_NAMES.RAN_EDIT_HEADER
                ),
              })(
                <StyledInput
                  width={205}
                  height={42}
                  id={RETURNS_FORM_FIELD_NAMES.RAN_EDIT_HEADER}
                  placeholderFontSize={12}
                  placeholder={ranPlaceholder}
                  onBlur={this.handleBlur(
                    RETURNS_FORM_FIELD_NAMES.RAN_EDIT_HEADER
                  )}
                  onChange={this.handleInputChange(
                    RETURNS_FORM_FIELD_NAMES.RAN_EDIT_HEADER
                  )}
                />
              )
            )}
          </InputWrapper>
        </Form.Item>
      </>
    );
  };

  renderOrderIdGroup = () => {
    const {
      form: { getFieldDecorator },
      companyModelStore: { companyModel },
    } = this.props;

    return (
      <>
        <Form.Item>
          <InputWrapper>
            <Label
              fontType="book"
              paddingLeft
              htmlFor={RETURNS_FORM_FIELD_NAMES.ORDER_ID_REGEX}
            >
              Order ID Regex
            </Label>
            {renderElementWithTooltip(
              getFieldDecorator(RETURNS_FORM_FIELD_NAMES.ORDER_ID_REGEX, {
                initialValue: get(
                  companyModel,
                  RETURNS_FORM_FIELD_NAMES.ORDER_ID_REGEX
                ),
                rules: [{ validator: validateRegex }],
              })(
                <StyledInput
                  width={120}
                  height={42}
                  id={RETURNS_FORM_FIELD_NAMES.ORDER_ID_REGEX}
                  placeholder="[\s\S]{1,50}"
                  onChange={this.handleInputChange(
                    RETURNS_FORM_FIELD_NAMES.ORDER_ID_REGEX
                  )}
                />
              )
            )}
          </InputWrapper>
        </Form.Item>
      </>
    );
  };

  renderOrderIdOnLabel = () => {
    const {
      form: { getFieldDecorator },
      companyModelStore,
    } = this.props;

    const value = extractFieldValue({
      path: RETURNS_FORM_FIELD_NAMES.ORDER_ON_LABEL,
      companyModelStore,
    });

    return (
      <Form.Item>
        {getFieldDecorator(RETURNS_FORM_FIELD_NAMES.ORDER_ON_LABEL)(
          <InputWrapper>
            <Label>Display Order ID on label</Label>
            <Switch
              withLeftBorder
              checked={value}
              onChange={this.handleSwitcherChange(
                RETURNS_FORM_FIELD_NAMES.ORDER_ON_LABEL
              )}
            />
          </InputWrapper>
        )}
      </Form.Item>
    );
  };

  get DATE_VALIDATION() {
    const {
      form: { getFieldDecorator },
      companyModelStore,
    } = this.props;
    const value = extractFieldValue({
      path: RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_VALIDATION_SWITCH,
      companyModelStore,
    });

    return (
      <PanelSectionNoWrap>
        <PanelIcon image={calendarLightIcon} width={66} height={66} />
        <TextSection title="Purchase date">
          Enable the return fields you want to show <br />
          to your consumer. Toggle them on/off
        </TextSection>
        <FormWrapper>
          <Form.Item>
            {getFieldDecorator(
              RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_VALIDATION_SWITCH
            )(
              <InputWrapper>
                <Label>Purchase date validation required</Label>
                <Switch
                  withLeftBorder
                  checked={value}
                  onChange={this.handleSwitcherChange(
                    RETURNS_FORM_FIELD_NAMES.PURCHASE_DATE_VALIDATION_SWITCH
                  )}
                />
              </InputWrapper>
            )}
          </Form.Item>
          {value && this.renderDateValidationGroup()}
        </FormWrapper>
      </PanelSectionNoWrap>
    );
  }

  get ORDER_ID() {
    const {
      form: { getFieldDecorator },
      companyModelStore,
      whiteLabeled: { showOrderIdOnLabel },
    } = this.props;

    const value = extractFieldValue({
      path: RETURNS_FORM_FIELD_NAMES.ORDER_ID_SWITCH,
      companyModelStore,
    });

    return (
      <PanelSectionNoWrap>
        <PanelIcon image={shoppingCartIcon} width={66} height={66} />
        <TextSection title="Order ID">
          Enable the return fields you want to show <br />
          to your consumer. Toggle them on/off
        </TextSection>
        <FormWrapper>
          <Form.Item>
            {getFieldDecorator(RETURNS_FORM_FIELD_NAMES.ORDER_ID_SWITCH)(
              <InputWrapper>
                <Label>Order ID</Label>
                <Switch
                  withLeftBorder
                  checked={value}
                  onChange={this.handleSwitcherChange(
                    RETURNS_FORM_FIELD_NAMES.ORDER_ID_SWITCH
                  )}
                />
              </InputWrapper>
            )}
          </Form.Item>
          {value && this.renderOrderIdGroup()}
          {showOrderIdOnLabel && this.renderOrderIdOnLabel()}
        </FormWrapper>
      </PanelSectionNoWrap>
    );
  }

  get RAN() {
    const {
      form: { getFieldDecorator },
      companyModelStore,
    } = this.props;

    const ranPlaceholder = get(
      WhiteLabelUi,
      'pages.paymentAndOrderDetails.ranPlaceholder'
    );

    const value = extractFieldValue({
      path: RETURNS_FORM_FIELD_NAMES.RAN,
      companyModelStore,
    });

    return (
      <PanelSectionNoWrap>
        <PanelIcon image={structureIcon} width={66} height={66} />
        <TextSection title="Return authorisation number">
          Enable the return fields you want to show <br />
          to your consumer. Toggle them on/off
        </TextSection>
        <FormWrapper>
          <Form.Item>
            {getFieldDecorator(RETURNS_FORM_FIELD_NAMES.RAN)(
              <InputWrapper>
                <Label>{ranPlaceholder}</Label>
                <Switch
                  withLeftBorder
                  checked={value}
                  onChange={this.handleSwitcherChange(
                    RETURNS_FORM_FIELD_NAMES.RAN
                  )}
                />
              </InputWrapper>
            )}
          </Form.Item>
          {value && this.renderRanGroup()}
        </FormWrapper>
      </PanelSectionNoWrap>
    );
  }

  get PHONE_NUMBER() {
    const {
      form: { getFieldDecorator },
      companyModelStore,
    } = this.props;

    return (
      <PanelSectionNoWrap>
        <PanelIcon image={phoneIcon} width={66} height={66} />
        <TextSection title="Phone number">
          Enable the return fields you want to show <br />
          to your consumer. Toggle them on/off
        </TextSection>
        <FormWrapper>
          <Form.Item>
            {getFieldDecorator(RETURNS_FORM_FIELD_NAMES.PHONE_NUMBER)(
              <InputWrapper marginTop={20}>
                <Label>Phone number</Label>
                <Switch
                  withLeftBorder
                  checked={extractFieldValue({
                    path: RETURNS_FORM_FIELD_NAMES.PHONE_NUMBER,
                    companyModelStore,
                  })}
                  onChange={this.handleSwitcherChange(
                    RETURNS_FORM_FIELD_NAMES.PHONE_NUMBER
                  )}
                />
              </InputWrapper>
            )}
          </Form.Item>
        </FormWrapper>
      </PanelSectionNoWrap>
    );
  }

  get TERMS_AND_CONDITIONS() {
    const {
      form: { getFieldDecorator },
      companyModelStore,
    } = this.props;

    return (
      <PanelSectionNoWrap>
        <PanelIcon image={termsIcon} width={66} height={66} />
        <TextSection title="Terms & Conditions">
          Add your Terms & conditions website URL
        </TextSection>
        <FormWrapper withoutBorder>
          <Label htmlFor={RETURNS_FORM_FIELD_NAMES.TERMS_AND_CONDITIONS}>
            Enter URL
          </Label>
          <Form.Item>
            <InputWrapper>
              {getFieldDecorator(
                RETURNS_FORM_FIELD_NAMES.TERMS_AND_CONDITIONS,
                {
                  initialValue: extractFieldValue({
                    path: RETURNS_FORM_FIELD_NAMES.TERMS_AND_CONDITIONS,
                    companyModelStore,
                  }),
                  rules: [
                    {
                      validator: (
                        rule: any,
                        value: string,
                        callback: (error?: Error) => void
                      ) => {
                        validateOnSpecialCharacters(rule, value, callback);
                      },
                    },
                  ],
                }
              )(
                <TermsInput
                  id={'termsAndConditionsUrl'}
                  placeholder="Enter URL to your terms and conditions here"
                  onBlur={this.handleBlur(
                    RETURNS_FORM_FIELD_NAMES.TERMS_AND_CONDITIONS
                  )}
                  onChange={this.handleInputChange(
                    RETURNS_FORM_FIELD_NAMES.TERMS_AND_CONDITIONS
                  )}
                />
              )}
            </InputWrapper>
          </Form.Item>
        </FormWrapper>
      </PanelSectionNoWrap>
    );
  }

  renderFormFields = fieldsOrder =>
    Array.isArray(fieldsOrder)
      ? fieldsOrder.map(name => {
          if (!this[name]) {
            console.error(
              'Payment & Order Details page. Invalid form field name provided!'
            );
            return null;
          } else
            return <React.Fragment key={name}>{this[name]}</React.Fragment>;
        })
      : null;

  render() {
    const { hasUnpublishedChanges } = this.state;
    const {
      uiStore: { editingCompanyByAdmin },
      whiteLabeled: { getFormFields },
      companyModelStore: { companyModel },
    } = this.props;

    const isIntegrated = get(
      companyModel,
      RETURNS_FORM_FIELD_NAMES.IS_INTEGRATED
    ); // Prone number is different on int and non-int.

    return (
      <Form>
        <Page>
          <Prompt
            when={!IS_DEVELOPMENT && hasUnpublishedChanges}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          {hasUnpublishedChanges && (
            <HeaderAbsoluteErrorMessage largeOffsetRight>
              Form has unpublished changes
            </HeaderAbsoluteErrorMessage>
          )}
          <Menu />
          <Content editingCompanyByAdmin={editingCompanyByAdmin}>
            <PageHeader column>
              <PageTitle>Return policies</PageTitle>
            </PageHeader>
            <Panel>
              <SectionsWrapper>
                {this.renderFormFields(getFormFields(isIntegrated))}
              </SectionsWrapper>
            </Panel>
          </Content>
        </Page>
      </Form>
    );
  }

}

export default compose(
  Form.create({}),
  withWhitelabelProps({
    getFormFields: 'ui.pages.returnsPolicies.getFormFields',
    showOrderIdOnLabel: 'ui.pages.returnsPolicies.orderIdOnLabelEnabled',
  })
)(ReturnPolicies);
