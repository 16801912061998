import { pick, get, includes, merge, isEmpty, omit } from 'lodash';
import { sanitizeWithRule } from '_common/utils/sanitize';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import { getValidationRegex } from './orderDetailsUtils';

// it's a getter, because of circular deps issue
const getPublishableFields = () => ({
  CONSUMER_URL_LIVE: `products.${
    WhiteLabelConstants.PRODUCT_NAME
  }.consumerUrlLive`,
  SPEED_TYPE: `returns.returnsSpeedType`,
});

/**
 * Add required pros for Companies API. Merchant Card.
 * @param originalConfig
 * @param updates
 */
export const addRequiredFieldsForPatch = (originalConfig, updates) => {
  const updatedConfig = merge(
    pick(
      originalConfig,
      `products.${WhiteLabelConstants.PRODUCT_NAME}.enabled`
    ),
    pick(
      originalConfig,
      `products.${WhiteLabelConstants.PRODUCT_NAME}.customerPayments.isEnabled`
    ),
    sanitizeWithRule(updates)
  );

  return { originalConfig, updatedConfig };
};

/** method for removing publishable-only fields from auto-save request */
export const addRequiredFieldsForAutoSave = (config, updates) => {
  const publishableOnly = Object.values(getPublishableFields());

  const { originalConfig, updatedConfig } = addRequiredFieldsForPatch(
    config,
    updates
  );

  const filtered = publishableOnly.reduce(
    (config, path) => omit(config, path),
    updatedConfig
  );

  return { originalConfig, updatedConfig: filtered };
};

/**
 * Some data from model for Select elements MUST to converted to string.
 */
export const extractValueFromModel = ({ model, fieldName, toStringFields }) => {
  const value = get(model, fieldName);
  if (!value) {
    return value;
  }

  return includes(toStringFields, fieldName) ? `${value}` : value;
};

/**
 * For some resources, like address FE need to send ALL model, even if was change only one property from all set.
 */
export const mergeWithRequiredModel = (originalConfig, updatedConfig) => {
  const updatedAddress = get(updatedConfig, `returns.routings[0].address`);
  if (!isEmpty(updatedAddress)) {
    updatedConfig.returns.routings[0] = {
      address: {
        ...originalConfig.returns.routings[0].address,
        ...updatedAddress,
      },
    };
  }

  return updatedConfig;
};
export const mergeWithDefaults = config => {
  const returnReasons = get(
    config,
    `products.${WhiteLabelConstants.PRODUCT_NAME}.returnReasons`
  );

  if (!returnReasons) return config;

  const updatedReasons = {
    ...returnReasons,
    validationRegex: getValidationRegex(
      'validationRegex',
      returnReasons.validationRegex
    ),
    RANValidationRegex: getValidationRegex(
      'RANValidationRegex',
      returnReasons.RANValidationRegex
    ),
  };

  config.products[
    `${WhiteLabelConstants.PRODUCT_NAME}`
  ].returnReasons = updatedReasons;
  return config;
};
