// @flow

import { action, observable } from 'mobx';
import emailService from '_common/services/emailService';
import { getEmailChangesEmail } from '_common/utils';
import moment from 'moment-timezone';
import { NOTIFICATIONS_DISPLAY_TIME } from '_common/constants/timeout';
import {
  CHANGES_TRACKING_EMAIL_ADDRESS,
  ERROR_LOG_LEVEL,
} from '_common/constants/appConfig';
import { ERROR_LOG_LEVELS } from '_common/constants/common';
import { DEFAULT_ERROR_MESSAGE } from '_common/constants/apiErrorResponces';
import { includes } from 'lodash';
import storage, { IS_EDIT_MODE } from 'storage';

export const TrackingBlocks = {
  ReturnDetails: 'ReturnDetails',
  Branding: 'Branding',
  PaymentAndReasonCodes: 'PaymentAndReasonCodes',
  ReturnPolicies: 'ReturnPolicies',
};

const Translations = {
  companyName: 'Company trading name',
  website: 'Website',
  phoneNumber: 'Phone number',
  returnsAddress: 'Returns address',
  paymentType: 'Consumer payment',
  speedOption: 'Select product',
  returnCompanyName: 'Return company name',
  departmentName: 'Department Name and/or PO Box Number',
  line1: 'Street address', // USPS not under tracking now, can skip white-labeling configs for address
  town: 'Suburb',
  area: 'State',
  postcode: 'Postcode',
};

const translate = (name: string) => Translations[name] || name;
const filterOutForbiddenChanges = (name: string) =>
  !includes(['mainInput'], name);

class UIStore {

  @observable
  hasUnsavedChanges: boolean = false;

  @observable
  applicationErrorMessage: string | null = '';

  @observable
  applicationNotification: string | null = '';

  /** Based on this we can suggest, is it a Editing Mode fo
r Admin. */
  @observable
  editingCompanyByAdmin: string | null = null;

  @observable
  editingCompanyNameByAdmin: string | null = null;

  @observable
  isEditingModeRecoveredFromStorage: boolean = storage.get(IS_EDIT_MODE);

  @observable
  googlePlacesMainInput: string = ''; // TODO: rempve a

  constructor() {
    if (this.isEditingModeRecoveredFromStorage) {
      /** Remove editing flag after init */
      storage.set(IS_EDIT_MODE, false);
    }
  }

  getStateForDebug = () => ({
    hasUnsavedChanges: this.hasUnsavedChanges,
    applicationErrorMessage: this.applicationErrorMessage,
    applicationNotification: this.applicationNotification,
    editingCompanyByAdmin: this.editingCompanyByAdmin,
    isEditingModeRecoveredFromStorage: this.isEditingModeRecoveredFromStorage,
  });

  hashOfChanges = Object.keys(TrackingBlocks).reduce((acc, cur) => {
    acc[cur] = {};
    return acc;
  }, {});

  @action
  setHasUnsavedChanges = (flag: boolean) => {
    this.hasUnsavedChanges = flag;
  };

  trackChange = (
    trackingBlock: TrackingBlocks,
    changeName: string,
    oldValue: string,
    newValue: string
  ) => {
    changeName = changeName.split('.').pop();
    if (!this.hashOfChanges[trackingBlock][changeName]) {
      this.hashOfChanges[trackingBlock][changeName] = {
        oldValue,
        newValue,
      };
    } else {
      this.hashOfChanges[trackingBlock][changeName].newValue = newValue;
    }
  };

  getChanges = async (
    blocks: string[],
    retailerName: string,
    loggedMerchantEmail: string
  ) => {
    let listOfChanges = [];
    blocks.forEach(block => {
      listOfChanges = [
        ...listOfChanges,
        ...Object.keys(this.hashOfChanges[block])
          .filter(filterOutForbiddenChanges)
          .map(translate),
      ];
      this.hashOfChanges[block] = {};
    });

    if (listOfChanges.length > 0) {
      await emailService.sendEmail(
        getEmailChangesEmail(
          retailerName,
          CHANGES_TRACKING_EMAIL_ADDRESS,
          loggedMerchantEmail,
          moment().format('hh:mm A'),
          moment.tz.guess(),
          listOfChanges
        )
      );
    }
  };

  @action
  setApplicationErrorMessage = (message: string = null) => {
    /** For prod env we need to show 'generic' message */
    if (message && ERROR_LOG_LEVEL === ERROR_LOG_LEVELS.PROD) {
      message = DEFAULT_ERROR_MESSAGE;
    }

    this.applicationErrorMessage = message;
    if (message) {
      setTimeout(() => {
        this.setApplicationErrorMessage();
      }, NOTIFICATIONS_DISPLAY_TIME * 3);
    }
  };

  @action
  setApplicationNotification = (message: string = null) => {
    this.applicationNotification = message;
    if (message) {
      setTimeout(() => {
        this.setApplicationNotification();
      }, NOTIFICATIONS_DISPLAY_TIME * 3);
    }
  };

  @action
  setEditingCompanyByAdmin = (companyId?: string, companyName?: string) => {
    this.editingCompanyByAdmin = companyId || null;
    this.editingCompanyNameByAdmin = companyName || null;
    storage.set(IS_EDIT_MODE, !!companyId);
  };

  @action
  setGooglePlacesMainInput = address => {
    this.googlePlacesMainInput = address;
  };

}

export default UIStore;
