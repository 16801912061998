// @flow
/* eslint camelcase: 0 */
import React, { Component } from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Script from 'react-load-script';

import { Label, Input, TextSection } from '_common/components';
import {
  DetailsPanelSection,
  PanelIcon,
} from '_common/components/CommonStyledElements';
import { returnAddressIcon } from 'assets';
import commonActions from '_common/actions';
import { FIELD_NAMES } from '../../_constants';
import { requiredFieldRule } from '_common/utils/formUtils';
import {
  validateOnSpecialCharacters,
  mapFrontAddressToBack,
} from '_common/utils';
import { convertToBackendModel, recalculatePrice } from '../../_utils';
import {
  addRequiredFieldsForPatch,
  extractValueFromModel,
} from '_common/utils/directoryUtils';
import Amplitude from '_common/utils/amplitude';

import { InputRow } from '_common/components/GooglePlacesAutocomplete/elements';
import { ManualErrors } from '_common/constants/apiErrorResponces';
import {
  AddressFormWrapper,
  AddressHeaderWrapper,
  AddressFieldsWrapper,
  AddressFieldsLeft,
  AddressFieldsRight,
} from './elements';
import { CompanyModelStore } from 'stores';

const GooglePlaceTypeToModelMapping = new Map([
  ['locality', 'town'],
  ['street_number', 'line1'],
  ['route', 'line1'],
  ['administrative_area_level_1', 'area'],
  ['postal_code', 'postcode'],
]);

const GA_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const getInitState = () => ({
  line1: '',
  town: '',
  area: '',
  postcode: '',
});

type Address = {
  long_name: string,
  short_name: string,
  types: string[],
};

type Props = {
  companyModelStore: CompanyModelStore,
  errorAddressFields: Object,
  form: Object,
  onSave: () => void,
  onChange: () => void,
  companyModel: Object,
  googlePlacesMainInput: string,
};

type State = {
  isAddressSelected: boolean,
};

@observer
class ReturnAddress extends Component<Props, State> {

  /** Contains array on nested forms. */
  controlledForms = [];

  state = {
    isAddressSelected: false,
  };

  handleScriptLoad = () => {
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(`mainInput`),
      { types: ['geocode'], componentRestrictions: { country: ['au'] } }
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(['address_component', 'formatted_address']);
    this.autocomplete.setComponentRestrictions({ country: ['au'] });

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  };

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  geoLocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        this.autocomplete && this.autocomplete.setBounds(circle.getBounds());
      });
    }
  };

  handlePlaceChanged = async () => {
    const {
      form: { validateFieldsAndScroll, setFields },
    } = this.props;
    const {
      address_components,
      formatted_address,
    } = this.autocomplete.getPlace();

    if (!address_components) {
      console.error('Cannot get google addresses', formatted_address);
      return;
    }

    /**  It's hack to fill input controlled by Google Autocomplete */
    this.setState({ isAddressSelected: true });

    setFields({ mainInput: { value: formatted_address } });
    commonActions.setGooglePlacesMainInput(formatted_address);

    const form = {
      ...getInitState(),
      ...address_components.reduce(
        (acc, { long_name, types, short_name }: Address) => {
          if (!types.length) {
            return acc;
          }

          const type = types[0];
          if (!GooglePlaceTypeToModelMapping.has(type)) {
            return acc;
          }
          const fieldName = GooglePlaceTypeToModelMapping.get(type);
          const fieldValue = (fieldName === 'area'
            ? short_name
            : long_name
          ).trim();
          acc[fieldName] = acc[fieldName]
            ? (acc[fieldName] += ` ${fieldValue}`)
            : fieldValue;
          return acc;
        },
        {}
      ),
    };

    const { town: city, area: state, postcode, line1 } = form;
    if (!city || !state || !postcode) {
      return this.setAddressValidationError();
    }

    /** Need to update fields via setFields to allow validation get new values */
    setFields({
      [FIELD_NAMES.POSTAL_TOWN]: { value: city },
      [FIELD_NAMES.STATE]: { value: state },
      [FIELD_NAMES.POSTAL_CODE]: { value: postcode },
      [FIELD_NAMES.STREET_WITH_NUMBER]: { value: line1 },
    });

    commonActions.setMerchantDetails({
      returns: {
        routings: [{ address: form }],
      },
    });

    validateFieldsAndScroll({ scroll: { offsetBottom: 100 } }, async err => {
      if (err) {
        return;
      }

      let requestValid = true;

      try {
        await commonActions.validateAddress(
          mapFrontAddressToBack({ city, state, postcode })
        );
        commonActions.setMerchantDetails({
          returns: {
            routings: [{ address: form }],
          },
        });
      } catch (error) {
        requestValid = false;
        if (error === ManualErrors.ADDRESS_NOT_VALID) {
          return this.setAddressValidationError();
        } else {
          console.error('CompanyDetailsForm::handlePlaceChanged::', error);
        }
      } finally {
        if (requestValid) {
          this.handleSave();
        }
      }
    });
  };

  setAddressValidationError = () => {
    this.props.form.setFields({
      mainInput: {
        value: this.props.form.getFieldValue('mainInput'),
        errors: [new Error('Only AU address is supported')],
      },
    });
  };

  handleSave = () => {
    const {
      form: { validateFieldsAndScroll },
      companyModelStore: { companyModel, companyModelUpdates },
    } = this.props;

    /** force validation in all nested forms. */
    let isControlledFormsValid = true;
    this.controlledForms.map(form =>
      form.validateFields(err => {
        if (err) {
          isControlledFormsValid = false;
        }
      })
    );

    validateFieldsAndScroll({ scroll: { offsetBottom: 100 } }, err => {
      if (!isControlledFormsValid || err || isEmpty(companyModelUpdates)) {
        return;
      }

      this.props.onSave(
        convertToBackendModel(
          recalculatePrice(
            addRequiredFieldsForPatch(companyModel, companyModelUpdates)
          )
        )
      );
    });
  };

  onBlurHandler = (field_name: string) => () => {
    //TODO amplitude event name
    Amplitude.logEvent('field_change', {
      page_name: 'Return details',
      field_name,
    });
    this.handleSave();
  };

  render() {
    const {
      form: { getFieldDecorator },
      errorAddressFields: {
        city: cityError,
        state: stateError,
        postcode: postcodeError,
      },
    } = this.props;
    const { isAddressSelected } = this.state;
    const { isMerchant } = commonActions.getIsMerchantUser();
    const isAddressFieldsRequired = isAddressSelected || isMerchant;
    return (
      <>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${GA_KEY}&libraries=places`}
          onLoad={this.handleScriptLoad}
        />
        <DetailsPanelSection marginBottom={30}>
          <AddressHeaderWrapper>
            <PanelIcon image={returnAddressIcon} width={72} height={66} />
            <TextSection title="Returns Address*">
              This is where all items will be returned to and will appear on the
              consumers return label.
            </TextSection>
          </AddressHeaderWrapper>
          <AddressFieldsWrapper>
            <AddressFieldsLeft>
              <Form.Item>
                <div>
                  <Label htmlFor={FIELD_NAMES.RETURN_COMPANY_NAME}>
                    Return company name*
                  </Label>
                  {getFieldDecorator(FIELD_NAMES.RETURN_COMPANY_NAME, {
                    validateTrigger: 'onBlur',
                    rules: [
                      requiredFieldRule,
                      {
                        validator: (
                          rule: any,
                          value: string,
                          callback: (error?: Error) => void
                        ) => {
                          validateOnSpecialCharacters(rule, value, callback);
                        },
                      },
                    ],
                  })(
                    <Input
                      style={{ width: 360 }}
                      onBlur={this.onBlurHandler('Return company name')}
                      id={FIELD_NAMES.RETURN_COMPANY_NAME}
                      placeholder="Return Company Name*"
                    />
                  )}
                </div>
              </Form.Item>
              <Form.Item>
                <div>
                  <Label htmlFor={FIELD_NAMES.DEPARTMENT_NAME}>
                    Department Name and/or PO Box Number
                  </Label>
                  {getFieldDecorator(FIELD_NAMES.DEPARTMENT_NAME, {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        validator: (
                          rule: any,
                          value: string,
                          callback: (error?: Error) => void
                        ) => {
                          validateOnSpecialCharacters(rule, value, callback);
                        },
                      },
                    ],
                  })(
                    <Input
                      placeholderFontSize={12}
                      style={{ width: 360 }}
                      onBlur={this.onBlurHandler(
                        'Department Name and/or PO Box Number'
                      )}
                      id={FIELD_NAMES.DEPARTMENT_NAME}
                      placeholder={'Department Name and/or PO Box Number'}
                    />
                  )}
                </div>
              </Form.Item>
              <Form.Item>
                <Label>
                  Search for or edit your returns address here
                  {isAddressFieldsRequired ? '*' : ''}
                </Label>
                {getFieldDecorator('mainInput', {
                  rules: [
                    {
                      validator: (
                        rule: any,
                        value: string,
                        callback: (error?: Error) => void
                      ) => {
                        validateOnSpecialCharacters(rule, value, callback);
                      },
                    },
                  ],
                })(
                  <Input
                    style={{ width: 360 }}
                    onFocus={this.geoLocate}
                    placeholder={'Start typing an address'}
                  />
                )}
              </Form.Item>
            </AddressFieldsLeft>
            <AddressFieldsRight paddingTop={20}>
              <AddressFormWrapper
                isAddressSelected={!isAddressSelected}
                title={
                  isAddressSelected
                    ? undefined
                    : 'Search for or edit your returns address here*'
                }
              >
                <Form.Item>
                  <div>
                    <Label htmlFor={FIELD_NAMES.STREET_WITH_NUMBER}>
                      Street address*
                    </Label>
                    {getFieldDecorator(FIELD_NAMES.STREET_WITH_NUMBER, {
                      validateTrigger: 'onBlur',
                      rules: [
                        isAddressFieldsRequired && requiredFieldRule,
                        {
                          validator: (
                            rule: any,
                            value: string,
                            callback: (error?: Error) => void
                          ) => {
                            validateOnSpecialCharacters(rule, value, callback);
                          },
                        },
                      ],
                    })(
                      <Input
                        readOnly={!isAddressSelected}
                        style={{ width: 360 }}
                        onBlur={
                          isAddressSelected &&
                          this.onBlurHandler('Street address')
                        }
                        id={FIELD_NAMES.STREET_WITH_NUMBER}
                        placeholder={'Street address with number'}
                      />
                    )}
                  </div>
                </Form.Item>
                <Form.Item>
                  <div>
                    <Label htmlFor={FIELD_NAMES.POSTAL_TOWN}>Suburb*</Label>
                    {getFieldDecorator(FIELD_NAMES.POSTAL_TOWN, {
                      validateTrigger: 'onBlur',
                      rules: [
                        isAddressFieldsRequired && requiredFieldRule,
                        {
                          validator: (
                            rule: any,
                            value: string,
                            callback: (error?: Error) => void
                          ) => {
                            validateOnSpecialCharacters(rule, value, callback);
                          },
                        },
                      ],
                    })(
                      <Input
                        readOnly={!isAddressSelected}
                        style={{ width: 360 }}
                        onBlur={
                          isAddressSelected && this.onBlurHandler('Suburb')
                        }
                        id={FIELD_NAMES.POSTAL_TOWN}
                        placeholder={'Suburb'}
                        className={cityError ? 'field-error' : null}
                      />
                    )}
                  </div>
                </Form.Item>
                <InputRow maxWidth={360}>
                  <Form.Item>
                    <div>
                      <Label htmlFor={FIELD_NAMES.POSTAL_TOWN}>State*</Label>
                      {getFieldDecorator(FIELD_NAMES.STATE, {
                        validateTrigger: 'onBlur',
                        rules: [
                          isAddressFieldsRequired && {
                            ...requiredFieldRule,
                            pattern: /^[A-Z]*$/,
                            message: 'Invalid state.',
                          },
                          {
                            validator: (
                              rule: any,
                              value: string,
                              callback: (error?: Error) => void
                            ) => {
                              validateOnSpecialCharacters(
                                rule,
                                value,
                                callback
                              );
                            },
                          },
                        ],
                      })(
                        <Input
                          readOnly={!isAddressSelected}
                          style={{ width: 146 }}
                          maxLength={3}
                          onBlur={
                            isAddressSelected && this.onBlurHandler('State')
                          }
                          id={FIELD_NAMES.STATE}
                          placeholder={'State'}
                          className={stateError ? 'field-error' : null}
                        />
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div>
                      <Label htmlFor={FIELD_NAMES.POSTAL_TOWN}>Postcode*</Label>
                      {getFieldDecorator(FIELD_NAMES.POSTAL_CODE, {
                        validateTrigger: 'onBlur',
                        rules: [
                          isAddressFieldsRequired && {
                            ...requiredFieldRule,
                            pattern: /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
                            message: 'Invalid postcode.',
                          },
                          {
                            validator: (
                              rule: any,
                              value: string,
                              callback: (error?: Error) => void
                            ) => {
                              validateOnSpecialCharacters(
                                rule,
                                value,
                                callback
                              );
                            },
                          },
                        ],
                      })(
                        <Input
                          readOnly={!isAddressSelected}
                          style={{ width: 146 }}
                          maxLength={4}
                          onBlur={
                            isAddressSelected && this.onBlurHandler('Postcode')
                          }
                          id={FIELD_NAMES.POSTAL_CODE}
                          placeholder={'Postcode'}
                          className={postcodeError ? 'field-error' : null}
                        />
                      )}
                    </div>
                  </Form.Item>
                </InputRow>
              </AddressFormWrapper>
            </AddressFieldsRight>
          </AddressFieldsWrapper>
        </DetailsPanelSection>
      </>
    );
  }

}

function mapPropsToFields({ companyModel, googlePlacesMainInput }) {
  return Object.values(FIELD_NAMES).reduce(
    (acc, fieldName) => {
      acc[fieldName] = Form.createFormField({
        value: extractValueFromModel({
          model: companyModel,
          fieldName,
          toStringFields: [FIELD_NAMES.PRICING_TIER],
        }),
      });
      return acc;
    },
    { mainInput: Form.createFormField({ value: googlePlacesMainInput }) }
  );
}

export default compose(
  inject('companyModelStore'),
  Form.create({
    mapPropsToFields,
    onValuesChange(props, values) {
      props.onChange(values);
    },
  })
)(ReturnAddress);
