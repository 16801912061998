import styled from 'styled-components';

export const FlexibleTextLabel = styled.div`
  width: 308px;
  min-width: 200px;

  @media only screen and (max-width: 1440px) {
    width: 100% !important;
  }
`;
