// @flow

import React from 'react';
import { StyledSpinner } from './elements';
import { PanelLoader, PanelLoaderText } from '../CommonStyledElements';

const Spinner = ({ loaderText = 'Loading...' }) => (
  <PanelLoader>
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="3"
      />
    </StyledSpinner>
    <PanelLoaderText>{loaderText}</PanelLoaderText>
  </PanelLoader>
);

export default Spinner;
