// @flow

import commonActions from '_common/actions';
import links from '_common/routes/urls';
import { toWebSafeFormat } from '_common/utils';

export const OPTION_ALL_LOCATIONS = {
  value: 'ALL_LOCATIONS',
  label: 'All location types',
};

export const OPTION_EXTENDED_HOURS = {
  value: 'EXTENDED_HOURS',
  label: 'Extended hours',
};

export const LOCATIONS_MAPPER = {
  POST_OFFICES: 'POSTOFFICE',
  STREET_POST_BOXES: 'POSTBOX',
  EXPRESS_POSTBOX: 'EXPRESS_POSTBOX',
  OTHER_RETAILERS: 'STORE',
};

export const getAllLocationsMapper = () => {
  const activeLocationTypes = commonActions.getLocationTypes();
  const allLocations = activeLocationTypes.reduce((acc, { value }) => {
    const mappedName = LOCATIONS_MAPPER[value];
    if (!mappedName) {
      return acc;
    }
    return acc ? `${acc},${mappedName}` : mappedName;
  }, '');

  return {
    ...LOCATIONS_MAPPER,
    ALL_LOCATIONS: allLocations,
    EXTENDED_HOURS: allLocations,
  };
};

/**
 * Check if allowed to '/locate' page access.
 */
export const isAllowedToLocatePageAccess = async () => {
  const existingRoutes = Object.values(links)
    .map(path => path.replace('/:company', '').split('/')[1])
    .filter(p => p);
  const [company] = window.location.pathname.split('/').filter(p => p);
  if (company && existingRoutes.indexOf(company) === -1) {
    return toWebSafeFormat(company);
  } else {
    return false;
  }
};

export const MAX_STORES = 40;
export const MAX_DISTANCE = 50;

export const getMapVisibleDistance = (map, google) => {
  const SW = map.getBounds().getSouthWest();
  const NE = map.getBounds().getNorthEast();
  const SE = { lat: SW.lat, lng: NE.lng };
  const NW = { lat: NE.lat, lng: SW.lng };
  const geoLib = google.maps.geometry.spherical;
  const mapSideA = geoLib.computeDistanceBetween(NW, SW);
  const mapSideB = geoLib.computeDistanceBetween(SW, SE);
  const shortestMapSide = mapSideA - mapSideB < 0 ? mapSideA : mapSideB;
  const searchRadiusInKm = Math.floor(shortestMapSide / 2 / 1000);
  return searchRadiusInKm > MAX_DISTANCE ? MAX_DISTANCE : searchRadiusInKm || 1;
};

const STREET_POSTBOX_NAME = 'STREET POSTING BOX';

export const normalizeStoreName = (storeName, locationType) =>
  locationType === 'POSTBOX' || locationType === 'EXPRESS_POSTBOX'
    ? STREET_POSTBOX_NAME
    : storeName;
