import React from 'react';
import { AsyncStatus } from '_common/constants/common';

type Props = {
  src: string,
  shouldFetch: string,
  imageProp: Object,
  fallbackProp: Object,
};

export default class ImageLoader extends React.Component<Props> {

  constructor(props) {
    super(props);
    this.state = { status: props.src ? AsyncStatus.LOADING : AsyncStatus.IDLE };
  }

  componentDidMount() {
    if (this.state.status === AsyncStatus.LOADING) {
      this.createLoader();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState({
        status: nextProps.src ? AsyncStatus.LOADING : AsyncStatus.IDLE,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.status === AsyncStatus.LOADING && !this.img) {
      this.createLoader();
    }
  }

  componentWillUnmount() {
    this.destroyLoader();
  }

  createLoader = () => {
    const { src, shouldFetch } = this.props;
    if (!src || !shouldFetch) {
      return;
    }

    this.destroyLoader(); // We can only have one loader at a time.

    const img = new Image();
    img.onload = this.handleLoad;
    img.onerror = this.handleError;
    img.src = src;
    img.srcset = src;
    this.img = img;
  };

  destroyLoader = () => {
    if (this.img) {
      this.img.onload = null;
      this.img.onerror = null;
      this.img = null;
    }
  };

  handleLoad = () => {
    this.destroyLoader();
    this.setState({ status: AsyncStatus.LOADED });
  };

  handleError = () => {
    this.destroyLoader();
    this.setState({ status: AsyncStatus.FAILED });
  };

  render() {
    const { src, srcSet, imageProp, fallbackProp } = this.props;
    const { status } = this.state;

    switch (status) {
      case AsyncStatus.LOADED:
        return imageProp(src, srcSet);
      case AsyncStatus.FAILED:
        return fallbackProp;
      default:
        return null;
    }
  }

}
