// @flow
import React from 'react';

import {
  Footer,
  Link,
  LinkSection,
  SocialLinkSection,
  SocialLink,
} from './elements';

class LocateFooter extends React.Component {

  render() {
    const { termsAndConditions, privacy, socialLinks } = this.props;
    return (
      <Footer>
        <LinkSection>
          <Link href={termsAndConditions.href}>{termsAndConditions.label}</Link>
          <Link href={privacy.href}>{privacy.label}</Link>
        </LinkSection>
        {socialLinks && (
          <SocialLinkSection>
            {socialLinks.map(({ href, src, alt }) => (
              <SocialLink href={href} key={alt}>
                <img src={src} alt={alt} />
              </SocialLink>
            ))}
          </SocialLinkSection>
        )}
      </Footer>
    );
  }

}

export default LocateFooter;
