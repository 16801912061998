// @flow

import * as React from 'react';
import { noop, find } from 'lodash';
import SwitchOption from './components/SwitchOption';

import { Root } from './SwitchGroupElements';
import Input from '_common/components/Input';
import {
  SwitchInputGroup,
  SwitchInputLabel,
} from '_common/components/SwitchGroup/SwitchGroupElements';
import { InputTooltip } from '_common/components/index';
import { Form } from 'antd';

type Option = {
  label: string,
  checked?: boolean,
  disabled?: boolean,
  type?: string,
  precondition?: string,
};

type Props = {
  options: Option[],
  width?: number,
  formModel?: Object,
  onChange: (option: Option, index: number) => void,
  onRegexChange?: (value: string) => void,
  renderMenuComponent: (option: Option, index: number) => ?React.Node,
  renderLabelComponent: (option: Option, index: number) => ?React.Node,
  disabled?: boolean,
};

type State = {};

class SwitchGroup extends React.Component<Props, State> {

  static defaultProps = {
    onChange: noop,
    renderMenuComponent: noop,
    renderLabelComponent: noop,
  };

  handleOptionChange = (checked: boolean, label: string, index: number) => {
    this.props.onChange({ label, checked }, index);
  };

  handleInputChange = (fieldName: string) => ({
    target: { value },
  }: SyntheticInputEvent<HTMLInputElement>) => {
    const { onRegexChange } = this.props;
    onRegexChange && onRegexChange(fieldName, value);
  };

  validateRegex = (
    _: any,
    validationRegex: string,
    callback: (msg?: string) => void
  ) => {
    try {
      new RegExp(validationRegex);
      return callback();
    } catch (e) {
      return callback('Invalid regex syntax');
    }
  };

  validateFieldName = (
    _: any,
    value: string,
    callback: (msg?: string) => void
  ) => {
    if (!value) {
      return callback();
    }
    if (value.length > 30) {
      return callback('Max length 30 characters');
    }
    if (!/^[a-z\s]+$/i.test(value)) {
      return callback('Only letters allowed');
    }
    return callback();
  };

  renderElementWithTooltip = element => (
    <InputTooltip
      trigger={['focus', 'hover']}
      title={
        'A regular expression or regex is a sequence of characters that define a search pattern. Speak to your technical team to get your regex.'
      }
      placement={'left'}
      beforeInput
      drawBorderLeft
    >
      {element}
    </InputTooltip>
  );

  renderOption = (option: Option, index: number) => {
    const {
      type,
      precondition,
      placeholder,
      withTooltip,
      fieldName,
      hideBorder,
      width,
    } = option;

    if (type === 'input') {
      let shouldBeVisible = true;

      if (precondition) {
        const [predicate, booleanState] = precondition;
        const dependency = find(this.props.options, { label: predicate });
        shouldBeVisible = dependency && dependency.checked === booleanState;
      }

      if (!shouldBeVisible) {
        return null;
      }

      const value = this.props.formModel[fieldName];
      const validator = withTooltip
        ? this.validateRegex
        : this.validateFieldName;

      const input = (
        <Form.Item>
          {this.props.form.getFieldDecorator(fieldName, {
            validateTrigger: 'onKeyUp',
            rules: [
              {
                validator,
              },
            ],
          })(
            <div>
              <Input
                width={width}
                id={`${fieldName}-id`}
                onChange={this.handleInputChange(fieldName)}
                placeholder={placeholder || '[\\\\s\\\\S]{1,50}'}
                value={value}
              />
            </div>
          )}
        </Form.Item>
      );

      return (
        <SwitchInputGroup key={index} hideBorder={hideBorder}>
          <SwitchInputLabel>{option.label}</SwitchInputLabel>
          {withTooltip ? this.renderElementWithTooltip(input) : input}
        </SwitchInputGroup>
      );
    }

    return (
      <SwitchOption
        key={index}
        option={option}
        index={index}
        onChange={this.handleOptionChange}
        menuComponent={this.props.renderMenuComponent(option, index)}
        labelComponent={this.props.renderLabelComponent(option, index)}
        noToggle={this.props.noToggle}
      />
    );
  };

  render() {
    const { options, width } = this.props;
    return <Root width={width}>{options.map(this.renderOption)}</Root>;
  }

}

export default SwitchGroup;
