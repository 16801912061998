import { SENTRY_DSN_URL } from '_common/constants/appConfig';
import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

export const initSentry = () => {
  if (SENTRY_DSN_URL === 'N/A') {
    console.error('Sentry is disabled for current env.');
  }
  Sentry.init({
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    dsn: SENTRY_DSN_URL,
    integrations: [
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
  });
};
