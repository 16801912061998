import links from '_common/routes/urls';

import Branding from './branding';
import Communications from './communications';
import PaymentOptionsAndReasonCodes from './paymentOptionsAndReasonCodes';
import ReturnDetails from './returnDetails';
import ReturnPolicies from './returnPolicies';

export default {
  regular: [
    {
      path: links.branding,
      component: Branding,
    },
    {
      path: links.communications,
      component: Communications,
    },
    {
      path: links.paymentOptionsAndReasonCodes,
      component: PaymentOptionsAndReasonCodes,
    },
    {
      path: links.returnDetails,
      component: ReturnDetails,
    },
    {
      path: links.returnPolicies,
      component: ReturnPolicies,
    },
  ],
  edit: [
    {
      path: links.editBranding,
      component: Branding,
    },
    {
      path: links.editCommunications,
      component: Communications,
    },
    {
      path: links.editPaymentOptionsAndReasonCodes,
      component: PaymentOptionsAndReasonCodes,
    },
    {
      path: links.editReturnDetails,
      component: ReturnDetails,
    },
    {
      path: links.editReturnPolicies,
      component: ReturnPolicies,
    },
  ],
};
