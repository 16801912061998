// @flow

import stores from 'stores';

const updateMerchantConfigAndRefresh = (
  companyId: string,
  updatedConfig: Object
) => {
  return stores.merchantStore.updateMerchantConfigAndRefresh(
    companyId,
    updatedConfig
  );
};

export default updateMerchantConfigAndRefresh;
