import AdminFieldsForm from './AdminFieldsForm';
import MerchantDetails from './MerchantDetails';
import ReturnAddress from './ReturnAddress';
import SignUpCheckboxes from './SignUpCheckboxes';

export default {
  AdminFieldsForm,
  MerchantDetails,
  ReturnAddress,
  SignUpCheckboxes,
};
