import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 64px;
  height: 64px;

  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    transform: translate(0, -50%);
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 4px;
    border: 4px solid #01adee;
    border-radius: 50%;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #01adee transparent transparent transparent;
    left: 0;
    top: 0;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  span {
    position: absolute;
    bottom: -60px;
    width: 300px;
    left: -125px;
    font-family: ${props => props.theme.fonts.basic};
    font-size: 24px;
    font-weight: bold;
    line-height: 1.75;
    text-align: center;
    color: #222222;
  }
`;
