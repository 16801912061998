// @flow

import stores from 'stores';

const confirmUpdatingPassword = (
  organisationId: string,
  token: string,
  password: string
) => {
  return stores.merchantStore.confirmUpdatingPassword(
    organisationId,
    token,
    password
  );
};

export default confirmUpdatingPassword;
