//@ flow

import React from 'react';
import { Wrapper } from './LoaderArcElements';

export default () => (
  <Wrapper>
    <div />
    <div />
    <div />
    <div />
    <span>Loading results…</span>
  </Wrapper>
);
