// @flow

import * as React from 'react';
import { StyledButton } from './elements';

class Button extends React.Component<{ children?: React.Node }, {}> {

  render() {
    return <StyledButton {...this.props}>{this.props.children}</StyledButton>;
  }

}

export default Button;
