import styled from 'styled-components';
import { questionMark } from 'assets';
import {
  PrimaryButton,
  SecondaryButton,
} from 'merchants/pages/elements/commonCard';

export const DeleteModalWrapper = styled.div`
  width: 411px;
  padding: 40px 45px;
`;

export const QuestionMarkIcon = styled.div`
  min-width: 50px;
  min-height: 50px;
  flex-shrink: 0;
  background-size: contain;
  background: url(${questionMark}) no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: ${props => props.theme.fonts.book};
  font-size: 30px;
  margin-bottom: 11px;
`;

export const Icon = styled.div`
  background: url(${({ icon }) => icon}) no-repeat center;
  min-width: 50px;
  min-height: 50px;
  margin-bottom: 10px;
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #222222;
  text-align: center;
  margin-bottom: 32px;
`;

export const DeletePrimaryButton = styled(PrimaryButton)`
  height: 40px;
  width: 240px;
`;

export const DeleteSecondaryButton = styled(SecondaryButton)`
  height: 40px;
  width: 240px;
`;
