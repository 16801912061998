// @flow

import { Select } from 'antd';
import React, { forwardRef } from 'react';
import { SelectWrapper } from './elements';

const Option = Select.Option;

type OptionProps = {
  value: string,
  label: string,
};

const CustomSelect = (props: any, ref) => {
  const options = props.options ? props.options : props.children;

  return (
    <SelectWrapper width={props.width} ref={ref}>
      <Select {...props}>
        {options.map(({ value, label, name }: OptionProps) => {
          return (
            <Option key={value} value={value} name={name}>
              {label}
            </Option>
          );
        })}
      </Select>
    </SelectWrapper>
  );
};

export default forwardRef(CustomSelect);
