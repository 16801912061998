import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { includes } from 'lodash';
import { withRouter } from 'react-router-dom';

import { AbsolutePositionPublishButton } from './elements';

@inject('publishStore')
@observer
class GlobalPublishButton extends Component {

  handlePublish = () => {
    const {
      publishStore: { activePublishMethod },
    } = this.props;
    if (typeof activePublishMethod !== 'function') return;
    activePublishMethod();
  };

  /* Conditional rendering flag, in case we don't want to render Publish button on some urls */
  isRenderingAllowed = () => {
    const {
      enabledPagesList,
      location: { pathname },
    } = this.props;
    return Array.isArray(enabledPagesList)
      ? enabledPagesList.some(path => includes(pathname, path))
      : true;
  };

  render() {
    const {
      publishStore: { isLoading, isPublished, isError },
    } = this.props;
    return (
      <>
        {this.isRenderingAllowed() && (
          <AbsolutePositionPublishButton
            disabled={isLoading}
            isPublished={isPublished}
            isError={isError}
            onClick={this.handlePublish}
          >
            {isPublished ? 'Published' : 'Publish'}
          </AbsolutePositionPublishButton>
        )}
      </>
    );
  }

}
export default withRouter(GlobalPublishButton);
