const defaultTheme = {
  fonts: {
    basicRounded: 'GothamRounded',
    lightItalicRounded: 'GothamRounded-LightItalic',
    boldRounded: 'GothamRounded-Bold',
    lightRounded: 'GothamRounded-Light',
    bookRounded: 'GothamRounded-Book',
    bookItalicRounded: 'GothamRounded-BookItalic',
    ultraRounded: 'GothamRounded-Ultra',
    medium: 'Gotham Medium',
    light: 'Gotham Light',
    book: 'Gotham Book',
    ultra: 'Gotham Ultra Italic',
  },
};

export default defaultTheme;
