// @flow

import React from 'react';
import { StyledLabel } from './elements';

class Label extends React.Component<any> {

  render() {
    return <StyledLabel {...this.props}>{this.props.children}</StyledLabel>;
  }

}

export default Label;
