// @flow
import stores from 'stores';
import { getUserRoleType } from '_common/utils';
import { ROLE_TYPES } from '_common/constants/common';

export default () => {
  const roles = stores.authStore.getUserRoles() || [];
  return {
    isAdmin: getUserRoleType(roles) === ROLE_TYPES.ADMIN,
    user: stores.authStore.loggedUser,
  };
};
