import ui from './ui';
import components from './components';
import stores from './stores';
import services from './services';
import {
  mapToChannelModelForPatch,
  getInitialAdminFieldsModel,
} from './_utils';
import { PRODUCT_NAME, IS_ENABLED_TRACK_CHANGES } from './_constants';

export default {
  ui,
  components,
  stores,
  services,
  utils: { mapToChannelModelForPatch, getInitialAdminFieldsModel },
  constants: { PRODUCT_NAME, IS_ENABLED_TRACK_CHANGES },
};
