import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;

  .ant-pagination-item {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-color: #979797;

    &:focus,
    &:hover {
      border-color: #222222;
      a {
        color: #222222;
      }
    }
  }

  .ant-pagination-item.ant-pagination-item-active {
    border-color: #222222;

    a {
      color: #222222;
    }
  }
  ${({ pagerDisabled }) =>
    pagerDisabled &&
    `
    .ant-pagination-item,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
        display: none;
    }
  `};
  & li.ant-pagination-disabled.ant-pagination-prev {
    & button {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
    }
  }
  & li.ant-pagination-disabled.ant-pagination-next {
    & button {
      ${({ hasNextPage }) =>
        !hasNextPage &&
        ` cursor: not-allowed;
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;`}
    }
  }
`;

const PaginationButtons = styled.button`
  width: 90px;
  background-color: transparent;
  border-radius: 28px;
  border: solid 1px #979797;
  cursor: pointer;
  &:hover {
    border: solid 1px #222222;
  }
  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
  }
`;

export const PrevButton = styled(PaginationButtons)``;

export const NextButton = styled(PaginationButtons)``;

export const PageInfo = styled.div`
  font-size: 10px;
  color: #131314;
  margin-top: 12px;
`;
