// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Header, Cell, Row, CellId } from './TrackingTableElements';
import type { Return } from 'tracking/services/trackingService';

type Props = {
  returns: Return[],
};
type State = {};

@observer
class TrackingTable extends Component<Props, State> {

  renderRow(row: Return, index: number) {
    return (
      <Row key={index}>
        <CellId>{row.id}</CellId>
        <Cell>{row.date}</Cell>
        <Cell>{row.time}</Cell>
        <Cell>{row.merchant}</Cell>
        <Cell>{row.status}</Cell>
      </Row>
    );
  }

  render() {
    return (
      <div>
        <Header>
          <CellId>Tracking ID</CellId>
          <Cell>Date</Cell>
          <Cell>Local time</Cell>
          <Cell>Merchant</Cell>
          <Cell>Status</Cell>
        </Header>
        {this.props.returns.map(this.renderRow)}
      </div>
    );
  }

}

export default TrackingTable;
