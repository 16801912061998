import { extractFieldValue } from '_common/utils/companyUtils';
import stores from 'stores';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import changeCompanyModel from './changeCompanyModel';
import Amplitude from '_common/utils/amplitude';
import { TrackableChanges } from '_common/stores/companyModelStore';
import commonActions from '_common/actions';
import { TrackingBlocks } from '_common/stores/uiStore';

const { PRODUCT_NAME } = WhiteLabelConstants;

const FIELDS = {
  PAYMENT_METHOD: `products.${PRODUCT_NAME}.customerPayments.paymentType`,
  RETURN_REASONS_ARRAY: `products.${PRODUCT_NAME}.returnReasons.reasons`,
};

export default paymentType => {
  Amplitude.unpublishedChanges.add(TrackableChanges.PAYMENT_METHOD);
  commonActions.trackChange(
    TrackingBlocks.PaymentAndReasonCodes,
    TrackableChanges.PAYMENT_METHOD
  );
  const getReasonStatus = reason => {
    switch (paymentType) {
      case 'MERCHANT_PAID':
        Amplitude.unpublishedChanges.add(TrackableChanges.REASON_CODES);
        commonActions.trackChange(
          TrackingBlocks.PaymentAndReasonCodes,
          TrackableChanges.REASON_CODES
        );
        return { ...reason, paymentRequired: false };
      case 'CONSUMER_PAID':
        Amplitude.unpublishedChanges.add(TrackableChanges.REASON_CODES);
        commonActions.trackChange(
          TrackingBlocks.PaymentAndReasonCodes,
          TrackableChanges.REASON_CODES
        );
        return { ...reason, paymentRequired: true };
      default:
        return reason;
    }
  };

  const reasonCodesArray = extractFieldValue({
    companyModelStore: stores.companyModelStore,
    path: FIELDS.RETURN_REASONS_ARRAY,
  }).map(getReasonStatus);

  changeCompanyModel({
    changesObject: {
      products: {
        [PRODUCT_NAME]: {
          customerPayments: {
            paymentType,
          },
          returnReasons: {
            reasons: reasonCodesArray,
          },
        },
      },
    },
  });
};
