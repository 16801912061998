import styled, { css } from 'styled-components';
import { StyledButton } from '_common/components/Button/elements';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: solid 1px #c7c7cc;
  background-color: #fefefe;
  position: relative;
  ${({ isDeclineMode }) =>
    isDeclineMode &&
    css`
      background-color: #8e8e8e;
    `};

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: ${({ basis }) => `${basis}%`};
  padding-left: 45px;
  align-items: flex-start;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`};
  @media only screen and (max-width: 1440px) {
    & > div {
      width: 100% !important;
    }
  }
`;

export const CellButtons = styled(Cell)`
  align-items: flex-end;
`;

export const CellSpaceBetween = styled(Cell)`
  justify-content: space-between;
  align-items: flex-end;
`;

export const CellTitle = styled.div`
  font-size: 14px;
  color: #282d32;
  margin-bottom: 8px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
`;

export const CellValue = styled.div`
  font-size: 13px;
  color: #282d32;
  font-family: ${props => props.theme.fonts.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
`;

export const CellBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & {
    margin-bottom: 25px;
  }
  & :last-child {
    margin-bottom: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const PrimaryButton = styled(StyledButton)`
  border-radius: 28px;
  background-color: #4a90e2;
  height: 32px;
  color: #fff;
  font-family: ${props => props.theme.fonts.book};
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 160px;
  padding: 0;

  @media only screen and (max-width: 1440px) {
    width: 100%;
    min-width: 100px;
  }

  &:hover {
    background-color: #32659e;
  }

  &:disabled {
    background-color: rgba(74, 144, 226, 0.3);
    cursor: not-allowed;
  }
`;

export const SecondaryButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${props => props.theme.fonts.book};
  font-size: 12px;

  width: 160px;
  height: 32px;
  border-radius: 28px;
  border: solid 1px #979797;

  margin-top: 10px;
  padding: 0;

  background-color: transparent;
  color: #222222;
  cursor: pointer;
  outline: none;

  @media only screen and (max-width: 1440px) {
    width: 100%;
    min-width: 100px;
  }

  &:hover {
    background-color: transparent;
    border: solid 1px #222222;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
`;

export const ResendActivationEmailButton = styled(SecondaryButton)`
  width: 100px;
  height: 32px;
  border-radius: 28px;
  border: solid 1px #979797;
  ${({ isActivationEmailResend }) =>
    isActivationEmailResend &&
    css`
      background-color: #38b13a !important;
    `};

  &:disabled {
    background-color: #d8d8d8;
    opacity: 1;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 16px;
`;
