import styled from 'styled-components';
import { StyledButton } from '_common/components/Button/elements';

export const PaymentMessage = styled.div`
  width: 100%;
  font-size: 10px;
  color: #131314;
  text-align: right;
  margin-bottom: 10px;
`;

export const ReasonCodesButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;

  width: 100px;
  height: 30px;
  border-radius: 32px;
  border: solid 1px #979797;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;

  background-color: transparent;
  color: #222222;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: transparent;
    border: solid 1px #222222;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const SaveButton = styled(StyledButton)`
  width: 80px;
  height: 30px;
  border-radius: 32px;
  background-color: #4a90e2;
  margin-right: 5px;
`;

export const Delimeter = styled.div`
  height: 25px;
  width: 1px;
  background: #efefef;
`;

export const ReasonCodeActionIconWrapper = styled.div`
  height: 30px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #c7c7cc;
  & > img {
    cursor: pointer;
  }
`;
