// @flow

import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { observer, inject } from 'mobx-react';
import { invoke } from 'lodash';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { reaction } from 'mobx';

import LocationStore from 'locate/stores/locationStore';
import type { Store } from 'locate/stores/locationStore';
import locationActions from 'locate/actions';
import SearchHeader from '../SearchHeader';
import Marker from '../Marker/Marker';
import { MapBackground } from './DesktopMapElements';
import { GOOGLE_MAP_DEFAULT_STYLES } from '_common/constants/googleMap';
import { AsyncStatus } from '_common/constants/common';
import { getMapVisibleDistance } from '../../utils/locationUtils';
import { withWhitelabelProps, WhiteLabelUi } from '_common/whitelabelConfig';

type Props = RouteComponentProps & {
  google: any,
  map?: any,
  locationStore: LocationStore,
};

const GA_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

@observer
class Contents extends Component<Props> {

  searchHeaderRef = React.createRef();
  state = {
    bounds: null,
  };
  componentDidMount() {
    this.props.locationStore.hideMapForInitialState();
    this.initAutocomplete();
    this.disposeReaction = reaction(
      () => this.props.locationStore.stores,
      () => {
        this.setBounds();
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map) {
      this.initAutocomplete();
    }
  }

  setBounds = () => {
    const {
      locationStore: {
        stores,
        isNewLocationSearch,
        mapDragActive,
        clientGeoCoordinates,
      },
      google,
    } = this.props;
    if (!isNewLocationSearch || mapDragActive) return;
    const bounds = new google.maps.LatLngBounds();
    if (!stores.length) {
      bounds.extend({
        lat: clientGeoCoordinates.lat - 0.1,
        lng: clientGeoCoordinates.lng - 0.1,
      });
      bounds.extend({
        lat: clientGeoCoordinates.lat + 0.1,
        lng: clientGeoCoordinates.lng + 0.1,
      });
    } else {
      stores.forEach(({ geo }) => {
        bounds.extend({ lat: geo.lat, lng: geo.lon });
      });
    }
    this.setState({ bounds });
  };

  onSubmit(e) {
    e.preventDefault();
  }

  initAutocomplete() {
    const { google, map, whiteLabeled: countryCode } = this.props;
    const searchHeader = this.searchHeaderRef.current;
    const searchInput = searchHeader
      ? invoke(searchHeader.wrappedInstance, 'getSearchInput')
      : null;
    if (!google || !map || !searchInput) return;

    const autocomplete = new google.maps.places.Autocomplete(searchInput, {
      types: ['geocode'],
      componentRestrictions: { country: [countryCode.countryCode] },
    });
    autocomplete.bindTo('bounds', map);
    autocomplete.setComponentRestrictions({
      country: [countryCode.countryCode],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(14);
      }

      const position = place.geometry.location;
      locationActions.setSearchGeoCoordinates({
        lat: position.lat(),
        lng: position.lng(),
      });
    });
  }

  handleDrag = async (mapProps, map) => {
    const { google } = this.props;
    const newCenter = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
    const distance = getMapVisibleDistance(map, google);
    try {
      await locationActions.searchStoresByCoords(newCenter, true, distance);
    } catch (e) {
      this.setState({ hasError: true });
    }
  };

  renderMarker = (storeData: Store) => {
    const { storeId, geo, locationType } = storeData;
    return (
      <Marker
        key={storeId}
        storeId={storeId}
        lat={geo.lat}
        lng={geo.lon}
        locationType={locationType}
        isDesktop
      />
    );
  };

  renderMap() {
    const {
      locationStore: {
        stores,
        clientGeoCoordinates,
        lastSearch,
        isMapShouldBeVisible,
      },
    } = this.props;

    const isShowMap = !!lastSearch && isMapShouldBeVisible;

    return (
      <>
        {isShowMap ? (
          <Map
            {...this.props}
            centerAroundCurrentLocation={false}
            bounds={this.state.bounds}
            containerStyle={{
              height: isShowMap ? 'calc(100vh - 270px)' : 0,
              position: 'relative',
            }}
            visible={isShowMap}
            styles={GOOGLE_MAP_DEFAULT_STYLES}
            onDragend={this.handleDrag}
          >
            <Marker
              isDefaultUser
              lat={clientGeoCoordinates.lat}
              lng={clientGeoCoordinates.lng}
            />
            {stores.map(this.renderMarker)}
          </Map>
        ) : (
          <MapBackground />
        )}
      </>
    );
  }

  render() {
    const { company } = this.props.match.params;
    const { asyncState } = this.props.locationStore;
    return (
      <div style={{ position: 'relative' }}>
        <SearchHeader
          ref={this.searchHeaderRef}
          company={company}
          disabled={asyncState === AsyncStatus.LOADING}
        />
        {this.renderMap()}
      </div>
    );
  }

}

type WrapperProps = {
  initialCenter: { lat: number, lng: number },
  zoom: number,
  google: any,
  locationStore: LocationStore,
};

const MapWrapper = (props: WrapperProps) => {
  return (
    <Map {...props} visible={false}>
      <Contents {...props} />
    </Map>
  );
};

MapWrapper.defaultProps = {
  initialCenter: WhiteLabelUi.common.defaultMapCenter,
  zoom: 5,
  containerStyle: {
    width: '100%',
    height: 'calc(100vh - 270px)',
    position: 'relative',
    top: 0,
  },
};

export default compose(
  GoogleApiWrapper({
    apiKey: GA_KEY,
    libraries: ['places', 'geometry'],
  }),
  withRouter,
  inject('locationStore'),
  withWhitelabelProps({
    countryCode: 'ui.common.countryCode',
  }),
  observer
)(MapWrapper);
