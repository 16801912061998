// @flow

import axios, { AxiosInstance } from 'axios';

type Config = {
  baseUrl: string,
  headers: {
    [key: string]: string,
  },
};

export default (config: Config): AxiosInstance => {
  return axios.create({
    // withCredentials: true,
    baseURL: config.baseUrl,
    headers: config.headers,
  });
};
