// @flow

import type { Asset } from '_common/services/assetManagement';
import stores from 'stores';
import { ROOT_ORG_ID } from '_common/constants/appConfig';

const uploadAsset = (asset: Asset, editingCompanyByAdmin: string) => {
  let organisationId;
  const { organisationId: storedOrganisationId } = stores.authStore.loggedUser;

  /** Company id could be forced from args. */
  if (storedOrganisationId === ROOT_ORG_ID && editingCompanyByAdmin) {
    organisationId = editingCompanyByAdmin;
  } else {
    organisationId = storedOrganisationId;
  }

  if (asset.resourceId === 'logoImage') {
    stores.assetManagementStore.latestUploadedLogo = asset.content;
  }

  return stores.assetManagementStore.uploadAsset(organisationId, asset);
};

export default uploadAsset;
