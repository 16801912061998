import styled, { AnyStyledComponent, css } from 'styled-components';
import { StyledButton } from '_common/components/Button/elements';
import { isIE } from 'react-device-detect';

export const PageTitle = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 28px;
  color: #282d32;
  align-self: flex-start;
`;

export const PageSubTitle = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 17px;
  line-height: 1.18;
  color: #282d32;
  margin-top: 15px;
  align-self: flex-start;
  max-width: 100%;
  white-space: pre-wrap;
`;

export const PageHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${props =>
    props.withSubTitle &&
    css`
      align-items: baseline;
      flex-direction: column;
    `};
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      align-items: start;
    `}
`;

export const ColumnPageHeader = styled(PageHeader)`
  flex-direction: column;
  align-items: self-start;
  margin-top: initial;
`;

export const Panel = styled.div`
  padding: 25px;
  margin-bottom: 115px;
  border-radius: 3px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  background-color: #ffffff;
`;

export const PanelLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  width: 100%;
  height: 100%;
`;

export const PanelLoaderText = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 25px;
  color: #282d32;
  text-transform: uppercase;
`;

export const PanelSectionTitle = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 18px;
  color: #282d32;
  margin-top: 25px;

  &:first-child {
    margin-top: 0;
  }
`;

export const PanelSection = styled.div`
  border-radius: 2px;
  display: flex;
  border: solid 1px #c7c7cc;
  background-color: #fefefe;
  padding: 20px 26px 32px 27px;
  flex-wrap: wrap;
`;

export const PanelSectionNoWrap = styled(PanelSection)`
  flex-wrap: nowrap;
`;

export const DetailsPanelSection = styled(PanelSection)`
  flex-direction: column;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`};
`;

export const TermsPanelSection = styled(PanelSection)`
  align-items: center;
  flex-wrap: nowrap;

  .ant-row.ant-form-item {
    padding: 0;
    margin: 0;
  }
`;

export const PanelLeftSide = styled.div`
  margin-right: 55px;
  display: flex;
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}px;`};

  @media only screen and (max-width: 1366px) {
    margin-right: 30px;
  }

  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
`;
export const PanelRightSide = styled.div`
  ${({ flex }) => (flex ? `flex: ${flex}` : isIE ? 'flex: 1' : undefined)};
  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
  .ant-input:first-child {
    margin-bottom: 0;
  }
`;

export const DoubleColumnSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > div:nth-child(even) {
    flex-basis: 50%;
  }

  & > div:nth-child(even) {
    flex-basis: 50%;
    padding-left: 55px;
  }

  @media only screen and (max-width: 1366px) {
    & > div:nth-child(even) {
      padding-left: 30px;
    }
  }
`;

export const PanelIcon: AnyStyledComponent = styled.div`
  width: ${(props: any) => props.width + 'px'}; //todo add typings
  min-width: ${(props: any) => props.width + 'px'};
  max-width: ${(props: any) => props.width + 'px'};
  height: ${(props: any) => props.height + 'px'};
  margin-right: 35px;
  background-image: url(${(props: any) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ImagePreview = styled(PanelIcon)`
  margin: -20px 35px -32px -27px;
`;

export const PanelRow = styled.div`
  display: flex;
  width: 100%;
  ${({ flex }) => flex && `flex: ${flex}`};
  margin-left: ${({ isOffset }) => (isOffset ? '121px' : 0)};
  &:not(:first-of-type) {
    margin-top: 45px;
  }
`;

export const PanelRowWithSmallOffset = styled(PanelRow)`
  &:not(:first-of-type) {
    margin-top: 35px;
  }
`;

export const Page = styled.div`
  display: flex;
`;

export const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  max-height: ${({ editingCompanyByAdmin }) =>
    editingCompanyByAdmin ? 'calc(100vh - 118px)' : 'calc(100vh - 71px)'};
  padding: 0 50px 0 25px;
`;

export const PublishButton = styled(StyledButton)`
  align-self: center;
  position: relative;
  transition: all 0.7s linear;
  ${({ noPadding }) => noPadding && `padding: 0;`};
  width: 160px;

  ${({ isEmptyForm }) =>
    isEmptyForm &&
    css`
      &::after {
        content: 'All fields are blank. Please fill in all fields to publish';
        position: absolute;
        bottom: -27px;
        color: #ff3654;
        text-align: right;
        right: 0;
        width: 500px;
      }
    `}

  ${({ isError }) =>
    isError &&
    css`
      &::after {
        content: 'Publishing error, please try again';
        position: absolute;
        bottom: -27px;
        color: #ff3654;
        text-align: right;
        right: 0;
        width: 500px;
      }
    `}

  ${({ isPublished }) =>
    isPublished &&
    css`
      background-color: #38b13a;
    `}
`;

export const HeaderAbsoluteErrorMessage = styled.div`
  position: absolute;
  top: 23px;
  right: ${({ largeOffsetRight }) => (largeOffsetRight ? '330px' : '50px')};
  color: #ff3654;
`;

export const Row = styled.div`
  display: flex;
  ${({ flex }) => flex && `flex: ${flex}`}
`;
