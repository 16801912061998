// @flow

import stores from 'stores';

export default ({
  login,
  password,
  organisation,
  staffPermissions,
}: {
  login: string,
  password: string,
  organisation: string,
  staffPermissions: string[],
}) => {
  return stores.authStore.authorizeStaff(
    login,
    password,
    organisation,
    staffPermissions
  );
};
