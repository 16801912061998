import { Layout } from '_common/components';
import styled, { createGlobalStyle, css } from 'styled-components';
import { mobileMap } from 'assets';

export const LocateGlobalStyles = createGlobalStyle`
  #root {
    min-width: 320px !important;
  }
`;

export const Wrapper = styled.div`
  height: calc(100vh - 170px); // 100px - header, 70px -footer

  overflow: hidden;

  ${Layout.LeftSection} {
    display: flex;
    width: 480px;
    flex-direction: column;
    border: 1px solid #c7c7cc;
    margin-bottom: 0;
    background: #ffffff;

    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
      height: initial;
      overflow: initial;
    }
  }

  ${Layout.RightSection} {
    background: #ffffff;
  }

  @media only screen and (max-width: 768px) {
    height: calc(100vh - 192px); // 92px - bottom AP logo, 100px - footer
    min-height: 760px;

    ${Layout.LeftSection} {
      padding: 0;
      border: none;
      width: 100%;
    }
  }
`;

export const StoreListWrapper = styled.div`
  max-height: calc(100vh - 266px);
  overflow-y: scroll;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const LoaderBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100%;

  @media only screen and (max-width: 768px) {
    height: 200px;
    background: transparent;

    ${props =>
      props.map &&
      css`
        width: 100%;
        height: 500px;
        background-size: 100% 510px;
        background: url(${mobileMap}) no-repeat center;
        position: absolute;
        top: 261px; // Sum of heights: 100px (header) + 60px (search input) + 50px (location type) + 51px (tab of view)
        z-index: 1;
      `},

    span {
      font-family: Arial, sans-serif;
      font-size: 20px;
      color: #222222;
    }
  }
`;

export const LocateTopContentTitle = styled.div`
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0;
  color: #000000;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
`;

export const LocateTopContentDescription = styled.div`
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0;
  color: #000000;
  margin-top: 15px;

  & img {
    padding: 0 10px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const LocateTopContentWrapper = styled.div`
  padding: 30px 40px;
  ${({ showBorderBottom }) =>
    showBorderBottom &&
    css`
      border-bottom: 1px solid #e6e7e8;
    `};

  @media only screen and (max-width: 768px) {
    border-bottom: 10px solid #222222;
    padding: 30px 34px;
  }
`;

export const BottomLogo = styled.div`
  background: #ffffff;
  display: none;
  padding: 20px;
  height: 92px;

  @media only screen and (max-width: 768px) {
    display: block;
  }

  img {
    width: 150px;
    height: auto;
    margin: 0 auto;
    display: block;
  }
`;
