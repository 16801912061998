// @flow

import React, { Component } from 'react';
import { compose } from 'recompose';

import { NOTIFICATIONS_DISPLAY_TIME } from '_common/constants/timeout';
import { Root, Item, Button } from './EmailsListElements';
import { extractError } from '_common/utils';
import commonActions from '_common/actions';
import { withWhitelabelProps } from '_common/whitelabelConfig';

type Props = {};
type EmailItem = {
  name: string,
  isSend: boolean,
  isDisabled: boolean,
  configFunc: () => void,
};
type State = {
  items: EmailItem[],
};

class EmailsList extends Component<Props, State> {

  state = {
    items: this.props.whiteLabeled.consumerEmailsList,
  };

  showTemporalNotification = (index: number) => {
    this.setState({
      items: {
        ...this.state.items,
        [index]: { ...this.state.items[index], isSend: true },
      },
    });

    /** hide notification after 3s. */
    setTimeout(() => {
      this.setState({
        items: {
          ...this.state.items,
          [index]: { ...this.state.items[index], isSend: false },
        },
      });
    }, NOTIFICATIONS_DISPLAY_TIME);
  };

  setDisableStatus = (index: number, isDisabled) => {
    this.setState({
      items: {
        ...this.state.items,
        [index]: { ...this.state.items[index], isDisabled },
      },
    });
  };

  sendEmailPreview = (index: number, configFunc: () => void) => async () => {
    this.setDisableStatus(index, true);
    try {
      await commonActions.sendEmailsPreview(configFunc);
      this.showTemporalNotification(index);
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      console.error('err::sendEmailPreview', e);
    } finally {
      this.setDisableStatus(index, false);
    }
  };

  render() {
    const { items } = this.state;
    return (
      <Root>
        {Object.entries(items).map(
          ([index, { name, isSend, configFunc, isDisabled }]) => (
            <Item key={name}>
              {name}
              <Button
                onClick={this.sendEmailPreview(index, configFunc)}
                data-sent={isSend}
                disabled={isDisabled}
              >
                {isSend ? 'Sent' : 'Preview'}
              </Button>
            </Item>
          )
        )}
      </Root>
    );
  }

}

export default compose(
  withWhitelabelProps({
    consumerEmailsList: 'ui.pages.settingsAndCommunications.emailsList',
  })
)(EmailsList);
