//@flow
import { onlyProd } from '_common/utils';

interface IAdobeEvent {
  step: string;
  stage: string;
}

class AdobeAnalytics {

  devLogger(args) {
    args[0] &&
      // eslint-disable-next-line no-console
      console.log(
        '%c [Adobe Event: %s]',
        'color: #f48024',
        args[0].stage,
        args[0].step
      );
  }

  @onlyProd('devLogger')
  trackSignupPageEvent({ stage, step }: IAdobeEvent) {
    const { analytics, _satellite } = window;
    analytics.component.form.name = 'form:merchant collect & return access';
    analytics.component.form.step = step;
    analytics.component.form.stage = stage;
    _satellite.track('form-navigate');
  }

}

export default new AdobeAnalytics();
