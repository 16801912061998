// @flow

import { action, computed, observable, runInAction } from 'mobx';
import { get, isEmpty, set as setLodash, merge } from 'lodash';
import companyModelService from '_common/services/companyModelService';
import commonActions from '_common/actions';
import { extractError } from '_common/utils';
import {
  mergeWithRequiredModel,
  mergeWithDefaults,
} from '_common/utils/directoryUtils';
import { AsyncStatus } from '_common/constants/common';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import type { Option } from '_common/utils/orderDetailsUtils';
import { mapOrderDetailsToFront } from '_common/utils/orderDetailsUtils';
import { TrackingBlocks } from '_common/stores/uiStore';
import cacheAsync from '_common/utils/cacheAsync';
import { RETURNS_FORM_FIELD_NAMES } from 'settings/returnPolicies';

export type GroupName = 'reasonsOptions' | 'formFieldsOptions';

export const TrackableChanges = {
  PAYMENT_METHOD: 'Payment Method',
  REASON_CODES: 'Reason codes',
  RETURNS_AUTH: 'Return authorisation',
  RETURN_POLICIES: 'Return policies',
  DELIVERY_SPEED: 'Delivery speed',
  CONSUMER_URL_TOGGLE: 'Consumer URL toggle',
  REASON_COMMENTS: 'Reason comments',
};

export const COMPANY_ENTITIES = {
  DEACTIVATE_PORTAL: 'settings_deactivate_portal',
};

// these values must be replaced in case of nullish value input
const DEFAULT_FIELD_VALUES = {
  [RETURNS_FORM_FIELD_NAMES.ORDER_ID_REGEX]: '[\\s\\S]{1,50}',
  [RETURNS_FORM_FIELD_NAMES.RAN_REGEX]: '[\\s\\S]{1,50}',
};

class CompanyModelStore {

  /** processing statuses to disable fields on UI */
  @observable
  processingEntities: ObservableSet<string> = observable.set();

  @observable
  reasonsOptions: Option[] = [];

  @observable
  formFieldsOptions: Option[] = [];

  @observable
  paymentType: ?string = null;

  @observable
  customerPayments: Object;

  @observable
  formModel = {
    validationRegex: void 0,
    RAN: void 0,
    RANValidationRegex: void 0,
    RANFieldName: void 0,
    rmaOption: false,
    rmaDays: '30',
  };

  /** end payments */

  @observable
  asyncStatus = AsyncStatus.IDLE;

  /** Stores original company model */
  @observable
  companyModel: Object = {};

  /** Stores 'updates' which are not yet committed to model. */
  @observable
  companyModelUpdates: Object = {};

  getStateForDebug = () => ({
    reasonsOptions: this.reasonsOptions,
    formFieldsOptions: this.formFieldsOptions,
    paymentType: this.paymentType,
    formModel: this.formModel,
    customerPayments: this.customerPayments,
  });

  @computed
  get isLoading() {
    return this.asyncStatus === AsyncStatus.LOADING;
  }

  @computed
  get consumerUrlLive() {
    return get(
      this.companyModel,
      `products.${WhiteLabelConstants.PRODUCT_NAME}.consumerUrlLive`,
      true
    );
  }

  @computed
  get canDisableReasons() {
    return true; // keep that logic for first time, can remove if wont be roll-backed from BA side
  }

  @computed
  get isAllReasonsDisabled() {
    return this.reasonsOptions.every(({ disabled }) => disabled);
  }

  @computed
  get isAllAvailableReasonsRequiresPayment() {
    return this.reasonsOptions
      .filter(({ disabled }) => !disabled)
      .every(({ checked }) => checked);
  }

  @computed
  get isAllAvailableReasonsNotRequiresPayment() {
    return this.reasonsOptions
      .filter(({ disabled }) => !disabled)
      .every(({ checked }) => !checked);
  }

  @observable
  isBothPaidDisabled = true;

  @action
  setBothPaid = isBothPaidDisabled => {
    runInAction(() => {
      this.isBothPaidDisabled = isBothPaidDisabled;
    });
  };

  @computed
  get paymentOptions() {
    return [
      { label: 'Merchant paid only', value: 'MERCHANT_PAID' },
      { label: 'Consumer paid only', value: 'CONSUMER_PAID' },
      {
        label: 'Both merchant & consumer paid',
        value: 'BOTH_PAID',
        disabled: this.isBothPaidDisabled,
      },
    ];
  }

  @action
  @cacheAsync
  async getCompanyModel(companyId: string) {
    /** Each page request to page should clean-up changes. */
    this.companyModelUpdates = {};

    if (
      !isEmpty(this.companyModel) &&
      this.companyModel.companyId === companyId
    ) {
      return Promise.resolve();
    }

    this.companyModel = {};
    this.asyncStatus = AsyncStatus.LOADING;
    try {
      const companyModel = await companyModelService.getCompany(companyId);
      const mappedResponse = mapOrderDetailsToFront(companyModel);
      runInAction(() => {
        this.paymentType = mappedResponse.paymentType;
        this.reasonsOptions = mappedResponse.reasonsOptions;
        this.formFieldsOptions = mappedResponse.formFieldsOptions;
        this.formModel = mappedResponse.formModel;
        this.customerPayments = mappedResponse.customerPayments;

        this.companyModel = companyModel;
        this.asyncStatus = AsyncStatus.SUCCESS;
      });
      return Promise.resolve();
    } catch (e) {
      runInAction(() => {
        this.asyncStatus = AsyncStatus.FAILED;
      });
      return Promise.reject(e);
    }
  }

  @action
  updateCompany = async (companyId, companyUpdateConfig) => {
    try {
      const updatedConfig = await companyModelService.updateCompany(
        companyId,
        mergeWithRequiredModel(
          this.companyModel,
          mergeWithDefaults(companyUpdateConfig)
        )
      );
      runInAction(() => {
        this.companyModel = updatedConfig;
      });
      return Promise.resolve(updatedConfig);
    } catch (e) {
      console.error('err', e);
      commonActions.setApplicationErrorMessage(extractError(e));
      await this.getCompanyModel(companyId);
      return Promise.reject(e);
    }
  };

  @action
  changeCompanyModel = changes => {
    /** this is only way to trigger mobx renders */
    this.companyModelUpdates = {
      ...this.companyModelUpdates,
      ...merge(this.companyModelUpdates, changes),
    };
  };

  @action
  invalidate = () => {
    this.companyModel = {};
    this.companyModelUpdates = {};
  };

  trackPaymentChange = newPaymentValue => {
    if (this.paymentType !== newPaymentValue) {
      commonActions.trackChange(
        TrackingBlocks.PaymentAndReasonCodes,
        TrackableChanges.PAYMENT_METHOD,
        this.paymentType,
        newPaymentValue
      );
    }
  };

  @action
  setOrderDetailsFormField = (fieldName: string, value: string) => {
    if (this.formModel[fieldName] === value) {
      return;
    }
    this.formModel[fieldName] = value;
  };

  @action
  updateCompanyModel = (path, value) => {
    let defaultValue;
    if (DEFAULT_FIELD_VALUES[path] && !value) {
      defaultValue = DEFAULT_FIELD_VALUES[path];
    }
    setLodash(this.companyModelUpdates, path, defaultValue || value);
  };

  @action
  addProcessingEntity = (path, entityName) => {
    if (get(this.companyModel, path) !== get(this.companyModelUpdates, path)) {
      this.processingEntities.add(entityName);
    }
  };

  @action
  deleteProcessingEntity = entityName => {
    this.processingEntities.delete(entityName);
  };

}

export default CompanyModelStore;
