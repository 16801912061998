// @flow

import stores from 'stores';

const invalidateAssets = () => {
  stores.assetManagementStore.invalidateAssets();
  stores.companyModelStore.invalidate();
};

export default invalidateAssets;
