// @flow

export default function cacheAsync(
  target: Object,
  key: string,
  descriptor: Object
) {
  const cacheableFn = descriptor.value;
  if (typeof cacheableFn !== 'function') return;
  const cacheKey = `__${key}PromiseCache`;
  descriptor.value = async function(...args) {
    if (!target[cacheKey]) {
      const fnResult = cacheableFn.apply(this, args);
      if (!(fnResult instanceof Promise)) return fnResult;
      target[cacheKey] = fnResult;
    }

    const result = await target[cacheKey];
    target[cacheKey] = undefined;
    return result;
  };
  return descriptor;
}
