// @flow

import { get } from 'lodash';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import stores from 'stores';

export const DEFAULT_FIELDS = {
  purchaseDate: true,
  phoneNumber: false,
  orderId: true,
  reasonComments: false,
  RAN: false,
};

export const DEFAULT_REASONS = {
  enabled: true,
  reasons: [
    {
      enabled: true,
      description: 'Looks different to image',
      code: 'R1',
      paymentRequired: false,
    },
    {
      enabled: true,
      description: 'Arrived too late',
      code: 'R2',
      paymentRequired: false,
    },
    {
      enabled: true,
      description: 'Doesn’t fit',
      code: 'R3',
      paymentRequired: false,
    },
    {
      enabled: true,
      description: 'Doesn’t suit me',
      code: 'R4',
      paymentRequired: false,
    },
    {
      enabled: true,
      description: 'Incorrect item',
      code: 'R5',
      paymentRequired: false,
    },
    {
      enabled: true,
      description: 'Parcel damaged',
      code: 'R6',
      paymentRequired: false,
    },
    {
      enabled: true,
      description: 'Poor quality / Faulty',
      code: 'R7',
      paymentRequired: false,
    },
    {
      enabled: true,
      description: 'Ordered more than one size',
      code: 'R8',
      paymentRequired: false,
    },
  ],
};

export const DEFAULT_RMA = 'NO_RMA';

export const DEFAULT_WARRANTY_DAYS = 30;

export const FIELD_LABEL_TO_CODE = {
  'Purchase date': 'purchaseDate',
  'Phone number': 'phoneNumber',
  'Reason comments': 'reasonComments',
  'Return authorisation number': 'RAN',
  'Order ID Regex': 'validationRegex',
  'Edit field header': 'RANFieldName',
  'RAN Regex': 'RANValidationRegex',
  'Purchase date validation required': 'rma',
  'Purchase date within policy (days)': 'purchaseWarrantyPeriodDays',
};

const FORM_FIELD_OPTIONS = [
  { label: 'Purchase date validation required', checked: false },
  {
    label: 'Purchase date within policy (days)',
    type: 'input',
    precondition: ['Purchase date validation required', true],
    placeholder: '30',
    fieldName: 'purchaseWarrantyPeriodDays',
    hideBorder: true,
    width: 200,
  },
  { label: 'Purchase date', checked: true },
  { label: 'Phone number', checked: false },
  {
    label: 'Order ID Regex',
    type: 'input',
    withTooltip: true,
    fieldName: 'validationRegex',
  },
  { label: 'Return authorisation number', checked: false },
  {
    label: 'Edit field header',
    type: 'input',
    precondition: ['Return authorisation number', true],
    placeholder: 'Return authorisation number',
    fieldName: 'RANFieldName',
    hideBorder: true,
  },
  {
    label: 'RAN Regex',
    type: 'input',
    precondition: ['Return authorisation number', true],
    withTooltip: true,
    fieldName: 'RANValidationRegex',
    hideBorder: true,
  },
  { label: 'Reason comments', checked: false },
];

export type Option = { label: string, checked?: boolean, disabled?: boolean };
export type BEReason = {
  description: string,
  code: string,
  enabled?: boolean,
  paymentRequired?: boolean,
};

export type OrderDetailsConfig = {
  products: {
    [key: string]: {
      enabled: boolean,
      returnReasons: {
        enabled: boolean,
        validationRegex?: string,
        reasons: BEReason[],
      },
      rma: string,
      purchaseWarrantyPeriodDays?: number,
      returnFormFields: typeof DEFAULT_FIELDS,
    },
  },
};

export function mapOrderDetailsToFront(res: OrderDetailsConfig) {
  const { PRODUCT_NAME } = WhiteLabelConstants;
  const {
    returnFormFields = DEFAULT_FIELDS,
    returnReasons = DEFAULT_REASONS,
    rma = DEFAULT_RMA,
    purchaseWarrantyPeriodDays = DEFAULT_WARRANTY_DAYS,
  } = get(res, `products.${PRODUCT_NAME}`, {});

  const formModel = {
    validationRegex: get(
      res,
      `products.${PRODUCT_NAME}.returnReasons.validationRegex`
    ),
    RAN: get(res, `products.${PRODUCT_NAME}.returnFormFields.RAN`),
    RANValidationRegex: get(
      res,
      `products.${PRODUCT_NAME}.returnReasons.RANValidationRegex`
    ),
    RANFieldName: get(
      res,
      `products.${PRODUCT_NAME}.returnFormFields.RANFieldName`
    ),
    rmaOption: get(res, `products.${PRODUCT_NAME}.rma`),
    rmaDays: get(res, `products.${PRODUCT_NAME}.purchaseWarrantyPeriodDays`),
  };

  const result = {
    formModel,
    reasonsOptions: returnReasons.reasons.map<Option>(reason => ({
      disabled: !reason.enabled,
      checked: reason.paymentRequired,
      label: reason.description,
    })),
    rmaOption: rma,
    rmaDays: purchaseWarrantyPeriodDays,
    formFieldsOptions: FORM_FIELD_OPTIONS.map<Option>(option => {
      // TODO: where is it used?
      return option.type === 'input'
        ? {
            ...option,
            type: 'input',
          }
        : {
            ...option,
            checked: returnFormFields[FIELD_LABEL_TO_CODE[option.label]],
          };
    }),
    paymentType: get(
      res,
      `products.${PRODUCT_NAME}.customerPayments.paymentType`,
      'CONSUMER_PAID'
    ),
    customerPayments: get(res, `products.${PRODUCT_NAME}.customerPayments`),
    validationRegex: get(
      res,
      `products.${PRODUCT_NAME}.returnReasons.validationRegex`
    ),
  };

  if (rma === 'APPROVED_BY_PORTAL') {
    const purchaseDateOption = result.formFieldsOptions.find(
      ({ label }) => label === 'Purchase date'
    );
    purchaseDateOption.disabled = true;
  }
  return result;
}

export const getValidationRegex = (
  fieldName: string,
  validationRegex?: string
) => {
  const defaultRegex = '[\\s\\S]{1,50}';
  if (!validationRegex) {
    return defaultRegex;
  }
  try {
    new RegExp(validationRegex);
    return validationRegex;
  } catch (e) {
    stores.companyModelStore.setOrderDetailsFormField(fieldName, defaultRegex);
    return defaultRegex;
  }
};
