import * as React from 'react';
import { ErrorWrapper, ErrorCard, Heading, Message, Stack } from './elements';
import stores from 'stores';
import { toJS } from 'mobx';
import axios from 'axios';
import storage, {
  IS_MERCHANT,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TOKEN_LIFETIME,
  USER_ROLES,
  IS_EDIT_MODE,
} from 'storage';

type State = {
  error: null | Object,
};

class ErrorBoundary extends React.Component<{}, State> {

  static STORES = [
    'authStore',
    'merchantStore',
    'aclStore',
    'uiStore',
    'trackingStore',
    'usersStore',
    'statisticsStore',
  ];

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    const dumpOfState = ErrorBoundary.STORES.map(store => {
      return { [store]: toJS(stores[store].getStateForDebug()) };
    });
    dumpOfState.push({
      storage: {
        IS_MERCHANT: storage.get(IS_MERCHANT),
        REFRESH_TOKEN_KEY: storage.get(REFRESH_TOKEN_KEY),
        TOKEN_KEY: storage.get(TOKEN_KEY),
        TOKEN_LIFETIME: storage.get(TOKEN_LIFETIME),
        USER_ROLES: storage.get(USER_ROLES),
        IS_EDIT_MODE: storage.get(IS_EDIT_MODE),
      },
    });
    axios.post('self-send-debug-message', dumpOfState);
    console.error('dumpOfState', dumpOfState);
    console.error('Application catch an error:', error);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <ErrorWrapper>
          <ErrorCard>
            <Heading>Internal application error</Heading>
            <Message>{error.message}</Message>
            <Stack>{error.stack}</Stack>
          </ErrorCard>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }

}

export default ErrorBoundary;
