// @flow
import React, { Component } from 'react';

import trackingActions from 'tracking/actions';
import { StyledInput } from './SearchInputElements';

type Props = {};
type State = {};

class SearchInput extends Component<Props, State> {

  handleSearch = (value: string) => {
    trackingActions.searchReturns(value);
  };

  handleInputChange = () => {
    trackingActions.setErrorState(false);
  };

  render() {
    return (
      <StyledInput
        onChange={this.handleInputChange}
        placeholder="Search by tracking ID"
        onSearch={this.handleSearch}
      />
    );
  }

}

export default SearchInput;
