import {
  isEmpty,
  isString,
  isArray,
  isPlainObject,
  filter,
  map,
  pickBy,
  mapValues,
  isNull,
} from 'lodash';

const sanitize = object => {
  if (isString(object)) return sanitizeString(object);
  if (isArray(object)) return sanitizeArray(object);
  if (isPlainObject(object)) return sanitizeObject(object);
  return object;
};

const sanitizeString = string => {
  if (isEmpty(string)) {
    return null;
  }
  const trimmed = string.trim();
  return isEmpty(trimmed) ? null : trimmed;
};

const sanitizeArray = array => {
  return filter(map(array, sanitize), isProvided);
};

const sanitizeObject = object => {
  return pickBy(mapValues(object, sanitize), isProvided);
};

const isProvided = value => {
  const typeIsNotSupported =
    !isNull(value) &&
    !isString(value) &&
    !isArray(value) &&
    !isPlainObject(value);
  return typeIsNotSupported || !isEmpty(value);
};

const sanitizeRevivers = {
  EMPTY_TO_NULL: (defaultValues = null) => (k, v) => {
    if (typeof v === 'string') {
      v = v.trim();
    }

    if (v === undefined || v === '') {
      if (!defaultValues) {
        return null;
      }
      return defaultValues[k] || null;
    } else {
      return v;
    }
  },
  REMOVE_EMPTY: (defaultValues = null) => (k, v) => {
    if (v === undefined || v === '') {
      if (!defaultValues) {
        return undefined;
      }
      return defaultValues[k] || undefined;
    } else {
      return v;
    }
  },
};
const sanitizeWithRule = (o, fn = sanitizeRevivers.EMPTY_TO_NULL()) =>
  JSON.parse(JSON.stringify(o, fn));

export { sanitize, sanitizeWithRule, sanitizeRevivers };
