// @flow

import stores from 'stores';
import type { MerchantDetails } from '../services/assetManagement';
import { getFlattedKeyValuePairsFromObject } from '_common/utils/trackingChanges';
import commonActions from '_common/actions';
import { TrackingBlocks } from '_common/stores/uiStore';
import { get, merge } from 'lodash';
import { runInAction } from 'mobx';

const setMerchantDetails = (details: MerchantDetails) => {
  /** Tracking changes. */
  getFlattedKeyValuePairsFromObject(details).forEach(([key, value]) => {
    if (key === 'website') return;
    commonActions.trackChange(
      TrackingBlocks.ReturnDetails,
      key,
      get(stores.companyModelStore.companyModel, key),
      value
    );
  });

  if (details.hasOwnProperty('mainInput')) {
    return stores.uiStore.setGooglePlacesMainInput(details.mainInput);
  }

  /** TECH-7766, explicit for one value, but probably need to be scaled in future. */
  if (details.departmentName === '') {
    details.departmentName = null;
  }

  runInAction(() => {
    stores.companyModelStore.companyModel = merge(
      { ...stores.companyModelStore.companyModel },
      details
    );
    stores.companyModelStore.companyModelUpdates = merge(
      { ...stores.companyModelStore.companyModelUpdates },
      details
    );
  });
};

export default setMerchantDetails;
