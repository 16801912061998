// @flow

export default {
  root: '/',
  notFound: '/not-found',

  // root Login
  rootLogin: '/login',

  // merchant login
  merchantLogin: '/:company/login',

  // Dashboard of root user e.g. A.Post
  dashboardRoot: '/dashboard',

  // Dashboard for merchant
  dashboardMerchant: '/:company/dashboard',

  // Locate for merchant
  merchantLocate: '/:company/locate',

  // Merchant signup
  signUp: '/signup',

  // Setting merchant's password
  resetPassword: '/resetpassword',

  // Merchant
  requests: '/merchants/request',

  // Merchants Overview
  overview: '/merchants/overview',

  // Forgot password
  forgotPassword: '/:company/forgot-password',

  // Create new password
  createNewPassword: '/:company/create-new-password',

  // Users
  users: '/:company/users',

  // Editing mode Users
  editUsers: '/users',

  // Merchant's Tracking Page
  merchantTracking: '/:company/tracking',

  // Admin's Tracking Page
  adminTracking: '/tracking',

  // Statics for Merchant
  merchantStatistics: '/:company/statistics',

  // Statistics for Portal Admin
  adminStatistics: '/statistics',

  // Settings pages
  returnDetails: '/:company/settings/return-details',
  branding: '/:company/settings/branding',
  paymentOptionsAndReasonCodes: '/:company/settings/payment-reason-codes',
  returnPolicies: '/:company/settings/return-policies',
  communications: '/:company/settings/communications',

  // Settings pages -- edit mode
  editReturnDetails: '/settings/return-details',
  editBranding: '/settings/branding',
  editPaymentOptionsAndReasonCodes: '/settings/payment-reason-codes',
  editReturnPolicies: '/settings/return-policies',
  editCommunications: '/settings/communications',
};
