import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorCard = styled.div`
  width: 70vw;
  height: 70vh;

  background-color: rgba(206, 17, 38, 0.05);

  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Message = styled.div`
  font-size: 25px;
  text-align: center;
  margin-bottom: 10px;
`;

export const Stack = styled.div`
  overflow-y: auto;
`;
