import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
`;

export const MerchantWrapper = styled(Wrapper)`
  width: 100%;
  position: relative;
`;

export const LogoContainer = styled.div`
  img {
    height: 60px;
  }
`;

export const CarrierLogoContainer = styled.div`
  position: absolute;
  left: 45px;
  top: 15px;

  img {
    height: 60px;
  }
`;
