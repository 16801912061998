// @flow
import React, { Component } from 'react';
import { Logo, Text, Img } from './MerchantLogoElements';
import apiEndpoins from '_common/api/endpoints';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import MerchantsStore from 'merchants/stores/merchantsStore';
import merchantActions from 'merchants/actions';
import { WhiteLabelConstants } from '_common/whitelabelConfig';

type Props = {
  companyName?: string,
  companyId?: string,
  merchantStore: MerchantsStore,
  isDefaultImageOnly: boolean,
  darkMode: boolean,
};

type State = {
  imageLoaded: boolean,
};

function getLogoAbbreviation(companyName: string) {
  const firstWordStart = companyName.match(/[A-Za-z0-9]/);
  if (!firstWordStart) {
    return '';
  }

  const secondWordStart = companyName
    .substr(firstWordStart.index + 1)
    .match(/[\s\-_.,][A-Za-z0-9]/);
  if (secondWordStart) {
    return firstWordStart[0] + secondWordStart[0][1];
  }
  return firstWordStart[0];
}

@inject('merchantStore')
@observer
class MerchantLogo extends Component<Props, State> {

  state = {
    imageLoaded: false,
  };

  componentWillMount() {
    const { companyId, isDefaultImageOnly } = this.props;
    !isDefaultImageOnly &&
      merchantActions.getAssetsConfigForMerchant(companyId);
  }

  @computed
  get imageUrl() {
    const { companyId } = this.props;
    const stored = this.props.merchantStore.merchantsAssetsRegistry.get(
      companyId
    );

    if (stored) {
      const {
        assets: { logoImage },
      } = stored;

      if (logoImage) {
        return `${apiEndpoins.ASSETS_URL}/${logoImage}?ts=${Date.now()}`;
      }
    }

    return `${apiEndpoins.ASSETS_URL}/${
      WhiteLabelConstants.PRODUCT_NAME
    }/${companyId}/assets/logoImage.jpeg`;
  }

  onLoad = () => {
    this.setState({ imageLoaded: true });
  };

  render() {
    const { companyName, isDefaultImageOnly, darkMode } = this.props;
    const { imageLoaded } = this.state;

    return (
      <Logo darkMode={darkMode}>
        <Text imageLoaded={imageLoaded}>
          {getLogoAbbreviation(companyName)}
        </Text>
        {!isDefaultImageOnly && (
          <Img
            onLoad={this.onLoad}
            imageLoaded={imageLoaded}
            src={this.imageUrl}
            alt={`${companyName} logo`}
          />
        )}
      </Logo>
    );
  }

}

export default MerchantLogo;
