import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9eaea;
  background-color: #fff;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 17px;
  color: #ff3654;
  margin-right: ${({ largeOffsetRight }) =>
    largeOffsetRight ? '230px' : '50px'};
  background-color: #fff;
  z-index: 100;
`;
