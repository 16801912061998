// @flow

import { Layout, LoaderArc, InfoPanelCloseBtn } from '_common/components';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Wrapper,
  StoreListWrapper,
  LocateTopContentTitle,
  LocateTopContentDescription,
  LocateTopContentWrapper,
  LocateGlobalStyles,
  BottomLogo,
} from './elements/index';
import LocationStore from 'locate/stores/locationStore';
import { Form } from 'antd';
import DesktopMap from './components/DesktopMap';
import StoreList from './components/StoreList/StoreList';
import MobileMap from './components/MobileMap/MobileMap';
import Header from './components/Header';
import Footer from './components/Footer';
import locationActions from './actions';
import type { Store } from './stores/locationStore';
import { LoaderBlock } from 'locate/elements';
import AuthStore from '_common/stores/authStore';
import { printerDarkIcon } from 'assets';
import { computed } from 'mobx';
import { AsyncStatus } from '_common/constants/common';
import links from '_common/routes/urls';
import { isAllowedToLocatePageAccess } from 'locate/utils/locationUtils';
import commonActions from '_common/actions';
import { withWhitelabelProps } from '_common/whitelabelConfig';

type Props = RouteComponentProps & {
  locationStore: LocationStore,
  authStore: AuthStore,
};

type State = {
  isUserAwaitingNewTab: boolean,
};

@observer
class LocatePage extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      isUserAwaitingNewTab: false,
      geo: {},
    };
  }

  @computed
  get isLoading() {
    const { asyncState, mapDragActive } = this.props.locationStore;
    return mapDragActive ? false : asyncState === AsyncStatus.LOADING;
  }

  async componentDidMount() {
    const {
      history: { push },
      authStore,
    } = this.props;

    try {
      if (!authStore.loggedUser) {
        await authStore.authoriseApplication();
      }

      let isValidCompany = false;
      const companyName = await isAllowedToLocatePageAccess();
      if (companyName)
        isValidCompany = await commonActions.getCompany(companyName);

      if (!isValidCompany) {
        push(links.notFound);
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleStoreClick = (store: Store) => {
    this.setState({
      geo: {
        lat: store.geo.lat,
        lng: store.geo.lon,
      },
    });
    locationActions.setActiveStoreId(store.storeId);
  };

  renderMainBlock = () => (
    <>
      <MobileMap />
      <StoreListWrapper>
        <StoreList onStoreClick={this.handleStoreClick} />
      </StoreListWrapper>
    </>
  );

  renderLoaderBlock = () => (
    <LoaderBlock map>
      <LoaderArc />
    </LoaderBlock>
  );

  handleToggleInfoPanelVisibility = (visibility: boolean) => {
    return this.props.locationStore.setInfoPanelVisibility(visibility);
  };

  renderInfoPanel = () => {
    const {
      locationStore: { showInfoPanel },
    } = this.props;
    return (
      showInfoPanel && (
        <InfoPanelCloseBtn onClick={this.handleToggleInfoPanelVisibility}>
          Australia Post does not have permission for this browser to use your
          location
        </InfoPanelCloseBtn>
      )
    );
  };

  renderNoPrinterText = () => (
    <LocateTopContentDescription>
      No printer? Just show the barcode at a location which displays this symbol
      <img src={printerDarkIcon} alt="printer logo" />
      in the list below and we will print your label for you
    </LocateTopContentDescription>
  );

  renderLocationTopContent = () => {
    const {
      locationStore: { asyncState },
    } = this.props;

    switch (asyncState) {
      case AsyncStatus.SUCCESS:
        return (
          <LocateTopContentWrapper showBorderBottom>
            <LocateTopContentTitle>
              Return location results
            </LocateTopContentTitle>
          </LocateTopContentWrapper>
        );
      case AsyncStatus.LOADING:
        return (
          <LocateTopContentWrapper>
            <LocateTopContentTitle>
              Loading search results
            </LocateTopContentTitle>
            {this.renderNoPrinterText()}
          </LocateTopContentWrapper>
        );
      default:
        return (
          <LocateTopContentWrapper>
            <LocateTopContentTitle>
              Thanks for booking your return.
            </LocateTopContentTitle>
            {this.renderNoPrinterText()}
          </LocateTopContentWrapper>
        );
    }
  };

  render() {
    const {
      whiteLabeled: { footerWlProps, headerWlProps, logoWlProps },
    } = this.props;
    return (
      <React.Fragment>
        <LocateGlobalStyles />
        <Header {...headerWlProps} />
        <Wrapper>
          {this.renderInfoPanel()}
          <Layout.Container>
            <Layout.LeftSection style={{ border: 'none' }}>
              {this.renderLocationTopContent()}
              {this.renderMainBlock()}
              {this.isLoading && this.renderLoaderBlock()}
            </Layout.LeftSection>
            <Layout.RightSection>
              <DesktopMap currentGeo={this.state.geo} />
            </Layout.RightSection>
          </Layout.Container>
        </Wrapper>
        <BottomLogo>
          <img alt={logoWlProps.alt} src={logoWlProps.src} />
        </BottomLogo>
        <Footer {...footerWlProps} />
      </React.Fragment>
    );
  }

}

export default compose(
  inject('locationStore', 'authStore'),
  withWhitelabelProps({
    footerWlProps: 'ui.pages.locate.footer',
    headerWlProps: 'ui.pages.locate.header',
    logoWlProps: 'ui.pages.locate.logo',
  }),
  Form.create<Props>({
    mapPropsToFields(props) {
      const { locationStore } = props;
      return {
        locationSearch: Form.createFormField({
          value: locationStore.formFields.locationSearch,
        }),
      };
    },
    onValuesChange(props, fields) {
      const keys = Object.keys(fields);
      if (!keys.length) {
        return;
      }

      // @ts-ignore
      const { name, value, errors } = fields[keys[0]];

      props.locationStore.setFormField(name, value, errors);
    },
  })
)(LocatePage);
