import * as React from 'react';
import { Form, Icon } from 'antd';
import { Input, Label, Select } from '_common/components';
import {
  chargeAccountFieldRule,
  requiredFieldRule,
} from '_common/utils/formUtils';
import {
  validateNumberSelect,
  validateOnSpecialCharacters,
} from '_common/utils';
import type { TMerchant } from 'merchants/models';
import { get } from 'lodash';
import { FIELD_NAMES } from '../../_constants';

type Props = {
  merchant: TMerchant,
  form: Form,
};

class AdminFieldsForm extends React.Component<Props> {

  render() {
    const {
      form: { getFieldDecorator },
      merchant,
    } = this.props;

    return (
      <>
        <Form.Item>
          <div>
            <Label fontType="book" htmlFor={FIELD_NAMES.MERCHANT_API_KEY}>
              Merchant API Key*
            </Label>
            {getFieldDecorator(FIELD_NAMES.MERCHANT_API_KEY, {
              initialValue: get(merchant, FIELD_NAMES.MERCHANT_API_KEY),
              rules: [
                { ...requiredFieldRule },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <Input
                style={{ width: 308 }}
                id={FIELD_NAMES.MERCHANT_API_KEY}
                placeholder={'Add merchant API Key'}
              />
            )}
          </div>
        </Form.Item>

        <Form.Item>
          <div>
            <Label fontType="book" htmlFor={FIELD_NAMES.API_SECRET}>
              API secret
            </Label>
            {/* not required field, data stored on backend - not exposes outside */}
            {getFieldDecorator(FIELD_NAMES.API_SECRET, {
              rules: [
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <Input
                style={{ width: 308 }}
                id={FIELD_NAMES.API_SECRET}
                placeholder={'Add secret API Key'}
                suffix={
                  get(merchant, FIELD_NAMES.HAS_API_SECRET_SET) && (
                    <Icon
                      type="check-circle"
                      theme="filled"
                      style={{ color: '#37cb1d', fontSize: '20px' }}
                    />
                  )
                }
              />
            )}
          </div>
        </Form.Item>

        <Form.Item>
          <div>
            <Label fontType="book" htmlFor={FIELD_NAMES.PRICING_TIER}>
              Select pricing tier*
            </Label>
            {getFieldDecorator(FIELD_NAMES.PRICING_TIER, {
              initialValue: get(merchant, FIELD_NAMES.PRICING_TIER),
              rules: [
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateNumberSelect(rule, value, callback);
                  },
                },
              ],
            })(
              <Select
                id={FIELD_NAMES.PRICING_TIER}
                style={{ width: 308 }}
                placeholder={'Select pricing tier'}
                options={[
                  {
                    label: 'Tier 1',
                    value: 1,
                  },
                  {
                    label: 'Tier 2',
                    value: 2,
                  },
                  {
                    label: 'Tier 3',
                    value: 3,
                  },
                  {
                    label: 'Tier 4',
                    value: 4,
                  },
                ]}
              />
            )}
          </div>
        </Form.Item>

        <Form.Item>
          <div>
            <Label fontType="book" htmlFor={FIELD_NAMES.LOCATION_ID}>
              Merchant location ID*
            </Label>
            {getFieldDecorator(FIELD_NAMES.LOCATION_ID, {
              initialValue: get(merchant, FIELD_NAMES.LOCATION_ID),
              validateTrigger: 'onKeyUp',
              rules: [
                {
                  ...requiredFieldRule,
                },
                {
                  pattern: /^[a-z0-9]{3}$|^[a-z0-9]{5}$/i,
                  message: 'Please enter a valid merchant location ID',
                },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <Input
                style={{ width: 308 }}
                id={FIELD_NAMES.LOCATION_ID}
                placeholder={'Add merchant location ID'}
              />
            )}
          </div>
        </Form.Item>

        <Form.Item>
          <div>
            <Label
              fontType="book"
              htmlFor={FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT}
            >
              Merchant charge account*
            </Label>
            {getFieldDecorator(FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT, {
              initialValue: get(merchant, FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT),
              validateTrigger: 'onKeyUp',
              rules: [
                {
                  ...requiredFieldRule,
                },
                {
                  ...chargeAccountFieldRule,
                },
                {
                  validator: (
                    rule: any,
                    value: string,
                    callback: (error?: Error) => void
                  ) => {
                    validateOnSpecialCharacters(rule, value, callback);
                  },
                },
              ],
            })(
              <Input
                style={{ width: 308 }}
                id={FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT}
                placeholder={'1234567'}
              />
            )}
          </div>
        </Form.Item>
      </>
    );
  }

}

export default AdminFieldsForm;
