import styled from 'styled-components';

export const Description = styled.div`
  width: 351px;
  height: 40px;
  font-family: ${props => props.theme.fonts.book};
  font-size: 13px;
  line-height: 1.54;
  color: #282d32;
  margin-bottom: 15px;
`;
