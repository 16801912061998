import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #eeeeee;
  height: 50px;
  align-items: center;
  font-family: ${props => props.theme.fonts.book};
`;

export const Header = styled(Row)`
  font-family: ${props => props.theme.fonts.medium};
  font-weight: bold;
`;

export const Cell = styled.div`
  padding: 0 5px;
  flex-grow: 1;
  flex-basis: calc((100% - 240px) / 4);
  width: calc((100% - 240px) / 4);
  color: #131314;
  font-size: 12px;
`;

export const CellId = styled(Cell)`
  flex-basis: 240px;
  width: 240px;
`;
