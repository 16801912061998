// @flow

import stores from 'stores';
import { ROOT_ORG_ID } from '_common/constants/appConfig';
import commonActions from '_common/actions';
import { extractError } from '_common/utils';

const getDashboardConfigs = (companyIdFromUrl?: string) => {
  let organisationId;
  const { organisationId: storedOrganisationId } = stores.authStore.loggedUser;

  /** Company id could be force-sended as argument for root. */
  if (storedOrganisationId === ROOT_ORG_ID && companyIdFromUrl) {
    organisationId = companyIdFromUrl;
  } else {
    organisationId = storedOrganisationId;
  }

  Promise.all([
    commonActions.companyActions.getCompany(organisationId),
    commonActions.assetsActions.getAssets(organisationId),
  ]).catch(e => {
    commonActions.setApplicationErrorMessage(extractError(e));
  });
};

export default getDashboardConfigs;
