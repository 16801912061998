// @flow

import { authClient } from '_common/api/clients/clients';
import qs from 'qs';

const PERMISSIONS = [
  'auth_staff_password', // signup flow
  'auth_refresh_token', // signup flow
  'prereg:write',
  'prereg:read',
  'authmanagement_staff:read',
  'authmanagement_staff:write',
  'stores:read', // Need for locate page to access for the list os stories.
  'companies:read', // Need to get Location Types from company config.
  'authmanagement_staff:password_reset', // this is for calling of API for setting new password by token.
  'authmanagement_staff:password_reset_trigger', // this is for forgot password - reset password flow. resetemail
  'returns_portal_service:app:admin', // generic scope for service layer APIs
];

export type User = {
  login: string,
  organisationId: string,
};

export type TokenResponse = {
  access_token: string,
  appInfo: {
    appName: string,
    organisationId: string,
  },
  expires_in: number,
  refresh_token: string,
  scope: string,
  token_type: string,
  user: User,
};

export type Tokens = {
  accessToken: string,
  refreshToken?: string,
  expiresIn?: number,
};

export type OktaTokenInfo1 = {
  accessToken: string,
  authorizeUrl: string,
  expiresAt: number,
  scopes: string[],
  tokenType: string,
  userinfoUrl: string,
};

export type OktaTokenInfo2 = {
  authorizeUrl: string,
  claims: Object,
  clientId: string,
  expiresAt: number,
  scopes: string[],
  idToken: string,
  issuer: string,
};

export type OktaConfig = {
  status: string,
  [key: 0]: OktaTokenInfo1,
  [key: 1]: OktaTokenInfo2,
};

const authorizeApplication = async (): Promise<any> => {
  const body = qs.stringify({
    grant_type: 'client_credentials', //eslint-disable-line
    scope: [...PERMISSIONS].join(' '),
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

const authorizeStaff = async (
  username: string,
  password: string,
  organisation: string,
  staffPermissions: string[]
): Promise<TokenResponse> => {
  const body = qs.stringify({
    grant_type: 'staff_password', //eslint-disable-line
    username,
    password,
    organisationId: organisation,
    scope: [...staffPermissions, ...PERMISSIONS].join(' '),
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

const refreshToken = async (refreshTokenKey: string): Promise<any> => {
  const body = qs.stringify({
    grant_type: 'refresh_token', //eslint-disable-line
    refresh_token: refreshTokenKey, //eslint-disable-line
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

const validateTokenFromOkta = async (token: string) => {
  const body = qs.stringify({
    grant_type: 'ap_token', //eslint-disable-line
    ausPostToken: token, //eslint-disable-line
  });

  const response = await authClient.post('/v1/oauth/token', body);

  return response.data;
};

export default {
  authorizeApplication,
  authorizeStaff,
  refreshToken,
  validateTokenFromOkta,
};
