import { get, merge } from 'lodash';
import { TIERS_MAPPING, PRODUCT_NAME } from '../_constants';
import { addRequiredFieldsForPatch } from '_common/utils/directoryUtils';
import type { ApproveRequestConfig } from 'merchants/stores/merchantsStore';
import {
  formatEmailAddress,
  toWebSafeFormat,
  trimStrings,
} from '_common/utils';
import { getCustomerPaymentsConfig } from '../_utils';
import { ausPostLogo } from '../_assets';

export default {
  settingCommunicationsStore: {
    defaultLogoUrl: ausPostLogo,
    DEFAULT_SPEED_OPTION: 'PR',
    getUpdatedPaymentsConfig: ({ companyModel, speedOption }) => {
      const pricingTier = get(
        companyModel,
        `products.${PRODUCT_NAME}.customerPayments.pricingTier`
      );
      return addRequiredFieldsForPatch(companyModel, {
        products: {
          [PRODUCT_NAME]: {
            customerPayments: {
              price: TIERS_MAPPING[pricingTier][speedOption],
            },
          },
        },
        returns: {
          returnsSpeedType: speedOption,
        },
      }).updatedConfig;
    },
  },
  merchantsStore: {
    getSignUpFlowConfig: config => config,
    getBodyMerchantSignUpRequestConfig: (
      approveRequestConfig: ApproveRequestConfig
    ) => {
      const {
        request: {
          requestId,
          applicant: {
            contactName,
            companyName,
            emailAddress,
            phoneNumber,
            legalEntity,
          },
        },
        initialConfig,
      } = approveRequestConfig;

      const pricingTier = get(
        initialConfig,
        `products.${PRODUCT_NAME}.customerPayments.pricingTier`
      );
      const companyId = toWebSafeFormat(companyName);
      const redirectUrl = `${window.location.origin}/${companyId}/login`;
      const data = {
        inviteType: 'merchant_sign_up',
        login: formatEmailAddress(emailAddress),
        companyId,
        redirectUrl,
        companyName,
        contactName,
        phoneNumber,
        requestId,
        legalEntity,
        ...merge(
          initialConfig,
          getCustomerPaymentsConfig({
            returnsSpeedType: 'PR',
            pricingTier,
            withConsumerApiKey: true,
          }),
          { returns: { returnsSpeedType: 'PR' } }
        ),
      };
      return trimStrings(data);
    },
  },
};
