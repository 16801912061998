// @flow

import React from 'react';
import { inject, observer } from 'mobx-react';

import type { Store } from 'locate/stores/locationStore';
import LocationStore from 'locate/stores/locationStore';
import {
  LocationAddress,
  LocationDistance,
  LocationInfo,
  LocationItem,
  LocationOhTableBody,
  LocationOhTableData,
  LocationOhTableRow,
  LocationOpeningHours,
  LocationOpeningHoursTitle,
  LocationSeeMoreBtn,
  LocationSubInfo,
  LocationTitle,
  TooltipText,
} from './StoreItemElements';

import { DAYS } from '_common/constants/date';
import { get } from 'lodash';
import { printerDarkIcon, printerRedIcon } from 'assets';
import { Tooltip } from '_common/components';
import { isMobile } from 'react-device-detect';
import {
  LOCATIONS_MAPPER,
  normalizeStoreName,
} from '../../utils/locationUtils';

type Props = {
  isActive: boolean,
  store: Store,
  locationStore: LocationStore,
  onClick?: (store: Store) => void,
};

type State = {};

@observer
class StoreItem extends React.Component<Props, State> {

  rootRef = React.createRef<HTMLElement>();

  state = {
    seeMoreBtn: false,
  };

  componentDidUpdate(props: Props) {
    if (this.props.isActive && !props.isActive && this.rootRef.current) {
      if (!isMobile) {
        this.rootRef.current.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      } else {
        this.rootRef.current.scrollIntoView({
          block: 'nearest',
          behavior: 'auto',
        });
      }
    }
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.store);
    }
  };

  getFormattedTime = timeString =>
    `${timeString.substring(0, 2)}:${timeString.substring(2, 4)}`;

  getWorkingTimeText = ({ hours, isOpen }) => {
    if (!isOpen) {
      return 'Closed';
    }

    return hours
      .reduce((accumulated, [openTime, closeTime]) => {
        const timeRange = `${this.getFormattedTime(
          openTime
        )} - ${this.getFormattedTime(closeTime)}`;
        accumulated.push(timeRange);
        return accumulated;
      }, [])
      .join(' & ');
  };

  renderOpeningHoursTable = openingHours => {
    return (
      <LocationOhTableBody>
        {DAYS.map((day, i) => {
          const dayLowerCase = day.toLowerCase();
          return (
            <LocationOhTableRow key={DAYS[i]}>
              <LocationOhTableData>{day}</LocationOhTableData>
              {openingHours[dayLowerCase].isOpen ? (
                openingHours[dayLowerCase].hours[0].map((hour, idx) => {
                  return (
                    <LocationOhTableData key={idx}>
                      {hour.substring(0, 2) + ':' + hour.substring(2, 4)}
                    </LocationOhTableData>
                  );
                })
              ) : (
                <LocationOhTableData>Closed</LocationOhTableData>
              )}
            </LocationOhTableRow>
          );
        })}
      </LocationOhTableBody>
    );
  };

  renderMoreStoreDetails = openingHours => (
    <>
      <LocationOpeningHoursTitle>Opening hours</LocationOpeningHoursTitle>
      <table>
        <LocationOhTableBody>
          {DAYS.map(({ fullName, shortName }) => (
            <LocationOhTableRow key={fullName}>
              <LocationOhTableData>{shortName}</LocationOhTableData>
              <LocationOhTableData>
                {this.getWorkingTimeText(openingHours[fullName])}
              </LocationOhTableData>
            </LocationOhTableRow>
          ))}
        </LocationOhTableBody>
      </table>
    </>
  );

  seeMoreClickHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(prevState => ({
      seeMoreBtn: !prevState.seeMoreBtn,
    }));
  };

  renderTooltipTitle = () => (
    <>
      <img src={printerRedIcon} alt="Printer logo" />
      <TooltipText>Paperless returns available</TooltipText>
    </>
  );

  get acceptsPaperless() {
    const {
      store: { services, locationType },
    } = this.props;
    const thirdPartyStoreType = LOCATIONS_MAPPER.OTHER_RETAILERS;
    return locationType === thirdPartyStoreType
      ? true
      : get(services, 'RETURNS.acceptsPaperless', false);
  }

  render() {
    const {
      isActive,
      store: {
        storeName,
        locationType,
        place: { address },
        openingHours,
        locationInfo,
      },
    } = this.props;

    const distance = this.props.locationStore.getDistanceToLastSearch(
      locationInfo
    );

    return (
      <LocationItem
        isActive={isActive}
        ref={this.rootRef}
        onClick={this.onClick}
      >
        <LocationInfo>
          <LocationTitle>
            {normalizeStoreName(storeName, locationType)}
          </LocationTitle>
          {this.acceptsPaperless && (
            <Tooltip placement="right" title={this.renderTooltipTitle}>
              <img src={printerDarkIcon} alt="Printer logo" />
            </Tooltip>
          )}
        </LocationInfo>

        <LocationSubInfo>
          <LocationAddress>
            {address.line1}, {address.line2 ? `${address.line2}, ` : null}
            {address.town}, {address.area}, {address.postcode}
          </LocationAddress>
          <LocationDistance>{distance}</LocationDistance>
        </LocationSubInfo>

        {openingHours ? (
          <LocationSeeMoreBtn onClick={this.seeMoreClickHandler}>
            {this.state.seeMoreBtn ? 'See less' : 'See more'}
          </LocationSeeMoreBtn>
        ) : null}
        {this.state.seeMoreBtn && openingHours ? (
          <>
            <LocationOpeningHours>
              {this.renderMoreStoreDetails(openingHours)}
            </LocationOpeningHours>
          </>
        ) : null}
      </LocationItem>
    );
  }

}

export default inject('locationStore')(StoreItem);
