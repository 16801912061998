import styled, { css } from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Root = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Item = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.book};
  font-size: 12px;
  color: #2b3034;
  padding: 4px 0;
  border-bottom: 1px solid #eeeeee;
  height: 60px;
  display: flex;
  align-items: center;
`;

export const Button = styled(AntdButton)`
  &.ant-btn {
    margin-left: auto;
    border: solid 1px #979797;
    font-family: ${props => props.theme.fonts.book};
    border-radius: 32px;
    height: 36px;
    width: 100px;
    transition: all 700ms ease-in-out;
  }

  ${props =>
    props['data-sent'] &&
    css`
      &.ant-btn,
      &.ant-btn:focus,
      &.ant-btn:hover {
        border-color: #38b13a;
        background-color: #38b13a;
        color: #fff;
      }
    `}
`;
