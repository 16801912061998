import styled from 'styled-components';

export const StyledButton = styled.button`
  border-radius: 21px;
  background-color: #4a90e2;
  font-size: 16px;
  padding: 0 50px;
  height: 42px;
  color: #fff;
  font-family: ${props => props.theme.fonts.book};
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #32659e;
  }

  &:disabled {
    background-color: rgba(74, 144, 226, 0.3);
    cursor: not-allowed;
  }
`;
