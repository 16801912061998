// @flow

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'antd';
import lodashGet from 'lodash/get';

import type { TMerchant } from 'merchants/models';
import {
  Row,
  CardWrapper,
  Cell,
  CellTitle,
  CellValue,
  CellBody,
  PrimaryButton,
  SecondaryButton,
  CellSpaceBetween,
  ButtonsWrapper,
  ResendActivationEmailButton,
} from 'merchants/pages/elements/commonCard';
import MerchantLogo from '../MerchantLogo/MerchantLogo';
import merchantActions from 'merchants/actions';
import MerchantsStore from 'merchants/stores/merchantsStore';
import type { MerchantDetails } from '_common/services/assetManagement';
import { getValueWithDefault } from '_common/utils';
import {
  WhiteLabelUtils,
  WhiteLabelComponents,
  WhiteLabelUi,
} from '_common/whitelabelConfig';
import DeleteConfirmation from './DeleteConfirmation';
import { NOTIFICATIONS_DISPLAY_TIME } from '_common/constants/timeout';
import { AsyncStatus, COMPANY_NAME_LABELS } from '_common/constants/common';
import { Tick } from 'assets';

type Props = {
  merchant: TMerchant,
  merchantStore: MerchantsStore,
  onEnterEditMode: (companyId: string, companyName: string) => void,
};

type State = {
  isLoading: boolean,
  isDeleteModalOpen: false,
  isDeleteCompleted: false,
  resendActivationStatus: AsyncStatus,
};

/** Hardcoded "Operational" value here approved in TECH-5006 */
@inject('merchantStore')
@observer
class MerchantCard extends React.Component<Props, State> {

  state = {
    isLoading: false,
    isDeleteModalOpen: false,
    isDeleteCompleted: false,
    resendActivationStatus: AsyncStatus.IDLE,
  };

  componentWillUnmount() {
    merchantActions.setEditingInOverviewCompanyId(null);
  }

  handleToggleEditMode = () => {
    merchantActions.setEditingInOverviewCompanyId(
      this.props.merchant.companyId
    );
    this.setState({ isDeleteModalOpen: false, isDeleteCompleted: false });
  };

  handleToggleDeleteMode = () => {
    this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });
  };

  handleResendActivationEmail = async () => {
    this.setState({ resendActivationStatus: AsyncStatus.LOADING });

    try {
      await merchantActions.resendActivationEmail(this.props.merchant);

      this.setState({ resendActivationStatus: AsyncStatus.SUCCESS });
      setTimeout(() => {
        this.setState({ resendActivationStatus: AsyncStatus.IDLE });
      }, NOTIFICATIONS_DISPLAY_TIME);
    } catch (e) {
      this.setState({ resendActivationStatus: AsyncStatus.FAILED });
    }
  };

  handleReviewSetup = () => {
    const {
      onEnterEditMode,
      merchant: { companyId, companyName },
    } = this.props;
    onEnterEditMode(companyId, companyName);
  };

  handleSave = () => {
    const {
      form: { validateFieldsAndScroll },
      merchant,
      merchantStore: { editingInOverviewCompanyConfig },
    } = this.props;

    validateFieldsAndScroll(async err => {
      if (err) {
        return;
      }
      try {
        this.setState({ isLoading: true });
        if (editingInOverviewCompanyConfig) {
          await merchantActions.updateMerchantConfigAndRefresh(
            merchant.companyId,
            WhiteLabelUtils.mapToChannelModelForPatch(
              merchant,
              editingInOverviewCompanyConfig
            )
          );
        } else {
          this.handleToggleEditMode();
        }
      } finally {
        this.setState({ isLoading: false });
      }
    });
  };

  handleDeleteMerchant = async () => {
    const {
      merchant: { companyId },
    } = this.props;
    try {
      this.setState({ isLoading: true });

      /** This callback should remove deleted merchant from store after delay */
      const postDeleteCallback = await merchantActions.deleteMerchant(
        companyId
      );
      this.showTemporalNotification(
        'isDeleteCompleted',
        'isDeleteModalOpen',
        postDeleteCallback
      );
    } catch {
      this.setState({ isLoading: false });
    }
  };

  showTemporalNotification = (keyOpen, keyClose, postDeleteCallback) => {
    this.setState({
      [keyOpen]: true,
    });

    /** hide notification after 3s. */
    setTimeout(() => {
      this.setState({ [keyOpen]: false, [keyClose]: false });
      postDeleteCallback();
    }, NOTIFICATIONS_DISPLAY_TIME);
  };

  renderActionButtons = (isEditingMode: boolean) => {
    const { isLoading } = this.state;
    const showDeleteButton = lodashGet(
      WhiteLabelUi,
      'pages.merchantCard.deleteMerchantEnabled'
    );
    if (isEditingMode) {
      return (
        <CellSpaceBetween basis={20}>
          <ButtonsWrapper>
            <PrimaryButton disabled={isLoading} onClick={this.handleSave}>
              Save
            </PrimaryButton>
            <SecondaryButton
              disabled={isLoading}
              onClick={this.handleReviewSetup}
            >
              Review set up
            </SecondaryButton>
          </ButtonsWrapper>
          {showDeleteButton && (
            <SecondaryButton
              disabled={isLoading}
              onClick={this.handleToggleDeleteMode}
            >
              Delete portal
            </SecondaryButton>
          )}
        </CellSpaceBetween>
      );
    } else {
      return (
        <CellSpaceBetween basis={20} marginTop={16}>
          <PrimaryButton
            disabled={isLoading}
            onClick={this.handleToggleEditMode}
          >
            Edit
          </PrimaryButton>
        </CellSpaceBetween>
      );
    }
  };

  renderDetails = () => {
    const {
      merchant: { contactName, phoneNumber, emailAddress },
    } = this.props;
    const { resendActivationStatus } = this.state;
    const isActivationEmailResend =
      resendActivationStatus === AsyncStatus.SUCCESS;

    return (
      <>
        <CellBody>
          <CellTitle>Customer name</CellTitle>
          <CellValue>{getValueWithDefault(contactName)}</CellValue>
        </CellBody>
        <CellBody>
          <CellTitle>Email address</CellTitle>
          <CellValue>{getValueWithDefault(emailAddress)}</CellValue>
        </CellBody>
        <CellBody>
          <CellTitle>Phone number</CellTitle>
          <CellValue>{getValueWithDefault(phoneNumber)}</CellValue>
        </CellBody>
        <CellBody>
          <CellTitle>Resend activation email</CellTitle>
          <CellValue>
            <ResendActivationEmailButton
              isActivationEmailResend={isActivationEmailResend}
              disabled={resendActivationStatus === AsyncStatus.LOADING}
              onClick={this.handleResendActivationEmail}
            >
              {isActivationEmailResend ? (
                <img src={Tick} alt="Tick" />
              ) : (
                <div>Resend</div>
              )}
            </ResendActivationEmailButton>
          </CellValue>
        </CellBody>
      </>
    );
  };

  render() {
    const {
      form,
      merchant,
      merchant: { companyId, companyName, legalEntity },
      merchantStore: { editingInOverviewCompanyId },
    } = this.props;
    const { isDeleteModalOpen, isLoading, isDeleteCompleted } = this.state;
    const isEditingMode =
      editingInOverviewCompanyId && editingInOverviewCompanyId === companyId;

    return (
      <CardWrapper>
        <Form>
          <Row>
            <MerchantLogo companyName={companyName} companyId={companyId} />
            <Cell basis={30}>
              <CellBody>
                <CellTitle marginTop={10}>
                  {COMPANY_NAME_LABELS.COMPANY_NAME}
                </CellTitle>
                <CellValue>{companyName}</CellValue>
              </CellBody>
              {legalEntity && (
                <CellBody>
                  <CellTitle>Company name / legal entity</CellTitle>
                  <CellValue>{legalEntity}</CellValue>
                </CellBody>
              )}
              {isEditingMode && this.renderDetails()}
            </Cell>
            <Cell basis={50}>
              <CellBody>
                <CellTitle marginTop={10}>Status</CellTitle>
                <CellValue>Operational</CellValue>
              </CellBody>
              {isEditingMode && (
                <WhiteLabelComponents.AdminFieldsForm
                  merchant={merchant}
                  form={form}
                />
              )}
            </Cell>
            {this.renderActionButtons(isEditingMode)}
          </Row>
          {isEditingMode && isDeleteModalOpen && (
            <DeleteConfirmation
              toggle={this.handleToggleDeleteMode}
              action={this.handleDeleteMerchant}
              loadingState={isLoading}
              isDeleteCompleted={isDeleteCompleted}
            />
          )}
        </Form>
      </CardWrapper>
    );
  }

}

export default Form.create({
  onValuesChange(props: Props, values: MerchantDetails) {
    merchantActions.setEditingInOverviewCompanyConfig(values);
  },
})(MerchantCard);
