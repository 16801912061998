// @flow

import React from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import { geoIcon } from 'assets';
import {
  Button,
  ClearButton,
  ErrorMessage,
  GeoIcon,
  Input,
  InputWrapper,
  Root,
  Select,
} from './SearchHeaderElements';
import locationActions from 'locate/actions';
import LocationStore from 'locate/stores/locationStore';
import { WhiteLabelUi } from '_common/whitelabelConfig';

const { Option } = Select;

type Props = {
  locationStore: LocationStore,
  logFieldEvent: (eventName: string, extraPayload: ?Object) => void,
  company: string,
  disabled: boolean,
};

type State = {
  hasError: boolean,
  isSearchButtonDisabled: boolean,
};

@observer
class SearchHeader extends React.Component<Props, State> {

  autocompleteRef = React.createRef();

  state = {
    hasError: false,
    isSearchButtonDisabled: false,
  };

  getSearchInput = () => {
    return this.autocompleteRef.current;
  };

  onGeoIconClick = async () => {
    const searchInput = this.autocompleteRef && this.autocompleteRef.current;
    if (!searchInput || this.props.disabled) return;
    searchInput.value = '';
    searchInput.value = await locationActions.searchStoresNearMe(
      this.props.company
    );
  };

  onSearchClick = async () => {
    this.setState({ hasError: false });
    try {
      await locationActions.searchFromLastLocation();
    } catch (e) {
      this.setState({ hasError: true });
    }
  };

  handleClearSearch = () => {
    this.autocompleteRef.current.value = '';
    this.setState({ isSearchButtonDisabled: true });
  };

  changeSearchButtonState = ({ target: { value } }) => {
    const isSearchButtonDisabled = !value || !value.trim();
    this.setState({ isSearchButtonDisabled });
  };

  renderOption = (option: { value: string, label: string }, index: number) => {
    const { value, label } = option;
    return (
      <Option key={`option-${index}`} value={value}>
        {label}
      </Option>
    );
  };

  renderSelect() {
    const { locationTypesOptions } = this.props.locationStore;
    const { disabled } = this.props;
    const defaultValue = locationTypesOptions.length
      ? locationTypesOptions[0].value
      : null;
    return (
      <Select
        defaultValue={defaultValue}
        placeholder="Filter by location type"
        onChange={locationActions.setLocationType}
        disabled={disabled}
      >
        {locationTypesOptions.map(this.renderOption)}
      </Select>
    );
  }

  render() {
    const {
      disabled,
      locationStore: { possibleSearch, lastSearch },
    } = this.props;
    const { hasError, isSearchButtonDisabled } = this.state;
    const icon = (
      <GeoIcon
        image={geoIcon}
        onClick={this.onGeoIconClick}
        width={24}
        height={24}
        disabled={disabled}
      />
    );

    const isDisabled =
      isSearchButtonDisabled || disabled || (!possibleSearch && !lastSearch);
    return (
      <Root>
        <InputWrapper>
          <Input
            placeholder={get(WhiteLabelUi, 'pages.locate.searchPlaceholder')}
            ref={this.autocompleteRef}
            onChange={this.changeSearchButtonState}
            disabled={disabled}
          />
          <ClearButton onClick={this.handleClearSearch}>Clear</ClearButton>
          {icon}
          {this.renderSelect()}
          <Button onClick={this.onSearchClick} disabled={isDisabled}>
            Search
          </Button>
        </InputWrapper>
        {hasError && <ErrorMessage>Error while loading</ErrorMessage>}
      </Root>
    );
  }

}

export default compose(inject('locationStore'))(SearchHeader);
