import styled from 'styled-components';
import { Panel as CommonPanel } from '_common/components/CommonStyledElements';

export const Panel = styled(CommonPanel)`
  margin-top: 17px;
`;

export const ErrorMessage = styled.div`
  margin-top: 20px;
`;
