// @flow

import * as React from 'react';
import { noop } from 'lodash';
import {
  Root,
  LabelWrapper,
  Label,
  StyledSwitch,
  Stretch,
  LeftBorder,
} from './SwitchElements';

type Props = {
  label?: string,
  onChange: (checked: boolean, label: string) => void,
  disabled?: boolean,
  menuComponent?: React.Node,
  labelComponent?: React.Node,
  withoutDisabledLabel?: boolean,
  noToggle?: boolean,
};

type State = {};

class Switch extends React.Component<Props, State> {

  static defaultProps = {
    onChange: noop,
  };

  handleChange = (checked: boolean) => {
    this.props.onChange(checked, this.props.label);
  };

  render() {
    const {
      label,
      menuComponent,
      labelComponent,
      disabled,
      withLeftBorder,
      withoutDisabledLabel,
      noToggle,
      ...rest
    } = this.props;

    return (
      <Root>
        {labelComponent || (
          <LabelWrapper borderRight={!noToggle}>
            <Label disabled={disabled}>
              {label && label}{' '}
              {disabled && !withoutDisabledLabel ? '(disabled)' : ''}
            </Label>
            <Stretch /> {menuComponent}
          </LabelWrapper>
        )}
        {!noToggle && (
          <>
            {withLeftBorder && <LeftBorder />}
            <StyledSwitch
              {...rest}
              disabled={disabled}
              onChange={this.handleChange}
            />
          </>
        )}
      </Root>
    );
  }

}

export default Switch;
