// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AuthStore, AssetManagementStore, UIStore } from 'stores';
import { get } from 'lodash';
import {
  Wrapper,
  LogoContainer,
  MerchantWrapper,
  CarrierLogoContainer,
} from './elements';
import apiEndpoins from '_common/api/endpoints';
import { getAssetsUrlFromBucket } from '_common/utils';
import { WhiteLabelUi } from '_common/whitelabelConfig';
import { ImageLoader } from '_common/components';

type Props = {
  uiStore: UIStore,
  authStore: AuthStore,
  assetManagementStore: AssetManagementStore,
};

@inject('uiStore', 'authStore', 'assetManagementStore')
@observer
class Logo extends Component<Props> {

  constructor(props) {
    super(props);
    this.commonConfig = WhiteLabelUi.common;
  }

  renderImage = src => (
    <LogoContainer>
      <img src={src} alt="company logo" />
    </LogoContainer>
  );

  render() {
    const {
      assetManagementStore,
      authStore: { isMerchantUser },
    } = this.props;

    if (!isMerchantUser) {
      return (
        <Wrapper>
          <CarrierLogoContainer>
            {this.commonConfig.carrierLogo}
          </CarrierLogoContainer>
        </Wrapper>
      );
    }

    const logoImageUrl = getAssetsUrlFromBucket(
      get(assetManagementStore, 'assetsConfig.assets.logoImage')
    );

    const logoSrc =
      assetManagementStore.latestPublishedLogo ||
      `${apiEndpoins.ASSETS_URL}/${logoImageUrl}`;

    return (
      <MerchantWrapper>
        <CarrierLogoContainer>
          {this.commonConfig.carrierLogo}
        </CarrierLogoContainer>
        <ImageLoader
          fallbackProp={null}
          imageProp={this.renderImage}
          src={logoSrc}
          shouldFetch={!!logoImageUrl}
        />
      </MerchantWrapper>
    );
  }

}

export default Logo;
