// @flow

import React, { Component } from 'react';
import { LoaderContainer } from './elements';

class Loader extends Component<{}, {}> {

  render() {
    return (
      <LoaderContainer>
        <div />
      </LoaderContainer>
    );
  }

}

export default Loader;
