import styled from 'styled-components';

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${({ maxWidth }) => maxWidth || 308}px;
  @media only screen and (max-width: 1440px) {
    max-width: initial;
  }
`;
