// @flow

import React from 'react';
import { Form } from 'antd';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import {
  Row,
  CardWrapper,
  Cell,
  CellTitle,
  CellValue,
  CellBody,
  CellButtons,
  PrimaryButton,
  SecondaryButton,
} from '../../elements/commonCard';
import { pendingCircleIcon, iconCircleCrossRed, iconCircleTick } from 'assets';
import MerchantLogo from '../MerchantLogo/MerchantLogo';
import merchantActions from 'merchants/actions';
import MerchantsStore from 'merchants/stores/merchantsStore';
import type { RequestsApiResponse } from 'merchants/services/merchantsService';
import DeclineConfirmation from './DeclineConfirmation';
import AsyncProcessCard from 'merchants/pages/components/AsyncProcessCard';
import { NOTIFICATIONS_DISPLAY_TIME } from '_common/constants/timeout';
import { getValueWithDefault } from '_common/utils';
import {
  WhiteLabelServices,
  WhiteLabelUi,
  WhiteLabelComponents,
  WhiteLabelUtils,
} from '_common/whitelabelConfig';
import { COMPANY_NAME_LABELS } from '_common/constants/common';

const STATES = {
  /** Nothing running */
  IDLE: 'IDLE',
  /** Running declining mode */
  DECLINING: 'DECLINING',
  /** Async process running */
  PROCESSING: 'PROCESSING',
  /** Process was completed and merch approved */
  SUCCESS: 'SUCCESS',
  /** error while approving */
  FAILED: 'FAILED',
};

type Props = {
  request: RequestsApiResponse,
  merchantStore: MerchantsStore,
};

type State = {
  currentState: STATES,
};

@inject('merchantStore')
@observer
class RequestCard extends React.Component<Props, State> {

  state = {
    currentState: STATES.IDLE,
    config: WhiteLabelUi.pages.requestCard,
  };

  @computed
  get isEditMode() {
    const {
      request: { requestId },
      merchantStore: { editingMerchantRequestId },
    } = this.props;
    return requestId === editingMerchantRequestId;
  }

  componentWillUnmount() {
    merchantActions.setEditingMerchantRequestId(null);
  }

  /** Running merchant's request approving process */
  handleApproveMerchantRequest = () => {
    const {
      request,
      form: { validateFieldsAndScroll },
    } = this.props;

    validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      try {
        this.setState({ currentState: STATES.PROCESSING });
        //
        await merchantActions.approveRequest({
          request,
          initialConfig: values,
        });
        this.setState({ currentState: STATES.SUCCESS });

        /** Need to remove this request from list */
        setTimeout(
          () => this.removeRequestFromPending(),
          NOTIFICATIONS_DISPLAY_TIME
        );
      } catch (e) {
        // error handled in action.
        console.error('Merchant card error ::', e);
        this.setState({ currentState: STATES.FAILED });
      }
    });
  };

  /** Filter-out request from list in store. */
  removeRequestFromPending = () => {
    merchantActions.removeRequestFromPending(this.props.request.requestId);
  };

  handleChangeCurrentState = (newState: STATES) => () => {
    this.setState({ currentState: newState });
  };

  /** Show/close admin input fields. */
  handleEditMerchantRequest = (requestId: string | null) => () => {
    merchantActions.setEditingMerchantRequestId(requestId);
  };

  renderActionButtons = () => {
    const {
      request: { requestId },
    } = this.props;

    return (
      <CellButtons basis={20} marginTop={10}>
        {this.isEditMode ? (
          <>
            <PrimaryButton onClick={this.handleApproveMerchantRequest}>
              Confirm
            </PrimaryButton>
            <SecondaryButton onClick={this.handleEditMerchantRequest(null)}>
              Cancel
            </SecondaryButton>
          </>
        ) : (
          <>
            <PrimaryButton onClick={this.handleEditMerchantRequest(requestId)}>
              Onboard
            </PrimaryButton>
            <SecondaryButton
              onClick={this.handleChangeCurrentState(STATES.DECLINING)}
            >
              Decline
            </SecondaryButton>
          </>
        )}
      </CellButtons>
    );
  };

  /** Render inputs of form depending of current state */
  renderFromElements = () => {
    const { currentState } = this.state;
    const {
      form,
      request: { applicant },
    } = this.props;

    if (this.isEditMode) {
      return (
        <WhiteLabelComponents.AdminFieldsForm
          merchant={WhiteLabelUtils.getInitialAdminFieldsModel(applicant)}
          form={form}
        />
      );
    }

    switch (currentState) {
      case STATES.PROCESSING:
      case STATES.SUCCESS:
      case STATES.FAILED:
        return (
          <WhiteLabelComponents.AdminFieldsForm
            merchant={WhiteLabelUtils.getInitialAdminFieldsModel(applicant)}
            form={form}
          />
        );
      default:
        return null;
    }
  };

  renderDynamicDetailsCell = () => {
    const { currentState } = this.state;
    const {
      request: {
        applicant: { contactName, emailAddress },
        requestId,
      },
    } = this.props;

    if (currentState === STATES.DECLINING) {
      const emailConfig = WhiteLabelServices.emailConfigForReject(
        emailAddress,
        contactName
      );
      return (
        <DeclineConfirmation
          emailConfig={emailConfig}
          requestId={requestId}
          onCancelDeclining={this.handleChangeCurrentState(STATES.IDLE)}
        />
      );
    }

    return (
      <>
        <Cell basis={40}>
          <CellBody>
            <CellTitle marginTop={10}>Status</CellTitle>
            <CellValue>Awaiting approval</CellValue>
          </CellBody>
          {this.renderFromElements()}
        </Cell>
        {this.renderActionButtons()}
      </>
    );
  };

  renderStaticDetailsCell = () => {
    const {
      request: {
        applicant: {
          companyName,
          contactName,
          emailAddress,
          phoneNumber,
          legalEntity,
        },
      },
    } = this.props;

    return (
      <Cell basis={40}>
        <CellBody>
          <CellTitle marginTop={10}>
            {COMPANY_NAME_LABELS.COMPANY_NAME}
          </CellTitle>
          <CellValue>{getValueWithDefault(companyName)}</CellValue>
        </CellBody>
        {legalEntity && (
          <CellBody>
            <CellTitle>{COMPANY_NAME_LABELS.LEGAL_ENTITY}</CellTitle>
            <CellValue>{legalEntity}</CellValue>
          </CellBody>
        )}
        {this.isEditMode && (
          <>
            <CellBody>
              <CellTitle>Customer name</CellTitle>
              <CellValue>{getValueWithDefault(contactName)}</CellValue>
            </CellBody>
            <CellBody>
              <CellTitle>Email address</CellTitle>
              <CellValue>{getValueWithDefault(emailAddress)}</CellValue>
            </CellBody>
            <CellBody>
              <CellTitle>Phone number</CellTitle>
              <CellValue>{getValueWithDefault(phoneNumber)}</CellValue>
            </CellBody>
          </>
        )}
      </Cell>
    );
  };

  renderOverlay = () => {
    const { currentState } = this.state;
    const {
      request: {
        applicant: { companyName },
      },
    } = this.props;

    switch (currentState) {
      case STATES.PROCESSING:
        return (
          <AsyncProcessCard
            icon={pendingCircleIcon}
            status="Request processing"
            subStatus={`for ${companyName}`}
          />
        );
      case STATES.FAILED:
        return (
          <AsyncProcessCard
            onClick={this.removeRequestFromPending}
            icon={iconCircleCrossRed}
            status="Error onboarding this merchant."
            subStatus="This request will be removed."
          />
        );
      case STATES.SUCCESS:
        return (
          <AsyncProcessCard
            icon={iconCircleTick}
            status="Request accepted"
            subStatus={`from ${companyName}`}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      request: {
        applicant: { companyName },
      },
    } = this.props;
    const { currentState } = this.state;

    return (
      <CardWrapper isDeclineMode={currentState === STATES.DECLINING}>
        <Form>
          <Row>
            <MerchantLogo
              companyName={companyName}
              isDefaultImageOnly
              darkMode={currentState === STATES.DECLINING}
            />
            {this.renderStaticDetailsCell()}
            {this.renderDynamicDetailsCell()}
            {this.renderOverlay()}
          </Row>
        </Form>
      </CardWrapper>
    );
  }

}

export default Form.create()(RequestCard);
