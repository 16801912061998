import styled from 'styled-components';
import { iconCircleCrossRed } from 'assets';

export const DeclineConfirmationWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #979797;
  background-color: #f4f4f5;
  padding: 0 10px 0 20px;
`;

export const RejectIcon = styled.div`
  min-width: 50px;
  min-height: 50px;
  flex-shrink: 0;
  background-size: contain;
  background: url(${iconCircleCrossRed}) no-repeat center;
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #222222;
  margin-left: 25px;
  margin-right: auto;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.alignCenter && `align-items: center`}
`;
