// @flow
/* eslint camelcase: 0 */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Menu } from '_common/components';
import {
  Page,
  Content,
  PageHeader,
  PageTitle,
} from '_common/components/CommonStyledElements';
import TrackingStore from '../stores/trackingStore';
import SearchInput from '../components/SearchInput/SearchInput';
import TrackingTable from '../components/TrackingTable/TrackingTable';
import { Panel, ErrorMessage } from './TrackingPageElements';
import trackingActions from 'tracking/actions';
import Amplitude from '_common/utils/amplitude';

type Props = {
  trackingStore: TrackingStore,
};
type State = {};

@inject('trackingStore')
@observer
class TrackingPage extends Component<Props, State> {

  componentDidMount() {
    Amplitude.logEvent('page_open', { page_name: 'Tracking' });
  }

  componentWillUnmount() {
    trackingActions.resetStore();
  }

  render() {
    const { returns, isError } = this.props.trackingStore;
    return (
      <Page>
        <Menu />
        <Content>
          <PageHeader>
            <PageTitle>Tracking</PageTitle>
          </PageHeader>
          <SearchInput />
          {!!returns.length && (
            <Panel>
              <TrackingTable returns={returns} />
            </Panel>
          )}
          {isError && (
            <ErrorMessage>
              Sorry, no results were found. Please try again.
            </ErrorMessage>
          )}
        </Content>
      </Page>
    );
  }

}

export default TrackingPage;
