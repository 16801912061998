export const AsyncStatus = Object.freeze({
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  IDLE: 'IDLE',
});

export const ROLE_TYPES = {
  ADMIN: 'ADMIN',
  MERCHANT: 'MERCHANT',
  UNKNOWN: 'UNKNOWN',
};

export const ERROR_LOG_LEVELS = {
  PROD: 'PROD',
  FULL: 'FULL',
};

export const COMPANY_NAME_LABELS = {
  COMPANY_NAME: 'Trading name or Brand',
  LEGAL_ENTITY: 'Company name / legal entity',
};
