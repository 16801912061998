import stores from 'stores';
import { extractFieldValue } from '_common/utils/companyUtils';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import changeCompanyModel from './changeCompanyModel';
import changePaymentType from './changePaymentType';
import Amplitude from '_common/utils/amplitude';
import { TrackableChanges } from '_common/stores/companyModelStore';
import { TrackingBlocks } from '_common/stores/uiStore';
import commonActions from '_common/actions';

const { PRODUCT_NAME } = WhiteLabelConstants;

export default reasons => {
  Amplitude.unpublishedChanges.add(TrackableChanges.REASON_CODES);
  changeCompanyModel({
    changesObject: {
      products: {
        [PRODUCT_NAME]: {
          returnReasons: {
            reasons,
          },
        },
      },
    },
  });

  const isAllReasonsDisabled = reasons.every(({ enabled }) => !enabled);
  const isAllAvailableReasonsRequiresPayment = reasons
    .filter(({ enabled }) => enabled)
    .every(({ paymentRequired }) => paymentRequired);
  const isAllAvailableReasonsNotRequiresPayment = reasons
    .filter(({ enabled }) => enabled)
    .every(({ paymentRequired }) => !paymentRequired);

  stores.companyModelStore.setBothPaid(
    isAllReasonsDisabled ||
      isAllAvailableReasonsRequiresPayment ||
      isAllAvailableReasonsNotRequiresPayment
  );

  const PATH = `products.${PRODUCT_NAME}.customerPayments.paymentType`;
  const paymentType = extractFieldValue({
    path: PATH,
    companyModelStore: stores.companyModelStore,
  });

  commonActions.trackChange(
    TrackingBlocks.PaymentAndReasonCodes,
    TrackableChanges.REASON_CODES
  );
  /** Need to check additional logic on Disable/Enable reason changes. */

  if (isAllReasonsDisabled) {
    /** Use Merchant paid as default */

    if (paymentType === 'BOTH_PAID') {
      commonActions.trackChange(
        TrackingBlocks.PaymentAndReasonCodes,
        TrackableChanges.PAYMENT_METHOD,
        paymentType,
        'MERCHANT_PAID'
      );
      Amplitude.unpublishedChanges.add(TrackableChanges.PAYMENT_METHOD);
      changePaymentType('MERCHANT_PAID');
    }

    if (paymentType === 'CONSUMER_PAID') {
      return;
    }
  }

  if (!isAllReasonsDisabled) {
    if (isAllAvailableReasonsRequiresPayment) {
      stores.companyModelStore.trackPaymentChange('CONSUMER_PAID');
      Amplitude.unpublishedChanges.add(TrackableChanges.PAYMENT_METHOD);
      return changeCompanyModel({
        path: PATH,
        singleValue: 'CONSUMER_PAID',
      });
    }
    if (isAllAvailableReasonsNotRequiresPayment) {
      stores.companyModelStore.trackPaymentChange('MERCHANT_PAID');
      Amplitude.unpublishedChanges.add(TrackableChanges.PAYMENT_METHOD);
      return changeCompanyModel({
        path: PATH,
        singleValue: 'MERCHANT_PAID',
      });
    }
    if (paymentType !== 'BOTH_PAID') {
      stores.companyModelStore.trackPaymentChange('BOTH_PAID');
      Amplitude.unpublishedChanges.add(TrackableChanges.PAYMENT_METHOD);
      return changeCompanyModel({
        path: PATH,
        singleValue: 'BOTH_PAID',
      });
    }
  }
};
