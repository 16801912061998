// @flow

import apiEndpoins from '_common/api/endpoints';
import {
  API_ERROR_CODES,
  AXIOS_CANCELLED,
} from '_common/constants/apiErrorResponces';
import storage, { TOKEN_KEY } from 'storage';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { Base64 } from 'js-base64';
import createClient from './clientFactory';
import commonActions from '_common/actions';
import { includes } from 'lodash';

const apiKey = process.env.REACT_APP_DODDLE_API_KEY || '';
const apiSecret = process.env.REACT_APP_DODDLE_API_SECRET || '';

const pureClient = createClient({});

const authClient = createClient({
  baseUrl: apiEndpoins.LEGACY_DODDLE_API_URL,
  headers: {
    Authorization: `Basic ${Base64.encode(`${apiKey}:${apiSecret}`)}`,
    'cache-control': 'no-cache',
  },
});

const apiClient = createClient({
  baseUrl: apiEndpoins.LEGACY_DODDLE_API_URL,
  headers: {
    'cache-control': 'no-cache',
  },
});

const assetsClient = createClient({
  baseUrl: apiEndpoins.ASSETS_URL,
  headers: {},
});

const interceptorsReq = (config: AxiosRequestConfig) => {
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
};

const apiClientInterceptorsReq = (config: AxiosRequestConfig) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${storage.get(TOKEN_KEY)}`,
    },
  };
};

const interceptorsRes = (response: AxiosResponse) => {
  const {
    headers,
    config: { url },
    status,
    data,
  } = response;

  /** This is a huge work-around above proxy on cloudfront.
   *  Justification: Proxy always returns HTML file for case if requested resource not found (or 403 also).
   *  We cannot remove that logic, otherwise FE will just fails with error for /url/part, which handled by SPA.
   *  Need to manually throw an error for that case, instead of trying to handle it as success flow.
   * */
  if (
    headers['content-type'] === 'text/html' &&
    headers['x-cache'] === 'Error from cloudfront' &&
    status === 200 &&
    includes(url, 'application-config.json') &&
    includes(data, 'You need to enable JavaScript to run this app.')
  ) {
    const customError: Error & { response: AxiosResponse } = new Error(
      'Application config not found'
    );
    customError.response = response;
    customError.request = response.request;
    customError.config = response.config;
    customError.isAxiosError = true;

    return Promise.reject(customError);
  }
  return response;
};

const interceptorsResError = (error: AxiosError) => {
  if (axios.isCancel(error)) {
    return Promise.reject(AXIOS_CANCELLED);
  }
  if (!error.response) {
    return Promise.reject('Empty error response');
  }

  if (error.response.status === 401) {
    if (
      error.response.data.errors.some(
        (err: { message: string, code: string }) => {
          return (
            err.message === API_ERROR_CODES.InvalidApToken ||
            err.code === API_ERROR_CODES.InvalidUserCredentials
          );
        }
      )
    ) {
      return Promise.reject(error);
    } else {
      commonActions.logout();
    }
  }

  return Promise.reject(error);
};

authClient.interceptors.request.use(interceptorsReq);
authClient.interceptors.response.use(interceptorsRes, interceptorsResError);

apiClient.interceptors.request.use(apiClientInterceptorsReq);
apiClient.interceptors.response.use(interceptorsRes, interceptorsResError);

assetsClient.interceptors.request.use(interceptorsReq);
assetsClient.interceptors.response.use(interceptorsRes, interceptorsResError);

export { authClient, apiClient, assetsClient, pureClient };
