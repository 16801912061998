// @flow

import stores from 'stores';

const sendInvite = (email: string) => {
  const { organisationId } = stores.authStore.loggedUser;
  const { editingCompanyByAdmin } = stores.uiStore;

  return stores.usersStore.sendInvite(
    editingCompanyByAdmin || organisationId,
    email
  );
};

export default sendInvite;
