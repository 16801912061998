import React, { Component } from 'react';
import { Form } from 'antd';

import { Agreement } from 'login/pages/elements/Login';
import { Checkbox } from '_common/components';
import {
  Subtext,
  AgreementText,
  CollectionsCheckboxesWrapper,
} from './elements';

export default class SignUpCheckboxes extends Component {

  renderCheckbox = ({ id, label, rules }) => {
    return (
      <Form.Item key={id}>
        {this.props.getFieldDecorator(id, { initialValue: false, rules })(
          <Agreement>
            <Checkbox />
            <AgreementText>{label}</AgreementText>
          </Agreement>
        )}
      </Form.Item>
    );
  };

  render() {
    const { checkboxes } = this.props;
    return (
      <>
        <Subtext>I would like to register my interest for:</Subtext>
        <CollectionsCheckboxesWrapper>
          {checkboxes && checkboxes.map(this.renderCheckbox)}
        </CollectionsCheckboxesWrapper>
      </>
    );
  }

}
