// @flow

import getSubCompanies from './getSubCompanies';
import getIsMerchantUser from './getIsMerchantUser';
import logout from './logout';
import refreshToken from './refreshToken';
import staffLogin from './staffLogin';
import companyActions from './company';
import assetsActions from './assets';
import appLogin from './appLogin';
import trackChange from './trackChange';
import getChanges from './getChanges';
import validateTokenFromOkta from './validateTokenFromOkta';
import setApplicationErrorMessage from './setApplicationErrorMessage';
import setApplicationNotification from './setApplicationNotification';
import validateAddress from './validateAddress';
import forceTokenRefresh from './forceTokenRefresh';
import getLocationTypes from './getLocationTypes';
import getCompany from './getCompany';
import getIsAdminUser from './getIsAdminUser';
import sendEmailsPreview from './sendEmailsPreview';
import publishBrandingAssets from './publishBrandingAssets';
import setGooglePlacesMainInput from './setGooglePlacesMainInput';
import setMerchantDetails from './setMerchantDetails';
import getDashboardConfigs from './getDashboardConfigs';

export default {
  staffLogin,
  refreshToken,
  logout,
  getSubCompanies,
  getIsMerchantUser,
  companyActions,
  assetsActions,
  appLogin,
  trackChange,
  getChanges,
  validateTokenFromOkta,
  setApplicationNotification,
  setApplicationErrorMessage,
  validateAddress,
  forceTokenRefresh,
  getLocationTypes,
  getCompany,
  getIsAdminUser,
  sendEmailsPreview,
  publishBrandingAssets,
  setGooglePlacesMainInput,
  setMerchantDetails,
  getDashboardConfigs,
};
