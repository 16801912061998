import { Dropdown, Icon, Menu } from 'antd';
import React, { Component } from 'react';
import {
  Avatar,
  AvatarHolder,
  GlobalUserProfileTabStyles,
  IconWrapper,
  UserMenuItem,
} from './elements';
import { inject, observer } from 'mobx-react';
import { CompanyModelStore, UIStore } from 'stores';
import { isEmpty } from 'lodash';
import commonActions from '_common/actions';
import { getCaptionFromContactName } from '_common/utils/companyUtils';
import { withRouter } from 'react-router';
import links from '_common/routes/urls';

type Props = {
  companyModelStore: CompanyModelStore,
  uiStore: UIStore,
};

type State = {
  isVisible: boolean,
};

@inject('companyModelStore', 'uiStore')
@observer
class UserProfileTab extends Component<Props, State> {

  state = {
    isVisible: false,
  };

  handleRedirect = () => {
    const {
      history: { push },
      uiStore: { editingCompanyByAdmin },
      companyModelStore: {
        companyModel: { companyId },
      },
    } = this.props;

    this.setState({ isVisible: false });
    push(editingCompanyByAdmin ? `/users` : `/${companyId}/users`);
  };

  handleLogout = () => {
    const {
      uiStore: { hasUnsavedChanges, editingCompanyByAdmin },
    } = this.props;

    if (editingCompanyByAdmin) {
      const {
        uiStore: { setEditingCompanyByAdmin },
        history: { push },
      } = this.props;
      push(links.overview);
      setEditingCompanyByAdmin();
    } else {
      if (hasUnsavedChanges) {
        window.onbeforeunload = null;
        const confirm = window.confirm('Changes you made may not be saved.');
        if (confirm) {
          commonActions.logout();
        }
      } else {
        commonActions.logout();
      }
    }
  };

  renderMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <UserMenuItem onClick={this.handleRedirect}>Users</UserMenuItem>
        </Menu.Item>
        <Menu.Item>
          <UserMenuItem disabled>Help</UserMenuItem>
        </Menu.Item>
        <Menu.Item>
          <UserMenuItem onClick={this.handleLogout}>Logout</UserMenuItem>
        </Menu.Item>
      </Menu>
    );
  };

  onVisibleChange = isVisible => {
    this.setState({ isVisible });
  };

  render() {
    const { isVisible } = this.state;
    const {
      companyModelStore: { companyModel },
    } = this.props;

    if (isEmpty(companyModel)) {
      return null;
    }

    return (
      <>
        <GlobalUserProfileTabStyles />
        <Dropdown
          visible={isVisible}
          overlay={this.renderMenu()}
          trigger={['click']}
          onVisibleChange={this.onVisibleChange}
          overlayClassName="UserProfileTab"
        >
          <AvatarHolder isVisible={isVisible}>
            <Avatar>{getCaptionFromContactName(companyModel)}</Avatar>
            <IconWrapper>
              <Icon type={isVisible ? 'up' : 'down'} />
            </IconWrapper>
          </AvatarHolder>
        </Dropdown>
      </>
    );
  }

}

export default withRouter(UserProfileTab);
