// @flow

import React from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { StyledColorPreview, ColorSwatch, ColorLabel } from './elements';

type Props = {
  color: string,
};

const ColorPreview = ({ color }: Props) => {
  return (
    <StyledColorPreview>
      <ColorLabel>{color.toUpperCase()}</ColorLabel>
      <ColorSwatch color={color} />
    </StyledColorPreview>
  );
};

export default compose(observer)(ColorPreview);
