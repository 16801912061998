// @flow

import React from 'react';
import { StyledInput } from './elements';

type Props = {
  [key: string]: any,
  getRef?: (ref: React.Ref<React.Component>) => void,
  width?: number,
};

class Input extends React.Component<Props> {

  inputRef = (ref: React.Ref<React.Component>) => {
    if (this.props.getRef) {
      this.props.getRef(ref);
    }
  };

  render() {
    const { getRef, ...rest } = this.props; // excluded getRef prop. DO NOT DELETE!!!

    return <StyledInput innerRef={this.inputRef} {...rest} />;
  }

}

export default Input;
