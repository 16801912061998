// @flow
import stores from 'stores';
import { get } from 'lodash';

const searchReturns = async (searchKey: string) => {
  const isMerchant = stores.authStore.getIsMerchantUser();
  const companyName = get(stores.companyModelStore, 'companyModel.companyName');
  const merchantTrackingName = isMerchant ? companyName : null;
  return stores.trackingStore.searchReturns(searchKey, merchantTrackingName);
};

export default searchReturns;
