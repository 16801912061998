import stores from 'stores';

const changeUserSuspendStatus = (switchIsOn: boolean, login: string) => {
  const { organisationId } = stores.authStore.loggedUser;
  const { editingCompanyByAdmin } = stores.uiStore;
  const organisation = editingCompanyByAdmin || organisationId;

  return switchIsOn
    ? stores.usersStore.enableUser(organisation, login)
    : stores.usersStore.disableUser(organisation, login);
};

export default changeUserSuspendStatus;
