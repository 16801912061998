// @flow
/* eslint camelcase: 0 */

import commonActions from '_common/actions';
import { Input, InputTooltip } from '_common/components';
import { AUTH_TYPE, ROOT_ORG_ID } from '_common/constants/appConfig';
import links from '_common/routes/urls';
import AuthStore from '_common/stores/authStore';
import { extractError } from '_common/utils';
import MerchantsStore from 'merchants/stores/merchantsStore';
import { Form, Col, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import {
  ButtonWrapper,
  ForgotCredsLink,
  FormContainer,
  LoginButton,
  LoginInputLabel,
  LoginTitle,
  Wrapper,
  LoginSubTitle,
} from '../elements/Login';
import OktaLogin from 'login/pages/components/OktaLogin';
import OktaSignInWidget from 'login/pages/components/OktaSignInWidget';
import loginConfig from '../config/LoginConfig';
import { WhiteLabelUi } from '_common/whitelabelConfig';
import Amplitude from '_common/utils/amplitude';

type Props = RouteComponentProps &
  FormComponentProps & {
    merchantStore: MerchantsStore,
    authStore: AuthStore,
  };

@observer
class Login extends Component<Props> {

  constructor(props) {
    super(props);
    this.config = WhiteLabelUi.pages.login;
  }

  async componentDidMount() {
    const {
      match: {
        params: { company },
      },
    } = this.props;
    await commonActions.appLogin();
    company && Amplitude.logWithoutCheck('page_open', { page_name: 'Login' });
  }

  @computed
  get errorMessage() {
    return this.props.merchantStore.error || this.props.authStore.error;
  }

  @computed
  get isLoading() {
    return this.props.merchantStore.isLoading || this.props.authStore.isLoading;
  }

  login = async (e: Event) => {
    e.preventDefault();
    const {
      match: {
        params: { company },
      },
      form: { validateFields, setFields },
      history: { push },
    } = this.props;

    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const organisation = company || ROOT_ORG_ID;
      const eventPayload = {
        user_email: values.login,
        company_name: organisation,
      };

      try {
        await commonActions.staffLogin({
          ...values,
          organisation,
          staffPermissions: [`organisation_${organisation}`],
        });

        Amplitude.logEvent('login_success', eventPayload);
        /** Redirect to Root or Merchant Dashboard */
        if (!company || company === ROOT_ORG_ID) {
          push(links.requests);
        } else {
          push(`/${company}${links.dashboardRoot}`);
        }
      } catch (e) {
        const message = this.errorMessage || extractError(e);

        /** Need to send error login event if merchant attempt was failed */
        if (company && company !== ROOT_ORG_ID) {
          Amplitude.logWithoutCheck('login_error', eventPayload);
        }
        setFields({
          login: {
            value: values.login,
            errors: [new Error(message)],
          },
        });
      }
    });
  };

  /**
   * Redirect to page of restoring password.
   */
  handleForgotPasswordRedirect = () => {
    const {
      match: {
        params: { company },
      },
      history: { push },
    } = this.props;

    push(`/${company || ROOT_ORG_ID}/forgot-password`);
  };

  wrapWithTooltip = (input, tooltip) => {
    if (tooltip) {
      return (
        <InputTooltip
          trigger={['focus', 'hover']}
          title={tooltip}
          placement="right"
          outboundToRight
        >
          {input}
        </InputTooltip>
      );
    }
    return input;
  };

  renderForm = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.login}>
        <Row gutter={60} type="flex" justify="start">
          {loginConfig.inputs.map(inputConfig => {
            const {
              id,
              label,
              placeholder,
              rules,
              type,
              validateFirst,
              tooltip,
            } = inputConfig;

            return (
              <Col span={24} key={id}>
                <Form.Item>
                  {getFieldDecorator(id, { rules, validateFirst })(
                    <div>
                      <LoginInputLabel htmlFor={`${id}${type}`}>
                        {label}
                      </LoginInputLabel>
                      {this.wrapWithTooltip(
                        <Input
                          autoComplete="off"
                          type={type}
                          id={`${id}${type}`}
                          placeholder={placeholder}
                        />,
                        tooltip
                      )}
                    </div>
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        {this.renderFormFooter()}
      </Form>
    );
  };

  renderFormFooter = () => {
    return (
      <ButtonWrapper>
        <LoginButton disabled={this.isLoading} htmlType="submit">
          {loginConfig.submit}
        </LoginButton>
        <ForgotCredsLink onClick={this.handleForgotPasswordRedirect}>
          Forgot password
        </ForgotCredsLink>
      </ButtonWrapper>
    );
  };

  renderRegularLoginForm = () => {
    return (
      <FormContainer>
        {this.config.loginLogo}
        <LoginTitle align="left">{loginConfig.title}</LoginTitle>
        <LoginSubTitle>{loginConfig.subtitle}</LoginSubTitle>
        {this.renderForm()}
      </FormContainer>
    );
  };

  renderSpecificLoginForm = () => {
    const { history } = this.props;

    switch (AUTH_TYPE) {
      case 'DODDLE_OKTA':
        return <OktaSignInWidget history={history} />;
      case 'AUS_POST_OKTA':
        return <OktaLogin history={history} />;
      case 'DODDLE':
        return this.renderRegularLoginForm();
      default:
        return <div>Unable to find auth provider for current Auth type.</div>;
    }
  };

  render() {
    const {
      match: {
        params: { company },
      },
    } = this.props;
    const isMerchantLogin = company && company !== ROOT_ORG_ID;

    return (
      <Wrapper>
        {isMerchantLogin
          ? this.renderRegularLoginForm()
          : this.renderSpecificLoginForm()}
      </Wrapper>
    );
  }

}

export default compose(
  inject('merchantStore', 'authStore'),
  Form.create()
)(Login);
