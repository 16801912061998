// @flow

import * as React from 'react';
import { Root, Title, Description } from './TextSectionElements';
import InputTooltip from '../InputTooltip';

type Props = {
  title?: string,
  children?: React.Node,
  withToolTip?: boolean,
  toolTipText?: string,
  maxWidth?: number,
};

function TextSection(props: Props) {
  const { title, children, withToolTip, toolTipText, ...rest } = props;
  return (
    <Root {...rest}>
      {withToolTip && toolTipText ? (
        <InputTooltip
          trigger={['focus', 'hover']}
          title={toolTipText}
          placement="rightBottom"
          tooltipWidth={335}
          outboundToRight
          inline
        >
          <Title {...rest}>{title}</Title>
        </InputTooltip>
      ) : (
        <Title {...rest}>{title}</Title>
      )}

      <Description {...rest}>{children}</Description>
    </Root>
  );
}

export default TextSection;
