// @flow

import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { CircleLoader, Menu, Pagination } from '_common/components';
import { Content, Wrapper } from './elements/requestsElements';
import { EmptyResultsMessage, ListWrapper } from './elements/overviewElements';
import MerchantsStore from '../stores/merchantsStore';
import RequestCard from './components/RequestCard';
import {
  ColumnPageHeader,
  PageTitle,
} from '_common/components/CommonStyledElements';
import type { RequestsApiResponse } from 'merchants/services/merchantsService';

type Props = {
  merchantStore: MerchantsStore,
};

@inject('merchantStore')
@observer
class Requests extends Component<Props> {

  componentDidMount() {
    const { merchantStore } = this.props;
    merchantStore.fetchRequests();
  }

  componentWillUnmount() {
    this.props.merchantStore.clearFilters();
  }

  renderList = () => {
    const {
      merchantStore: {
        pendingRequests,
        setPaginationPage,
        currentPaginationPage,
        ITEMS_PER_PAGE,
        getEntitiesByPagination,
        isLoading,
      },
    } = this.props;
    const slicedForPaginationData = getEntitiesByPagination<RequestsApiResponse>(
      pendingRequests
    );

    return (
      <ListWrapper>
        {isLoading ? (
          <CircleLoader />
        ) : slicedForPaginationData.length ? (
          slicedForPaginationData.map((request: RequestsApiResponse) => (
            <RequestCard key={request.requestId} request={request} />
          ))
        ) : (
          <EmptyResultsMessage>Merchant was not found</EmptyResultsMessage>
        )}

        <Pagination
          perPage={ITEMS_PER_PAGE}
          onPageChange={setPaginationPage}
          total={pendingRequests.length}
          currentPage={currentPaginationPage}
        />
      </ListWrapper>
    );
  };

  render() {
    return (
      <Wrapper>
        <Menu />
        <Content>
          <ColumnPageHeader>
            <PageTitle>Merchant Requests</PageTitle>
          </ColumnPageHeader>
          {this.renderList()}
        </Content>
      </Wrapper>
    );
  }

}

export default Requests;
