import React, { Component } from 'react';
import {
  DeclineConfirmationWrapper,
  RejectIcon,
  Text,
  ActionButtons,
} from './elements';
import {
  PrimaryButton,
  SecondaryButton,
} from 'merchants/pages/elements/commonCard';
import merchantActions from 'merchants/actions';

type Props = {
  requestId: string,
  onCancelDeclining: () => void,
  emailConfig: Object,
};

type State = {
  isLoading: boolean,
};

class DeclineConfirmation extends Component<Props, State> {

  _mounted: boolean; // anti-patten, but use it while not hooks;

  state = {
    isLoading: false,
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleDeclineRequest = async () => {
    const { emailConfig, requestId } = this.props;
    try {
      this.setState({ isLoading: true });

      await merchantActions.rejectRequest(emailConfig, requestId);
    } finally {
      if (this._mounted) {
        this.setState({ isLoading: false });
      }
    }
  };

  handleCancelDeclining = () => {
    this.props.onCancelDeclining();
  };

  render() {
    const { isLoading } = this.state;
    return (
      <DeclineConfirmationWrapper>
        <RejectIcon />
        <Text>Decline request?</Text>
        <ActionButtons>
          <PrimaryButton
            disabled={isLoading}
            onClick={this.handleDeclineRequest}
          >
            Yes
          </PrimaryButton>
          <SecondaryButton
            disabled={isLoading}
            onClick={this.handleCancelDeclining}
          >
            No
          </SecondaryButton>
        </ActionButtons>
      </DeclineConfirmationWrapper>
    );
  }

}

export default DeclineConfirmation;
