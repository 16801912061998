import { createGlobalStyle } from 'styled-components';
import { isIE } from 'react-device-detect';

export const GlobalStyles = createGlobalStyle`
  ${isIE &&
    `
    html,
    body,
    #root {
      height: auto !important;
    }
  `};
`;
