import LOGIN_BG_IMAGE from 'assets/images/login-bg.jpg';
import styled, { AnyStyledComponent } from 'styled-components';
import { isIE } from 'react-device-detect';

export const Wrapper = styled.div`
  width: 100%;
  ${!isIE && 'min-height: 100vh'};
  height: ${props => (props.isSignUp ? (isIE ? '100vh' : 'auto') : '100%')};
  background: url(${LOGIN_BG_IMAGE});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SignUpWrapper = styled(Wrapper)`
  min-height: 100vh;
  height: auto;
`;

export const FormContainer = styled.div`
  width: ${({ isSignUp }) => (isSignUp ? '820px' : '420px')};
  margin-top: 50px;
  margin-bottom: 50px;
  background: #fff;
  padding: ${({ isSignUp }) =>
    isSignUp ? '38px 60px 35px 47px' : '38px 35px 47px'};
  display: flex;
  flex-direction: column;
  border-radius: 3px;

  .ant-row {
    margin-top: -10px;
  }

  .ant-form-item label {
    top: 5px;
    white-space: nowrap;
  }

  .has-error .ant-form-explain {
    color: #ff3654;
  }

  .has-error .ant-input {
    border-color: #ff3654;
  }

  .ant-form-explain {
    font-size: 11px;
    color: #222;
    margin-top: 1px;
  }
`;

export const FormContainerFlexed = styled(FormContainer)`
  .ant-form {
    display: flex;
    flex-direction: column;
  }
`;

export const LoginTitle = styled.div`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 20px;
  color: #282d32;
  text-align: ${({ align }) => align || 'center'};
  margin-bottom: 15px;
`;

export const LoginInputLabel: AnyStyledComponent = styled.label`
  && {
    font-size: 12px;
    font-family: ${props => props.theme.fonts.book};
    color: #282d32;
  }
`;

export const LoginButton: AnyStyledComponent = styled.button`
  color: #fff;
  font-size: ${props => props.fontSize || 20}px;
  height: 42px;
  width: 180px;
  border-radius: 28px;
  background-color: #4a90e2;
  font-family: ${props => props.theme.fonts.book};
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #32659e;
  }

  &:disabled {
    background-color: rgba(74, 144, 226, 0.3);
    cursor: not-allowed;
  }
`;

export const LoginButtonLarge = styled(LoginButton)`
  width: 300px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ paddingTop }) => paddingTop || '10px'};
  & > .ant-row {
    width: 100%;
  }
`;

export const CollectionsCheckboxesWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 24px;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 12px;

  .ant-row.ant-form-item {
    margin-top: 0;
    margin-bottom: 12px;
  }

  .ant-row.ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    line-height: 18px;
  }
`;

export const ForgotCredsLink = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;
  color: #282d32;
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
`;

export const Agreement = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  padding-bottom: 10px;
`;

export const AgreementText = styled.div`
  font-family: ${props => props.theme.fonts.book};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-top: 4px;
`;

export const AgreementLink = styled.a`
  text-decoration: underline;
  padding: 0 5px;
  cursor: pointer;
  &,
  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const LoginCardWrapper = styled.div`
  width: 30vw;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  color: #282d32;
  font-family: ${props => props.theme.fonts.medium};
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  & span {
    max-width: 100%;
  }
`;

export const ConfirmedRequestMessage = styled.div`
  text-align: center;
`;

export const LoginSubTitle = styled.div`
  margin: 15px 0;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.book};
`;

export const ResetPasswordContent = styled.div`
  text-align: left;
`;
