import styled from 'styled-components';

export const LocationsWrapper = styled.div``;

export const LocationsItems = styled.div`
  height: 100%;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    max-height: 275px;
  }
`;
