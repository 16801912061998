// @flow
import { action, observable, runInAction } from 'mobx';
import statisticsService from 'statistics/services/statisticsService';
import commonActions from '_common/actions';
import { extractError } from '_common/utils';

type DashboardConfig = {|
  name: string,
  src: string,
|};

export type PeriscopeFilter = {
  column_value: string,
  filter_name: string,
};

class StatisticsStore {

  @observable
  dashboardConfig: DashboardConfig | null = null;

  @observable
  dashboardInitialConfig: DashboardConfig | null = null;

  @observable
  isDrilledDown: boolean = false;

  getStateForDebug = () => ({
    dashboardConfig: this.dashboardConfig,
    dashboardInitialConfig: this.dashboardInitialConfig,
    isDrilledDown: this.isDrilledDown,
  });

  @action
  getDashboardConfig = async config => {
    try {
      const dashboardConfig = await statisticsService.getDashboardConfig({
        ...config,
      });
      runInAction(() => {
        this.dashboardConfig = dashboardConfig;
        this.dashboardInitialConfig = dashboardConfig;
        this.isDrilledDown = false;
      });
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      return Promise.reject(e);
    }
  };

  @action
  getDashboardConfigDrillDown = async config => {
    try {
      const dashboardConfig = await statisticsService.getDashboardConfigDrillDown(
        { ...config }
      );
      runInAction(() => {
        this.dashboardConfig = dashboardConfig;
        this.isDrilledDown = true;
      });
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      return Promise.reject(e);
    }
  };

  @action
  drillOut = () => {
    this.dashboardConfig = this.dashboardInitialConfig;
    this.isDrilledDown = false;
  };

}

export default StatisticsStore;
