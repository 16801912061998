import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 300;

  & > .ant-select {
    width: ${props => (props.width ? props.width + 'px' : '180px')};

    @media only screen and (max-width: 1440px) {
      width: 100% !important;
    }
  }
  & .ant-select-selection {
    border-radius: 2px;
    border: solid 1px #979797;
    background-color: #ffffff;
    height: 42px;
    & .ant-select-selection__rendered {
      margin-top: 4px;
    }
  }
  & > .ant-select-disabled {
    & .ant-select-selection {
      color: #545454;
      background-color: #ebebe4;
      &:hover {
        border-color: #979797;
      }
    }
  }
`;
