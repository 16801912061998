// @flow

import type { Applicant } from 'merchants/services/merchantsService';
import stores from 'stores';

const signUpMerchant = async (config: Applicant) => {
  return stores.merchantStore.signUpMerchant(config);
};

export default signUpMerchant;
