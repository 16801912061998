// @flow

import { Checkbox as AntdCheckbox } from 'antd';
import React, { Component } from 'react';
import { CheckboxWrapper } from './elements';

class Index extends Component<any> {

  render() {
    return (
      <CheckboxWrapper>
        <AntdCheckbox {...this.props} />
      </CheckboxWrapper>
    );
  }

}

export default Index;
