import styled from 'styled-components';

export const ListHeader = styled.div`
  width: 100%;
  height: 13px;
  font-family: ${props => props.theme.fonts.basic};
  font-size: 12px;
  font-weight: bold;
  color: #131314;
  margin-bottom: 10px;
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
`;
export const ListItem = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 12px;
  color: #131314;
  border-top: solid 1px #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 15px 17px 0;
  height: 65px;
  display: flex;
  align-items: center;
  &:last-child {
    border-bottom: solid 1px #eeeeee;
  }
`;
