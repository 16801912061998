// @flow

import { apiClient, assetsClient } from '_common/api/clients/clients';
import { WhiteLabelConstants } from '_common/whitelabelConfig';

export type MerchantDetails = {
  companyName?: string,
  website?: string,
  returnsUrl?: string,
  phoneNumber?: string,
};

export type ConfigResp = {
  companyId: string,
  version: string,
  assets?: {
    [key: string]: string,
  },
  config: {
    [key: string]: string | Object,
    css: {
      [key: string]: string,
    },
  },
};

export type AssetManagementConfig = {
  [key: string]: string | Object | typeof undefined,
  css?: {
    [key: string]: string,
  },
  merchantDetails?: MerchantDetails,
};

export type Asset = {
  type: string,
  resourceId: string,
  content: string,
};

const getConfig = async (organisationId: string): Promise<ConfigResp> => {
  const response = await assetsClient.get(
    `/${
      WhiteLabelConstants.PRODUCT_NAME
    }/${organisationId}/application-config.json?ts=${Date.now()}`
  );

  return response.data;
};

const createConfig = async (
  organisationId: string,
  version: string,
  config: AssetManagementConfig
): Promise<ConfigResp> => {
  const response = await apiClient.post(
    `/v1/asset-management/${
      WhiteLabelConstants.PRODUCT_NAME
    }/${organisationId}/${version}/`,
    config
  );

  return response.data.resource;
};

const updateConfig = async (
  organisationId: string,
  version: string,
  config: AssetManagementConfig
): Promise<ConfigResp> => {
  const response = await apiClient.patch(
    `/v1/asset-management/${
      WhiteLabelConstants.PRODUCT_NAME
    }/${organisationId}/${version}/`,
    config
  );

  return response.data.resource;
};

const uploadAsset = async (
  organisationId: string,
  version: string,
  asset: Asset
): Promise<ConfigResp> => {
  const response = await apiClient.post(
    `/v1/asset-management/${
      WhiteLabelConstants.PRODUCT_NAME
    }/${organisationId}/${version}/upload`,
    asset
  );

  return response.data.resource;
};

const publishAsset = async (
  organisationId: string,
  version: string
): Promise<ConfigResp> => {
  const response = await apiClient.post(
    `/v1/asset-management/${
      WhiteLabelConstants.PRODUCT_NAME
    }/${organisationId}/${version}/publish`
  );

  return response.data.resource;
};

export default {
  createConfig,
  uploadAsset,
  updateConfig,
  getConfig,
  publishAsset,
};
