// @flow
/* eslint camelcase: 0 */

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import {
  Label,
  Menu,
  RadioGroup,
  Switch,
  TextSection,
} from '_common/components';
import {
  Content,
  HeaderAbsoluteErrorMessage,
  Page,
  PageHeader,
  PageTitle,
  Panel,
  PanelIcon,
  PanelSectionNoWrap,
} from '_common/components/CommonStyledElements';
import { paymentIcon, reasonCommentsIcon } from 'assets';
import { CompanyModelStore, PublishStore, UIStore } from 'stores';
import { Prompt } from 'react-router';
import { IS_DEVELOPMENT } from '_common/constants/appConfig';
import commonActions from '_common/actions';
import { Form } from 'antd';
import {
  WhiteLabelConstants,
  WhiteLabelUi,
  withWhitelabelProps,
} from '_common/whitelabelConfig';
import Amplitude from '_common/utils/amplitude';

import { extractError, validateFieldName } from '_common/utils';
import ReasonCodes from 'settings/paymentOptionsAndReasonCodes/components/ReasonCodes';
import { SectionsWrapper } from '../common/elements';
import { addRequiredFieldsForPatch } from '_common/utils/directoryUtils';
import { get } from 'lodash';
import { extractFieldValue } from '_common/utils/companyUtils';
import { FormWrapper, InputWrapper, LeftBorder, StyledInput } from './elements';
import { TrackableChanges } from '_common/stores/companyModelStore';
import { TrackingBlocks } from '_common/stores/uiStore';

const { PRODUCT_NAME } = WhiteLabelConstants;
export const RETURNS_FORM_FIELDS_SECTION = `products.${PRODUCT_NAME}.returnFormFields`;
const FIELDS = {
  PAYMENT_METHOD: `products.${PRODUCT_NAME}.customerPayments.paymentType`,
  RETURN_REASONS_ARRAY: `products.${PRODUCT_NAME}.returnReasons.reasons`,
  REASON_COMMENTS: `${RETURNS_FORM_FIELDS_SECTION}.reasonComments`,
  REASON_COMMENTS_FIELD_NAME: `${RETURNS_FORM_FIELDS_SECTION}.reasonCommentsFieldName`,
  REASON_COMMENTS_MANDATORY: `${RETURNS_FORM_FIELDS_SECTION}.reasonCommentsMandatory`,
};

type Props = {
  companyModelStore: CompanyModelStore,
  uiStore: UIStore,
  publishStore: PublishStore,
};

type State = {
  isPublishing: boolean,
  isPublished: boolean,
  hasUnpublishedChanges: boolean,
};

const renderElementWithBorderLeft = element => (
  <>
    <LeftBorder />
    {element}
  </>
);

@inject('companyModelStore', 'uiStore', 'publishStore')
@observer
class PaymentOptionsAndReasonCodes extends Component<Props, State> {

  static AMPLITUDE_CONFIG = [
    {
      fields: [RETURNS_FORM_FIELDS_SECTION],
      amplitudeName: 'Return reason comments',
    },
  ];

  state = {
    isPublishing: false,
    isPublished: false,
    hasUnpublishedChanges: false,
  };

  async componentDidMount() {
    this.props.publishStore.setCurrentPublish(this.handlePublish);
    // TODO: to generic
    Amplitude.unpublishedChanges.clear();
    Amplitude.logEvent('page_open', {
      page_name: WhiteLabelUi.pages.paymentOptionsAndReasonCodes.pageTitle,
    });
  }

  componentDidUpdate() {
    const { hasUnpublishedChanges } = this.state;
    const { uiStore } = this.props;
    if (!IS_DEVELOPMENT && hasUnpublishedChanges) {
      window.onbeforeunload = () => true;
      uiStore.setHasUnsavedChanges(true);
    } else {
      window.onbeforeunload = undefined;
      uiStore.setHasUnsavedChanges(false);
    }
  }

  componentWillUnmount() {
    const { uiStore } = this.props;
    window.onbeforeunload = undefined;
    uiStore.setHasUnsavedChanges(false);
    Amplitude.unpublishedChanges.clear();
  }

  handlePaymentChange = ({
    target: { value: paymentType },
  }: SyntheticInputEvent<>) => {
    commonActions.companyActions.changePaymentType(paymentType);
    this.setState({ hasUnpublishedChanges: true });
  };

  handleReasonsCodesChange = () => {
    Amplitude.unpublishedChanges.add(TrackableChanges.REASON_CODES);
    this.setState({ hasUnpublishedChanges: true });
  };

  handlePublish = async () => {
    const {
      form: { validateFieldsAndScroll },
      companyModelStore: { companyModel, companyModelUpdates },
      uiStore: { editingCompanyByAdmin },
      match: {
        params: { company },
      },
      publishStore: {
        isPublished,
        setIsLoading,
        setIsPublished,
        publishStart,
        showPublishNotification,
      },
    } = this.props;

    if (isPublished) {
      return;
    }

    validateFieldsAndScroll(async err => {
      if (err) {
        return;
      }

      publishStart();
      try {
        const { updatedConfig } = addRequiredFieldsForPatch(
          companyModel,
          companyModelUpdates
        );

        /** updated for Reason comments - BE REQUIRES PROPS WHICH NOT CHANGED! */
        const oldReturnFormFields = get(
          companyModel,
          RETURNS_FORM_FIELDS_SECTION
        );
        updatedConfig.products[PRODUCT_NAME].returnFormFields = {
          ...oldReturnFormFields,
          ...updatedConfig.products[PRODUCT_NAME].returnFormFields,
        };
        /** enabled required to BE */
        updatedConfig.products[PRODUCT_NAME].returnReasons = {
          enabled: get(
            companyModel,
            `products.${PRODUCT_NAME}.returnReasons.enabled`
          ), // Check that if copy-paste
          ...updatedConfig.products[PRODUCT_NAME].returnReasons,
        };

        commonActions.getChanges([TrackingBlocks.PaymentAndReasonCodes]);

        await commonActions.companyActions.updateCompany(
          editingCompanyByAdmin || company,
          updatedConfig
        );

        Amplitude.logFieldsChanges({
          pageName: WhiteLabelUi.pages.paymentOptionsAndReasonCodes.pageTitle,
          model: companyModelUpdates,
          config: PaymentOptionsAndReasonCodes.AMPLITUDE_CONFIG,
        });

        showPublishNotification('isPublished');
        setIsPublished(true);
        this.setState({ hasUnpublishedChanges: false });
      } catch (e) {
        commonActions.setApplicationErrorMessage(extractError(e));
        console.error('catch err :: handlePublish', e);
      } finally {
        setIsPublished(true);
        setIsLoading(false);
      }
    });
  };

  handleSwitcherChange = (path: string) => (updatedValue: boolean) => {
    this.updateCompanyModel(path, updatedValue);
  };

  handleInputChange = (path, typeToConvert) => ({ target: { value } }) => {
    if (typeToConvert) {
      value = typeToConvert(value);
    }

    commonActions.companyActions.changeCompanyModel({
      path,
      singleValue: value,
    });
    this.setState({ hasUnpublishedChanges: true });
  };

  handleBlur = path => ({ target: { value } }) => {
    if (!value || this.props.form.getFieldError(path)) {
      return;
    }

    const updatedValue = value.trim();
    this.props.form.setFieldsValue({ [path]: updatedValue });
    this.updateCompanyModel(path, updatedValue);
  };

  updateCompanyModel = (path, updatedValue) => {
    commonActions.trackChange(
      TrackingBlocks.PaymentAndReasonCodes,
      TrackableChanges.REASON_COMMENTS
    );

    commonActions.companyActions.changeCompanyModel({
      path,
      singleValue: updatedValue,
    });
    this.setState({ hasUnpublishedChanges: true });
  };

  /**
   * Render the return comments fields, including the swithcer and header
   * @returns {*}
   */
  renderReasonCommentsGroup = () => {
    const {
      form: { getFieldDecorator },
      companyModelStore,
    } = this.props;

    return (
      <>
        <Form.Item>
          <InputWrapper>
            <Label
              fontType="book"
              htmlFor={FIELDS.REASON_COMMENTS_FIELD_NAME}
              paddingLeft
            >
              Edit header
            </Label>
            {renderElementWithBorderLeft(
              getFieldDecorator(FIELDS.REASON_COMMENTS_FIELD_NAME, {
                validateTrigger: 'onKeyUp',
                rules: [{ validator: validateFieldName }],
                initialValue: extractFieldValue({
                  path: FIELDS.REASON_COMMENTS_FIELD_NAME,
                  companyModelStore,
                }),
              })(
                <StyledInput
                  width={205}
                  height={42}
                  id={FIELDS.REASON_COMMENTS_FIELD_NAME}
                  placeholderFontSize={12}
                  placeholder={'Reason comments'}
                  onBlur={this.handleBlur(FIELDS.REASON_COMMENTS_FIELD_NAME)}
                  onChange={this.handleInputChange(
                    FIELDS.REASON_COMMENTS_FIELD_NAME
                  )}
                />
              )
            )}
          </InputWrapper>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(FIELDS.REASON_COMMENTS_MANDATORY)(
            <InputWrapper>
              <Label
                fontType="book"
                htmlFor={FIELDS.REASON_COMMENTS_FIELD_NAME}
                paddingLeft
              >
                Make reason comments mandatory
              </Label>
              <Switch
                withLeftBorder
                checked={extractFieldValue({
                  path: FIELDS.REASON_COMMENTS_MANDATORY,
                  companyModelStore,
                })}
                onChange={this.handleSwitcherChange(
                  FIELDS.REASON_COMMENTS_MANDATORY
                )}
              />
            </InputWrapper>
          )}
        </Form.Item>
      </>
    );
  };

  get PAYMENT_METHOD() {
    const {
      companyModelStore,
      companyModelStore: { paymentOptions },
    } = this.props;

    const value = extractFieldValue({
      companyModelStore,
      path: FIELDS.PAYMENT_METHOD,
    });

    return (
      <>
        <PanelSectionNoWrap>
          <PanelIcon image={paymentIcon} width={62} height={53} />
          <TextSection title="Payment Method">
            Merchant paid, consumer paid, or a mix of the two.
          </TextSection>
          <RadioGroup
            width={400}
            options={paymentOptions}
            value={value}
            onChange={this.handlePaymentChange}
          />
        </PanelSectionNoWrap>
      </>
    );
  }

  get REASON_CODES() {
    return <ReasonCodes onReasonsCodesChange={this.handleReasonsCodesChange} />;
  }

  get FORM_FIELDS() {
    const {
      companyModelStore,
      form: { getFieldDecorator },
    } = this.props;
    const value = extractFieldValue({
      path: FIELDS.REASON_COMMENTS,
      companyModelStore,
    });

    return (
      <>
        <PanelSectionNoWrap>
          <PanelIcon image={reasonCommentsIcon} width={66} height={66} />
          <TextSection title="Return reason comments">
            Add reason comments, change the heading and <br /> make it mandatory
            to add comments.
          </TextSection>
          <FormWrapper width={350}>
            <Form.Item>
              {getFieldDecorator(FIELDS.REASON_COMMENTS)(
                <InputWrapper>
                  <Label>Reason comments</Label>
                  <Switch
                    withLeftBorder
                    checked={value}
                    onChange={this.handleSwitcherChange(FIELDS.REASON_COMMENTS)}
                  />
                </InputWrapper>
              )}
            </Form.Item>
            {value && this.renderReasonCommentsGroup()}
          </FormWrapper>
        </PanelSectionNoWrap>
      </>
    );
  }

  renderPageSections = pageSectionsOrder =>
    Array.isArray(pageSectionsOrder)
      ? pageSectionsOrder.map(name => {
          if (!this[name]) {
            console.error(
              'Payment & Order Details page. Invalid section name provided!'
            );
            return null;
          } else
            return <React.Fragment key={name}>{this[name]}</React.Fragment>;
        })
      : null;

  render() {
    const { hasUnpublishedChanges } = this.state;
    const {
      uiStore: { editingCompanyByAdmin },
      whiteLabeled: { pageSectionsOrder, pageTitle },
    } = this.props;

    return (
      <Form>
        <Page>
          <Prompt
            when={!IS_DEVELOPMENT && hasUnpublishedChanges}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          {hasUnpublishedChanges && (
            <HeaderAbsoluteErrorMessage largeOffsetRight>
              Form has unpublished changes
            </HeaderAbsoluteErrorMessage>
          )}
          <Menu />
          <Content editingCompanyByAdmin={editingCompanyByAdmin}>
            <PageHeader column>
              <PageTitle>{pageTitle}</PageTitle>
            </PageHeader>
            <Panel>
              <SectionsWrapper>
                {this.renderPageSections(pageSectionsOrder)}
              </SectionsWrapper>
            </Panel>
          </Content>
        </Page>
      </Form>
    );
  }

}

export default compose(
  Form.create({}),
  withWhitelabelProps({
    pageSectionsOrder:
      'ui.pages.paymentOptionsAndReasonCodes.pageSectionsOrder',
    pageTitle: 'ui.pages.paymentOptionsAndReasonCodes.pageTitle',
  })
)(PaymentOptionsAndReasonCodes);
