// @flow

import React from 'react';
import { StyledRadioGroup } from './RadioGroupElements';

type Props = {
  options: { label: string, value: string }[],
  alignLeft?: boolean,
  noBorders?: boolean,
  isInvalid?: boolean,
};

class RadioGroup extends React.Component<Props> {

  render() {
    const { options, ...rest } = this.props;
    return <StyledRadioGroup options={options} {...rest} />;
  }

}

export default RadioGroup;
