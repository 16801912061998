// @flow

import stores from 'stores';
import emailService from '_common/services/emailService';

const sendEmailsPreview = (configFunc: () => void) => {
  const { loggedUser } = stores.authStore;
  const { editingCompanyByAdmin, editingCompanyNameByAdmin } = stores.uiStore;
  const { logoImageUrl } = stores.assetManagementStore;
  const { companyName } = stores.companyModelStore.companyModel;
  const retailerName = editingCompanyNameByAdmin || companyName;
  const { organisationId, login, userId } = loggedUser;

  /** Admin and merchant User objects are different in login (email) for m. and userId (email) for adm. */
  return emailService.sendEmail(
    configFunc(
      userId || login,
      editingCompanyByAdmin || organisationId,
      retailerName,
      logoImageUrl
    )
  );
};

export default sendEmailsPreview;
