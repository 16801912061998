// @flow
/* eslint camelcase: 0 */

import {
  ManualErrors,
  HTTP_STATUS_4XX_REGEX,
} from '_common/constants/apiErrorResponces';
import links from '_common/routes/urls';
import {
  AUTH_TYPE,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
  ROOT_ORG_ID,
} from '_common/constants/appConfig';
import AuthService from '_common/services/authService';
import type { Tokens, User } from '_common/services/authService';
import {
  extractError,
  getUserRoleType,
  checkHttpStatus,
  getMerchantFromUrl,
} from '_common/utils';
import storage, {
  IS_MERCHANT,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TOKEN_LIFETIME,
  USER_ROLES,
} from 'storage';
import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { includes, get } from 'lodash';
import commonActions from '_common/actions';
import { REFRESH_TOKEN_INTERVAL } from '_common/constants/timeout';
import { parseJWT } from '_common/utils/JWT';
import OktaAuth from '@okta/okta-auth-js';
import { ROLE_TYPES } from '_common/constants/common';
import * as OktaSignIn from '@okta/okta-signin-widget';
import Amplitude from '_common/utils/amplitude';
import companyModelService from '_common/services/companyModelService';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import { throwManualFormError } from '_common/utils/formUtils';
import { initHotJar } from '_common/utils/hotJar';
import userPilot from '_common/utils/userPilot';

const getLoggedUser = JWT => {
  //check if it is a merchant route
  const merchantFromUrl = getMerchantFromUrl();
  // check if scopes have merchant company in it
  if (merchantFromUrl) {
    const scopes = get(JWT, 'scope');
    if (
      scopes &&
      includes(scopes.split(' '), `organisation_${merchantFromUrl}`)
    ) {
      return {
        organisationId: merchantFromUrl,
        login: get(JWT, 'doddle.staff.login'),
      };
    }
  } else {
    return get(JWT, 'doddle.staff');
  }
};

class AuthStore {

  /**
   * This is a placeholder for custom Auth client like OKTA etc.
   */
  externalAuthClient: Object = null;

  @observable
  accessToken: string | null = storage.get(TOKEN_KEY);

  @observable
  refreshToken: string = storage.get(REFRESH_TOKEN_KEY);

  @observable
  parsedToken: Object = parseJWT(this.accessToken);

  @observable
  loggedUser: User = getLoggedUser(this.parsedToken);

  @observable
  scopes: string = get(this.parsedToken, 'scope', '');

  @observable
  isLoading: boolean = false;

  @observable
  newTokenHandler: any;

  @observable
  error: string = '';

  @observable
  isMerchantUser: boolean | null = null;

  @observable
  userRoles: string[];

  constructor() {
    /** on application startup trying to get stored value. Here could be migration to guard hooks. */
    const storedMerchantValue = storage.get(IS_MERCHANT);
    if (storedMerchantValue) {
      this.isMerchantUser = storedMerchantValue;
      initHotJar();
    }
    if (!this.externalAuthClient && !this.isMerchantUser) {
      this.registerOktaClient();
    }

    this.newTokenHandler = setInterval(
      () => this.getNewAccessToken(),
      REFRESH_TOKEN_INTERVAL
    );
  }

  getStateForDebug = () => ({
    accessToken: this.accessToken,
    refreshToken: this.refreshToken,
    parsedToken: this.parsedToken,
    loggedUser: this.loggedUser,
    scopes: this.scopes,
    isLoading: this.isLoading,
    newTokenHandler: this.newTokenHandler,
    error: this.error,
    isMerchantUser: this.isMerchantUser,
  });

  authoriseApplication = async () => {
    /** Flag to check is application init call required or not. */
    let isAuthorizeApplicationRequired = true;

    /** Here we have two sources - storage and url. */
    const urlPath = window.location.pathname.split('/').filter(p => p);

    /** need to check that stored data in sync with incoming from URL */
    const existingRoutes = Object.values(links)
      .map(path => path.replace('/:company', '').split('/')[1])
      .filter(p => p);

    /** We need to figure out which scope need to be fetched on app starts. */
    let currentOrganizationId = ROOT_ORG_ID;

    /** case for hostname/login, then we go as AUS.POST */
    if (urlPath.length === 1 && urlPath[0] === 'login') {
      currentOrganizationId = ROOT_ORG_ID;

      /** Don't send a token request for ADMIN - all should be handled by OKTA token parse */
      isAuthorizeApplicationRequired = false;
    } else if (!includes(existingRoutes, urlPath[0])) {
      /** If part in url not inside know app urls - think that it's a org.name */
      currentOrganizationId = urlPath[0];
    }

    /** Check for signup page */
    const isSignupRoute = urlPath.length === 1 && urlPath[0] === 'signup';

    /** If stored appName not match with URL - logout. */
    if (
      !isSignupRoute &&
      this.loggedUser &&
      this.loggedUser.organisationId &&
      this.loggedUser.organisationId !== currentOrganizationId
    ) {
      return this.logout(currentOrganizationId);
    }

    Amplitude.setRetailerName(currentOrganizationId);
    /** if user is not logged in on another tab - get a token */
    if (isAuthorizeApplicationRequired && !this.loggedUser) {
      const res = await AuthService.authorizeApplication(currentOrganizationId);
      this.setTokens({ accessToken: res.access_token });
    }

    return Promise.resolve();
  };

  @computed
  get getUserOrganisations(): string[] {
    return this.createUserOrganisations();
  }

  @action
  handleScopesOnLogin = (scopes: string) => {
    this.scopes = scopes;

    this.createUserOrganisations();
  };

  createUserOrganisations = (): string[] => {
    const scopesArray = this.scopes.split(' ');
    return scopesArray
      .filter(scope => {
        return scope.indexOf('organisation_') !== -1;
      })
      .map(org => {
        return org.replace('organisation_', '');
      });
  };

  /**
   * This auth function can be called by MERCHANT and DODDLE ADMINs.
   * @param login
   * @param password
   * @param organisation
   * @param staffPermissions
   * @returns {Promise<unknown>}
   */
  @action
  authorizeStaff = async (
    login: string,
    password: string,
    organisation: string,
    staffPermissions: string[]
  ) => {
    this.isLoading = true;
    this.error = '';

    try {
      /** Gets initial user validation and access tokens to calling protected APIs. */
      const res = await AuthService.authorizeStaff(
        login,
        password,
        organisation,
        staffPermissions
      );

      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        user,
      } = res;

      const userRoleType = getUserRoleType(user.roles);

      switch (userRoleType) {
        case ROLE_TYPES.MERCHANT: {
          /**
           * Load merchant's company to check if it's disabled or not.
           * DO NOT send both requests in parallel, need to check if staff account really exists firstly.
           **/
          const company = await companyModelService.getCompany(organisation);

          /** We need to prevent login merchant to inactive organisations. */
          if (
            !get(
              company,
              `products.${WhiteLabelConstants.PRODUCT_NAME}.enabled`
            )
          ) {
            return Promise.reject(
              throwManualFormError(ManualErrors.COMPANY_IS_DISABLED)
            );
          }
          initHotJar();
          break;
        }
        case ROLE_TYPES.ADMIN: {
          /** no special admin checks yet. */
          break;
        }
        default:
          /** User hasn't allowed roles to get access */
          return Promise.reject(throwManualFormError(ManualErrors.PERMISSIONS));
      }

      // analytics script
      userPilot.identifyUser(res.user);
      /** Updates token to received from initial login. */
      this.setTokens({ accessToken, refreshToken, expiresIn });
      const isMerchantUser = userRoleType === ROLE_TYPES.MERCHANT; // TODO: Refactor this?

      runInAction(() => {
        this.isLoading = false;
        this.isMerchantUser = isMerchantUser;
        storage.set(IS_MERCHANT, isMerchantUser);
        storage.set(USER_ROLES, user.roles);
        this.setUser(res.user, res.scope);
        this.setUserRoles(user.roles);
      });
    } catch (e) {
      console.error('catch error:', e);
      let error;
      if (checkHttpStatus(e, HTTP_STATUS_4XX_REGEX)) {
        error = ManualErrors.LOGIN_NOT_VALID;
      } else {
        error = extractError(e);
      }
      runInAction(() => {
        this.isLoading = false;
        this.error = error;
      });

      return Promise.reject(e.response);
    }

    return new Promise(resolve => resolve());
  };

  @action
  setUserRoles = roles => {
    this.userRoles = roles;
    storage.set(USER_ROLES, roles);
  };

  getUserRoles = () => {
    if (this.userRoles) return this.userRoles;
    const rolesFromToken = this.loggedUser && this.loggedUser.roles;
    if (rolesFromToken) {
      this.setUserRoles(rolesFromToken);
      return rolesFromToken;
    }
    const rolesFromStorage = storage.get(USER_ROLES);
    if (rolesFromStorage) {
      this.setUserRoles(rolesFromStorage);
      return rolesFromStorage;
    }
  };

  getIsMerchantUser = () => {
    /** Try to find auth value from store. */
    if (this.isMerchantUser !== null) {
      return this.isMerchantUser;
    }
    return storage.get(IS_MERCHANT);
  };

  @action
  setTokens = (tokens: Tokens) => {
    this.accessToken = tokens.accessToken;

    if (tokens.refreshToken) {
      this.refreshToken = tokens.refreshToken;
      storage.set(REFRESH_TOKEN_KEY, tokens.refreshToken);
    }

    const tokenExpiredTime = tokens.expiresIn;
    storage.set(TOKEN_KEY, tokens.accessToken);

    this.parsedToken = parseJWT(this.accessToken);
    this.scopes = get(this.parsedToken, 'scope', '');

    if (tokenExpiredTime) {
      storage.set(
        TOKEN_LIFETIME,
        moment().add(tokenExpiredTime - 300, 'seconds')
      );
    }
  };

  isTokenValid = (extraSeconds: number = 300) => {
    //check if it is a merchant route
    const merchantFromUrl = getMerchantFromUrl();
    // check if scopes have merchant company in it
    if (merchantFromUrl) {
      if (!includes(this.getUserOrganisations, merchantFromUrl)) return false;
    }
    const tokeLifetime = storage.get(TOKEN_LIFETIME);
    return (
      tokeLifetime &&
      moment(tokeLifetime).add(extraSeconds, 'seconds') > moment()
    );
  };

  @action
  setUser = ({ organisationId, staffId, userId }, scope) => {
    // new merchants are alowed to have multiple organisations
    const newMerchantToken = typeof organisationId === 'undefined';
    if (newMerchantToken) {
      const orgId = getMerchantFromUrl();
      if (
        orgId &&
        scope &&
        includes(scope.split(' '), `organisation_${orgId}`)
      ) {
        this.loggedUser = {
          organisationId: orgId,
          login: userId || staffId,
        };
      } else {
        console.error('Invalid merchant/scopes combination');
        this.logout();
      }
    } else {
      /** userId for ROOT login, staffId - merchant */
      this.loggedUser = {
        organisationId,
        login: userId || staffId,
      };
    }
  };

  @action //TODO:: now this is ASYNC
  logout = (redirectUrl: ?string) => {
    this.accessToken = '';
    this.refreshToken = '';
    const organisationId = get(this, 'loggedUser.organisationId', '');

    storage.removeAll();

    clearInterval(this.newTokenHandler);

    const doRedirect = () => {
      const redirectPath =
        redirectUrl || organisationId || getMerchantFromUrl();

      /** In case of root organisation we dont need extra path in URL. */
      if (!redirectPath || redirectPath === ROOT_ORG_ID) {
        return (window.location.href = `/login`);
      }
      /** Redirect to login for specific company name. */
      window.location.href = `/${redirectPath}/login`;
    };

    if (this.externalAuthClient) {
      this.externalAuthClient.tokenManager.remove('token');
      this.externalAuthClient
        .signOut()
        .then(doRedirect)
        .fail(error => {
          console.error('Logout OKTA error::', error);
          doRedirect();
        });
    } else {
      doRedirect();
    }
  };

  @action
  getNewAccessToken = async () => {
    if (
      !this.isTokenValid(0) &&
      storage.get(TOKEN_LIFETIME) &&
      this.refreshToken
    ) {
      const res = await AuthService.refreshToken(this.refreshToken);
      runInAction(() => {
        this.setTokens({
          accessToken: res.access_token,
          expiresIn: res.expires_in,
        });
      });
    }
  };

  @action
  forceTokenRefresh = async () => {
    if (this.refreshToken) {
      const res = await AuthService.refreshToken(this.refreshToken);
      runInAction(() => {
        this.setTokens({
          accessToken: res.access_token,
          expiresIn: res.expires_in,
        });
      });
    } else {
      console.error('Cannot refresh - no refresh token');
      return this.logout();
    }
  };

  /**
   * This is sign up flow for ADMIN, which now could come only from AP - OKTA.
   * @param accessToken
   * @returns {Promise<unknown>}
   */
  @action
  validateTokenFromOkta = async (accessToken: string) => {
    try {
      const res = await AuthService.validateTokenFromOkta(accessToken);
      if (getUserRoleType(res.user.roles) !== ROLE_TYPES.ADMIN) {
        console.error('No required admin role for user found.');
        return Promise.reject();
      }

      runInAction(() => {
        this.isMerchantUser = false;
      });
      storage.set(IS_MERCHANT, false);

      // analytics script
      userPilot.identifyUser(res.user);

      this.setTokens({
        accessToken: res.access_token,
        refreshToken: res.refresh_token,
        expiresIn: res.expires_in,
      });
      this.setUser(res.user, res.scope);
      if (!this.externalAuthClient) {
        this.registerOktaClient();
      }
      return Promise.resolve();
    } catch (e) {
      console.error('catch error:', e);
      runInAction(() => {
        this.isLoading = false;
        this.error = extractError(e);
        commonActions.setApplicationErrorMessage(this.error);
      });

      return Promise.reject(e.response);
    }
  };

  @action
  registerOktaClient = () => {
    switch (AUTH_TYPE) {
      case 'DODDLE_OKTA':
        return (this.externalAuthClient = new OktaSignIn({
          baseUrl: OKTA_ISSUER.split('/oauth2')[0],
          clientId: OKTA_CLIENT_ID,
          redirectUri: `${window.location.origin}/login`,
          authParams: {
            issuer: OKTA_ISSUER,
            responseType: ['token', 'id_token'],
            scopes: 'openid profile email'.split(' '),
          },
        }));
      case 'AUS_POST_OKTA':
        return (this.externalAuthClient = new OktaAuth({
          url: OKTA_ISSUER,
          clientId: OKTA_CLIENT_ID,
          redirectUri: `${window.location.origin}/login`,
          responseType: 'token',
          scopes: 'openid, profile, email',
        }));
      default:
        return;
    }
  };

}

export default AuthStore;
