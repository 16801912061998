// @flow

import React from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import {
  ButtonContainer,
  Overlay,
  PickColorButton,
  PickerContainer,
  PickerWrapper,
  SelectColorButton,
} from './elements';

type Props = {
  color: string,
  onChange: (color: string) => void,
};

type State = {
  showPicker: boolean,
  selectedColor: ?string,
};

class ColorPicker extends React.Component<Props, State> {

  state = {
    showPicker: false,
    selectedColor: undefined,
  };

  onOpen = () => {
    this.setState({ showPicker: !this.state.showPicker });
  };

  onClose = () => {
    this.setState({ showPicker: false });
  };

  onPickColor = () => {
    this.setState({ showPicker: false });
    this.props.onChange(this.state.selectedColor || this.props.color);
  };

  onColorChange = (color: ColorResult) => {
    this.setState({ selectedColor: color.hex });
  };

  render() {
    const { showPicker, selectedColor } = this.state;
    const color = selectedColor || this.props.color;
    return (
      <PickerWrapper>
        <PickColorButton onClick={this.onOpen}>Pick Color</PickColorButton>
        {showPicker && (
          <PickerContainer>
            <Overlay onClick={this.onClose} />
            <ChromePicker
              color={color}
              disableAlpha
              onChange={this.onColorChange}
            />
            <ButtonContainer>
              <SelectColorButton onClick={this.onPickColor}>
                Confirm accent
              </SelectColorButton>
            </ButtonContainer>
          </PickerContainer>
        )}
      </PickerWrapper>
    );
  }

}

export default ColorPicker;
