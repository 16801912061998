// @flow
/* eslint camelcase: 0 */

import { action, observable, runInAction } from 'mobx';
import trackingService from 'tracking/services/trackingService';
import moment from 'moment';
import companyModelService from '_common/services/companyModelService';
import { ROOT_ORG_ID } from '_common/constants/appConfig';
import Amplitude from '_common/utils/amplitude';

type TrackingEvent = {
  eventDateTime: string,
  doddleTrackingEventId: string,
  eventDescription: string,
  customerVisible: boolean,
};

class TrackingStore {

  static EVENTS_MAPPING = {
    'Return requested': 'Return requested',
    'Picked up by carrier': 'Picked up from drop off point',
    'At Doddle Store': 'Lodged by customer',
    'Back at warehouse': 'Delivered',
  };

  @observable
  returns = [];

  @observable
  isError = false;

  getStateForDebug = () => ({
    returns: this.returns,
    isError: this.isError,
  });

  @action
  searchReturns = async (
    searchKey: string,
    merchantTrackingName: string | null
  ) => {
    try {
      this.returns = [];
      this.setErrorState(false);

      const returns = await trackingService.searchReturns(searchKey);

      if (returns.events.length <= 0) {
        this.setErrorState(true);
        Amplitude.logEvent('search_performed', {
          page_name: 'Tracking',
          is_found: false,
        });
        return Promise.reject();
      }

      Amplitude.logEvent('search_performed', {
        page_name: 'Tracking',
        is_found: true,
      });

      const { carrierTrackingId: id, retailerId } = returns;
      const companyConfig = await companyModelService.getCompany(retailerId);
      const { companyName } = companyConfig;

      /** Admin login flow */
      if (!merchantTrackingName || companyName !== ROOT_ORG_ID) {
        merchantTrackingName = companyName;
      }

      runInAction(() => {
        const eventsRegistry = {};

        returns.events.forEach((event: TrackingEvent) => {
          const { eventDescription, eventDateTime } = event;
          /** We interested only in specific event types. */
          if (!Boolean(TrackingStore.EVENTS_MAPPING[eventDescription])) {
            return;
          }

          /** We need to collect only oldest event, requirement from Melissa */
          const storedEvent = eventsRegistry[eventDescription];
          if (
            !storedEvent ||
            moment(eventDateTime) > moment(storedEvent.eventDateTime)
          ) {
            eventsRegistry[eventDescription] = event;
          }
        });

        this.returns = Object.values(eventsRegistry)
          .sort((d1, d2) =>
            moment(d1.eventDateTime) > moment(d2.eventDateTime) ? 1 : -1
          )
          .map(({ eventDateTime, eventDescription }: TrackingEvent) => {
            const localTime = moment.utc(eventDateTime).local();
            return {
              id,
              merchant: merchantTrackingName || retailerId,
              status: TrackingStore.EVENTS_MAPPING[eventDescription],
              date: localTime.format('DD.MM.YYYY'),
              time: localTime.format('h:mm a'),
            };
          });
      });
    } catch (e) {
      runInAction(() => {
        this.setErrorState(true);
      });
      Amplitude.logEvent('search_performed', {
        page_name: 'Tracking',
        is_found: false,
      });
      return Promise.reject(e);
    }
  };

  @observable
  setErrorState = (isError: boolean) => {
    if (isError === this.isError) {
      return;
    }
    runInAction(() => {
      this.isError = isError;
    });
  };

  @action
  reset() {
    this.returns = [];
    this.isError = false;
  }

}

export default TrackingStore;
