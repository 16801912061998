import { get } from 'lodash';

/**
 * This function extract a single value for a property.
 * Finding flow is: [updated config] ->  [initial company model] -> [default value]
 * @param companyModel - original company model
 * @param companyModelUpdates - updates made for company - 'slim' model
 * @param path - string path like `prop1.prop2.value`
 * @param defaultValue - default possible value.
 * @returns {*}
 */
export const extractFieldValue = ({
  companyModelStore: { companyModel, companyModelUpdates },
  path,
  defaultValue,
}) => {
  const updatedValue = get(companyModelUpdates, path);
  if (typeof updatedValue !== 'undefined') {
    return updatedValue;
  }
  // TODO: probably if flow reached default value - have sense to update company?

  return get(companyModel, path, defaultValue);
};

export const getCaptionFromContactName = companyModel => {
  const contactName = get(companyModel, 'contactName');
  if (!contactName) {
    return null;
  }

  const [fn, sn] = contactName.split(' ').filter(Boolean);
  return `${fn[0]}${sn ? sn[0] : ''}`;
};
