// @flow
/* eslint camelcase: 0 */

import {
  computed,
  observable,
  ObservableMap,
  ObservableSet,
  action,
  runInAction,
} from 'mobx';
import includes from 'lodash/includes';
import type { User } from 'users/services/usersService';
import UsersService from 'users/services/usersService';
import merchantsService from 'merchants/services/merchantsService';
import { extractError } from '_common/utils';
import Amplitude from '_common/utils/amplitude';
import commonActions from '_common/actions';
import { ROOT_ORG_ID, ALLOWED_ROLES_CONFIG } from '_common/constants/appConfig';

const getChangeUserStatusConfig = (login, organisationId, isAdmin) => ({
  login,
  companyId: organisationId,
  ...(isAdmin && {
    adminStateChange: true,
  }),
});

class UsersStore {

  @observable
  usersMap: ObservableMap<string, User> = observable.map();

  @observable
  hasNextPage = false;

  @observable
  usersLoading = false;

  @observable
  processingUsersSet: ObservableSet<string> = observable.set();

  getStateForDebug = () => ({
    usersMap: this.usersMap,
  });

  @computed
  get users(): Array<User> {
    return [...this.usersMap.values()];
  }

  @action
  fetch = async (organisationId, isPaginatedRequest) => {
    // for fetch with offset - do not clear users, add new ones to existing
    !isPaginatedRequest && this.usersMap.clear();
    this.usersLoading = true;
    try {
      const { resources, hasNextPage } =
        organisationId === ROOT_ORG_ID
          ? /**
             * this branch is for admins as it's called from the root users url.
             * need to think about the case with multiple admin roles - what roles to fetch and what roles can manage others
             */
            await UsersService.getUsersByRole(
              ALLOWED_ROLES_CONFIG.adminRoles[0],
              isPaginatedRequest
            )
          : await UsersService.getUsersByOrganisation(
              organisationId,
              isPaginatedRequest
            );

      runInAction(() => {
        resources &&
          resources.forEach((user: User) => {
            if (
              Array.isArray(user.organisationIds)
                ? includes(user.organisationIds, organisationId)
                : organisationId === user.organisationId
            ) {
              this.usersMap.set(user.login, user);
            }
          });
        this.hasNextPage = hasNextPage;
        this.usersLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.usersLoading = false;
      });
      commonActions.setApplicationErrorMessage(extractError(err));
      console.error(err);
    }
  };

  sendInvite = async (organisationId: string, email: string) => {
    try {
      const inviteAdmin =
        organisationId === ROOT_ORG_ID &&
        commonActions.getIsAdminUser().isAdmin;

      await merchantsService.inviteUserRequest(
        { organisationId, email },
        inviteAdmin
      );
      Amplitude.logEvent('invite_sent', { page_name: 'Users' });

      runInAction(() => {
        this.usersMap.set(email, {
          login: email,
          organisationId: organisationId,
          status: 'active',
        });
      });
    } catch (err) {
      commonActions.setApplicationErrorMessage(extractError(err));
      return Promise.reject(err);
    }
  };

  @action
  disableUser = async (organisationId, login) => {
    this.processingUsersSet.add(login);
    try {
      const isAdmin =
        organisationId === ROOT_ORG_ID &&
        commonActions.getIsAdminUser().isAdmin;
      const body = getChangeUserStatusConfig(login, organisationId, isAdmin);
      await UsersService.suspendUser(body);
      Amplitude.logEvent('user_status_changed', {
        page_name: 'Users',
        toggle_value: 'Disabled',
      });

      runInAction(() => {
        this.usersMap.set(login, {
          login,
          organisationId,
          status: 'suspended',
        });
      });
    } catch (err) {
      commonActions.setApplicationErrorMessage(extractError(err));
      console.error(err);
    } finally {
      runInAction(() => {
        this.processingUsersSet.delete(login);
      });
    }
  };

  @action
  enableUser = async (organisationId, login) => {
    this.processingUsersSet.add(login);
    try {
      const isAdmin =
        organisationId === ROOT_ORG_ID &&
        commonActions.getIsAdminUser().isAdmin;
      const body = getChangeUserStatusConfig(login, organisationId, isAdmin);
      await UsersService.unSuspendUser(body);
      Amplitude.logEvent('user_status_changed', {
        page_name: 'Users',
        toggle_value: 'Enabled',
      });

      runInAction(() => {
        this.usersMap.set(login, { login, organisationId, status: 'active' });
      });
    } catch (err) {
      commonActions.setApplicationErrorMessage(extractError(err));
      console.error(err);
    } finally {
      runInAction(() => {
        this.processingUsersSet.delete(login);
      });
    }
  };

}

export default UsersStore;
