//@flow
/* eslint camelcase: 0 */
import React, { Component } from 'react';
import { includes, get } from 'lodash';
import { isIE } from 'react-device-detect';

import { TextSection, UploadFile, ColorPicker } from '_common/components';
import {
  ImagePreview,
  PanelIcon,
} from '_common/components/CommonStyledElements';
import {
  PanelSection,
  SpacerForIE,
} from 'settings/branding/components/elements';
import apiEndpoins from '_common/api/endpoints';
import { WhiteLabelUi } from '_common/whitelabelConfig';
import { TrackingBlocks } from '_common/stores/uiStore';
import { bgIcon, logoIcon } from 'assets';
import { IMAGE_TYPE } from '_common/constants/imageDimensions';
import ColorPreview from 'settings/branding/components/ColorPreview';
import commonActions from '_common/actions';

export default class UploadAssetsComponent extends Component {

  state = {
    logoImageSrc: null,
    bgImageSrc: null,
    currentColor: null,
  };

  logoRef: { current: any } = React.createRef();
  bgRef: { current: any } = React.createRef();

  static getDerivedStateFromProps(
    { companyName, assetsConfig },
    { bgImageSrc, logoImageSrc }
  ) {
    if (!assetsConfig || !assetsConfig.assets) {
      return null;
    }

    const { backgroundImage, logoImage } = assetsConfig.assets;
    if (
      companyName &&
      !includes(backgroundImage, companyName) &&
      !includes(logoImage, companyName)
    ) {
      return null;
    }

    if (
      (bgImageSrc === null && backgroundImage) ||
      (logoImageSrc === null && logoImage)
    ) {
      /** Preview images from async loaded config. */
      return {
        logoImageSrc: logoImage
          ? `${apiEndpoins.ASSETS_URL}/${logoImage}?ts=${Date.now()}`
          : null,
        bgImageSrc: backgroundImage
          ? `${apiEndpoins.ASSETS_URL}/${backgroundImage}?ts=${Date.now()}`
          : null,
      };
    }
    return null;
  }
  componentDidMount() {
    this.props.onMount(this.handlePublish);
  }

  handlePublish = async () => {
    const { companyName } = this.props;

    let logo;
    let logoImageName;
    let bgImage;
    let bgImageName;

    if (this.logoRef.current) {
      logo = this.logoRef.current.getBase64();
      logoImageName = this.logoRef.current.state.previewName;
    }

    if (this.bgRef.current) {
      bgImage = this.bgRef.current.getBase64();
      bgImageName = this.bgRef.current.state.previewName;
    }

    const { currentColor: accentColor } = this.state;

    try {
      // save assets
      await commonActions.publishBrandingAssets({
        logo,
        logoImageName,
        bgImage,
        bgImageName,
        accentColor,
        companyName,
      });

      const changedFields = [
        logoImageName && 'Logo',
        bgImageName && 'Background image',
        accentColor && 'Accent colour',
      ]
        .filter(Boolean)
        .join(',');

      return Promise.resolve(changedFields);
    } catch (error) {
      console.error('catch err :: assets publish', error);
    }
  };

  handleLogoLoad = (logoImageSrc: string) => {
    this.props.onChange(TrackingBlocks.Branding, 'Branding: Logo image');
    this.setState({ logoImageSrc });
  };

  handleBgLoad = (bgImageSrc: string) => {
    this.props.onChange(TrackingBlocks.Branding, 'Branding: Background image');
    this.setState({ bgImageSrc });
  };

  handleColorChange = (color: string) => {
    this.props.onChange(
      TrackingBlocks.Branding,
      'Branding: Accent colour',
      this.state.currentColor,
      color
    );
    this.setState({ currentColor: color });
  };

  renderImagePreview(src, defaultSrc) {
    if (src) {
      return <ImagePreview image={src} width={120} height={140} />;
    }
    return <PanelIcon image={defaultSrc} width={93} height={93} />;
  }

  renderImageTooltip() {
    return (
      <span>
        <span>Supported file formats: JPG or PNG</span>
        <br />
        <span>Maximum file size: 2 MB</span>
        <br />
        <span>Aspect ratio: 16:9</span>
        <br />
        <span>Suggested image resolutions: 1280×720, 1600×900,</span>
        <br />
        <span>1920×1080, 2048×1152</span>
      </span>
    );
  }

  render() {
    const { logoImageSrc, bgImageSrc, currentColor } = this.state;
    const {
      accentColor,
      latestPublishedLogo,
      isPublished,
      pageHasChanges,
    } = this.props;
    const logoSrc = pageHasChanges
      ? logoImageSrc
      : latestPublishedLogo || logoImageSrc;

    const logoImage = this.renderImagePreview(logoSrc, logoIcon);
    const bgImage = this.renderImagePreview(bgImageSrc, bgIcon);
    return (
      <>
        <PanelSection>
          {logoImage}
          <TextSection title="Logo">
            We recommend uploading a transparent PNG <br />
            cropped to the edges of your logo and up to <br /> 500px wide or
            200px tall.
          </TextSection>
          {isIE && <SpacerForIE />}
          <UploadFile ref={this.logoRef} onLoad={this.handleLogoLoad} />
        </PanelSection>
        <PanelSection>
          {bgImage}
          <TextSection
            title="Background image"
            withToolTip
            toolTipText={this.renderImageTooltip()}
          >
            We recommend uploading a JPG or PNG <br />
            that’s 2048px wide or 1536px tall.
          </TextSection>
          {isIE && <SpacerForIE />}
          <UploadFile
            isPublished={isPublished}
            ref={this.bgRef}
            type={IMAGE_TYPE.BACKGROUND_IMAGE}
            onLoad={this.handleBgLoad}
          />
        </PanelSection>
        <PanelSection>
          <ColorPreview color={currentColor || accentColor} />
          <TextSection
            title={get(WhiteLabelUi, 'pages.quickStart.colorPickerTitle')}
          >
            Choose a custom accent color for the primary <br /> buttons and
            selected pins.
          </TextSection>
          {isIE && <SpacerForIE />}
          <ColorPicker
            color={currentColor || accentColor}
            onChange={this.handleColorChange}
          />
        </PanelSection>
      </>
    );
  }

}
