// @flow

import { Input } from '_common/components';
import { Form } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  LoginInputLabel,
  FormContainerFlexed,
  LoginButton,
  LoginTitle,
  Wrapper,
} from '../elements/Login';
import { FormComponentProps } from 'antd/lib/form';
import { extractError, validatePassword } from '_common/utils';
import { VALIDATION_ERRORS } from '_common/constants/validation';
import merchantActions from 'merchants/actions';
import qs from 'qs';
import MerchantsStore from 'merchants/stores/merchantsStore';
import commonActions from '_common/actions';

type Props = FormComponentProps & {
  merchantStore: MerchantsStore,
};

@inject('merchantStore')
@observer
class CreateNewPassword extends Component<Props> {

  async componentDidMount() {
    await commonActions.appLogin();
  }

  /**
   * Handle resetting password.
   */
  submit = (e: Event) => {
    e.preventDefault();
    const {
      form: { validateFields, setFields },
      location: { search },
      match: {
        params: { company },
      },
      history: { push },
    } = this.props;

    validateFields((err, { password }) => {
      if (!err) {
        const { token } = qs.parse(search, { ignoreQueryPrefix: true });

        merchantActions
          .confirmUpdatingPassword(company, token, password)
          .then(() => push(`/${company}/login`))
          .catch(error => {
            setFields({
              password: {
                value: password,
                errors: [new Error(extractError(error))],
              },
            });
          });
      }
    });
  };

  compareToFirstPassword = (
    _: any,
    value: any,
    callback: (msg?: string) => void
  ) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(VALIDATION_ERRORS.notMatchPasswords);
    } else {
      callback();
    }
  };

  validateToNextPassword = (
    _: any,
    value: string,
    callback: (msg?: string) => void
  ) => {
    if (value && !validatePassword(value)) {
      callback(VALIDATION_ERRORS.invalidPassword);
    } else {
      callback();
    }
  };

  renderForm = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Form onSubmit={this.submit}>
        <Form.Item>
          {getFieldDecorator('password', {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Please enter your password',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(
            <div>
              <LoginInputLabel htmlFor="passwordId">
                New password
              </LoginInputLabel>
              <Input
                type="password"
                id="passwordId"
                placeholder="Enter new password"
              />
            </div>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('passwordTwo', {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Please confirm your password',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(
            <div>
              <LoginInputLabel htmlFor="passwordTwoId">
                Re-type new password
              </LoginInputLabel>
              <Input
                type="password"
                id="passwordTwoId"
                placeholder="Re-type new password"
              />
            </div>
          )}
        </Form.Item>
        <LoginButton htmlType="submit">Save</LoginButton>
      </Form>
    );
  };

  render() {
    return (
      <Wrapper>
        <FormContainerFlexed>
          <LoginTitle>Create a new password</LoginTitle>
          {this.renderForm()}
        </FormContainerFlexed>
      </Wrapper>
    );
  }

}

export default compose(Form.create())(CreateNewPassword);
