import { ManualErrors } from '_common/constants/apiErrorResponces';

export const requiredFieldRule = {
  required: true,
  whitespace: true,
  message: 'This field is mandatory',
};

export const chargeAccountFieldRule = {
  message: 'Charge account number is 5-7 digits only.',
  pattern: /^[0-9]{5,7}$/g,
};

/**
 * Format error to expected on UI form
 * @param message
 * @returns {{response: {data: {errors: [{message: *}]}}}}
 */
export const throwManualFormError = (message: ManualErrors) => ({
  response: {
    data: {
      errors: [
        {
          message,
        },
      ],
    },
  },
});
