import styled, { css } from 'styled-components';

export const Root = styled.div`
  width: 100%;
  margin-left: 100px;
  ${props =>
    props.width &&
    css`
      margin-left: initial;
      width: ${props.width}px;
    `}
`;

export const SwitchInputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      border-top: solid 1px #eeeeee;
    `};
  height: 49px;
  font-size: 12px;
  font-family: ${props => props.theme.fonts.book};
  padding-left: 4px;

  & .ant-row.ant-form-item {
    margin-top: auto;
    margin-bottom: auto;
  }

  & .ant-input {
    border-radius: 2px;
    height: 30px !important;
    position: relative;
    width: ${({ width }) => (width || 160) + 'px'};
    border: solid 1px #d8d8d8;

    @media only screen and (max-width: 1366px) {
      width: 130px;
    }
  }
`;

export const SwitchInputLabel = styled.div`
  margin-right: auto;
`;
