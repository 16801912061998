import styled, { AnyStyledComponent, css } from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  margin: 22px 31px 0 47px;
  border-radius: 3px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  background-color: #ffffff;
  padding: 20px;
  align-self: flex-start;
`;
export const MenuLink: AnyStyledComponent = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(199, 199, 204, 0.5);
  padding: 15px;
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};

  ${(props: any) =>
    props.withTopBorder &&
    css`
      border-top: solid 1px rgba(199, 199, 204, 0.5);
    `};

  ${(props: any) =>
    props.withoutBottomBorder &&
    css`
      border-bottom: none;
    `};

  &:hover {
    opacity: 0.6;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  color: #282d32;
  font-family: ${props => props.theme.fonts.book};
`;

export const DropdownWrapper: AnyStyledComponent = styled.div`
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;

  ${(props: any) =>
    props.isOpen &&
    css`
      max-height: 300px;
      padding-bottom: 20px;
      transition: max-height 0.4s ease;
    `};
`;

export const MenuSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px rgba(199, 199, 204, 0.5);
  font-family: ${props => props.theme.fonts.book};

  & .anticon {
    position: relative;
    top: 0.25em;
  }
`;

export const MenuSectionHeader = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.book};
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.6;
  }
`;

export const DropdownLinkWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 5px 35px;
`;

export const NavLink = styled(RouterNavLink)`
  font-size: 14px;
  &,
  &[disabled],
  &:hover {
    color: #282d32;
  }
  position: relative;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.6;
  }

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #d8d8d8;
    border-radius: 50%;
    top: 6px;
    left: -20px;
  }

  &.active-link:before {
    background-color: #4a90e2;
  }
`;

export const StyledLink = styled(RouterNavLink)`
  &,
  &[disabled],
  &:hover {
    color: initial;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};

  &.active > ${MenuLink} {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #4a90e2;
      border-radius: 50%;
      left: 0;
      top: 23px;
    }
  }
`;
