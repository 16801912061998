//@flow
import React, { Component } from 'react';
import { Menu, RadioGroup, TextSection } from '_common/components';
import {
  Content,
  HeaderAbsoluteErrorMessage,
  Page,
  PageHeader,
  PageTitle,
  Panel,
  PanelIcon,
  PanelSectionNoWrap,
} from '_common/components/CommonStyledElements';
import { Prompt } from 'react-router';
import { IS_DEVELOPMENT } from '_common/constants/appConfig';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import {
  WhiteLabelComponents,
  WhiteLabelStores,
  withWhitelabelProps,
} from '_common/whitelabelConfig';
import { extractError, mapFrontAddressToBack } from '_common/utils';
import commonActions from '_common/actions';
import { speedIcon } from 'assets';
import { ManualErrors } from '_common/constants/apiErrorResponces';
import { CompanyModelStore, PublishStore, UIStore } from 'stores';
import { extractFieldValue } from '_common/utils/companyUtils';
import { addRequiredFieldsForAutoSave } from '_common/utils/directoryUtils';
import { TrackingBlocks } from '_common/stores/uiStore';
import { TrackableChanges } from '_common/stores/companyModelStore';
import Amplitude from '_common/utils/amplitude';

type Props = RouteComponentProps & {
  companyModelStore: CompanyModelStore,
  uiStore: UIStore,
  publishStore: PublishStore,
  whiteLabeled: any,
};

type State = {
  hasUnpublishedChanges: boolean,
  errorAddressFields: Object,
};

@inject('uiStore', 'companyModelStore', 'publishStore')
@observer
class ReturnDetails extends Component<Props, State> {

  static AMPLITUDE_CONFIG = [
    {
      fields: ['returns.returnsSpeedType'],
      amplitudeName: 'Returns speed type',
    },
  ];

  state = {
    hasUnpublishedChanges: false,
    errorAddressFields: {},
  };

  async componentDidMount() {
    const {
      publishStore: { setCurrentPublish },
      uiStore: { setGooglePlacesMainInput },
    } = this.props;
    Amplitude.logEvent('page_open', { page_name: 'Return details' });
    setCurrentPublish(this.handlePublish);
    setGooglePlacesMainInput('');
  }

  merchantDetailsFormRef: {
    current: { wrappedInstance: any },
  } = React.createRef();

  returnAddressFormRef: {
    current: { wrappedInstance: any },
  } = React.createRef();

  get hasFormErrors() {
    const {
      validateFieldsAndScroll: validateFieldsMerchantDetailsForm,
      //wrappedInstance is from mobx-react (we are using v.5 where it is still required to forward refs)
    } = this.merchantDetailsFormRef.current.wrappedInstance;

    const {
      validateFieldsAndScroll: validateFieldsReturnAddressForm,
    } = this.returnAddressFormRef.current.wrappedInstance;

    let hasFormsErrors = false;
    validateFieldsMerchantDetailsForm({ scroll: { offsetTop: 100 } }, err => {
      if (err) {
        hasFormsErrors = true;
      }
    });
    validateFieldsReturnAddressForm({ scroll: { offsetBottom: 100 } }, err => {
      if (err) {
        hasFormsErrors = true;
      }
    });
    return hasFormsErrors;
  }

  handleAutoSave = async updates => {
    const {
      match: {
        params: { company },
      },
      uiStore: { editingCompanyByAdmin },
      companyModelStore: { companyModel },
    } = this.props;

    if (this.hasFormErrors) return;

    try {
      await commonActions.companyActions.updateCompany(
        company || editingCompanyByAdmin,
        addRequiredFieldsForAutoSave(companyModel, updates).updatedConfig
      );
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
    }
  };

  /** Publish also trigger amplitude */
  handlePublish = async () => {
    const {
      publishStore: {
        isPublished,
        setIsLoading,
        setIsPublished,
        publishStart,
        showPublishNotification,
      },
      companyModelStore: { companyModelUpdates },
      match: {
        params: { company },
      },
      uiStore: { editingCompanyByAdmin },
    } = this.props;

    if (isPublished) {
      return;
    }

    const {
      getFieldsValue: getFieldsValueReturnAddressForm,
    } = this.returnAddressFormRef.current.wrappedInstance;

    const {
      postal_town: city,
      administrative_area_level_1: state,
      postal_code: postcode,
    } = getFieldsValueReturnAddressForm([
      'postal_town',
      'administrative_area_level_1',
      'postal_code',
    ]);
    const addressFieldsValues = { city, state, postcode };

    if (!this.hasFormErrors) {
      publishStart();

      try {
        this.setState({
          errorAddressFields: {},
        });
        if (city || state || postcode) {
          await commonActions.validateAddress(
            mapFrontAddressToBack(addressFieldsValues)
          );
        }
        commonActions.getChanges([TrackingBlocks.ReturnDetails]);
        // for select product fields
        await commonActions.companyActions.updateCompany(
          company || editingCompanyByAdmin,
          companyModelUpdates
        );

        Amplitude.logFieldsChanges({
          pageName: 'Return details',
          model: companyModelUpdates,
          config: ReturnDetails.AMPLITUDE_CONFIG,
        });

        showPublishNotification('isPublished', 2);
        setIsPublished(true);
        this.setState({ hasUnpublishedChanges: false });
      } catch (error) {
        if (error === ManualErrors.ADDRESS_NOT_VALID) {
          this.setState({
            errorAddressFields: addressFieldsValues,
          });
        } else {
          console.error('catch err :: handlePublish', error);
        }
        showPublishNotification('isError');
      } finally {
        setIsLoading(false);
      }
    }
  };

  handleDetailsChange = (details: MerchantDetails) => {
    commonActions.setMerchantDetails(details);
  };

  handleSpeedChange = ({ target: { value } }) => {
    const { companyModelStore } = this.props;
    const speedOption = extractFieldValue({
      path: 'returns.returnsSpeedType',
      companyModelStore,
      defaultValue:
        WhiteLabelStores.settingCommunicationsStore.DEFAULT_SPEED_OPTION,
    });

    commonActions.trackChange(
      TrackingBlocks.ReturnDetails,
      TrackableChanges.DELIVERY_SPEED,
      speedOption,
      value
    );

    const changes = WhiteLabelStores.settingCommunicationsStore.getUpdatedPaymentsConfig(
      {
        companyModel: this.props.companyModelStore.companyModel,
        speedOption: value,
      }
    );

    commonActions.companyActions.changeCompanyModel({ changesObject: changes });
    this.setState({ hasUnpublishedChanges: true });
  };

  renderMerchantDetails = () => {
    return (
      <WhiteLabelComponents.MerchantDetails
        ref={this.merchantDetailsFormRef}
        onSave={this.handleAutoSave}
        onChange={this.handleDetailsChange}
        companyModel={this.props.companyModelStore.companyModel}
      />
    );
  };

  renderReturnAddress = () => {
    return (
      <WhiteLabelComponents.ReturnAddress
        ref={this.returnAddressFormRef}
        onSave={this.handleAutoSave}
        onChange={this.handleDetailsChange}
        errorAddressFields={this.state.errorAddressFields}
        companyModel={this.props.companyModelStore.companyModel}
        googlePlacesMainInput={this.props.uiStore.googlePlacesMainInput}
      />
    );
  };

  renderSelectProduct = () => {
    const {
      whiteLabeled: { selectProductDesc, selectProductSpeedOptions },
      companyModelStore,
    } = this.props;

    const speedOption = extractFieldValue({
      path: 'returns.returnsSpeedType',
      companyModelStore,
      defaultValue:
        WhiteLabelStores.settingCommunicationsStore.DEFAULT_SPEED_OPTION,
    });

    return (
      <>
        <PanelSectionNoWrap>
          <PanelIcon image={speedIcon} width={64} height={64} />
          <TextSection title="Return Service Speed">
            {selectProductDesc}
          </TextSection>
          <RadioGroup
            width={330}
            options={selectProductSpeedOptions}
            value={speedOption}
            onChange={this.handleSpeedChange}
          />
        </PanelSectionNoWrap>
      </>
    );
  };

  render() {
    const { hasUnpublishedChanges } = this.state;
    const {
      uiStore: { editingCompanyByAdmin },
      whiteLabeled: { pageTitle },
    } = this.props;
    return (
      <Page>
        <Prompt
          when={!IS_DEVELOPMENT && hasUnpublishedChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        {hasUnpublishedChanges && (
          <HeaderAbsoluteErrorMessage largeOffsetRight>
            Form has unpublished changes
          </HeaderAbsoluteErrorMessage>
        )}
        <Menu />
        <Content editingCompanyByAdmin={editingCompanyByAdmin}>
          <PageHeader>
            <PageTitle>{pageTitle}</PageTitle>
          </PageHeader>
          <Panel>
            {this.renderMerchantDetails()}
            {this.renderReturnAddress()}
            {this.renderSelectProduct()}
          </Panel>
        </Content>
      </Page>
    );
  }

}

export default compose(
  withWhitelabelProps({
    selectProductDesc: 'ui.pages.settingsAndCommunications.product.description',
    selectProductSpeedOptions:
      'ui.pages.settingsAndCommunications.SPEED_OPTIONS',
    pageTitle: 'ui.pages.returnDetails.pageTitle',
  })
)(ReturnDetails);
