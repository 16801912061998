import { ROUNDED_IMAGES_ASPECT_RATIO } from '_common/constants/imageDimensions';
import { includes } from 'lodash';

export const IMAGE_WIDTH_BOUNDS = {
  WIDTH: {
    MIN: 960,
    MAX: 5000,
  },
  HEIGHT: {
    MIN: 720,
    MAX: Infinity,
  },
};

export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];
export const MAX_IMAGE_SIZE = 2000000;

/**
 * Round number to decimal.
 * @param {number} number
 *
 * @return {number}
 */
export function decimalNumberRound(number) {
  return Number((Math.floor(number * 100) / 100).toFixed(2));
}

/**
 * Validate if image in valid aspect ratio.
 * @return {boolean}
 */
export const validateImageAspectRatio = ({ width, height }) => {
  const ratio = width / height;
  const roundRatio = decimalNumberRound(ratio);

  return ROUNDED_IMAGES_ASPECT_RATIO.includes(roundRatio);
};

/**
 * Validate width of image to be in strict bounds.
 * @returns {boolean}
 */
export const validateImageBounds = ({ width, height }) => {
  return (
    IMAGE_WIDTH_BOUNDS.WIDTH.MIN <= width &&
    width <= IMAGE_WIDTH_BOUNDS.WIDTH.MAX &&
    IMAGE_WIDTH_BOUNDS.HEIGHT.MIN <= height &&
    height <= IMAGE_WIDTH_BOUNDS.HEIGHT.MAX
  );
};

/**
 * Validate image by allowed file type.
 * @returns {boolean}
 */
export const validateFileAllowedTypes = ({ type }) => {
  return includes(ALLOWED_FILE_TYPES, type);
};

/**
 * Validate max image size.
 * @returns {boolean}
 */
export const validateFileMaxSize = ({ size }) => {
  return size < MAX_IMAGE_SIZE;
};

export const FILE_VALIDATOR_CHECKS = [
  {
    validatorFunction: validateFileAllowedTypes,
    errorMessage: 'Invalid file type, please use JPG or PNG.',
  },
  {
    validatorFunction: validateFileMaxSize,
    errorMessage: 'File size should be less than 2 MB.',
  },
];

export const BACKGROUND_IMAGE_VALIDATOR_CHECKS = [
  {
    validatorFunction: validateImageBounds,
    errorMessage: `This image is too small. Please use suggested image resolutions in the tooltip`,
  },
  {
    validatorFunction: validateImageAspectRatio,
    errorMessage: 'Incorrect image ratio. Update to 16:9.',
  },
];
