import { Input } from 'antd';
import React from 'react';
import styled, { AnyStyledComponent, css } from 'styled-components';

export const StyledInput: AnyStyledComponent = styled(
  ({ innerRef, isInvalid, placeholderFontSize, ...rest }) => <Input {...rest} />
)`
  & {
    border-radius: 3px;
    background-color: #ffffff;

    @media only screen and (max-width: 1440px) {
      width: 100% !important;
    }

    @media all and (-ms-high-contrast: none) {
      line-height: 1 !important;
    }

    &::placeholder {
      font-weight: 300;
      font-family: ${props => props.theme.fonts.book};
      text-align: left;
      font-size: ${({ placeholderFontSize = 14 }) =>
        placeholderFontSize + 'px'};
      color: #9b9b9b !important;
    }

    ${props =>
      props.suffix
        ? `
          &.ant-input-affix-wrapper{
            display: block;
          }
          & input.ant-input {
          width: ${({ width }) => (width ? `${width}px` : '100% ')};
          height: 42px;
          line-height: 42px;
          border: solid 1px #979797;
          font-family: ${props => props.theme.fonts.book};
          color: #222222;
          font-size: 14px;
          display: block;
          @media only screen and (max-width: 1366px) {
            width: ${({ width }) => (width ? `${width - 50}px` : '100% ')};
          }
          &::placeholder {
            font-weight: 300;
            font-family: ${props => props.theme.fonts.book};
            text-align: left;
            font-size: ${({ placeholderFontSize }) =>
              placeholderFontSize || '14px'};
            color: #9b9b9b !important;
          }
        }
        & .ant-input-suffix {
          cursor: default;
        }
        `
        : `&.ant-input {
          width: ${({ width }) => (width ? `${width}px` : '100% ')};
          height: 42px;
          line-height: 42px;
          border: solid 1px #979797;
          font-family: ${props => props.theme.fonts.book};
          color: #222222;
          font-size: 14px;
          display: block;
          @media only screen and (max-width: 1366px) {
            width: ${({ width }) => (width ? `${width - 50}px` : '100% ')};
          }

          &::placeholder {
            font-weight: 300;
            font-family: ${props => props.theme.fonts.book};
            text-align: left;
            font-size: ${({ placeholderFontSize }) =>
              placeholderFontSize || '14px'};
            color: #9b9b9b !important;
          }
        }`};

    &:read-only {
      cursor: not-allowed;
    }

    &:disabled {
      cursor: not-allowed;
      color: #545454;
      background-color: #ebebe4;
    }

    ${({ isInvalid }) =>
      isInvalid &&
      css`
        border-color: #ff3654 !important;
        &:focus {
          border-color: #ff3654 !important;
          border-right-width: 1px !important;
          outline: 0 !important;
          box-shadow: 0 0 0 2px rgba(236, 21, 57, 0.2) !important;
        }
      `}
  }
`;
