// @flow
/* eslint camelcase: 0 */

import commonActions from '_common/actions';
import { Checkbox, Input, InputTooltip } from '_common/components';
import AuthStore from '_common/stores/authStore';
import LoginCard from 'login/pages/components/LoginCard';
import MerchantsStore from 'merchants/stores/merchantsStore';
import { Form, Col, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Agreement,
  FormContainer,
  LoginButton,
  LoginInputLabel,
  LoginTitle,
  SignUpWrapper,
  LoginSubTitle,
} from '../elements/Login';
import {
  WhiteLabelUi,
  WhiteLabelServices,
  WhiteLabelComponents,
} from '_common/whitelabelConfig';
import Amplitude from '_common/utils/amplitude';
import { GlobalStyles } from 'themes/globalStyles';
import { isIE } from 'react-device-detect';
import { renderReactList } from '_common/utils';

type Props = RouteComponentProps &
  FormComponentProps & {
    merchantStore: MerchantsStore,
    authStore: AuthStore,
  };

@observer
class SignUp extends Component<Props> {

  async componentDidMount() {
    await commonActions.appLogin();
    WhiteLabelServices.trackSignupPageEvent({ eventType: 'onPageLoad' });
    Amplitude.logWithoutCheck('page_open', { page_name: 'Signup' });
  }

  constructor(props) {
    super(props);
    this.config = WhiteLabelUi.pages.signUp(props.form);
    this.renderFields = renderReactList.bind(this);
  }

  @computed
  get errorMessage() {
    return this.props.merchantStore.error || this.props.authStore.error;
  }

  @computed
  get isLoading() {
    return this.props.merchantStore.isLoading || this.props.authStore.isLoading;
  }

  submit = async (e: Event) => {
    e.preventDefault();
    const {
      form: { validateFields, setFields },
    } = this.props;

    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      await WhiteLabelServices.actionOnSignup(values, setFields);
    });
  };

  wrapWithTooltip = (input, tooltip) => {
    if (tooltip) {
      return (
        <InputTooltip
          trigger={['focus', 'hover']}
          title={tooltip}
          placement="right"
          outboundToRight
        >
          {input}
        </InputTooltip>
      );
    }
    return input;
  };

  get CHECKBOXES() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <WhiteLabelComponents.SignUpCheckboxes
        getFieldDecorator={getFieldDecorator}
        checkboxes={this.config.checkboxes}
      />
    );
  }

  get INPUTS() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Row gutter={60} type="flex" justify="start">
        {this.config.inputs.map(inputConfig => {
          const {
            id,
            label,
            placeholder,
            rules,
            type,
            validateFirst,
            tooltip,
            maxLength,
          } = inputConfig;

          return (
            <Col span={12} key={id}>
              <Form.Item>
                {getFieldDecorator(id, { rules, validateFirst })(
                  <div>
                    <LoginInputLabel htmlFor={`${id}${type}`}>
                      {label}
                    </LoginInputLabel>
                    {this.wrapWithTooltip(
                      <Input
                        maxLength={maxLength}
                        autoComplete="off"
                        type={type}
                        id={`${id}${type}`}
                        placeholder={placeholder}
                        placeholderFontSize={this.config.placeholderFontSize}
                      />,
                      tooltip
                    )}
                  </div>
                )}
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    );
  }

  get FOOTER() {
    const { getFieldDecorator } = this.props.form;
    const { agreementText, submit, SignupButtonWrapper } = this.config;

    return (
      <SignupButtonWrapper>
        <Form.Item>
          {getFieldDecorator('terms', {
            initialValue: false,
            rules: [
              {
                transform: (value: any) => value || undefined,
                type: 'boolean',
                required: true,
                message:
                  'Please confirm your agreement to the Terms of Service and Privacy Policy above',
              },
            ],
          })(
            <Agreement>
              <Checkbox />
              {agreementText}
            </Agreement>
          )}
        </Form.Item>
        <LoginButton disabled={this.isLoading} htmlType="submit" fontSize={16}>
          {submit}
        </LoginButton>
      </SignupButtonWrapper>
    );
  }

  renderFormContainer = () => {
    const { logo, title, subtitle, uiBlocksOrder } = this.config;
    return (
      <FormContainer isSignUp>
        {logo}
        <LoginTitle align="left">{title}</LoginTitle>
        <LoginSubTitle>{subtitle}</LoginSubTitle>
        <Form onSubmit={this.submit}>{this.renderFields(uiBlocksOrder)}</Form>
      </FormContainer>
    );
  };

  renderSignUpCards = () => {
    const {
      merchantStore: { isCollectionsWidgetSubmitted, isReturnsPortalSubmitted },
    } = this.props;

    WhiteLabelServices.trackSignupPageEvent({ eventType: 'onSubmit' });

    return (
      <LoginCard>
        <span>
          {isCollectionsWidgetSubmitted && isReturnsPortalSubmitted
            ? this.config.isCollectionsWidgetAndReturnsPortalSubmitted
            : isCollectionsWidgetSubmitted
            ? this.config.isCollectionWidgetOnlySubmitted
            : this.config.successMessage}
        </span>
        <br />
        <span>
          We’ll send you an email with next steps once we’ve reviewed your
          request.
        </span>
      </LoginCard>
    );
  };

  render() {
    return (
      <SignUpWrapper isSignUp>
        {isIE && <GlobalStyles />}
        {!this.errorMessage && this.props.merchantStore.isAccessRequested
          ? this.renderSignUpCards()
          : this.renderFormContainer()}
      </SignUpWrapper>
    );
  }

}

export default compose(
  inject('merchantStore', 'authStore'),
  Form.create()
)(SignUp);
