import styled, { css } from 'styled-components';

export const Root = styled.div`
  flex: 1;
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  padding-right: 40px;
`;

export const Title = styled.div`
  font-size: 14px;
  display: inline-block;
  font-family: ${props => props.theme.fonts.medium};
  color: #282d32;
  margin-bottom: 4px;
  ${({ padding }) =>
    padding &&
    css`
      padding: 8px 4px;
    `}
`;

export const Description = styled.div`
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  line-height: 20px;
  color: #282d32;
  ${({ reasonCodeTitle }) =>
    reasonCodeTitle &&
    `
      margin-bottom: 24px;
      & > img {
        width: 14px;
        height: 14px;
        margin: 0 4px;
      }
      & > img:last-child {
        width: 22px;
        height: 16px;
      }
  `}
`;
