// @flow

import React, { Component } from 'react';
import { ModalBody, ModalOverlay } from './elements';

type ModalProps = {
  children?: React.Node,
  toggle?: () => void,
};

class BaseModal extends Component<ModalProps, {}> {

  handleOverlayClick = () => {
    this.props.toggle && this.props.toggle();
  };
  render() {
    return (
      <>
        <ModalBody>{this.props.children}</ModalBody>
        <ModalOverlay onClick={this.handleOverlayClick} />
      </>
    );
  }

}

export default BaseModal;
