// @flow

import React, { Component } from 'react';
import { Wrapper } from './elements';

export const InfoPanelColors = {
  ERROR: '#dc1928',
  SUCCESS: '#38b13a',
};

type Props = {
  message: string,
  color: InfoPanelColors,
  actionButton?: any,
};

class InfoPanel extends Component<Props, {}> {

  render() {
    const { message, actionButton, color } = this.props;
    return (
      <Wrapper color={color}>
        <div>{message}</div>
        {actionButton && <div>{actionButton}</div>}
      </Wrapper>
    );
  }

}

export default InfoPanel;
