import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import validateTokenFromOkta from '_common/actions/validateTokenFromOkta';
import links from '_common/routes/urls';
import type { OktaConfig } from '_common/services/authService';
import { inject, observer } from 'mobx-react';
import AuthStore from '_common/stores/authStore';

type Props = {
  history: History,
  authStore: AuthStore,
};

@inject('authStore')
@observer
class OktaSignInWidget extends Component<Props> {

  handleSuccessOktaAuth = async (config: OktaConfig) => {
    const {
      history: { push },
    } = this.props;
    const accessToken = config[0].accessToken;

    try {
      await validateTokenFromOkta(accessToken);
      /** Redirect to Root Start Page */
      push(links.requests);
    } catch (e) {
      console.error(e);
    }
  };

  handleErrorOktaAuth = err => {
    console.error(err);
  };

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    this.props.authStore.externalAuthClient.renderEl(
      { el },
      this.handleSuccessOktaAuth,
      this.handleErrorOktaAuth
    );
  }

  componentWillUnmount() {
    this.props.authStore.externalAuthClient.remove();
  }

  render() {
    return <div />;
  }

}

export default OktaSignInWidget;
