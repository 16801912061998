// @flow

import { ROOT_ORG_ID } from '_common/constants/appConfig';
import { apiClient } from '_common/api/clients/clients';
import { paginatedMarksQueue } from '_common/utils';
import { USERS_PER_PAGE } from '../pages/Users';

export type User = {
  login: string,
  status: string,
  organisationId: string,
  scope: Array<string>,
};

const getUsersByOrganisation = async (
  organisationId: string = ROOT_ORG_ID,
  isPaginated: boolean
): Promise<Array<User>> => {
  const baseUrl = `/v1/auth-management/staff/organisationId/${organisationId}`;

  const paginatedQuery = isPaginated
    ? paginatedMarksQueue.getPaginatedQueryForUrl(baseUrl)
    : '';

  const response = await apiClient.get(
    `${baseUrl}?limit=${USERS_PER_PAGE}${paginatedQuery}`
  );
  paginatedMarksQueue.enqueueNewMark(baseUrl, response.data.mark);
  return response.data;
};

const getUsersByRole = async (
  roleName: string,
  isPaginated: boolean
): Promise<Array<User>> => {
  const baseUrl = `/v1/auth-management/staff/roleName/${roleName}`;

  const paginatedQuery = isPaginated
    ? paginatedMarksQueue.getPaginatedQueryForUrl(baseUrl)
    : '';

  const response = await apiClient.get(
    `${baseUrl}?limit=${USERS_PER_PAGE}${paginatedQuery}`
  );
  paginatedMarksQueue.enqueueNewMark(baseUrl, response.data.mark);
  return response.data;
};

const suspendUser = async (body): Promise<any> => {
  return apiClient.post(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/disable-user`,
    body
  );
};

const unSuspendUser = async (body): Promise<any> => {
  return apiClient.post(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/enable-user`,
    body
  );
};

export default {
  getUsersByOrganisation,
  getUsersByRole,
  suspendUser,
  unSuspendUser,
};
