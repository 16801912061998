// @flow

import links from '_common/routes/urls';
import storage, { TOKEN_KEY, USER_ROLES } from 'storage';
import { get, includes } from 'lodash';
import { action, observable } from 'mobx';
import { parseJWT } from '_common/utils/JWT';

export const RESOURCES = {
  // Usage example
  USERS: {
    read: {
      scopes: [],
      roles: [],
    },
    write: {
      scopes: [],
      roles: [],
    },
  },
};

/** URLS which are forbidden for user roles. */
export const FORBIDDEN_URLS = {
  MERCHANT: [links.dashboardRoot, links.requests, links.overview],
  ADMIN: [links.dashboardMerchant],
};

class ACL {

  @observable
  parsedToken: Object = parseJWT(storage.get(TOKEN_KEY));

  @observable
  roles: string[] = [];

  @observable
  scopes: string[] = get(this.parsedToken, 'scope', '').split(' ');

  getStateForDebug = () => ({
    parsedToken: this.parsedToken,
    roles: this.roles,
    scopes: this.scopes,
  });

  @action
  initScopes = () => {
    if (this.scopes.length) {
      return;
    }

    const parsedToken = parseJWT(storage.get(TOKEN_KEY));
    this.scopes = get(parsedToken, 'scope', '').split(' ');
  };

  @action
  initRoles = () => {
    if (this.roles.length) {
      return;
    }

    this.roles = storage.get(USER_ROLES);
  };

  @action
  canAccessResource(resource: { scopes: string[], roles: string[] }): boolean {
    this.initRoles();
    this.initScopes();

    const roleGranted =
      this.roles.length && resource.roles.length
        ? resource.roles.every(role => this.roles.includes(role))
        : false;
    const scopeGranted =
      this.scopes.length && resource.scopes.length
        ? resource.scopes.every(scope => this.scopes.includes(scope))
        : false;

    return roleGranted || scopeGranted;
  }

  @action
  canAccessUrl(url: string, isMerchant: boolean): boolean {
    return !includes(
      isMerchant ? FORBIDDEN_URLS.MERCHANT : FORBIDDEN_URLS.ADMIN,
      url
    );
  }

}

export default ACL;
