// @flow

import type { AssetManagementConfig } from '_common/services/assetManagement';
import commonActions from '_common/actions';

const publishBrandingAssets = async ({
  logo,
  logoImageName,
  bgImage,
  bgImageName,
  accentColor,
  companyName,
}) => {
  const config: AssetManagementConfig = {};

  if (accentColor) {
    config.css = {
      accentColor,
    };
  }

  if (logoImageName) {
    config.logoImageName = logoImageName;
  }

  if (bgImageName) {
    config.bgImageName = bgImageName;
  }

  if (logo) {
    await commonActions.assetsActions.uploadAsset(
      {
        content: logo,
        resourceId: 'logoImage',
        type: 'image',
      },
      companyName
    );
  }

  if (bgImage) {
    await commonActions.assetsActions.uploadAsset(
      {
        content: bgImage,
        resourceId: 'backgroundImage',
        type: 'image',
      },
      companyName
    );
  }

  await commonActions.assetsActions.updateAssetConfig(config, companyName);

  /** explicit  */
  if (companyName) {
    await commonActions.assetsActions.publishAsset(companyName);
  } else {
    await commonActions.assetsActions.publishAsset();
  }
};

export default publishBrandingAssets;
