// @flow

import { action, computed, observable, runInAction } from 'mobx';
import { get } from 'lodash';

import locationService from '_common/services/locationService';
import {
  getAllLocationsMapper,
  OPTION_ALL_LOCATIONS,
  LOCATIONS_MAPPER,
  OPTION_EXTENDED_HOURS,
} from 'locate/utils/locationUtils';
import { requestQueue } from '_common/utils';
import { LOCATION_LABEL_BY_TYPE } from '_common/constants/location';
import companyModelService from '_common/services/companyModelService';
import { AsyncStatus } from '_common/constants/common';
import { AXIOS_CANCELLED } from '_common/constants/apiErrorResponces';
import { WhiteLabelUi, WhiteLabelConstants } from '_common/whitelabelConfig';

export type LatLon = {
  lat: number,
  lon: number,
};

export type LatLng = {
  lat: number,
  lng: number,
};

export type Store = {
  storeId: string,
  storeName: string,
  geo: LatLon,
  place: {
    address: {
      postcode: string, // OL8C 5LN
      town: string,
      line2: string, // 1474 Liujo Way
    },
  },
};

const ADDRESS_NOT_FOUND = 'address not found';
const { REACT_APP_GOOGLE_API_KEY } = process.env;

class LocationStore {

  static CLIENT_GEO_COORDINATES = WhiteLabelUi.common.defaultMapCenter;

  @observable
  clientGeoCoordinates = { ...LocationStore.CLIENT_GEO_COORDINATES };

  @observable
  lastSearch: ?LatLng = null;

  @observable
  possibleSearch: ?LatLng = null;

  @observable
  formFields = {
    locationSearch: null,
  };

  @observable
  stores: Store[] = [];

  @observable
  activeStoreId = null;

  @observable
  locationType: string = OPTION_ALL_LOCATIONS.value;

  @observable
  isMapShouldBeVisible: boolean = false;

  @observable
  showInfoPanel: boolean = false;

  @observable
  companyConfig = {};

  @observable
  asyncState: AsyncStatus = AsyncStatus.IDLE;

  @observable
  isNewLocationSearch: boolean = true;

  @observable
  mapDragActive: boolean = true;

  @action
  resetStore = () => {
    this.clientGeoCoordinates = { ...LocationStore.CLIENT_GEO_COORDINATES };
    this.lastSearch = null;
    this.possibleSearch = null;
    this.formFields = {
      locationSearch: null,
    };
    this.stores = [];
    this.activeStoreId = null;
    this.locationType = OPTION_ALL_LOCATIONS.value;
    this.asyncState = AsyncStatus.IDLE;
    this.isMapShouldBeVisible = false;
  };

  @action
  setFormField = (field: string, value: string) => {
    this.formFields[field] = value;
  };

  getDistanceToLastSearch({ distance, unit }) {
    if (this.lastSearch) {
      return `${distance} ${unit}`;
    }
    return null;
  }

  unifyStoresFormat(stores) {
    if (!stores.length) return [];
    const isNewFormat = !!stores[0].locationInfo && !!stores[0].store;
    if (isNewFormat) {
      return stores.map(({ locationInfo, store }) => ({
        ...store,
        locationInfo,
      }));
    }
    return stores;
  }

  reorderStoresByCPO(stores, cpoAmount = 2) {
    let cpoCount = 0;
    for (let i = 0; i < stores.length; i++) {
      if (cpoCount === cpoAmount) return;
      if (stores[i].locationType === 'POSTOFFICE') {
        stores.splice(cpoCount, 0, stores[i]);
        stores.splice(i + 1, 1);
        cpoCount++;
      }
    }
  }

  @action
  async searchStoresByCoords(
    coordinates: LatLng,
    onDrag: boolean,
    distance: number
  ) {
    /**
     * when searching on map drag - do not reset stores list, only update it
     * and do not change client location
     */
    if (!onDrag) {
      this.stores = [];
      this.clientGeoCoordinates = coordinates;
    }
    this.mapDragActive = onDrag;
    this.asyncState = AsyncStatus.LOADING;
    this.lastSearch = coordinates;

    this.setActiveStoreId(null);

    const locationTypesMapper = getAllLocationsMapper();

    const channelConfig = {
      ...coordinates,
      locationTypes:
        this.locationType === 'STREET_POST_BOXES'
          ? `${locationTypesMapper[this.locationType]},${
              LOCATIONS_MAPPER.EXPRESS_POST_BOXES
            }`
          : locationTypesMapper[this.locationType],
      distance,
      extendedHours: this.locationType === OPTION_EXTENDED_HOURS.value,
    };

    const onError = e => {
      if (e === AXIOS_CANCELLED) {
        return e;
      }
      console.error(e);
      runInAction(() => {
        this.asyncState = AsyncStatus.FAILED;
      });
      return [];
    };
    const { cancelToken } = requestQueue.enqueueNewRequest([
      'locationStore',
      'searchStoresByCoords',
    ]);

    let stores = await locationService
      .getGeoQueryStores(channelConfig, cancelToken)
      .catch(onError);

    if (stores === AXIOS_CANCELLED) return;

    stores = this.unifyStoresFormat(stores);

    if (stores && stores.length) {
      this.reorderStoresByCPO(stores, 2);
    }

    runInAction(() => {
      this.stores = stores;
      this.isMapShouldBeVisible = true;
      this.asyncState = AsyncStatus.SUCCESS;
    });
  }

  isBrowserSupportGeolocation(): boolean {
    return !!navigator.geolocation;
  }

  getUserGeoposition(): Promise<LatLng> {
    return new Promise((resolve, reject) => {
      if (!this.isBrowserSupportGeolocation()) {
        reject('Browser does not support geolocation');
      }

      const onSuccess = position => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        return resolve(coords);
      };

      const onError = error => {
        runInAction(() => {
          /** show info panel */
          this.setInfoPanelVisibility(true);
        });
        return reject(error);
      };

      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    });
  }

  @action
  searchStoresNearMe = async (company: string): Promise<string> => {
    this.asyncState = AsyncStatus.LOADING;

    const onError = e => {
      console.error(e);
      runInAction(() => (this.asyncState = AsyncStatus.FAILED));
      return null;
    };

    try {
      const userGeolocation = await this.getUserGeoposition();
      let result = ADDRESS_NOT_FOUND;

      if (!userGeolocation) return result;

      const res = await locationService
        .getReverseGeocode({
          ...userGeolocation,
          key: REACT_APP_GOOGLE_API_KEY || '',
        })
        .catch(onError);

      if (res && res.results && res.results.length) {
        result = res.results[0].formatted_address;
      }

      await this.searchStoresByCoords({ ...userGeolocation, company }).catch(
        onError
      );

      runInAction(() => (this.asyncState = AsyncStatus.SUCCESS));
      return result;
    } catch (e) {
      runInAction(() => (this.asyncState = AsyncStatus.FAILED));
      return null;
    }
  };

  @action
  searchFromLastLocation = () => {
    if (this.possibleSearch) {
      this.checkCoordsForNewLocation(this.possibleSearch);
      this.lastSearch = this.possibleSearch;
      if (!this.mapDragActive && this.isNewLocationSearch) {
        this.possibleSearch = null;
      }
    } else this.setIsNewLocation(false);

    if (this.lastSearch) {
      this.isMapShouldBeVisible = true;
      this.searchStoresByCoords(this.lastSearch);
    }
  };

  @action
  setSearchGeoCoordinates = (coords: LatLng) => {
    this.isMapShouldBeVisible = true;
    this.possibleSearch = coords;
  };

  @action
  checkCoordsForNewLocation = coords => {
    if (!this.lastSearch) return;
    const newLat = coords.lat;
    const newLng = coords.lng;
    const { lat, lng } = this.lastSearch;
    this.setIsNewLocation(newLat !== lat || newLng !== lng);
  };

  @action
  setIsNewLocation = (val: boolean) => {
    this.isNewLocationSearch = val;
  };

  @action
  setActiveStoreId = (storeId: ?string) => {
    this.activeStoreId = storeId;
  };

  @action
  setLocationType = (locationType: string) => {
    this.locationType = locationType;
  };

  @action
  hideMapForInitialState = () => {
    this.isMapShouldBeVisible = false;
  };

  @action
  setInfoPanelVisibility = (showInfoPanel: boolean = false) => {
    this.showInfoPanel = showInfoPanel;
  };

  @action
  getCompany = async (companyName: string) => {
    try {
      const config = await companyModelService.getCompany(companyName);
      runInAction('LocationStore::getCompany::success', () => {
        this.companyConfig = config;
      });
      return config;
    } catch (e) {
      console.error('LocationStore::getCompany', e);
    }
  };

  @computed
  get locationTypesOptions() {
    const options = [];

    const locationTypes = get(
      this.companyConfig,
      `products.${WhiteLabelConstants.PRODUCT_NAME}.locationTypes`,
      []
    );
    locationTypes.forEach(location => {
      if (location.enabled) {
        options.push({
          value: location.type,
          label: LOCATION_LABEL_BY_TYPE[location.type],
        });
      }
    });
    if (options.length !== 1) {
      options.unshift(OPTION_ALL_LOCATIONS);
    }
    options.push(OPTION_EXTENDED_HOURS);
    return options;
  }

}

export default LocationStore;
