import React from 'react';
import lodashGet from 'lodash/get';
import whitelabelConfig from './whitelabelConfig';

const addGetters = obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key].get = function(path) {
        return lodashGet(this, path);
      };
    }
  }
  obj.get = function(path) {
    return lodashGet(this, path);
  };
  return obj;
};

const customConfig = addGetters(whitelabelConfig);
const {
  ui: WhiteLabelUi,
  components: WhiteLabelComponents,
  stores: WhiteLabelStores,
  services: WhiteLabelServices,
  utils: WhiteLabelUtils,
  constants: WhiteLabelConstants,
} = customConfig;

const withWhitelabelProps = configProps => WrappedComponent => ownProps => {
  const { children, ..._props } = ownProps;
  const populatedProps = {};

  for (const key in configProps) {
    if (configProps.hasOwnProperty(key)) {
      populatedProps[key] = customConfig.get(configProps[key]);
    }
  }

  return (
    <WrappedComponent {..._props} whiteLabeled={populatedProps}>
      {children}
    </WrappedComponent>
  );
};

export {
  WhiteLabelUi,
  WhiteLabelComponents,
  WhiteLabelStores,
  WhiteLabelServices,
  withWhitelabelProps,
  WhiteLabelUtils,
  WhiteLabelConstants,
};

export default customConfig;
