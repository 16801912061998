import styled, { css } from 'styled-components';
import { Input } from '_common/components';

export const StyledInput = styled(Input)`
  @media only screen and (max-width: 1440px) {
    width: ${({ width = 120 }) => `${width}px !important`};
  }

  &.ant-input {
    width: ${({ width = 120 }) => `${width}px`};
    height: ${({ height = 42 }) => `${height}px`};
    display: inline-block;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
  }

  &::placeholder {
    ${({ placeholderFontSize }) =>
      placeholderFontSize &&
      css`
        font-size: ${placeholderFontSize}px !important;
      `};
  }
`;

export const FormWrapper = styled.div`
  width: ${({ width }) => `${width || 320}px`};
  max-width: ${({ width }) => `${width || 320}px`};
  flex: 2;
  & > .ant-row.ant-form-item {
    margin-bottom: 10px;
  }

  & > :not(:first-child) {
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
  }
`;

export const InputWrapper = styled.div`
  & label {
    font-size: 12px;
    font-family: ${props => props.theme.fonts.book};
    white-space: nowrap;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 370px;
`;

export const LeftBorder = styled.div`
  width: 1px;
  height: 32px;
  background-color: #eeeeee;
  margin-right: 10px;
`;
