import createAssetConfig from './createAssetConfig';
import publishAsset from './publishAsset';
import updateAssetConfig from './updateAssetConfig';
import uploadAsset from './uploadAsset';
import invalidateAssets from './invalidateAssets';
import getAssets from './getAssets';

export default {
  createAssetConfig,
  publishAsset,
  updateAssetConfig,
  uploadAsset,
  invalidateAssets,
  getAssets,
};
