// @flow
import { apiClient } from '_common/api/clients/clients';

export type Return = {
  id: string,
  date: string,
  time: string,
  merchant: string,
  status: string,
};

const searchReturns = async (searchKey: string) => {
  const response = await apiClient.get(`v1/tracking/anyRef/${searchKey}`);
  return response.data;
};

export default {
  searchReturns,
};
