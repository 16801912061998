import styled from 'styled-components';
import { StyledButton } from '_common/components/Button/elements';

export const EditButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;

  border-radius: 21px;
  border: solid 1px #fff;
  height: auto;
  padding: 5px 25px;

  background-color: transparent;
  color: #fff;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 55px;
  top: 5px;

  &:hover {
    background-color: transparent;
    border: solid 1px #979797;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
`;
