// @flow

import 'babel-polyfill';
import stores from 'stores';
import App from '_common/routes/App/App';
import { configure } from 'mobx';
import { Provider as MobxProvider } from 'mobx-react';
import { initSentry } from '_common/utils/sentry';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import themes from './themes';
import './themes/globals.css';
import { ErrorBoundary } from '_common/components';
import Amplitude from '_common/utils/amplitude';

configure({ enforceActions: 'observed' });
Amplitude.init();
initSentry();

ReactDOM.render(
  <ErrorBoundary>
    <MobxProvider {...stores}>
      <ThemeProvider theme={themes.default}>
        <App />
      </ThemeProvider>
    </MobxProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
