// @flow

import links from '_common/routes/urls';
import Users from 'users/pages/Users';

export default {
  edit: [
    {
      path: links.editUsers,
      component: Users,
    },
  ],
  regular: [
    {
      path: links.users,
      component: Users,
    },
  ],
};
