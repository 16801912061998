import styled, { css } from 'styled-components';
import { StyledButton } from '_common/components/Button/elements';

export const Panel = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 25px 33px 33px 33px;
`;

export const PanelSubTitle = styled.div`
  font-family: ${props => props.theme.fonts.basic};
  font-size: 20px;
  font-weight: 500;
  color: #131314;
`;

export const EmailInputLabel = styled.label`
  && {
    font-size: 12px;
    font-family: ${props => props.theme.fonts.book};
    color: #282d32;
  }
`;

export const EmailInputWrapper = styled.div`
  display: flex;

  .ant-input {
    border-radius: 2px;
    border: solid 1px rgba(151, 151, 151, 0.25);
  }
`;

export const AddUserButton = styled(StyledButton)`
  width: 160px;
  height: 42px;
  padding: 0;
  border-radius: 21px;
  font-size: 16px;
  margin-left: 24px;
  ${({ isInviteSend }) =>
    isInviteSend &&
    css`
      background-color: #38b13a !important;
    `};

  &:disabled {
    background-color: #d8d8d8;
    opacity: 1;
  }
`;

export const ListWrapper = styled.div``;

export const Loading = styled.div`
  margin-top: 20px;
`;
