// @flow

import { apiClient } from '_common/api/clients/clients';
import { PRODUCT, ROOT_ORG_ID } from '_common/constants/appConfig';
import { getBodyConfig } from '_common/utils';
import { WhiteLabelServices } from '_common/whitelabelConfig';

export type Applicant = {
  emailAddress: string,
  companyName: string,
  phoneNumber?: string,
  collectionWidget: boolean,
  terms: boolean,
  contactName: string,
  locationId: string,
  customersToUse: boolean,
  merchantChargeAccount: string,
};

export interface EventHistory {
  dateTime: Date;
  newStatus: string;
  eventType: string;
  oldStatus: string;
}

export type RequestsApiResponse = {
  product: string,
  emailAddress: string,
  companyName: string,
  lastModified: number,
  createdAt: number,
  eventHistory: EventHistory[],
  organisationId: string,
  status: string,
  applicant: Applicant,
  requestId: string,
};

const getAllRequests = async (): Promise<RequestsApiResponse[]> => {
  const response = await apiClient.get(
    `/v1/prereg/${ROOT_ORG_ID}/${PRODUCT}?statuses=PENDING_APPROVAL&ts=${Date.now()}`
  );

  return response.data;
};

const getRequest = async (requestID: string): Promise<RequestsApiResponse> => {
  const response = await apiClient.get(
    `/v1/prereg/${ROOT_ORG_ID}/${PRODUCT}/${requestID}`
  );

  return response.data;
};

const approveRequest = async (
  requestID: string
): Promise<RequestsApiResponse> => {
  const response = await apiClient.post(
    `/v1/prereg/${ROOT_ORG_ID}/${PRODUCT}/${requestID}/approve`
  );

  return response.data;
};

const merchantSignUpRequest = async body => {
  const response = await apiClient.post(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/create-user`,
    body
  );

  return response.data;
};

const inviteUserRequest = async (body, isAdmin) => {
  const response = await apiClient.post(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/create-user`,
    WhiteLabelServices.getBodyInviteUserRequestConfig(body, isAdmin)
  );

  return response.data;
};

const rejectRequest = async (requestID: string): Promise<any> => {
  const response = await apiClient.post(
    `/v1/prereg/${ROOT_ORG_ID}/${PRODUCT}/${requestID}/ignore`
  );

  return response.data;
};

const updateToOnBoarded = async (id: string): Promise<any> => {
  const response = await apiClient.post(
    `/v1/prereg/${ROOT_ORG_ID}/${PRODUCT}/${id}/onboarded`
  );

  return response.data;
};

const createPreRegRequest = async (body: Applicant) => {
  const resource = await apiClient.post(`/v1/prereg/`, getBodyConfig(body));

  return resource.data;
};

export default {
  getAllRequests,
  getRequest,
  approveRequest,
  rejectRequest,
  updateToOnBoarded,
  createPreRegRequest,
  merchantSignUpRequest,
  inviteUserRequest,
};
