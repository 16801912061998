import styled from 'styled-components';
import { Select as StyledSelect } from 'antd';
import { Icon } from '_common/components';
import { mobileMap } from 'assets';

export const Root = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(151, 151, 151, 0.25);
  height: 50px;
`;

export const TabHeader = styled.div`
  font-family: Arial, sans-serif;
  letter-spacing: -0.32px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 50%;
  color: #231f20;
  margin-right: 0;
  padding: 16px;
  border-right: 1px solid #e5e5e5;
  border-bottom: ${props => (props.active ? '2px solid #231f20' : 'none')};
  cursor: pointer;

  &:last-of-type {
    border-right: none;
  }
`;

export const TabWrapper = styled.div`
  position: relative;
  height: calc(100vh - 460px);
  min-height: 500px;

  @media only screen and (max-width: 768px) {
    height: calc(100vh - 570px);
    min-height: 500px;
  }
`;

export const TabContent = styled.div`
  position: absolute;
  top: 0;
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  width: 100%;
  height: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  font-family: Arial, sans-serif;
  width: 100%;
  height: 60px;
  font-size: 16px;
  color: #231f20;
  padding-right: 95px;
  padding-left: 20px;
  border: solid 1px rgba(151, 151, 151, 0.25);
`;

export const GeoIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 22px;
  top: 18px;
`;

export const Select = styled(StyledSelect)`
  &.ant-select {
    color: #282d32;
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.25);
    border-top: none;
    font-family: Arial, sans-serif;
    font-size: 14px;

    &.ant-select-open {
      .ant-select-arrow {
        &:after {
          transform: rotate(-130deg);
        }
      }
    }

    .ant-select-selection {
      height: 50px;
      border: none;

      .ant-select-selection__rendered {
        line-height: 50px;
        margin-left: 20px;
        margin-right: 20px;
        padding-right: 10px;
      }
    }

    .ant-select-arrow {
      right: 24px;

      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid #222222;
        border-bottom: 2px solid #222222;
        transform: rotate(45deg);
        transition: all 0.4s;
      }

      i,
      svg {
        display: none;
      }
    }
  }
`;

export const StoreListWrapper = styled.div`
  height: calc(100vh - 540px);
  min-height: 500px;
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    min-height: initial;
    height: 100%;
  }
`;

export const InfoHeader = styled.div`
  font-size: 13px;
  display: flex;
  width: 200px;
`;

export const InfoTitle = styled.h4`
  flex-grow: 1;
  margin-right: 10px;
`;

export const InfoDistance = styled.div`
  font-size: 13px;
`;

export const InfoAddress = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const MobileMapBackground = styled.div`
  width: 100%;
  height: 100%;
  background: url(${mobileMap}) center;
  background-size: cover;
`;

export const ClearButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: rgb(199, 199, 204);
  text-decoration: underline;
  position: absolute;
  right: 55px;
  top: 16px;
`;
