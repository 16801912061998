// @flow

import {
  MerchantSearchWrapper,
  IconWrapper,
} from 'merchants/pages/elements/merchantSearch';
import * as React from 'react';
import { Icon } from 'antd';
import { Select } from '_common/components';

type Props = {};

export default (props: Props) => (
  <MerchantSearchWrapper>
    <IconWrapper>
      <Icon type="search" />
    </IconWrapper>
    <Select {...props} />
  </MerchantSearchWrapper>
);
