// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { Option } from '_common/utils/orderDetailsUtils';
import { SwitchGroup, TextSection } from '_common/components';
import {
  PanelIcon,
  PanelSection,
  Row,
} from '_common/components/CommonStyledElements';
import {
  reasonCodeDisableIcon,
  reasonCodeEditIcon,
  reasonCodeEnableIcon,
  reasonsIcon,
} from 'assets';
import SwitchInput from '../SwitchInput';
import {
  Delimeter,
  ReasonCodeActionIconWrapper,
} from 'settings/paymentOptionsAndReasonCodes/components/ReasonCodes/ReasonCodesElements';
import { WhiteLabelConstants, WhiteLabelUi } from '_common/whitelabelConfig';
import { CompanyModelStore } from 'stores';
import { extractFieldValue } from '_common/utils/companyUtils';
import commonActions from '_common/actions';
import { computed } from 'mobx';

const { PRODUCT_NAME } = WhiteLabelConstants;

type Props = {
  companyModelStore: CompanyModelStore,
  onReasonsCodesChange: () => void,
};

type State = {
  editedIndex: ?number,
};

type ReasonCodeActionProps = {
  action: () => void,
  iconSrc: string,
  altText: string,
};

const ReasonCodeAction = ({
  action,
  iconSrc,
  altText,
}: ReasonCodeActionProps) => {
  return (
    <ReasonCodeActionIconWrapper>
      <img onClick={action} src={iconSrc} alt={altText} />
    </ReasonCodeActionIconWrapper>
  );
};

@inject('companyModelStore')
@observer
class ReasonCodes extends Component<Props, State> {

  @computed
  get options() {
    return (
      extractFieldValue({
        companyModelStore: this.props.companyModelStore,
        path: `products.${PRODUCT_NAME}.returnReasons.reasons`,
      }) || []
    );
  }

  state = {
    editedIndex: null,
  };

  getChangedReasonCodesModel = (option: Option, index: number) => {
    const reasons = [...this.options];
    reasons[index] = { ...this.options[index], ...this.mapFromOption(option) };
    return {
      changesObject: {
        products: {
          [PRODUCT_NAME]: {
            returnReasons: {
              reasons,
            },
          },
        },
      },
    };
  };

  handleReasonsChange = (option: Option, index: number) => {
    const reasons = [...this.options];
    reasons[index] = { ...this.options[index], ...this.mapFromOption(option) };
    commonActions.companyActions.changeReasonCodes(reasons);
    this.props.onReasonsCodesChange();
  };

  handleEdit = (editedIndex: number) => () => {
    this.setState({ editedIndex });
  };

  handleDisableTrigger = (option: Option, index: number) => {
    return () => {
      const newOption = { ...option, disabled: !option.disabled };
      this.props.onReasonsCodesChange();
      const reasons = [...this.options];
      reasons[index] = {
        ...this.options[index],
        ...this.mapFromOption(newOption),
      };
      commonActions.companyActions.changeReasonCodes(reasons);
    };
  };

  renderButtons = (option: Option, index: number) => {
    const isDisabled = option.disabled;
    return (
      <>
        <ReasonCodeAction
          action={this.handleEdit(index)}
          iconSrc={reasonCodeEditIcon}
          altText="edit action icon"
        />
        <ReasonCodeAction
          action={this.handleDisableTrigger(option, index)}
          iconSrc={isDisabled ? reasonCodeEnableIcon : reasonCodeDisableIcon}
          altText={`${isDisabled ? 'enable' : 'disable'} action icon"`}
        />
      </>
    );
  };

  handleDiscard = () => {
    this.setState({ editedIndex: null });
  };

  handleSave = (newOption: Option, index: number) => {
    commonActions.companyActions.changeCompanyModel(
      this.getChangedReasonCodesModel(newOption, index)
    );
    this.props.onReasonsCodesChange();
    this.handleDiscard();
  };

  renderLabelComponent = (option: Option, index: number) => {
    if (index === this.state.editedIndex) {
      const isDisabled = option.disabled;
      const reasonCodesToggleDisabled =
        WhiteLabelUi.pages.paymentOptionsAndReasonCodes
          .reasonCodesToggleDisabled;
      const maxInputLength =
        WhiteLabelUi.pages.paymentOptionsAndReasonCodes.maxInputLength;

      return (
        <>
          <SwitchInput
            option={option}
            index={index}
            onDiscard={this.handleDiscard}
            onSave={this.handleSave}
            maxInputLength={maxInputLength}
          />
          <ReasonCodeAction
            action={this.handleDisableTrigger(option, index)}
            iconSrc={isDisabled ? reasonCodeEnableIcon : reasonCodeDisableIcon}
            altText={`${isDisabled ? 'enable' : 'disable'} action icon"`}
          />
          {!reasonCodesToggleDisabled && <Delimeter />}
        </>
      );
    }
    return null;
  };

  mapToOption = options =>
    options.map(reason => ({
      ...reason,
      disabled: !reason.enabled,
      checked: reason.paymentRequired,
      label: reason.description,
    }));

  mapFromOption = option => ({
    description: option.label,
    enabled: !option.disabled,
    paymentRequired: option.checked,
  });

  renderTitleText = () => (
    <TextSection title="Returns Reason codes" reasonCodeTitle>
      {WhiteLabelUi.pages.paymentOptionsAndReasonCodes.renderTitle(
        reasonCodeEditIcon,
        reasonCodeDisableIcon
      )}
    </TextSection>
  );

  render() {
    const reasonCodesToggleDisabled =
      WhiteLabelUi.pages.paymentOptionsAndReasonCodes.reasonCodesToggleDisabled;
    return (
      <PanelSection>
        <Row flex={1}>
          <PanelIcon image={reasonsIcon} width={64} height={70} />
          {this.renderTitleText()}
        </Row>
        <SwitchGroup
          options={this.mapToOption(this.options)}
          onChange={this.handleReasonsChange}
          renderMenuComponent={this.renderButtons}
          renderLabelComponent={this.renderLabelComponent}
          noToggle={reasonCodesToggleDisabled}
        />
      </PanelSection>
    );
  }

}

export default ReasonCodes;
