// @flow
import { CancelToken, type CancelTokenSource } from 'axios';
import { includes, isString, mapValues, isNumber, some } from 'lodash';
import { ValidationRule } from 'antd';

import {
  DEFAULT_ERROR_MESSAGE,
  IdempotentErrors,
} from '_common/constants/apiErrorResponces';
import {
  PRODUCT,
  ROOT_ORG_ID,
  ALLOWED_ROLES_CONFIG,
  ERROR_LOG_LEVEL,
  IS_DEVELOPMENT,
} from '_common/constants/appConfig';
import type { Applicant } from 'merchants/services/merchantsService';
import { VALIDATION_FIELDS_TYPE } from '_common/constants/validation';
import {
  AsyncStatus,
  ERROR_LOG_LEVELS,
  ROLE_TYPES,
} from '_common/constants/common';
import { requiredFieldRule } from '_common/utils/formUtils';
import links from '_common/routes/urls';
import React from 'react';

export type LoginConfig = {
  isSignUp: boolean,
  config: {
    title: string,
    submit: string,
    inputs: Array<{
      id: string,
      label: string,
      placeholder: string,
      type: string,
      rules?: ValidationRule[],
      validateFirst?: boolean,
      tooltip?: string,
    }>,
  },
  merchantOrgName?: string,
};

export type TAllowedRoles = {
  adminRoles: string[],
  merchantRoles: string[],
};

/**
 * Password must be 8-20 characters, with 1 capital and 1 number
 * @param password
 */
export const validatePassword = (password: string): boolean => {
  const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);

  return regex.test(password);
};

export const validatePhoneNumber = (
  _: any,
  phoneNumber: string,
  callback: (msg?: string) => void
) => {
  /** Required validation check already was runned before */
  if (!phoneNumber) {
    return callback();
  }
  const error = new Error('Please enter a valid phone number');

  // contains invalid chars (0-9, whitespaces, '(', ')', '-', '+' are valid)
  if (/[^\d\-\s()+]/.test(phoneNumber) || phoneNumber.includes('--')) {
    return callback(error);
  }
  const formattedValue = phoneNumber.replace(/[-\s]/g, '');
  const digitsCount = (formattedValue.match(/\d/g) || []).length;
  const validLength = digitsCount >= 4 && digitsCount <= 15;

  // +XXXXXX or +XX(XXX)XXX
  if (
    validLength &&
    (/^\+?\d+$/.test(formattedValue) ||
      /^\+?\d*\(\d+\)\d+$/.test(formattedValue))
  ) {
    return callback();
  }
  callback(error);
};
/**
 * TODO: Replace this validation and all uses with validateByPreconditions,
 * and ensure we're actually validating against special characters
 * @param _
 * @param value
 * @param callback
 * @param type
 */
export const validateOnSpecialCharacters = (
  _: any,
  value: string,
  callback: (msg?: string) => void,
  type: string
) => {
  let error = new Error('Characters "<>[]}{}!#$%^*" aren\'t allowed');
  let regexp = /([}{[\]<>!#$%^*])/;

  if (type === VALIDATION_FIELDS_TYPE.companyName) {
    error = new Error('Characters "<>[]}{}!#$%^*@&" aren\'t allowed');
    regexp = /([}{[\]<>!#$%^*@&])/;
  }

  if (regexp.test(value)) {
    return callback(error);
  }
  return callback();
};

/**
 * This util should be used for multiple validators checks. Allows passing in a
 * message to be used if the regex fails
 *
 * @param allowedLengths Allowed length of field
 * @param regex Regex to be used in the check
 * @param message Optional message to be passed in
 * @returns {Function}
 */
export const validateByPreconditions = ({ allowedLengths, regex, message }) => (
  _: any,
  value: string,
  callback: (msg?: string) => void
) => {
  if (!value) {
    return callback();
  }

  if (allowedLengths && !includes(allowedLengths, (value || '').length)) {
    return callback('Invalid length.');
  }

  if (regex && !regex.test(value)) {
    // Set default message if its not set
    message = message ? message : (message = 'Invalid regex check.');

    return callback(message);
  }

  return callback();
};

export function trimStrings(obj: Object) {
  return mapValues(obj, value => (isString(value) ? value.trim() : value));
}

export const getBodyConfig = (applicant: Applicant) => ({
  organisationId: ROOT_ORG_ID,
  product: PRODUCT,
  applicant: trimStrings(applicant),
});

/**
 * Extract first error from response or send default message.
 * @param errorObject
 */
export const extractError = (errorObject: any) => {
  /** For prod env we need to show 'generic' message */
  if (ERROR_LOG_LEVEL === ERROR_LOG_LEVELS.PROD) {
    return DEFAULT_ERROR_MESSAGE;
  }

  if (typeof errorObject === 'string') {
    return errorObject || DEFAULT_ERROR_MESSAGE;
  }

  if (
    errorObject &&
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.errors &&
    errorObject.response.data.errors[0] &&
    errorObject.response.data.errors[0].message
  ) {
    return errorObject.response.data.errors[0].message;
  }
  return DEFAULT_ERROR_MESSAGE;
};

type Error = {
  code: string,
  message: string,
};

/**
 * Checks that's catched error can be ignored.
 * @param errors
 */
export const isIdempotentErrors = (errors: Error[]) => {
  return errors.every(({ message }) =>
    IdempotentErrors.some(err => includes(message, err))
  );
};

/**
 * Check that's all fields in object are 'falsy'.
 * @param target
 * @returns {boolean}
 */
export const checkAllFieldsEmpty = (target: Object) => {
  return Object.values(target).every(val => !val);
};

/**
 * Converts string to websafe format.
 * @param str
 * @returns {string}
 */
export const toWebSafeFormat = (str: string): string => {
  return str
    .toUpperCase()
    .replace(/[^A-Z0-9_\s]/g, '')
    .trim()
    .replace(/\s+/g, '_');
};

/**
 * Wrapper around promise to use in Promise.all etc.
 * @param p
 */
export const reflectPromise = p =>
  p.then(
    v => ({ data: v, asyncStatus: AsyncStatus.SUCCESS }),
    e => ({ error: e, asyncStatus: AsyncStatus.FAILED })
  );

/**
 * Build a template config for aus-post-publish-notification email.
 * @param retailerName - company human readable name;
 * @param email - email address;
 * @param loggedMerchantEmail - email address of currently logged user (self);
 * @param time - current time of change;
 * @param tz - current timezone;
 * @param changes - list of changes;
 */
export const getEmailChangesEmail = (
  retailerName,
  email,
  loggedMerchantEmail,
  time,
  tz,
  changes
) => ({
  requestData: {
    recipient: {
      name: retailerName,
      email: email,
    },
    substitutionData: {
      retailer: {
        retailerName: retailerName,
        email: loggedMerchantEmail,
        time,
        tz,
        changes,
      },
    },
  },
  templateId: 'merchant_publish',
});

/**
 * Check assets url for valid state.
 * @param originalUrl
 * @returns {string|*}
 */
export const getAssetsUrlFromBucket = originalUrl => {
  if (/^asset-management.+/.test(originalUrl)) {
    // eslint-disable-next-line no-unused-vars
    const [_, portal, org, __, path] = originalUrl.split('/');
    return `${portal}/${org}/assets/${path}`;
  }
  return originalUrl;
};

/**
 * Transform model to AP validation API.
 * @param values
 * @returns {{postcode: *, suburb: *, state: *}}
 */
export const mapFrontAddressToBack = values => ({
  suburb: values.city,
  state: values.state,
  postcode: values.postcode,
});

export const formatEmailAddress = (emailAddress: string) => {
  return emailAddress.trim().replace(/\s+/g, '');
};

/**
 * Get distance between two geo-coordinates in METERS.
 * @param pointOne
 * @param pointTwo
 */
export const getHaversineDistance = (
  pointOne: number[],
  pointTwo: number[]
) => {
  const degreesToRadians = degrees => (degrees * Math.PI) / 180;
  const earthRadiusKm = 6371;
  const [lat1, lon1] = pointOne;
  const [lat2, lon2] = pointTwo;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  const lat1rad = degreesToRadians(lat1);
  const lat2rad = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1rad) *
      Math.cos(lat2rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
};

export const formatCompanyName = (name: string) => {
  return name.trim();
};

/**
 * Custom validator for Antd Select. Native didnt works well with numbers.
 * @param _
 * @param selectValue
 * @param callback
 */
export const validateNumberSelect = (
  _: any,
  selectValue: string,
  callback: (msg?: string) => void
) => {
  return !isNumber(selectValue)
    ? callback(requiredFieldRule.message)
    : callback();
};

/**
 * Just fallback if prop is missed in config.
 * @param value
 * @returns {string|string}
 */
export const getValueWithDefault = (value: string) => value || 'Not provided';

export const sleep = (t = Math.random() * 200 + 300) =>
  new Promise(resolve => setTimeout(resolve, t));

class RequestCancelQueue {

  controllersMap: {
    [key: string]: CancelTokenSource,
  } = {};

  enqueueNewRequest(methodPath: string[]) {
    const key = methodPath.join('-');
    if (this.controllersMap[key]) {
      this.controllersMap[key].cancel();
    }
    this.controllersMap[key] = CancelToken.source();
    const cancelToken = this.controllersMap[key].token;
    return { cancelToken, controller: this.controllersMap[key] };
  }

}

export const requestQueue = new RequestCancelQueue();

/**
 * Parse SSM param to list of admin/merchant allowed roles.
 */
export const getAllowedRolesConfig = (
  adminRolesString,
  merchantRolesString
): TAllowedRoles => {
  if (!adminRolesString || !merchantRolesString) {
    console.error(
      'Invalid available roles configuration. adminRolesString: %s, merchantRolesString: %s',
      adminRolesString,
      merchantRolesString
    );
  }
  const adminRoles = adminRolesString.split(',').filter(Boolean);
  const merchantRoles = merchantRolesString.split(',').filter(Boolean);
  return { adminRoles, merchantRoles };
};

/**
 * Returns user role type based on SSM stored roles and users.
 * @param roles
 * @returns {string}
 */
export const getUserRoleType = (roles: string[]) => {
  const isAdmin = some(ALLOWED_ROLES_CONFIG.adminRoles, adminRole =>
    includes(roles, adminRole)
  );

  const isMerchant = some(ALLOWED_ROLES_CONFIG.merchantRoles, merchantRole =>
    includes(roles, merchantRole)
  );

  if (!isAdmin && !isMerchant) {
    console.error("User hasn't any expected role.");
    return ROLE_TYPES.UNKNOWN;
  }

  if (isAdmin && isMerchant) {
    console.error('User has both roles.');
    return ROLE_TYPES.UNKNOWN;
  }

  if (isAdmin) {
    return ROLE_TYPES.ADMIN;
  }

  if (isMerchant) {
    return ROLE_TYPES.MERCHANT;
  }

  console.error('User has invalid roles config.');
  return ROLE_TYPES.UNKNOWN;
};

export const validateRegex = (
  _: any,
  validationRegex: string,
  callback: (msg?: string) => void
) => {
  try {
    new RegExp(validationRegex);
    return callback();
  } catch (e) {
    return callback('Invalid regex syntax');
  }
};

export const validateFieldName = (
  _: any,
  value: string,
  callback: (msg?: string) => void
) => {
  if (!value) {
    return callback();
  }
  if (value.length > 30) {
    return callback('Max length 30 characters');
  }
  // eslint-disable-next-line
  if (!/^[a-z~!@#$%^&*_+=(){}[\]:;"'<>,?.\s-]+$/i.test(value)) {
    return callback('Letters and special characters allowed');
  }
  return callback();
};

class PaginatedMarksQueue {

  marksMap: {
    [key: string]: string,
  } = {};

  enqueueNewMark(requestUrlPath: string, mark: string = '') {
    this.marksMap[requestUrlPath] = mark;
  }

  getPaginatedQueryForUrl(requestUrlPath) {
    const mark = this.marksMap[requestUrlPath];
    return mark && mark.length ? `&mark=${mark}` : '';
  }

}

export const paginatedMarksQueue = new PaginatedMarksQueue();

export const checkHttpStatus = (
  errorObject: any,
  predicate: number | RegExp
) => {
  if (!predicate) return false;
  if (
    errorObject &&
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.httpStatus
  ) {
    if (typeof predicate === 'number') {
      return errorObject.response.data.httpStatus === predicate;
    }
    if (predicate instanceof RegExp) {
      return predicate.test(errorObject.response.data.httpStatus);
    }
  }
  return false;
};

export const getMerchantFromUrl = () => {
  /** Need to check for merchant name in URL, to make redirect for that route if needed. */
  const urlPath = window.location.pathname.split('/').filter(p => p);

  /** This is could be Merchant name or regular route. */
  const merchantOrLoginPart = urlPath[0];

  /** need to check that stored data in sync with incoming from URL */
  const existingRoutes = Object.values(links)
    .map(path => path.replace('/:company', '').split('/')[1])
    .filter(p => p);

  /** Means route is a part of existing routes. */
  if (merchantOrLoginPart && !includes(existingRoutes, merchantOrLoginPart)) {
    return merchantOrLoginPart;
  }
};

/**
 * Decorator for class methods. It runs method only in production.
 * Can be provided with fallback method name (from a parent class only)
 */
export function onlyProd(devFallbackMethodName: string) {
  return function(target, key, descriptor) {
    const original = descriptor.value;
    descriptor.value = function(...args) {
      if (IS_DEVELOPMENT) {
        const fallback = target[devFallbackMethodName];
        typeof fallback === 'function' && fallback(args);
      } else return original.apply(this, [...args]);
    };
    return descriptor;
  };
}

export function renderReactList(list) {
  return Array.isArray(list)
    ? list.map(name => {
        if (!this[name]) {
          console.error(
            'React list rendering error. Invalid method name provided!'
          );
          return null;
        } else return <React.Fragment key={name}>{this[name]}</React.Fragment>;
      })
    : null;
}

export const webProtocolRegex = new RegExp('^https?');

/** taken from https://gist.github.com/dperini/729294, which is used be some vaidation libraries */
export const webUrlRegex = new RegExp(
  '^' +
    // protocol identifier (optional)
    // short syntax // still required
    '(?:(?:(?:https?):)?\\/\\/)' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broadcast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    '(?:' +
    '(?:' +
    '[a-z0-9\\u00a1-\\uffff]' +
    '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
    ')?' +
    '[a-z0-9\\u00a1-\\uffff]\\.' +
    ')+' +
    // TLD identifier name, may end with dot
    '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
    ')' +
    // port number (optional)
    '(?::\\d{2,5})?' +
    // resource path (optional)
    '(?:[/?#]\\S*)?' +
    '$',
  'i'
);
/**
 * Parses env variable with JSON of email addresses, diff for each env.
 * Dont wrap in try-catch, follow FF concept.
 * @param rawJsonWithEmails
 */
export const parseEmailAddresses = (rawJsonWithEmails: string) => {
  const parsedObject = {
    ...{
      SIGNUP_COLLECTION_WIDGET_EMAIL: null,
      SIGNUP_CUSTOMERS_EMAIL: null,
    },
    ...JSON.parse(rawJsonWithEmails),
  };

  /** That check required for AP, for Doddle WL just N/A as addresses. */
  if (
    !parsedObject.SIGNUP_COLLECTION_WIDGET_EMAIL ||
    !parsedObject.SIGNUP_CUSTOMERS_EMAIL
  ) {
    throw new Error('Invalid emails config provided.', rawJsonWithEmails);
  }
  return parsedObject;
};
