// @flow

import searchStoresByCoords from './searchStoresByCoords';
import searchStoresNearMe from './searchStoresNearMe';
import setActiveStoreId from './setActiveStoreId';
import setLocationType from './setLocationType';
import searchFromLastLocation from './searchFromLastLocation';
import setSearchGeoCoordinates from './setSearchGeoCoordinates';

export default {
  searchStoresByCoords,
  searchStoresNearMe,
  setActiveStoreId,
  setLocationType,
  searchFromLastLocation,
  setSearchGeoCoordinates,
};
