import styled from 'styled-components';

export const Footer = styled.div`
  height: 70px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #ffffff;

  @media only screen and (max-width: 768px) {
    height: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0 18px;
  }
`;

export const Link = styled.a`
  margin-left: 42px;
  padding: 3px 5px;
  box-sizing: border-box;
  border: 1px solid transparent;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }

  &:focus {
    border: 1px dotted;
    border-radius: 3px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 10px;
    font-family: Arial, sans-serif;
    margin-left: 0;
  }
`;

export const LinkSection = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
`;

export const SocialLinkSection = styled.div`
  @media only screen and (min-width: 769px) {
    margin-right: 35px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: right;
    padding: 0 30px;
  }
`;

export const SocialLink = styled.a`
  &:not(:last-child) {
    margin-right: 20px;
  }
  position: relative;

  & img {
    width: 35px;
    outline: none;
    filter: brightness(0.95);
  }

  &:hover {
    & img {
      filter: brightness(1);
    }
  }

  &:focus {
    &:after {
      border: 1px dotted #fff;
      content: '';
      display: block;
      height: 41px;
      left: -3px;
      pointer-events: none;
      position: absolute;
      top: -10px;
      width: 41px;
      border-radius: 50%;
    }
    & img {
      filter: brightness(1);
    }
  }
`;
