import styled from 'styled-components';

export const IframeWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  margin-top: 22px;

  & iframe {
    border: none;
  }
`;

export const DrillOutLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
