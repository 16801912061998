// @flow

import stores from 'stores';
import commonActions from '_common/actions';
import links from '_common/routes/urls';
import React from 'react';
import { Redirect, RouteProps } from 'react-router';

const RootRedirect = (props: RouteProps) => {
  // Do not remove props param
  const getLink = (): string => {
    if (stores.authStore.isTokenValid()) {
      const { isMerchant, user } = commonActions.getIsMerchantUser();
      if (isMerchant && user && user.organisationId) {
        return `/${user.organisationId}${links.dashboardRoot}`;
      } else if (!isMerchant) {
        return links.dashboardRoot;
      }
    }
    console.error('RootRedirect:token not valid');
    return links.rootLogin;
  };

  return <Redirect to={getLink()} />;
};

export default RootRedirect;
