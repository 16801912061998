import styled from 'styled-components';
import { Input } from 'antd';

export const StyledInput = styled(Input.Search)`
  height: 42px;
  border-radius: 2px;
  & .ant-input::placeholder {
    color: #2b3035;
    opacity: 0.5;
    font-family: ${props => props.theme.fonts.light};
  }

  & .ant-input {
    color: #222222;
    font-family: ${props => props.theme.fonts.book};
  }

  & .anticon-search {
    font-size: 20px;
  }
`;
