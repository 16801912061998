// @flow

import commonActions from '_common/actions';
import { CircleLoader, Menu, Pagination, Select } from '_common/components';
import type { TMerchant } from 'merchants/models';
import MerchantCard from 'merchants/pages/components/MerchantCard';
import MerchantSearch from 'merchants/pages/components/MerchantSearch';
import {
  ListWrapper,
  Row,
  EmptyResultsMessage,
  ClearFiltersButton,
  SearchSelectWrapper,
} from 'merchants/pages/elements/overviewElements';
import { Content, Wrapper } from 'merchants/pages/elements/requestsElements';
import MerchantsStore from 'merchants/stores/merchantsStore';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { ROOT_ORG_ID } from '_common/constants/appConfig';
import { RouteComponentProps } from 'react-router-dom';
import UIStore from '_common/stores/uiStore';
import { WhiteLabelUi } from '_common/whitelabelConfig';
const { searchFields } = WhiteLabelUi.pages.overview;

type Props = RouteComponentProps & {
  merchantStore: MerchantsStore,
  uiStore: UIStore,
};

@inject('merchantStore', 'uiStore')
@observer
class Overview extends Component<Props> {
  componentDidMount() {
    /** Exit editing mode */
    this.props.uiStore.setEditingCompanyByAdmin();
    commonActions.getSubCompanies(ROOT_ORG_ID);
  }

  componentWillUnmount() {
    this.props.merchantStore.clearFilters();
  }

  handleSelectSearchOption = (predicate: string, { props: { name } }) => {
    return this.props.merchantStore.setFiltersValue(predicate, name);
  };

  handleSearchChange = (value: string) => {
    this.props.merchantStore.setPaginationPage(1);
    this.props.merchantStore.setFiltersValue(value);
  };

  handleClearFilters = () => {
    this.props.merchantStore.clearFilters();
  };

  handleSearchPredicateChange = (predicate: string) => {
    this.props.merchantStore.setSearchPredicate(predicate);
  };

  renderToolbar = () => {
    const {
      merchantStore: {
        merchantsAsOptions,
        filters: { searchValue, predicate },
      },
    } = this.props;

    return (
      <Row>
        <MerchantSearch
          onChange={this.handleSelectSearchOption}
          onSearch={this.handleSearchChange}
          defaultActiveFirstOption={false}
          options={merchantsAsOptions}
          showArrow={false}
          filterOption={false}
          onBlur={null}
          showSearch
          placeholder="Search by merchant"
          notFoundContent={null}
          value={searchValue || undefined}
        />
        <SearchSelectWrapper>
          <Select
            value={predicate}
            options={searchFields}
            onChange={this.handleSearchPredicateChange}
            width={205}
          />
        </SearchSelectWrapper>

        <ClearFiltersButton onClick={this.handleClearFilters}>
          Clear
        </ClearFiltersButton>
      </Row>
    );
  };

  handleEnterEditMode = (companyId: string, companyName: string) => {
    const {
      uiStore: { setEditingCompanyByAdmin },
      history: { push },
    } = this.props;
    setEditingCompanyByAdmin(companyId, companyName);
    push(`/dashboard`);
  };

  renderList = () => {
    const {
      merchantStore: {
        filteredMerchants,
        isLoading,
        setPaginationPage,
        currentPaginationPage,
        getEntitiesByPagination,
        ITEMS_PER_PAGE,
      },
    } = this.props;

    const merchants = getEntitiesByPagination<TMerchant>(filteredMerchants);

    return (
      <ListWrapper>
        {isLoading ? (
          <CircleLoader />
        ) : merchants.length ? (
          merchants.map((merchant: TMerchant, index) => (
            <MerchantCard
              key={merchant.companyId + index}
              merchant={merchant}
              onEnterEditMode={this.handleEnterEditMode}
            />
          ))
        ) : (
          <EmptyResultsMessage>Merchant was not found</EmptyResultsMessage>
        )}

        <Pagination
          perPage={ITEMS_PER_PAGE}
          onPageChange={setPaginationPage}
          total={filteredMerchants.length}
          currentPage={currentPaginationPage}
        />
      </ListWrapper>
    );
  };

  render() {
    const { applicationNotification } = this.props.uiStore;
    return (
      <Wrapper>
        <Menu />
        <Content notificationIsVisible={Boolean(applicationNotification)}>
          {this.renderToolbar()}
          {this.renderList()}
        </Content>
      </Wrapper>
    );
  }
}

export default Overview;
