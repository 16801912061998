// @flow

import ACLStore from '_common/stores/aclStore';
import AuthStore from '_common/stores/authStore';
import CompanyModelStore from '_common/stores/companyModelStore';
import AssetManagementStore from '_common/stores/assetManagementStore';
import MerchantStore from 'merchants/stores/merchantsStore';
import UIStore from '_common/stores/uiStore';
import TrackingStore from 'tracking/stores/trackingStore';
import StatisticsStore from 'statistics/stores/statisticsStore';
import LocationStore from 'locate/stores/locationStore';
import UsersStore from 'users/stores/usersStore';
import PublishStore from './_common/stores/publishStore';

export {
  AuthStore,
  MerchantStore,
  ACLStore,
  UIStore,
  UsersStore,
  StatisticsStore,
  LocationStore,
  CompanyModelStore,
  AssetManagementStore,
  PublishStore,
};

const authStore = new AuthStore();
const merchantStore = new MerchantStore();
const aclStore = new ACLStore();
const uiStore = new UIStore();
const trackingStore = new TrackingStore();
const usersStore = new UsersStore();
const statisticsStore = new StatisticsStore();
const locationStore = new LocationStore();
const companyModelStore = new CompanyModelStore();
const assetManagementStore = new AssetManagementStore();
const publishStore = new PublishStore();

export default {
  authStore,
  merchantStore,
  aclStore,
  uiStore,
  trackingStore,
  usersStore,
  statisticsStore,
  locationStore,
  companyModelStore,
  assetManagementStore,
  publishStore,
};
