// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';

import LocationStore from 'locate/stores/locationStore';
import type { Store } from 'locate/stores/locationStore';
import StoreItem from '../StoreItem/StoreItem';
import { LocationsWrapper, LocationsItems } from './StoreListElements';

type Props = {
  locationStore: LocationStore,
  onStoreClick?: (store: Store) => void,
};
type State = {};

@observer
class StoreList extends React.Component<Props, State> {

  renderLocationItem = (store: Store) => {
    const isActive = this.props.locationStore.activeStoreId === store.storeId;
    return (
      <StoreItem
        key={store.storeId}
        isActive={isActive}
        store={store}
        onClick={this.props.onStoreClick}
      />
    );
  };

  render() {
    const {
      locationStore: { stores },
    } = this.props;

    return (
      <LocationsWrapper>
        <LocationsItems>{stores.map(this.renderLocationItem)}</LocationsItems>
      </LocationsWrapper>
    );
  }

}

export default inject('locationStore')(StoreList);
