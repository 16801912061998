/* eslint camelcase: 0 */

import amplitude from 'amplitude-js';
import { IS_DEVELOPMENT } from '_common/constants/appConfig';
import commonActions from '_common/actions';
import { has } from 'lodash';

class Amplitude {

  /** It's a set for changes which are not under control of basic companies flow. */
  unpublishedChanges = new Set();

  _amplitude;

  /**
   * Iterates over individual config for each page and check if property of inputs exists.
   * If it is - means it was changes and we need to collect that section.
   */
  getChangedFieldNames = (model, config) => {
    config.forEach(({ fields, amplitudeName }) => {
      fields.forEach(path => {
        if (has(model, path)) {
          this.unpublishedChanges.add(amplitudeName);
        }
      });
    });

    return [...this.unpublishedChanges].join(',');
  };

  _logEvent = (eventName, eventPayload) => {
    const payload = {
      ...eventPayload,
    };

    if (IS_DEVELOPMENT) {
      // eslint-disable-next-line no-console
      console.log('%c [Event: %s]', 'color: #bada55', eventName, payload);
    } else {
      this._amplitude.logEvent(eventName, payload);
    }
  };

  logEvent = (...args) => {
    const { isMerchant } = commonActions.getIsMerchantUser();
    if (!isMerchant) {
      return;
    }

    this._logEvent(...args);
  };

  logWithoutCheck = (...args) => {
    this._logEvent(...args);
  };

  logFieldsChanges = ({ pageName, model, config }) => {
    const changedFieldNames = this.getChangedFieldNames(model, config);
    if (!changedFieldNames) {
      return;
    }

    this.logEvent('field_change', {
      page_name: pageName,
      field_name: changedFieldNames,
    });
    this.unpublishedChanges.clear();
  };

  changeProp = (method, propKey, propValue) => {
    if (!window || IS_DEVELOPMENT) {
      return;
    }

    this._amplitude.identify(
      new this._amplitude.Identify()[method](propKey, propValue)
    );
  };

  setRetailerName = (company: string) => {
    if (!window || IS_DEVELOPMENT) {
      return;
    }

    if (!this._amplitude) {
      this.init();
    }

    this.changeProp('set', 'retailer_name', company);
  };

  init = () => {
    if (!window || IS_DEVELOPMENT) {
      return;
    }

    this._amplitude = amplitude.getInstance();
    this._amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
      includeReferrer: true,
    });

    this.changeProp('set', 'Domain', document.domain);
  };

}

export default new Amplitude();
