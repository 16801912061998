import styled, { css } from 'styled-components';

export const StyledLabel = styled.label`
  font-size: ${({ fontSize = '14' }) => fontSize + 'px'} !important;
  display: inline-block;
  font-family: ${({ theme, fontType = 'medium' }) =>
    theme.fonts[fontType]} !important;
  color: #282d32;

  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: 14px;
    `};
`;
