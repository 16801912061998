// @flow

export const API_ERROR_CODES = {
  InvalidUserCredentials: 'InvalidUserCredentials',
  InvalidApToken: 'invalid AP token',
};

/**
 * Array with error messages which can be ignored.
 */
export const IdempotentErrors = ['already existed', 'already exists'];

export const ManualErrors = {
  PERMISSIONS: 'User has no permissions to access this resource',
  EMAIL_USED: 'This email address has already been used',
  ADDRESS_NOT_VALID: 'Address not valid',
  LOGIN_NOT_VALID:
    'Unable to log in, please check your email and password are correct or contact customer support',
  COMPANY_IS_DISABLED:
    'This company has been disabled, please contact customer services for support.',
};
export const AXIOS_CANCELLED = 'AXIOS_CANCEL';

export const HTTP_STATUS_4XX_REGEX = /^4\d{2}$/;

export const DEFAULT_ERROR_MESSAGE = 'Error while processing...';
