import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  background-color: #ffffff;
  padding: 10px 40px;
  display: flex;
  height: 100px;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #e6e7e8;
    padding: 0;
    height: 70px;
    margin: 0 0 20px;
    display: none;
  }
`;

export const Logo = styled.img`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  align-self: center;
  margin-right: 30px;
  display: block;
`;
