// @flow

import getDashboardConfig from './getDashboardConfig';
import getDashboardConfigDrillDown from './getDashboardConfigDrillDown';
import drillOut from './drillOut';

export default {
  getDashboardConfig,
  getDashboardConfigDrillDown,
  drillOut,
};
