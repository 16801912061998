// @flow

import { apiClient } from '_common/api/clients/clients';

export type StaffBody = {
  login: string,
  password: string,
  scopes: string[],
};

const staffResetPasswordByEmail = (
  organisationId: string,
  login: string,
  redirectUrl: string
): Promise<any> => {
  return apiClient.post(
    `/v1/auth-management/staff/organisationId/${organisationId}/${login}/resetemail?redirectUrl=${redirectUrl}`
  );
};

const staffUpdatePassword = async (
  organisationId: string,
  token: string,
  password: string
): Promise<any> => {
  const body = {
    token,
    password,
  };

  const response = await apiClient.post(
    `/v1/auth-management/staff/organisationId/${organisationId}/password`,
    body
  );

  return response.data;
};

const createStaff = async (company: string, body: StaffBody) => {
  const response = await apiClient.post(
    `/v1/auth-management/staff/organisationId/${company}`,
    body
  );

  return response.data;
};

const createScopes = async (scopes: string[]) => {
  const body = {
    scopes,
  };
  const response = await apiClient.post(
    `/v1/auth-management/staff/scopes`,
    body
  );

  return response.data;
};

export default {
  staffResetPasswordByEmail,
  staffUpdatePassword,
  createStaff,
  createScopes,
};
