// @flow

import React from 'react';
import { GoogleApiWrapper, InfoWindow, Map } from 'google-maps-react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import type { Store } from 'locate/stores/locationStore';
import LocationStore from 'locate/stores/locationStore';
import { geoIcon } from 'assets';
import locationActions from 'locate/actions';
import StoreList from '../StoreList/StoreList';
import Marker from '../Marker/Marker';
import {
  ClearButton,
  GeoIcon,
  InfoAddress,
  InfoDistance,
  InfoHeader,
  InfoTitle,
  Input,
  InputWrapper,
  MobileMapBackground,
  Root,
  Select,
  StoreListWrapper,
  TabContent,
  TabHeader,
  Tabs,
  TabWrapper,
} from './MobileMapElements';
import { GOOGLE_MAP_DEFAULT_STYLES } from '_common/constants/googleMap';
import { computed, reaction } from 'mobx';
import { AsyncStatus } from '_common/constants/common';
import {
  getMapVisibleDistance,
  normalizeStoreName,
} from 'locate/utils/locationUtils';
import { WhiteLabelUi, withWhitelabelProps } from '_common/whitelabelConfig';

const { Option } = Select;

type Props = RouteComponentProps & {
  google: any,
  map?: any,
  locationStore: LocationStore,
};

type State = {
  position: ?{
    lat: () => number,
    lng: () => number,
  },
  key: string,
  activeMarker: ?any,
  activeStore: ?Store,
  infoWindowShown: boolean,
  bounds: ?object,
};

const GA_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

@observer
class MobileMap extends React.Component<Props, State> {

  state = {
    position: null,
    key: 'map',
    activeMarker: null,
    activeStore: null,
    infoWindowShown: false,
    geo: {
      lat: null,
      lng: null,
    },
    bounds: null,
  };

  @computed
  get isLoading() {
    const { asyncState } = this.props.locationStore;
    return asyncState === AsyncStatus.LOADING;
  }

  markerRefs = {};

  inputRef = React.createRef();

  componentDidMount() {
    this.initAutocomplete();
    this.disposeReaction = reaction(
      () => this.props.locationStore.stores,
      () => {
        this.setBounds();
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map) {
      this.initAutocomplete();
    }
  }

  onGeoIconClick = async () => {
    const searchInput = this.inputRef && this.inputRef.current;
    if (!searchInput) return;
    const { company } = this.props.match.params;
    this.setState({
      activeMarker: null,
      infoWindowShown: false,
    });
    searchInput.value = '';
    searchInput.value = await locationActions.searchStoresNearMe(company);
  };

  onMarkerClick = (marker: any, storeData: Store) => {
    this.setState({
      activeMarker: marker,
      activeStore: storeData,
      infoWindowShown: true,
    });
  };

  onMapClicked = () => {
    if (this.state.infoWindowShown) {
      this.setState({
        activeMarker: null,
        infoWindowShown: false,
      });
    }
  };

  onStoreClick = (store: Store) => {
    locationActions.setActiveStoreId(store.storeId);
    this.changeTab('map');
    this.setState({
      activeMarker: this.markerRefs[store.storeId].current.wrappedInstance
        .googleMarker,
      activeStore: store,
      infoWindowShown: true,
      geo: {
        lat: store.geo.lat,
        lng: store.geo.lon,
      },
    });
  };

  changeTab = key => {
    this.setState({ key });
    key === 'map' && this.setBounds();
  };

  setBounds = () => {
    const {
      locationStore: {
        stores,
        isNewLocationSearch,
        mapDragActive,
        clientGeoCoordinates,
      },
      google,
    } = this.props;
    if (!isNewLocationSearch || mapDragActive) return;
    const bounds = new google.maps.LatLngBounds();
    if (!stores.length) {
      bounds.extend({
        lat: clientGeoCoordinates.lat - 0.1,
        lng: clientGeoCoordinates.lng - 0.1,
      });
      bounds.extend({
        lat: clientGeoCoordinates.lat + 0.1,
        lng: clientGeoCoordinates.lng + 0.1,
      });
    } else {
      stores.forEach(({ geo }) => {
        bounds.extend({ lat: geo.lat, lng: geo.lon });
      });
    }
    this.setState({ bounds });
  };

  initAutocomplete() {
    const {
      google,
      map,
      match: {
        params: { company },
      },
      whiteLabeled: countryCode,
    } = this.props;
    const searchInput = this.inputRef.current;
    if (!google || !map || !searchInput) return;

    const autocomplete = new google.maps.places.Autocomplete(searchInput, {
      types: ['geocode'],
      componentRestrictions: { country: [countryCode.countryCode] },
    });
    autocomplete.bindTo('bounds', map);
    autocomplete.setComponentRestrictions({
      country: [countryCode.countryCode],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(14);
      }

      const position = place.geometry.location;
      this.setState({
        position,
        activeMarker: null,
        infoWindowShown: false,
      });
      locationActions.searchStoresByCoords({
        lat: position.lat(),
        lng: position.lng(),
        company,
      });
    });
  }

  handleClearSearch = () => {
    this.inputRef.current.value = '';
  };

  renderOption = (option: { value: string, label: string }, index: number) => {
    const { value, label } = option;
    return (
      <Option key={`option-${index}`} value={value}>
        {label}
      </Option>
    );
  };

  onStoreTypeChange = (storeType: string) => {
    locationActions.setLocationType(storeType);
    const { lastSearch } = this.props.locationStore;
    const {
      params: { company },
    } = this.props.match;
    if (!lastSearch) return;
    locationActions.searchStoresByCoords({
      lat: lastSearch.lat,
      lng: lastSearch.lng,
      company,
    });
  };

  handleDrag = async (mapProps, map) => {
    const { google } = this.props;
    const newCenter = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
    const distance = getMapVisibleDistance(map, google);
    try {
      await locationActions.searchStoresByCoords(newCenter, true, distance);
    } catch (e) {
      this.setState({ hasError: true });
    }
  };

  renderSelect() {
    const { locationTypesOptions } = this.props.locationStore;
    return (
      <Select
        placeholder="Find by location type"
        onChange={this.onStoreTypeChange}
        disabled={this.isLoading}
      >
        {locationTypesOptions.map(this.renderOption)}
      </Select>
    );
  }

  renderMarker = (storeData: Store) => {
    const { storeId, geo, locationType } = storeData;
    if (!this.markerRefs[storeId]) {
      this.markerRefs[storeId] = React.createRef();
    }

    return (
      <Marker
        key={storeId}
        ref={this.markerRefs[storeId]}
        storeId={storeId}
        lat={geo.lat}
        lng={geo.lon}
        storeData={storeData}
        onClick={this.onMarkerClick}
        locationType={locationType}
      />
    );
  };

  renderStoreInfo = () => {
    const { activeStore } = this.state;
    if (activeStore) {
      const {
        storeName,
        place: { address },
        geo,
        locationType,
      } = activeStore;

      const addressLine = address.line1 || address.line2 || '';
      const storeAddress = `${addressLine}, ${address.town}, ${
        address.postcode
      }`;
      const storeDistance = this.props.locationStore.getDistanceToLastSearch(
        geo
      );

      return (
        <>
          <InfoHeader>
            <InfoTitle>{normalizeStoreName(storeName, locationType)}</InfoTitle>
            <InfoDistance>{storeDistance}</InfoDistance>
          </InfoHeader>
          <InfoAddress>{storeAddress}</InfoAddress>
        </>
      );
    }

    return <>Store Info</>;
  };

  renderInfoWindow = () => {
    const { activeMarker, infoWindowShown } = this.state;

    return (
      <InfoWindow marker={activeMarker} visible={infoWindowShown}>
        {this.renderStoreInfo()}
      </InfoWindow>
    );
  };

  renderMap() {
    const {
      locationStore: { stores, clientGeoCoordinates, lastSearch },
    } = this.props;
    const { position, bounds, geo } = this.state;

    return (
      <>
        {!!lastSearch ? (
          <Map
            {...this.props}
            center={geo}
            defaultCenter={position}
            centerAroundCurrentLocation={false}
            bounds={bounds}
            containerStyle={{
              height: 'calc(100vh - 530px)',
              minHeight: 500,
              position: 'relative',
            }}
            styles={GOOGLE_MAP_DEFAULT_STYLES}
            onClick={this.onMapClicked}
            onDragend={this.handleDrag}
          >
            <Marker
              isDefaultUser
              lat={clientGeoCoordinates.lat}
              lng={clientGeoCoordinates.lng}
            />
            {stores.map(this.renderMarker)}
            {this.renderInfoWindow()}
          </Map>
        ) : (
          <MobileMapBackground />
        )}
      </>
    );
  }

  render() {
    const icon = (
      <GeoIcon
        image={geoIcon}
        onClick={this.onGeoIconClick}
        width={20}
        height={20}
      />
    );
    const { key } = this.state;

    return (
      <>
        <InputWrapper>
          <Input
            placeholder="Search for drop off locations"
            ref={this.inputRef}
            onClick={this.onInputClick}
            disabled={this.isLoading}
          />
          <ClearButton onClick={this.handleClearSearch}>Clear</ClearButton>
          {icon}
        </InputWrapper>
        {this.renderSelect()}
        <Tabs>
          <TabHeader
            active={key === 'list'}
            onClick={() => this.changeTab('list')}
          >
            List view
          </TabHeader>
          <TabHeader
            active={key === 'map'}
            onClick={() => this.changeTab('map')}
          >
            Map view
          </TabHeader>
        </Tabs>
        <TabWrapper>
          <TabContent active={key === 'list'}>
            <StoreListWrapper>
              <StoreList onStoreClick={this.onStoreClick} />
            </StoreListWrapper>
          </TabContent>
          <TabContent active={key === 'map'}>{this.renderMap()}</TabContent>
        </TabWrapper>
      </>
    );
  }

}

type WrapperProps = {
  initialCenter: { lat: number, lng: number },
  zoom: number,
  google: any,
  locationStore: LocationStore,
};

const MapWrapper = (props: WrapperProps) => {
  return (
    <Root>
      <Map {...props} visible={false}>
        <MobileMap {...props} />
      </Map>
    </Root>
  );
};

MapWrapper.defaultProps = {
  initialCenter: WhiteLabelUi.common.defaultMapCenter,
  zoom: 5,
  containerStyle: {
    width: '100%',
    minHeight: 'calc(100vh - 380px)',
    position: 'relative',
  },
};

export default compose(
  withRouter,
  GoogleApiWrapper({
    apiKey: GA_KEY,
    libraries: ['places', 'geometry'],
  }),
  inject('locationStore'),
  withWhitelabelProps({
    countryCode: 'ui.common.countryCode',
  }),
  observer
)(MapWrapper);
