import styled, { css } from 'styled-components';

export const Logo = styled.div`
  width: 87px;
  height: 94px;
  border: solid 1px ${({ darkMode }) => (darkMode ? '#777777' : '#eeeeee')};
  background-color: ${({ darkMode }) => (darkMode ? '#8e8e8e' : '#ffffff')};
  flex-shrink: 0;

  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Text = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.ultra};
  font-size: 26px;
  color: #282d32;
  text-align: center;
  text-transform: uppercase;

  ${props =>
    props.imageLoaded &&
    css`
      display: none;
    `}
`;

export const Img = styled.img`
  width: 87px;
  display: none;

  ${props =>
    props.imageLoaded &&
    css`
      display: block;
    `}
`;
