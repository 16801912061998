// @flow
/*eslint-disable camelcase*/

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Menu } from '_common/components';
import statisticsActions from 'statistics/actions';
import StatisticsStore from 'statistics/stores/statisticsStore';
import {
  Page,
  Content,
  PageHeader,
  PageTitle,
} from '_common/components/CommonStyledElements';
import { IframeWrapper, DrillOutLink } from './elements/StatisticsPageElements';
import Amplitude from '_common/utils/amplitude';

type Props = {
  statisticsStore: StatisticsStore,
};
type State = {
  isError: boolean,
};

@inject('statisticsStore')
@observer
class StatisticsPage extends Component<Props, State> {

  state = {
    isError: false,
  };

  componentDidMount() {
    Amplitude.logEvent('page_open', { page_name: 'Statistics' });
    statisticsActions.getDashboardConfig().catch(() => {
      this.setState({ isError: true });
    });
    window.addEventListener('message', this.handleDrillDown);
  }

  handleDrillDown = event => {
    if (typeof event.data !== 'object') {
      return;
    }

    const {
      filters,
      aggregation,
      daterange,
      event_detail,
      event_type,
    } = event.data;

    if (event_detail === 'filters_changed') {
      const filter_events = filters.map(({ name, value }) => ({
        filter_name: name,
        filter_value: value,
      }));
      Amplitude.logEvent('filter_applied', {
        page_name: 'Statistics',
        filter_events,
        aggregation,
        daterange,
      });
    }

    if (event_type === 'drilldown') {
      statisticsActions.getDashboardConfigDrillDown(event.data).catch(() => {
        this.setState({ isError: true });
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.handleDrillDown);
  }

  handleDrillOut = () => {
    statisticsActions.drillOut();
  };

  renderContent = () => {
    const { isError } = this.state;
    const {
      statisticsStore: { dashboardConfig },
    } = this.props;

    if (isError) {
      return 'Error while loading dashboard.';
    }

    if (dashboardConfig && dashboardConfig.src) {
      return (
        <IframeWrapper>
          <iframe
            id="periscope-statistics"
            title={dashboardConfig.name}
            width="100%"
            height="100%"
            src={dashboardConfig.src}
          />
        </IframeWrapper>
      );
    }
  };

  render() {
    const {
      statisticsStore: { isDrilledDown },
    } = this.props;

    return (
      <Page>
        <Menu />
        <Content>
          <PageHeader>
            <PageTitle>Statistics</PageTitle>
            {isDrilledDown && (
              <DrillOutLink onClick={this.handleDrillOut}>
                Restore dashboard
              </DrillOutLink>
            )}
          </PageHeader>
          {this.renderContent()}
        </Content>
      </Page>
    );
  }

}

export default StatisticsPage;
