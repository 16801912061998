// @flow

import React from 'react';
import { Background, Card, Icon, Text } from './elements';

type Props = {
  subStatus?: string,
  status: string,
  icon: Object,
  onClick?: () => void,
};

class AsyncProcessCard extends React.Component<Props> {

  handleClick = () => {
    const { onClick } = this.props;
    onClick && onClick();
  };

  render() {
    const { subStatus, icon, status, onClick } = this.props;

    return (
      <Background clickable={!!onClick} onClick={this.handleClick}>
        <Card>
          <Icon icon={icon} />
          <Text>{status}</Text>
          {subStatus && <Text>{subStatus}</Text>}
        </Card>
      </Background>
    );
  }

}

export default AsyncProcessCard;
