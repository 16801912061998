// @flow

import stores from 'stores';

export default () => {
  return {
    isMerchant: stores.authStore.getIsMerchantUser(),
    user: stores.authStore.loggedUser,
  };
};
