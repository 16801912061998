// @flow
import styled from 'styled-components';
import Input from '_common/components/Input';
import { Root } from '_common/components/TextSection/TextSectionElements';

export const TermsInput = styled(Input)`
  && {
    width: 360px;
    align-self: center;
    font-size: 12px;
    line-height: normal;
  }
`;

export const StyledColorPreview = styled.div`
  width: 120px;
  height: 140px;
  margin: -20px 35px -32px -27px;
`;

export const ColorLabel = styled.div`
  height: 50%;
  text-align: center;
  font-size: 16px;
  padding-top: 28px;
`;

export const ColorSwatch = styled.div`
  background-color: ${({ color }) => color};
  height: 50%;
`;

export const PanelSection = styled.div`
  border-radius: 2px;
  display: flex;
  border: solid 1px #c7c7cc;
  background-color: #fefefe;
  padding: 20px 26px 32px 27px;
  flex-wrap: nowrap;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${Root} {
    max-width: 480px;
    margin-right: auto;
  }
`;

export const SpacerForIE = styled.div`
  visibility: hidden;
  flex-grow: 1;
`;

export const PanelSectionNoWrap = styled(PanelSection)`
  flex-wrap: nowrap;
`;
