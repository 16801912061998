// @flow

import stores from 'stores';

const publishAsset = async (companyId, version) => {
  const { organisationId } = stores.authStore.loggedUser;
  return stores.assetManagementStore.publishAsset(
    companyId || organisationId,
    version
  );
};

export default publishAsset;
