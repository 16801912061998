import styled from 'styled-components';

export const DatepickerWrapper = styled.div`
  position: relative;

  font-size: 14px;
  font-weight: 300;
  color: #282d32;

  & input {
    border-radius: 2px;
    border: solid 1px rgba(151, 151, 151, 0.25);
    background-color: #ffffff;
    height: 42px;

    &::placeholder {
      color: #282d32;
      font-size: 14px;
      font-weight: 300;
    }
  }
`;

export const DatepickerIcon = styled.div`
  background-image: url(${({ image }) => image});
  background-size: cover;
  width: ${({ width }) => `${width}px !important`};
  height: ${({ height }) => `${height}px !important`};
  margin-top: ${({ marginTop }) => `-${marginTop}px !important`};
  cursor: pointer;
`;
