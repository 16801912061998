// @flow

import { getAllowedRolesConfig, parseEmailAddresses } from '_common/utils';
import { ERROR_LOG_LEVELS } from '_common/constants/common';

export const PRODUCT = 'RETURNS_PORTAL';
export const ROOT_ORG_ID = process.env.REACT_APP_ROOT_ORG_ID || '';
export const ROOT_STATISTICS_ID =
  process.env.REACT_APP_ROOT_STATISTICS_ID || '';
export const STATISTICS_SPACE = process.env.REACT_APP_STATISTICS_SPACE || '';
export const STATISTICS_DASHBOARD =
  process.env.REACT_APP_STATISTICS_DASHBOARD || '';
export const CONSUMER_URL = process.env.REACT_APP_CONSUMER_URL || '';
export const APPLICATION_NAME = 'returnsportaladmin';
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || '';
export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER || '';
export const ALLOWED_ROLES_CONFIG = getAllowedRolesConfig(
  process.env.REACT_APP_ALLOWED_ADMIN_ROLES,
  process.env.REACT_APP_ALLOWED_MERCHANT_ROLES
);
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const TEST_TRACKING_ID = 'TEST_TRACKING_ID';
export const AUTH_TYPE = process.env.REACT_APP_AUTH_TYPE;
export const ERROR_LOG_LEVEL =
  process.env.REACT_APP_ERROR_LOG_LEVEL || ERROR_LOG_LEVELS.FULL;
export const HOTJAR_ID_WITH_VERSION =
  process.env.REACT_APP_HOTJAR_ID_WITH_VERSION || '';
export const CHANGES_TRACKING_EMAIL_ADDRESS =
  process.env.REACT_APP_CHANGES_TRACKING_EMAIL_ADDRESS;
export const EMAIL_ADDRESSES = parseEmailAddresses(
  process.env.REACT_APP_EMAIL_ADDRESSES
);
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
