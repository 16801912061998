// @flow

import stores from 'stores';

export default (url: ?string) => {
  if (typeof url === 'string') {
    return stores.authStore.logout(url);
  }
  return stores.authStore.logout();
};
