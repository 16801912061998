// @flow

import stores from 'stores';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import getIsAdminUser from '_common/actions/getIsAdminUser';

export default (
  trackingBlock,
  changeName: string,
  oldValue: string,
  newValue: string
) => {
  if (
    WhiteLabelConstants.IS_ENABLED_TRACK_CHANGES &&
    !getIsAdminUser().isAdmin
  ) {
    return stores.uiStore.trackChange(
      trackingBlock,
      changeName,
      oldValue,
      newValue
    );
  }
};
