// @flow

import Main from '_common/routes/Main/Main';
import NotFound from '_common/routes/NotFound/NotFound';
import RootRedirect from '_common/routes/RootRedirect';
import Login from 'login/pages/components/Login';
import SignUp from 'login/pages/components/SignUp';
import ForgotPassword from 'login/pages/components/ForgotPassword';
import CreateNewPassword from 'login/pages/components/CreateNewPassword';
import ResetPassword from 'login/pages/components/ResetPassword';
import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import links from '../urls';
import Locate from 'locate';
import { Version } from './elements';
import packageJson from '../../../../package.json';

const App = () => (
  <Fragment>
    <Version>Version: {packageJson.version}</Version>
    <BrowserRouter>
      <Switch>
        <RootRedirect exact path={links.root} />
        <Route exact path={links.rootLogin} component={Login} />
        <Route exact path={links.merchantLocate} component={Locate} />
        <Route exact path={links.merchantLogin} component={Login} />
        <Route exact path={links.signUp} component={SignUp} />
        <Route exact path={links.forgotPassword} component={ForgotPassword} />
        <Route
          exact
          path={links.createNewPassword}
          component={CreateNewPassword}
        />
        <Route exact path={links.resetPassword} component={ResetPassword} />
        <Route exact path={links.notFound} component={NotFound} />
        <PrivateRoute component={Main} />
      </Switch>
    </BrowserRouter>
  </Fragment>
);

export default App;
