// @flow
import { apiClient } from '_common/api/clients/clients';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import { ROOT_ORG_ID } from '../constants/appConfig';

export type CompanyUpdateConfig = {};

const getCompany = async (companyId: string): Promise<any> => {
  const response = await apiClient.get(`/v1/companies/${companyId}`);
  return response.data.resources;
};

const updateCompany = async (
  companyId: string,
  companyUpdateConfig: CompanyUpdateConfig
): Promise<any> => {
  const response = await apiClient.post(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/update-company/${companyId}`,
    companyUpdateConfig
  );
  return response.data.body.resource;
};

const getSubCompanies = async (rootCompanyId: string) => {
  const response = await apiClient.get(
    `/v1/companies/${rootCompanyId}/products/${
      WhiteLabelConstants.PRODUCT_NAME
    }/admin?ts=${Date.now()}`
  );

  return response.data.resources;
};

export default {
  getCompany,
  updateCompany,
  getSubCompanies,
};
