import { get, merge, pick } from 'lodash';
import moment from 'moment';

import { TIERS_MAPPING, PRODUCT_NAME } from '../_constants';
import {
  TEST_TRACKING_ID,
  CONSUMER_URL,
  EMAIL_ADDRESSES,
} from '_common/constants/appConfig';
import type { Applicant } from 'merchants/services/merchantsService';

/**
 * Recalculates price based on pricingTier and returnsSpeedType.
 */
export const getRecalculatedPrice = (originalConfig, updatedConfig) => {
  const returnsSpeedType = get(
    originalConfig,
    'returns.returnsSpeedType',
    'PR'
  );
  const pricingTier = get(
    updatedConfig,
    `products.${PRODUCT_NAME}.customerPayments.pricingTier`
  );

  return pricingTier
    ? getCustomerPaymentsConfig({ pricingTier, returnsSpeedType })
    : {};
};

/**
 * AP-specific recalculations of price based on tier.
 */
export const recalculatePrice = ({ originalConfig, updatedConfig }) => {
  return merge(
    updatedConfig,
    getRecalculatedPrice(originalConfig, updatedConfig)
  );
};

/**
 * Convert model to backend schema.
 * @param updatedConfig
 * @returns {*}
 */
export const convertToBackendModel = updatedConfig => {
  const pricingTier = get(
    updatedConfig,
    `products.${PRODUCT_NAME}.customerPayments.pricingTier`
  );

  if (pricingTier) {
    updatedConfig.products[PRODUCT_NAME].customerPayments.pricingTier = Number(
      pricingTier
    );
  }

  return updatedConfig;
};

export const getEmailConfigForMerchantAcceptedRequestEmail = (
  companyName,
  reviewRequestCta,
  recipientEmail,
  merchantChargeAccount,
  locationId,
  contactName,
  contactPhone,
  contactEmail
) => ({
  requestData: {
    recipient: {
      name: 'Your request to Easy Returns has been accepted',
      email: recipientEmail,
    },
    substitutionData: {
      companyName,
      reviewRequestCta,
      merchantChargeAccount,
      locationId,
      contactName,
      contactPhone,
      contactEmail,
    },
  },
  templateId: 'merchant_request_notification',
});

/**
 * Email to send on merchant signup via the registration form if they have
 * registed for returns portal
 *
 * @param recipientEmail
 * @returns {{requestData: {recipient: {name: string, email: *}}, templateId: string}}
 */
export const getEmailConfigForMerchantRegisteredEmail = recipientEmail => ({
  requestData: {
    recipient: {
      name:
        'Thank you for registering to access the Australia Post Easy Returns Portal',
      email: recipientEmail,
    },
  },
  templateId: 'merchant_registration',
});

// TODO AP only
export const getEmailConfigForMerchantDeclinedRequestEmail = (
  emailAddress,
  contactName
) => ({
  requestData: {
    recipient: {
      name: 'Your request to Easy Returns has been declined',
      email: emailAddress,
    },
    substitutionData: {
      contactName,
    },
  },
  templateId: 'request_declined',
});

/**
 * return config for barcode email.
 * @param email
 * @param organisationId
 * @param retailerName - human-readable name
 */
export const getEmailConfigForBarcode = (
  email: string,
  organisationId: string,
  retailerName: string
) => ({
  requestData: {
    recipient: {
      name: retailerName,
      email: email,
    },
    substitutionData: {
      label: {
        pdfUrl: 'http://www.doddle.com',
      },
      trackingID: '111111111111',
      company: {
        companyId: '',
      },
      retailer: {
        retailerName,
      },
      referenceId: 'referenceId',
      referenceIdQrCode: {
        url: 'http://www.doddle.com',
      },
      links: {
        locate: 'http://www.doddle.com',
      },
    },
  },
  templateId: 'return_requested',
});

/**
 * return config for getEmailConfigForParcelIsLodged.
 */
export const getEmailConfigForParcelIsLodged = (
  email: string,
  organisationId: string,
  retailerName: string
) => ({
  requestData: {
    recipient: {
      name: retailerName,
      email: email,
    },
    substitutionData: {
      estimatedDeliveryDate: moment().format('DD-MM-YYYY'),
      retailerName,
      trackingIds: TEST_TRACKING_ID,
      links: {
        tracking: `${CONSUMER_URL}/${organisationId}/tracking?id=${TEST_TRACKING_ID}`,
      },
    },
  },
  templateId: 'returns_on_its_way',
});

/**
 * return config for getEmailConfigForDeliveredToWarehouse.
 */
export const getEmailConfigForDeliveredToWarehouse = (
  email: string,
  organisationId: string,
  retailerName: string
) => ({
  requestData: {
    recipient: {
      name: retailerName,
      email: email,
    },
    substitutionData: {
      retailerName,
      trackingIds: TEST_TRACKING_ID,
      links: {
        tracking: `${CONSUMER_URL}/${organisationId}/tracking?id=${TEST_TRACKING_ID}`,
      },
    },
  },
  templateId: 'return_delivered',
});

export const getEmailConfigForLocationFinderInfo = (
  emailAddress,
  companyName,
  contactName,
  phoneNumber,
  merchantChargeAccount,
  locationId,
  customersToUse,
  collectionWidget
) => ({
  requestData: {
    recipient: {
      name: 'Location finder info',
      email: EMAIL_ADDRESSES.SIGNUP_COLLECTION_WIDGET_EMAIL,
    },
    substitutionData: {
      emailAddress,
      companyName,
      contactName,
      phoneNumber,
      merchantChargeAccount,
      locationId,
      customersToUse,
      collectionWidget,
    },
  },
  templateId: 'location_finder_info',
});

/**
 * Returns config for customers payments section.
 */
export const getCustomerPaymentsConfig = ({
  pricingTier,
  returnsSpeedType,
  withConsumerApiKey = false,
}) => ({
  products: {
    [PRODUCT_NAME]: {
      customerPayments: {
        price: TIERS_MAPPING[pricingTier][returnsSpeedType],
        consumerChargeAccount:
          TIERS_MAPPING[pricingTier].CONSUMER_CHARGE_ACCOUNT,
        ...(withConsumerApiKey && {
          consumerApiKey: TIERS_MAPPING[pricingTier].API_KEY,
        }),
      },
    },
  },
});

/**
 * Add required pros for Companies API. Merchant Card.
 * @param originalConfig
 * @param updates
 */
export const mapToChannelModelForPatch = (originalConfig, updates) => {
  return merge(
    pick(originalConfig, `products.${PRODUCT_NAME}.enabled`),
    pick(originalConfig, `products.${PRODUCT_NAME}.customerPayments.isEnabled`),
    updates,
    getRecalculatedPrice(originalConfig, updates)
  );
};

/**
 * Prepare prereg model to be submitted to companies API.
 */
export const getInitialAdminFieldsModel = ({
  merchantChargeAccount,
  locationId,
}: Applicant) => ({
  products: {
    [PRODUCT_NAME]: {
      customerPayments: {
        merchantChargeAccount,
        locationId,
      },
    },
  },
});
