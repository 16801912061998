// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Marker as GoogleMarker } from 'google-maps-react';
import { computed } from 'mobx';
import { get } from 'lodash';

import {
  markerIcon,
  activeMarkerIcon,
  defaultUserMarker,
  postOfficeIcon,
  postOfficeActiveIcon,
  postBoxIcon,
  postBoxActiveIcon,
  lockerIcon,
  lockerActiveIcon,
  colocatedPostboxActive,
  colocatedPostbox,
} from 'assets';
import locationActions from 'locate/actions';
import LocationStore from 'locate/stores/locationStore';
import type { Store } from 'locate/stores/locationStore';

type Props = {
  storeId: string,
  lat: number,
  lng: number,
  isDesktop?: boolean,
  locationStore: LocationStore,
  storeData: Store,
  isDefaultUser: any,
  onClick?: (marker: any, storeData: Store) => void,
};

type State = {};

@observer
class Marker extends React.Component<Props, State> {

  googleMarkerRef = React.createRef();

  get googleMarker() {
    return get(this.googleMarkerRef.current, 'marker', null);
  }

  @computed
  get isActive() {
    return this.props.storeId === this.props.locationStore.activeStoreId;
  }

  onClick = (props: Props, marker: any) => {
    locationActions.setActiveStoreId(this.props.storeId);
    if (this.props.onClick) {
      this.props.onClick(marker, props.storeData);
    }
  };

  render() {
    const {
      storeId,
      isDefaultUser,
      lat,
      lng,
      locationStore,
      locationType,
      ...rest
    } = this.props;

    let iconUrl;
    switch (locationType) {
      case 'POSTOFFICE':
        iconUrl = this.isActive ? postOfficeActiveIcon : postOfficeIcon;
        break;

      case 'POSTBOX':
        iconUrl = this.isActive ? postBoxActiveIcon : postBoxIcon;
        break;

      case 'EXPRESS_POSTBOX':
        iconUrl = this.isActive ? colocatedPostboxActive : colocatedPostbox;
        break;

      case 'LOCKER':
        iconUrl = this.isActive ? lockerActiveIcon : lockerIcon;
        break;

      default:
        iconUrl = this.isActive ? activeMarkerIcon : markerIcon;
    }

    const markerImage = {
      url: isDefaultUser ? defaultUserMarker : iconUrl,
      scaledSize: new this.props.google.maps.Size(37, 36),
    };

    return (
      <GoogleMarker
        {...rest}
        ref={this.googleMarkerRef}
        position={{ lat, lng }}
        icon={markerImage}
        onClick={this.onClick}
      />
    );
  }

}

export default inject('locationStore')(Marker);
