import styled from 'styled-components';
import { Radio } from 'antd';

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper,
  .ant-radio-wrapper:hover {
    display: flex;
    align-items: center;
    flex-direction: ${({ alignLeft }) => (alignLeft ? 'row' : 'row-reverse')};
    font-family: ${props => props.theme.fonts.book};
    color: ${({ isInvalid }) => (isInvalid ? '#f5222d' : '#131314')};
    width: ${({ width }) => `${width || 380}px`};
    margin-right: 0;
    padding: 8px 13px 8px 4px;
    ${({ noBorders }) => !noBorders && `border-bottom: 1px solid #eeeeee;`};

    .ant-radio-inner {
      width: 32px;
      height: 32px;
      border-color: ${({ isInvalid }) => (isInvalid ? '#f5222d' : '#979797')};
    }

    .ant-radio-inner::after {
      background-color: #231f20;
      width: 9px;
      height: 9px;
      top: 10.5px;
      left: 10.5px;
    }

    .ant-radio {
      ${({ noBorders }) =>
        !noBorders && `box-shadow: -19px 0 0 white, -20px 0 0 #efefef;`}
      ${({ alignLeft, noBorders }) =>
        alignLeft && `margin-right: ${noBorders ? '20' : '35'}px;`}
    }
  }

  .ant-radio-wrapper > :last-child {
    flex-grow: 1;
    padding-left: 0;
  }
`;
