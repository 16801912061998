const TIERS_API_KEY = process.env.REACT_APP_TIERS_API_KEY;
export const PRODUCT_NAME = 'RETURNS_PORTAL';
export const IS_ENABLED_TRACK_CHANGES = true;

export const TIERS_MAPPING = {
  1: {
    CONSUMER_CHARGE_ACCOUNT:
      process.env.REACT_APP_TIER_1_CONSUMER_CHARGE_ACCOUNT,
    MLID: process.env.REACT_APP_TIER_1_MLID,
    API_KEY: TIERS_API_KEY,
    PR: '8.95',
    XPR: '11.95',
  },
  2: {
    CONSUMER_CHARGE_ACCOUNT:
      process.env.REACT_APP_TIER_2_CONSUMER_CHARGE_ACCOUNT,
    MLID: process.env.REACT_APP_TIER_2_MLID,
    API_KEY: TIERS_API_KEY,
    PR: '12.20',
    XPR: '15.70',
  },
  3: {
    CONSUMER_CHARGE_ACCOUNT:
      process.env.REACT_APP_TIER_3_CONSUMER_CHARGE_ACCOUNT,
    MLID: process.env.REACT_APP_TIER_3_MLID,
    API_KEY: TIERS_API_KEY,
    PR: '15.35',
    XPR: '19.35',
  },
  4: {
    CONSUMER_CHARGE_ACCOUNT:
      process.env.REACT_APP_TIER_4_CONSUMER_CHARGE_ACCOUNT,
    MLID: process.env.REACT_APP_TIER_4_MLID,
    API_KEY: TIERS_API_KEY,
    PR: '18.50',
    XPR: '25.50',
  },
};

export const FIELD_NAMES = {
  MERCHANT_API_KEY: `products.${PRODUCT_NAME}.customerPayments.merchantApiKey`,
  API_SECRET: `products.${PRODUCT_NAME}.apiSecret`,
  HAS_API_SECRET_SET: `products.${PRODUCT_NAME}.hasApiSecret`,
  LOCATION_ID: `products.${PRODUCT_NAME}.customerPayments.locationId`,
  MERCHANT_CHARGE_ACCOUNT: `products.${PRODUCT_NAME}.customerPayments.merchantChargeAccount`,
  PRICING_TIER: `products.${PRODUCT_NAME}.customerPayments.pricingTier`,
  COMPANY_NAME: 'companyName',
  WEBSITE: 'website',
  PHONE_NUMBER: 'phoneNumber',
  RETURN_COMPANY_NAME: `products.${PRODUCT_NAME}.returnCompanyName`,
  DEPARTMENT_NAME: `products.${PRODUCT_NAME}.departmentName`,
  STREET_WITH_NUMBER: 'returns.routings[0].address.line1',
  POSTAL_TOWN: 'returns.routings[0].address.town',
  STATE: 'returns.routings[0].address.area',
  POSTAL_CODE: 'returns.routings[0].address.postcode',
};
