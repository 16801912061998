import styled from 'styled-components';
import { StyledButton } from '_common/components/Button/elements';
import { isIE } from 'react-device-detect';

export const Row = styled.div`
  display: flex;
  justify-content: ${isIE ? 'flex-start' : 'space-between'};
`;

export const SearchSelectWrapper = styled.div`
  .ant-select-selection {
    border-radius: 0 2px 2px 0;
    border: solid 1px rgba(151, 151, 151, 0.25);
  }
  .ant-select-arrow {
    color: #111111;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 20px;
  border-radius: 3px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  background-color: #ffffff;
  padding: 20px;
`;

export const EmptyResultsMessage = styled.div``;

export const ClearFiltersButton = styled(StyledButton)`
  width: 125px;
  min-width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-left: 20px;
`;
