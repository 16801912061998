import styled from 'styled-components';
import { StyledButton } from '_common/components/Button/elements';

export const OktaLoginWrapper = styled.div`
  width: 40vw;
  height: 40vh;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  color: #282d32;
  font-family: ${props => props.theme.fonts.medium};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OktaActionButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;

  border-radius: 21px;
  border: solid 1px #979797;
  height: auto;
  padding: 5px 25px;

  margin-top: 5vh;

  background-color: transparent;
  color: #000;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: transparent;
    border: solid 1px #6a6a6a;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
`;
