// @flow

import React, { Component } from 'react';
import { Icon } from 'antd';
import {
  MenuSectionWrapper,
  MenuSectionHeader,
  DropdownWrapper,
  DropdownLinkWrapper,
  NavLink,
  Text,
} from './elements';

type Link = {
  href: string,
  title: string,
  disabled?: boolean,
};

type Props = {
  title: string,
  links: Link[],
};

type State = {
  isOpen: boolean,
};

class MenuSection extends Component<Props, State> {

  state = {
    isOpen: true,
  };

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderDropdownLink(link: Link, index: number) {
    return (
      <DropdownLinkWrapper key={`link-${index}`}>
        <NavLink
          to={link.href}
          activeClassName="active-link"
          disabled={link.disabled}
        >
          {link.title}
        </NavLink>
      </DropdownLinkWrapper>
    );
  }

  render() {
    const { title, links } = this.props;
    const { isOpen } = this.state;

    return (
      <MenuSectionWrapper>
        <MenuSectionHeader onClick={this.toggleMenu}>
          <Text>{title}</Text>
          {isOpen ? <Icon type="up" /> : <Icon type="right" />}
        </MenuSectionHeader>
        <DropdownWrapper isOpen={isOpen}>
          {links.map(this.renderDropdownLink)}
        </DropdownWrapper>
      </MenuSectionWrapper>
    );
  }

}

export default MenuSection;
