/**
 * Walks by object and flat key-value paris. Duplicates are not expected as it's form fields.
 * @param obj
 * @param path
 * @param flattedList
 * @returns {*[]}
 */
export const getFlattedKeyValuePairsFromObject = (
  obj,
  path = [],
  flattedList = []
) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      return getFlattedKeyValuePairsFromObject(
        value,
        [...path, key],
        flattedList
      );
    } else {
      const fullPath = [...path, key].join('.');
      flattedList.push([fullPath, value]);
    }
  });
  return flattedList;
};
