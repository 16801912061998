import styled from 'styled-components';
import { Input } from '_common/components';

export const StyledInput = styled(Input)`
  border: 1px solid #4a90e2;
  border-radius: 0;
  margin-right: 5px;
  background-color: #fefefe !important;
  .ant-input-group-addon {
    padding: 0;
    border: none;
  }

  .ant-btn,
  .ant-input {
    height: 58px;
    border: none;
  }

  .ant-input:focus {
    box-shadow: none;
  }
  .ant-input-group-addon {
    background: transparent;
  }
`;
