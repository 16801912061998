// @flow

import type { AssetManagementConfig } from '_common/services/assetManagement';
import stores from 'stores';

const createAssetConfig = (organisationId, config: AssetManagementConfig) => {
  return stores.assetManagementStore.createAssetConfig(organisationId, config);
};

export default createAssetConfig;
