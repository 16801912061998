// @flow
/* eslint camelcase: 0 */

import React, { Component } from 'react';
import Script from 'react-load-script';
import { Form, FormComponentProps } from 'antd';
import { Input, Label } from '_common/components/index';
import { InputRow } from '_common/components/GooglePlacesAutocomplete/elements';
import { toJS } from 'mobx';
import { isEqual, includes } from 'lodash';
import { observer } from 'mobx-react';
import commonActions from '_common/actions';

type Address = {
  long_name: string,
  short_name: string,
  types: string[],
};

type Props = FormComponentProps & {
  addressLabel: string,
  prefix: string,
  values: any,
  onGooglePlaceSelect?: () => void,
};

type State = {
  form: {
    streetWithNumber: string,
    postal_town: string,
    administrative_area_level_1: string,
    country: string,
    postal_code: string,
  },
  isAddressSelected: boolean,
};

const GA_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const getInitState = () => ({
  streetWithNumber: '',
  postal_town: '',
  administrative_area_level_1: '',
  postal_code: '',
  mainInput: '',
});

@observer
class GooglePlacesAutocomplete extends Component<Props, State> {

  autocomplete: any;

  static getDerivedStateFromProps({ values }, { form, isStoredDataLoaded }) {
    if (!values) {
      return {
        isStoredDataLoaded: false,
      };
    }
    if (isStoredDataLoaded || isEqual(values, form)) {
      return null;
    }

    return {
      form: {
        ...form,
        ...toJS(values),
      },
      isAddressSelected: true,
      isStoredDataLoaded: true,
    };
  }

  state = {
    form: getInitState(),
    isAddressSelected: false,
    isStoredDataLoaded: false,
  };

  componentDidMount(): void {
    this.props.onMounted(this.props.form);
  }

  handleScriptLoad = () => {
    const { prefix } = this.props;
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(`${prefix}-autocomplete`),
      { types: ['geocode'], componentRestrictions: { country: ['au'] } }
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields({
      fields: ['address_component', 'formatted_address'],
      restriction: { country: ['au'] },
    });
    this.autocomplete.setComponentRestrictions({ country: ['au'] });

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  };

  handlePlaceChanged = () => {
    const {
      prefix,
      form: { resetFields, validateFields },
      onGooglePlaceSelect,
    } = this.props;
    const {
      address_components,
      formatted_address,
    } = this.autocomplete.getPlace();
    if (!address_components) {
      console.error('Cannot get google addresses', formatted_address);
      return;
    }

    resetFields();
    const form = {
      ...getInitState(),
      ...address_components.reduce(
        (acc, { long_name, types }: Address) => {
          if (!types.length) {
            return acc;
          }

          const type = types[0];

          if (type === 'locality') {
            acc['postal_town'] = long_name;
          }

          /** now we need to concat two address fields to one. */
          if (includes(['street_number', 'route'], type)) {
            acc[`streetWithNumber`] += `${long_name} `;
          }

          /** only defined in state fields are used. */
          if (this.state.form[type] === void 0) {
            return acc;
          }
          acc[type] = long_name;
          return acc;
        },
        { streetWithNumber: '' }
      ),
      mainInput: formatted_address,
    };

    /** trim trailing space after concat. */
    form['streetWithNumber'] = form['streetWithNumber'].trim();

    this.setState(
      prevState => ({ ...prevState, isAddressSelected: true, form }),
      () => {
        validateFields(() => {
          /** ignore validation errors here */
        });
        commonActions.setMerchantDetails({ [prefix]: this.state.form });
        onGooglePlaceSelect && onGooglePlaceSelect();
      }
    );
  };

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  geoLocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        this.autocomplete && this.autocomplete.setBounds(circle.getBounds());
      });
    }
  };

  handleChange = ({ target: { name, value } }) => {
    const { prefix } = this.props;
    const newOb = {
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value,
      },
    };

    commonActions.setMerchantDetails({ [prefix]: newOb.form });
    this.setState(newOb);
  };

  validator = ({ field }, value, callback) => {
    const { prefix } = this.props;
    if (!!value) {
      return callback();
    }
    !!this.state.form[field.replace(`${prefix}-`, '')]
      ? callback()
      : callback('This field is mandatory');
  };

  render() {
    const {
      form: { getFieldDecorator },
      addressLabel,
      prefix,
    } = this.props;
    const {
      form: {
        administrative_area_level_1,
        postal_code,
        postal_town,
        mainInput,
        streetWithNumber,
      },
      isAddressSelected,
    } = this.state;

    return (
      <Form>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${GA_KEY}&libraries=places`}
          onLoad={this.handleScriptLoad}
        />
        <Form.Item>
          <Label>{addressLabel}</Label>
          <Input
            id={`${prefix}-autocomplete`}
            onFocus={this.geoLocate}
            placeholder={'Start typing an address'}
            value={mainInput}
            onChange={this.handleChange}
            name={`mainInput`}
          />
        </Form.Item>
        {/*<InputRow>*/}
        <Form.Item>
          {getFieldDecorator(`${prefix}-streetWithNumber`, {
            validateTrigger: 'onBlur',
            rules: [{ validator: this.validator }],
            onChange: this.handleChange,
          })(
            <div>
              <Input
                readOnly={!isAddressSelected}
                style={{ width: 308 }}
                id={`${prefix}-streetWithNumber`}
                placeholder={'Street address with number'}
                value={streetWithNumber}
                name={`streetWithNumber`}
              />
            </div>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(`${prefix}-postal_town`, {
            validateTrigger: 'onBlur',
            rules: [{ validator: this.validator }],
            onChange: this.handleChange,
          })(
            <div>
              <Input
                readOnly={!isAddressSelected}
                style={{ width: 308 }}
                value={postal_town}
                name={`postal_town`}
                id={`${prefix}-postal_town`}
                placeholder={'City'}
              />
            </div>
          )}
        </Form.Item>

        <InputRow>
          <Form.Item>
            {getFieldDecorator(`${prefix}-administrative_area_level_1`, {
              validateTrigger: 'onBlur',
              rules: [{ validator: this.validator }],
              onChange: this.handleChange,
            })(
              <div>
                <Input
                  readOnly={!isAddressSelected}
                  id={`${prefix}-administrative_area_level_1`}
                  placeholder={`State`}
                  style={{ width: 146 }}
                  name={`administrative_area_level_1`}
                  value={administrative_area_level_1}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(`${prefix}-postal_code`, {
              validateTrigger: 'onBlur',
              rules: [{ validator: this.validator }],
              onChange: this.handleChange,
            })(
              <div>
                <Input
                  readOnly={!isAddressSelected}
                  id={`${prefix}-postal_code`}
                  value={postal_code}
                  name={`postal_code`}
                  placeholder={`Zip code`}
                  style={{ width: 146 }}
                />
              </div>
            )}
          </Form.Item>
        </InputRow>
      </Form>
    );
  }

}

export default Form.create()(GooglePlacesAutocomplete);
