import styled from 'styled-components';

export const CollectionsCheckboxesWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 16px;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 17px;

  .ant-row.ant-form-item {
    margin-top: 0;
    margin-bottom: 17px;
  }

  .ant-row.ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    line-height: 18px;
  }
`;
export const Subtext = styled.span`
  display: block;
  font-family: ${props => props.theme.fonts.medium};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #282d32;
  margin-bottom: 15px;
`;

export const AgreementText = styled.div`
  font-family: ${props => props.theme.fonts.book};
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 3px;
  & > span:first-child {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    color: #282d32;
  }
`;
