// @flow

import styled from 'styled-components';

export const ReturnUrlWrapper = styled.div`
  margin-top: ${({ isMerchant }) => (isMerchant ? '37px' : '0')};
  label {
    margin: 13px 0;
  }

  a {
    word-break: break-all;
    white-space: normal;
  }
`;

export const Link = styled.a`
  display: flex;
  white-space: nowrap;
  font-weight: 500;
  color: #4a90e2;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export const FlexibleTextLabel = styled.div`
  width: 360px;
  min-width: 200px;

  @media only screen and (max-width: 1440px) {
    width: 100% !important;
  }
`;

export const FieldsWrapper = styled.div`
  width: 100%;
`;
