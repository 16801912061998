import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  max-height: ${({ notificationIsVisible }) =>
    notificationIsVisible ? 'calc(100vh - 118px)' : 'calc(100vh - 71px)'};
  padding: 25px 50px 25px 25px;

  .request-item-exit {
    opacity: 1;
  }

  .request-item-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-in-out;
  }
`;

export const LoadingMessage = styled.div`
  font-size: 16px;
`;

export const RequestWrapper = styled.div`
  display: flex;
  width: 1100px;
  flex-wrap: wrap;
`;
