//@flow
/*eslint-disable camelcase*/

import stores from 'stores';
import { ROOT_ORG_ID } from '_common/constants/appConfig';
import type { PeriscopeFilter } from 'statistics/stores/statisticsStore';

const getDashboardConfigDrillDown = ({
  filter_values,
}: {
  filter_values: PeriscopeFilter[],
}) => {
  const filter = filter_values[0];
  if (!filter) {
    console.warn('Drilldown filter not provided.');
    return;
  }

  const {
    loggedUser: { organisationId },
    getIsMerchantUser,
  } = stores.authStore;

  return stores.statisticsStore.getDashboardConfigDrillDown({
    filter,
    organisationId,
    ...(getIsMerchantUser() && {
      rootId: ROOT_ORG_ID,
    }),
  });
};

export default getDashboardConfigDrillDown;
