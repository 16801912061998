import React, { Component } from 'react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import validateTokenFromOkta from '_common/actions/validateTokenFromOkta';
import {
  OktaLoginWrapper,
  OktaActionButton,
} from 'login/pages/elements/OktaLogin';
import { CircleLoader } from '_common/components';
import links from '_common/routes/urls';
import { inject, observer } from 'mobx-react';
import AuthStore from '_common/stores/authStore';

const STATES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

type Props = {
  authStore: AuthStore,
};
type State = {
  authState: STATES,
};

@inject('authStore')
@observer
class OktaLogin extends Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      authState: STATES.IDLE,
    };

    props.authStore.registerOktaClient();

    /** Need to check that's user has stored token, and no redirects needed. */
    props.authStore.externalAuthClient.tokenManager
      .get('token')
      .then(tokenObject => {
        /** If token exists - validate it. */
        if (tokenObject) {
          this.handleOktaTokenValidation(tokenObject.accessToken);
          /** If token not found - parse it from URL hash */
        } else if (window.location && window.location.hash) {
          props.authStore.externalAuthClient.token
            .parseFromUrl()
            .then(parsedToken => {
              /** Store parsed token in Token Manager and validate */
              props.authStore.externalAuthClient.tokenManager.add(
                'token',
                parsedToken
              );
              this.setState({ authState: STATES.LOADING });
              this.handleOktaTokenValidation(parsedToken.accessToken);
            })
            .catch(this.handleError);
        }
      })
      .catch(this.handleError);
  }

  authenticate = () => {
    this.setState({ authState: STATES.LOADING });
    this.props.authStore.externalAuthClient.tokenManager
      .get('token')
      .then(tokenObject => {
        /** If token exists - validate it. */
        if (tokenObject) {
          this.handleOktaTokenValidation(tokenObject.accessToken);
          /** If token not found - parse it from URL hash */
        } else if (window.location && window.location.hash) {
          this.props.authStore.externalAuthClient.token
            .parseFromUrl()
            .then(parsedToken => {
              /** Store parsed token in Token Manager and validate */
              this.props.authStore.externalAuthClient.tokenManager.add(
                'token',
                parsedToken
              );
              this.handleOktaTokenValidation(parsedToken.accessToken);
            })
            .catch(this.handleError);
        } else {
          /** not logged in, need a new session */
          this.props.authStore.externalAuthClient.token.getWithRedirect({
            responseType: 'token',
          });
        }
      })
      .catch(this.handleError);
  };

  handleError = e => {
    console.error('parseFromUrl', e);
    return this.setState({ authState: STATES.ERROR });
  };

  handleOktaTokenValidation = async (accessToken: string) => {
    const {
      history: { push },
    } = this.props;

    try {
      await validateTokenFromOkta(accessToken);
      /** Redirect to Root Start Page */
      push(links.requests);
    } catch (e) {
      this.props.authStore.externalAuthClient.tokenManager.clear();
      this.handleError(e);
    }
  };

  render() {
    const { authState } = this.state;

    switch (authState) {
      case STATES.LOADING:
        return (
          <OktaLoginWrapper>
            <CircleLoader loaderText="Authentication..." />
          </OktaLoginWrapper>
        );
      case STATES.ERROR:
        return (
          <OktaLoginWrapper>
            <div>Unable to authenticate.</div>
            <OktaActionButton onClick={this.authenticate}>
              Retry
            </OktaActionButton>
          </OktaLoginWrapper>
        );
      default:
        return (
          <OktaLoginWrapper>
            <div>Authentication as administrator</div>
            <OktaActionButton onClick={this.authenticate}>
              Login
            </OktaActionButton>
          </OktaLoginWrapper>
        );
    }
  }

}

export default OktaLogin;
