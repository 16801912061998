import styled, { css } from 'styled-components';

export const LocationItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  flex-wrap: wrap;
  border-bottom: 1px solid #e6e7e8;

  ${({ isActive }) =>
    isActive &&
    css`
      border-left: 8px solid #4a90e2;
      padding-left: 32px;
    `};
`;

export const LocationInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LocationTitle = styled.div`
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
`;

export const LocationAddress = styled.div`
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  color: #000000;
  flex-basis: 70%;
`;

export const LocationSubInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: 11px;
`;

export const LocationDistance = styled.div`
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  color: #000000;
`;

export const LocationOpeningHours = styled.div`
  font-size: 12px;
  font-weight: normal;
  border-top: 1px solid #e6e7e8;
  margin-top: 20px;
  padding-top: 20px;
`;

export const LocationOpeningHoursTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const LocationOhTableBody = styled.tbody`
  border: none;
  padding: 0;
`;

export const LocationOhTableRow = styled.tr`
  border: none;
  padding: 0;
`;

export const LocationOhTableData = styled.td`
  border: none;
  padding: 0;
  text-align: left;
  font-weight: 200;
  font-size: 14px;
  line-height: 1.43;
  font-family: ${props => props.theme.fonts.basic};

  &:first-child {
    width: 80px;
  }
`;

export const LocationSeeMoreBtn = styled.button`
  width: 114px;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  min-height: 30px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #e2e2e2;
  border-image: initial;
  padding: 0;
  align-self: flex-end;
`;

export const TooltipText = styled.div`
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #282d32;
`;
