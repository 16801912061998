// @flow

import React, { Component } from 'react';
import {
  Container,
  ErrorMessage,
  Info,
  SelectButton,
  SelectedFile,
  UploadFileWrapper,
} from './elements';
import { IMAGE_TYPE } from '_common/constants/imageDimensions';
import {
  FILE_VALIDATOR_CHECKS,
  BACKGROUND_IMAGE_VALIDATOR_CHECKS,
} from '_common/utils/imageUtils';

type Props = {
  onLoad: (src: string) => void,
  isPublished?: boolean,
};

type State = {
  file: File | null,
  previewName: string,
  error: string,
  base64File: string,
};

class UploadFile extends Component<Props, State> {

  inputRef: { current: any } = React.createRef();

  state = {
    file: null,
    error: '',
    previewName: '',
    base64File: '',
  };

  componentDidUpdate(prevProps) {
    if (this.props.isPublished && !prevProps.isPublished && this.state.error) {
      this.setState({ error: '' });
    }
  }

  handleInputClick = () => {
    this.inputRef.current.value = null;
  };

  onFileChange = async (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault();

    this.setState({ error: '' });

    const target = e.target;
    const file = target.files && target.files[0];

    if (!file) {
      return;
    }

    for (const check of FILE_VALIDATOR_CHECKS) {
      if (!check.validatorFunction(file)) {
        return this.setState({
          error: check.errorMessage,
          file: null,
          previewName: '',
        });
      }
    }

    const reader = new FileReader();
    const base64FileData = await this.getFileData(file);
    reader.readAsDataURL(file);
    reader.onloadend = e => {
      if (this.props.type === IMAGE_TYPE.BACKGROUND_IMAGE) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          for (const check of BACKGROUND_IMAGE_VALIDATOR_CHECKS) {
            if (!check.validatorFunction(img)) {
              return this.setState({
                error: check.errorMessage,
                file: null,
                previewName: '',
              });
            }
          }

          this.props.onLoad(e.target.result);
          this.setState({
            file,
            previewName: file.name,
            error: '',
            base64File: base64FileData.data,
          });
        };
      } else {
        this.props.onLoad(e.target.result);
        this.setState({
          file,
          previewName: file.name,
          error: '',
          base64File: base64FileData.data,
        });
      }
    };
  };

  getBase64 = () => {
    return this.state.base64File;
  };

  getFileData = (file: Blob) => {
    return new Promise<{ data: string, contentType: string }>(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Result is data url, e.g: data:image/png;base64,xxxxxxx
        const result = ((reader.result: any): string);
        if (!result) {
          return;
        }

        const dataStart = result.indexOf(',');
        const contentType = result
          .slice(0, dataStart)
          .split(';')[0]
          .split(':')[1];

        resolve({ data: result, contentType });
      };
      reader.readAsDataURL(file);
    });
  };

  render() {
    const { previewName, error } = this.state;

    return (
      <UploadFileWrapper>
        <Container>
          <SelectButton>Select image</SelectButton>
          <input
            ref={this.inputRef}
            onChange={this.onFileChange}
            type="file"
            name="file"
            onClick={this.handleInputClick}
          />
        </Container>
        <Info>
          <SelectedFile>{previewName}</SelectedFile>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Info>
      </UploadFileWrapper>
    );
  }

}

export default UploadFile;
