// @flow
/*eslint-disable camelcase*/
import { apiClient } from '_common/api/clients/clients';
import type { PeriscopeFilter } from '../stores/statisticsStore';
import {
  STATISTICS_DASHBOARD,
  STATISTICS_SPACE,
} from '_common/constants/appConfig';

/*
FOR ADMINS

/v1/periscope-dashboard/:space/getUrl/dashboard/:dashboard/organisation/:organisationId

FOR MERCHANTS

/v1/periscope-dashboard/:space/getUrl/dashboard/:dashboard/organisation/:organisationId/:rootId
 */

const DASHBOARD_APPLICATION_NAME = 'returnsportal';

const getDashboardConfig = async ({ organisationId, rootId }) => {
  let url = `/v1/periscope-dashboard/${STATISTICS_SPACE}/${DASHBOARD_APPLICATION_NAME}/getUrl/dashboard/${STATISTICS_DASHBOARD}/organisation/${organisationId}`;
  if (rootId) {
    url += `/${rootId}`;
  }

  const response = await apiClient.get(url);
  return response.data.resource;
};

const getDashboardConfigDrillDown = async ({
  organisationId,
  rootId,
  filter,
}): Promise<any> => {
  const { column_value, filter_name }: PeriscopeFilter = filter;
  let url = `/v1/periscope-dashboard/${STATISTICS_SPACE}/${DASHBOARD_APPLICATION_NAME}/getUrl/dashboard/${STATISTICS_DASHBOARD}-dd/organisation/${organisationId}`;
  if (rootId) {
    url += `/${rootId}`;
  }
  url += `?${filter_name}=${column_value}`;

  const response = await apiClient.get(url);
  return response.data.resource;
};

export default {
  getDashboardConfig,
  getDashboardConfigDrillDown,
};
