// @flow

import * as React from 'react';
import { noop } from 'lodash';

import Switch from '_common/components/Switch';
import { OptionWrapper } from './SwitchOptionElements';

type Props = {
  index: number,
  option: { label: string, checked?: boolean, disabled?: boolean },
  onChange: (checked: boolean, label: string, index: number) => void,
  menuComponent?: React.Node,
  labelComponent?: React.Node,
  noToggle?: boolean,
};

type State = {};

class SwitchOption extends React.Component<Props, State> {

  static defaultProps = {
    onChange: noop,
  };

  handleChange = (checked: boolean, label: string) => {
    this.props.onChange(checked, label, this.props.index);
  };

  render() {
    const {
      option: { label, checked, disabled },
      menuComponent,
      labelComponent,
      noToggle,
      ...rest
    } = this.props;
    return (
      <OptionWrapper>
        <Switch
          {...rest}
          menuComponent={menuComponent}
          labelComponent={labelComponent}
          disabled={disabled}
          label={label}
          checked={checked}
          onChange={this.handleChange}
          noToggle={noToggle}
        />
      </OptionWrapper>
    );
  }

}

export default SwitchOption;
