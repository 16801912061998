import React from 'react';
import { Tooltip } from 'antd';
import styled, { AnyStyledComponent } from 'styled-components';

export const StyledTooltip: AnyStyledComponent = styled(props => {
  return <Tooltip {...props} overlayClassName={props.className} />;
})`
  & {
    position: relative;

    .ant-tooltip-inner {
      background-color: #fff;
      color: #222;
      font-size: 12px;
      padding: 12px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & img {
        margin-right: 20px;
      }
    }

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-content {
      position: relative;
      left: -44px;
      top: 1px;
      width: 260px;
      height: 50px;
    }
  }
`;
