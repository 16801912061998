/* eslint camelcase: 0 */

import * as qs from 'qs';
import { EMAIL_ADDRESSES, ROOT_ORG_ID } from '_common/constants/appConfig';
import merchantActions from 'merchants/actions';
import emailService from '_common/services/emailService';
import { sanitize } from '_common/utils/sanitize';
import { extractError, formatEmailAddress, trimStrings } from '_common/utils';
import {
  getEmailConfigForMerchantDeclinedRequestEmail,
  getEmailConfigForMerchantAcceptedRequestEmail,
  getEmailConfigForMerchantRegisteredEmail,
  getEmailConfigForLocationFinderInfo,
} from '../_utils';
import { apiClient } from '_common/api/clients/clients';
import Amplitude from '_common/utils/amplitude';
import AdobeAnalytics from '../_utils/adobe';

const validateAddress = async (address, accountNumber, companyId) => {
  const queryAddress = qs.stringify({ ...address, companyId, accountNumber });
  const res = await apiClient.get(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/validate-address?${queryAddress}`
  );
  return res.data;
};

const emailConfigForReject = (emailAddress, contactName) => ({
  emailAddress,
  contactName,
});

const sendMerchantRejectEmail = ({ emailAddress, contactName }) => {
  return emailService.sendEmail(
    getEmailConfigForMerchantDeclinedRequestEmail(emailAddress, contactName)
  );
};

const sendMerchantAcceptEmail = ({
  companyName,
  reviewRequestCta,
  recipientEmail,
  merchantChargeAccount,
  locationId,
  contactName,
  contactPhone,
  contactEmail,
}) => {
  return emailService.sendEmail(
    getEmailConfigForMerchantAcceptedRequestEmail(
      companyName,
      reviewRequestCta,
      recipientEmail,
      merchantChargeAccount,
      locationId,
      contactName,
      contactPhone,
      contactEmail
    )
  );
};

const sendMerchantRegistrationEmail = ({ recipientEmail }) => {
  return emailService.sendEmail(
    getEmailConfigForMerchantRegisteredEmail(recipientEmail)
  );
};

const sendCollectionWidgetEmail = (configFunc: () => void, values: object) => {
  const {
    emailAddress,
    companyName,
    contactName,
    phoneNumber,
    merchantChargeAccount,
    locationId,
    customersToUse,
    collectionWidget,
  } = values;

  return emailService.sendEmail(
    configFunc(
      emailAddress,
      companyName,
      contactName,
      phoneNumber,
      merchantChargeAccount,
      locationId,
      customersToUse,
      collectionWidget
    )
  );
};

const actionOnSignup = async (values, setFields) => {
  const {
    collectionWidget,
    customersToUse,
    merchantChargeAccount,
    locationId,
    contactName,
    phoneNumber,
  } = values;

  try {
    const formattedValues = sanitize(values);
    await merchantActions.signUpMerchant(formattedValues);
    Amplitude.logWithoutCheck('Request_submitted', {
      user_email: formattedValues.emailAddress,
      company_name: formattedValues.companyName,
    });

    if (collectionWidget) {
      await sendCollectionWidgetEmail(
        getEmailConfigForLocationFinderInfo,
        values
      );
    }

    const reviewRequestCta = `${window.location.origin}/login`;

    if (customersToUse) {
      await sendMerchantAcceptEmail({
        companyName: formattedValues.companyName,
        reviewRequestCta,
        recipientEmail: EMAIL_ADDRESSES.SIGNUP_CUSTOMERS_EMAIL,
        merchantChargeAccount,
        locationId,
        contactName,
        contactPhone: phoneNumber,
        contactEmail: formattedValues.emailAddress,
      });

      await sendMerchantRegistrationEmail({
        recipientEmail: formattedValues.emailAddress,
      });
    }
  } catch (err) {
    setFields({
      terms: {
        value: values.terms,
        errors: [new Error(extractError(err))],
      },
    });
  }
};

const getBodyInviteUserRequestConfig = ({ organisationId, email }) => {
  const redirectUrl = `${window.location.origin}/${organisationId}/login`;
  const data = {
    inviteType: 'new_user_invite',
    login: formatEmailAddress(email),
    companyId: organisationId,
    redirectUrl,
  };

  return trimStrings(data);
};

const getWhitelabelRoutes = () => [];

const trackSignupPageEvent = ({ eventType }) => {
  let options;
  switch (eventType) {
    case 'onPageLoad':
      options = {
        stage: 'start',
        step: 'landing page',
      };
      break;
    case 'onSubmit':
      options = {
        stage: 'submit',
        step: 'thank you',
      };
      break;
    default:
      break;
  }
  options && AdobeAnalytics.trackSignupPageEvent(options);
};

export default {
  validateAddress,
  sendMerchantRejectEmail,
  sendMerchantAcceptEmail,
  emailConfigForReject,
  actionOnSignup,
  getBodyInviteUserRequestConfig,
  getWhitelabelRoutes,
  trackSignupPageEvent,
};
