import styled from 'styled-components';

export const PickerWrapper = styled.div`
  align-self: center;
  position: relative;
`;

export const PickColorButton = styled.button`
  width: 160px;
  height: 42px;
  border-radius: 21px;
  border: solid 1px #979797;
  background-color: transparent;
  font-family: ${props => props.theme.fonts.book};
  font-size: 16px;
  color: #222222;
  cursor: pointer;
  align-self: center;
  outline: none;
  opacity: 1;
  transition: opacity 0.5s ease-out;

  &:hover {
    border: solid 1px #222222;
  }
`;

export const PickerContainer = styled.div`
  position: absolute;
  top: -200%;
  right: 0;
  z-index: 2;

  .chrome-picker {
    height: 280px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
`;

export const SelectColorButton = styled(PickColorButton)`
  background-color: #4a90e2;
  color: #fff;
  border: none;
  &:hover {
    background-color: #32659e;
    border: 1px solid #32659e;
  }
`;
