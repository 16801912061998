import styled, { createGlobalStyle, css } from 'styled-components';

export const UserMenuItem = styled.div`
  width: 200px;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.book};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
`;

export const GlobalUserProfileTabStyles = createGlobalStyle`
  div[style="position: absolute; top: 0px; left: 0px; width: 100%;"] {
    * {
      animation-duration: 0s !important;
    }
  }

  .UserProfileTab {
    z-index: 0;
    width: 200px;
  }

  .UserProfileTab ul {
    border: solid 1px rgba(151, 151, 151, 0.25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    top: -10px;
    left: 1px;

    & > li {
      margin: 0 5px;
      border-bottom: solid 1px rgba(151, 151, 151, 0.25);
      ${UserMenuItem} {
        padding: 5px 0;
      }
    }

    & > li:last-child {
      border-bottom: solid 1px transparent;
    }
  }
`;

export const AvatarHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 85px;
  margin-right: 40px;

  user-select: none;
  cursor: pointer;

  padding: 5px;
  z-index: 99999;
  background-color: #fff;
  border-radius: 2px;
  * {
    animation-duration: 0s !important;
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          box-shadow: 0 -3px 4px 1px rgba(0, 0, 0, 0.5);
          &:before {
            display: block;
            content: ' ';
            position: absolute;
            left: -114px;
            right: 0;
            bottom: -2px;
            background-color: #fff;
            height: 7px;
          }
        `
      : css`
          box-shadow: none;
        `};
`;

export const Avatar = styled.div`
  width: 44px;
  height: 36px;
  border-radius: 2px;
  background-color: #222222;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-family: ${props => props.theme.fonts.medium};
`;

export const IconWrapper = styled.div`
  width: 15px;
`;
