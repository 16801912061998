import stores from 'stores';
import { isEmpty } from 'lodash';

/** This action should be only one point at all to making changes to company. */
export default ({ path, singleValue, changesObject }) => {
  if (isEmpty(path) && isEmpty(changesObject)) {
    return;
  }

  if (changesObject) {
    return stores.companyModelStore.changeCompanyModel(changesObject);
  }

  if (path) {
    return stores.companyModelStore.updateCompanyModel(path, singleValue); // rename
  }

  console.error('Invalid usage of update function');
};
