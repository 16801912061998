// @flow

import React, { Component } from 'react';

class NotFound extends Component<{}, {}> {

  render() {
    return <div>Page Not Found</div>;
  }

}

export default NotFound;
