// @flow

import stores from 'stores';

const sendEmailWithPasswordResetToken = (
  organisationId: string,
  email: string
) => {
  return stores.merchantStore.sendEmailWithPasswordResetToken(
    organisationId,
    email
  );
};

export default sendEmailWithPasswordResetToken;
