// @flow

import { apiClient } from '_common/api/clients/clients';
import { ROOT_ORG_ID } from '_common/constants/appConfig';

type Recipient = {
  name: string,
  email: string,
};

type Data = {
  organisationId: string,
};

type Name = {
  firstName: string,
};

type Customer = {
  name: Name,
};

type SubstitutionData = {
  currentYear: string,
  data: Data,
  retailer: string,
  customer: Customer,
  substitution: {
    href: string,
  },
};

type SendData = {
  recipient: Recipient,
  templateId: string,
  substitutionData: SubstitutionData,
  apiKey: string,
};

type MetaData = {};

export type EmailConfig = {
  requestData: SendData,
  metaData: MetaData,
  templateId: string,
};

const sendEmail = async (emailConfig: EmailConfig) => {
  const { requestData, templateId } = emailConfig;
  const res = await apiClient.post(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/send-email/${templateId}`,
    requestData
  );
  return res.data;
};

export default {
  sendEmail,
};
