import { decimalNumberRound } from '_common/utils/imageUtils';

export const IMAGE_ASPECT_RATIO = {
  R_16_9: {
    value: '16:9',
    ratio: decimalNumberRound(16 / 9),
  },
  R_4_3: {
    value: '4:3',
    ratio: decimalNumberRound(4 / 3),
  },
};

export const ROUNDED_IMAGES_ASPECT_RATIO = [IMAGE_ASPECT_RATIO.R_16_9.ratio];

export const IMAGE_TYPE = {
  BACKGROUND_IMAGE: 'background-image',
};
