//@flow

import stores from 'stores';
import { ROOT_STATISTICS_ID } from '_common/constants/appConfig';

const getDashboardConfig = () => {
  const {
    loggedUser: { organisationId },
    getIsMerchantUser,
  } = stores.authStore;

  return stores.statisticsStore.getDashboardConfig({
    organisationId,
    ...(getIsMerchantUser() && {
      rootId: ROOT_STATISTICS_ID,
    }),
  });
};

export default getDashboardConfig;
