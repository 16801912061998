// @flow

import approveRequest from './approveRequest';
import fetchRequest from './fetchRequest';
import fetchRequests from './fetchRequests';
import rejectRequest from './rejectRequest';
import staffUpdatePassword from './staffUpdatePassword';
import signUpMerchant from './signUpMerchant';
import removeRequestFromPending from './removeRequestFromPending';
import sendEmailWithPasswordResetToken from './sendEmailWithPasswordResetToken';
import confirmUpdatingPassword from './confirmUpdatingPassword';
import getAssetsConfigForMerchant from './getAssetsConfigForMerchant';
import invalidateOrganisationConfig from './invalidateOrganisationConfig';
import setEditingInOverviewCompanyId from './setEditingInOverviewCompanyId';
import setEditingInOverviewCompanyConfig from './setEditingInOverviewCompanyConfig';
import updateMerchantConfigAndRefresh from './updateMerchantConfigAndRefresh';
import setEditingMerchantRequestId from './setEditingMerchantRequestId';
import deleteMerchant from './deleteMerchant';
import resendActivationEmail from './resendActivationEmail';

export default {
  fetchRequests,
  approveRequest,
  rejectRequest,
  staffUpdatePassword,
  signUpMerchant,
  fetchRequest,
  removeRequestFromPending,
  sendEmailWithPasswordResetToken,
  confirmUpdatingPassword,
  getAssetsConfigForMerchant,
  invalidateOrganisationConfig,
  setEditingInOverviewCompanyId,
  setEditingInOverviewCompanyConfig,
  updateMerchantConfigAndRefresh,
  setEditingMerchantRequestId,
  deleteMerchant,
  resendActivationEmail,
};
