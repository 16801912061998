// @flow

import stores from 'stores';
import type { EmailConfig } from '_common/services/emailService';

const resendActivationEmail = merchant => {
  const { companyId, emailAddress } = merchant;
  const redirectUrl = `${window.location.origin}/${companyId}/login`;

  const emailConfig: EmailConfig = {
    requestData: {
      login: emailAddress,
      companyId,
      redirectUrl,
    },
    templateId: 'merchant_sign_up',
  };
  return stores.merchantStore.resendActivationEmail(emailConfig);
};

export default resendActivationEmail;
