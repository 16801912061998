// @flow

import { action, observable, runInAction } from 'mobx';
import { NOTIFICATIONS_DISPLAY_TIME } from '_common/constants/timeout';

class PublishStore {

  @observable
  activePublishMethod: () => void;

  @observable
  isPublished: boolean = false;

  @observable
  isError: boolean = false;

  @observable
  isLoading: boolean = false;

  @action
  setCurrentPublish = method => {
    this.activePublishMethod = method;
  };
  @action
  setIsPublished = val => {
    this.isPublished = val;
  };
  @action
  setIsError = val => {
    this.isError = val;
  };
  @action
  setIsLoading = val => {
    this.isLoading = val;
  };
  @action
  publishStart = () => {
    this.isLoading = true;
    this.isError = false;
    this.isPublished = false;
  };
  @action
  showPublishNotification = (key, multiplier = 1, callback) => {
    this[key] = true;

    /** hide notification after 3s. */
    setTimeout(() => {
      runInAction(() => {
        this[key] = false;
      });
      callback && callback();
    }, NOTIFICATIONS_DISPLAY_TIME * multiplier);
  };

}

export default PublishStore;
