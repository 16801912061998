//@flow
/* eslint camelcase: 0 */
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Form } from 'antd';
import { Prompt } from 'react-router';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { Menu, TextSection, Input, RadioGroup } from '_common/components';
import {
  Page,
  Content,
  HeaderAbsoluteErrorMessage,
  PageHeader,
  PageTitle,
  Panel,
  PanelIcon,
} from '_common/components/CommonStyledElements';
import {
  PanelSection,
  PanelSectionNoWrap,
} from 'settings/branding/components/elements';
import { WebsiteWrapper } from './elements';
import { IS_DEVELOPMENT, CONSUMER_URL } from '_common/constants/appConfig';
import commonActions from '_common/actions';
import {
  toWebSafeFormat,
  extractError,
  webProtocolRegex,
  webUrlRegex,
} from '_common/utils';
import { requiredFieldRule } from '_common/utils/formUtils';
import {
  addRequiredFieldsForPatch,
  addRequiredFieldsForAutoSave,
} from '_common/utils/directoryUtils';
import amplitude from '_common/utils/amplitude';
import { detailsMainIcon, touchIcon } from 'assets';
import UploadAssetsComponent from './components/UploadAssetsComponent';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import {
  CompanyModelStore,
  PublishStore,
  UIStore,
  AssetManagementStore,
} from 'stores';
import { extractFieldValue } from '_common/utils/companyUtils';
import { TrackingBlocks } from '_common/stores/uiStore';
import {
  TrackableChanges,
  COMPANY_ENTITIES,
} from '_common/stores/companyModelStore';
import Amplitude from '_common/utils/amplitude';

const CONSUMER_URL_PATH = `products.${
  WhiteLabelConstants.PRODUCT_NAME
}.consumerUrlLive`;

type Props = RouteComponentProps & {
  uiStore: UIStore,
  companyModelStore: CompanyModelStore,
  publishStore: PublishStore,
  assetManagementStore: AssetManagementStore,
};

type State = {
  hasUnpublishedChanges: boolean,
};

@observer
class Branding extends Component<Props, State> {

  static AMPLITUDE_CONFIG = [
    {
      fields: [CONSUMER_URL_PATH],
      amplitudeName: TrackableChanges.CONSUMER_URL_TOGGLE,
    },
  ];
  state = {
    hasUnpublishedChanges: false,
  };

  assetsPublish = null;

  async componentDidMount() {
    this.props.publishStore.setCurrentPublish(this.handlePublish);
    amplitude.logEvent('page_open', { page_name: 'Branding' });
  }

  componentDidUpdate() {
    const { hasUnpublishedChanges } = this.state;
    const { uiStore } = this.props;
    if (!IS_DEVELOPMENT && hasUnpublishedChanges) {
      window.onbeforeunload = () => true;
      uiStore.setHasUnsavedChanges(true);
    } else {
      window.onbeforeunload = undefined;
      uiStore.setHasUnsavedChanges(false);
    }
  }

  componentWillUnmount() {
    const { uiStore } = this.props;
    window.onbeforeunload = undefined;
    uiStore.setHasUnsavedChanges(false);

    /** Need to invalidate stored assets. */
    if (this.props.uiStore.editingCompanyByAdmin) {
      commonActions.assetsActions.invalidateAssets();
    }
  }

  openPreviewLink = () => {
    const { editingCompanyByAdmin } = this.props.uiStore;
    const {
      user: { organisationId },
    } = commonActions.getIsMerchantUser();

    const companyNameInUrl = toWebSafeFormat(
      editingCompanyByAdmin || organisationId
    );
    window.open(`${CONSUMER_URL}/${companyNameInUrl}`, '_blank');
  };

  handleAssetsChange = (...args) => {
    commonActions.trackChange(...args);
    this.setState({ hasUnpublishedChanges: true });
  };

  handleConsumerUrlLiveChange = ({ target: { value } }) => {
    const {
      companyModelStore: { companyModel },
    } = this.props;
    commonActions.companyActions.changeCompanyModel({
      changesObject: addRequiredFieldsForPatch(companyModel, {
        products: {
          [WhiteLabelConstants.PRODUCT_NAME]: {
            consumerUrlLive: value,
          },
        },
      }).updatedConfig,
    });

    commonActions.trackChange(
      TrackingBlocks.Branding,
      `Branding: ${TrackableChanges.CONSUMER_URL_TOGGLE}`,
      !value,
      value
    );

    this.setState({ hasUnpublishedChanges: true });
  };

  updateCompany = async config => {
    const {
      match: {
        params: { company },
      },
      uiStore: { editingCompanyByAdmin },
    } = this.props;

    try {
      await commonActions.companyActions.updateCompany(
        company || editingCompanyByAdmin,
        config
      );
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
    }
  };

  handleAutoSave = () => {
    const {
      form: { validateFieldsAndScroll },
      companyModelStore: { companyModel, companyModelUpdates },
    } = this.props;

    validateFieldsAndScroll(async err => {
      if (err || isEmpty(companyModelUpdates)) {
        return;
      }

      commonActions.trackChange(TrackingBlocks.Branding, 'Branding: Website');

      await this.updateCompany(
        addRequiredFieldsForAutoSave(companyModel, companyModelUpdates)
          .updatedConfig
      );

      Amplitude.logFieldsChanges({
        pageName: 'Branding',
        model: companyModelUpdates,
        config: Branding.AMPLITUDE_CONFIG,
      });
    });
  };

  registerAssetsPublishMethod = method => {
    this.assetsPublish = method;
  };

  handlePublish = async () => {
    const {
      uiStore: { editingCompanyByAdmin },
      publishStore: {
        isPublished,
        setIsLoading,
        setIsPublished,
        publishStart,
        showPublishNotification,
      },
      companyModelStore: {
        addProcessingEntity,
        deleteProcessingEntity,
        companyModel,
        companyModelUpdates,
      },
      form: { validateFieldsAndScroll },
    } = this.props;

    if (isPublished) {
      return;
    }

    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }

      publishStart();

      addProcessingEntity(
        CONSUMER_URL_PATH,
        COMPANY_ENTITIES.DEACTIVATE_PORTAL
      );

      try {
        // save assets
        const assetsChanges = await this.assetsPublish();

        if (!editingCompanyByAdmin && assetsChanges) {
          amplitude.logEvent('ui_params_change', {
            page_name: 'Branding',
            ui_params_name: assetsChanges,
          });
        }

        await this.updateCompany(
          addRequiredFieldsForPatch(companyModel, companyModelUpdates)
            .updatedConfig
        );

        showPublishNotification('isPublished', 2, this.openPreviewLink);
        setIsPublished(true);
        this.setState({ hasUnpublishedChanges: false });
      } catch (error) {
        console.error('catch err :: handlePublish', error);
        showPublishNotification('isError');
      } finally {
        setIsLoading(false);
        deleteProcessingEntity(COMPANY_ENTITIES.DEACTIVATE_PORTAL);
      }
    });
  };

  onBlurHandler = (field_name: string) => () => {
    amplitude.logEvent('field_change', {
      page_name: 'Branding',
      field_name,
    });
    this.handleAutoSave();
  };

  renderWebsiteSection = () => {
    const {
      form: { getFieldDecorator },
      companyModelStore: { companyModel },
    } = this.props;

    return (
      <>
        <PanelSection marginTop={37}>
          <div>
            <PanelIcon image={detailsMainIcon} width={72} height={72} />
          </div>
          <TextSection title="Website*">
            Add your website URL including the http/https protocol (e.g.
            https://www.), so consumers can navigate back to your site.
          </TextSection>
          <WebsiteWrapper width={308}>
            <Form.Item>
              {getFieldDecorator('website', {
                initialValue: companyModel['website'],
                rules: [
                  requiredFieldRule,
                  {
                    pattern: webProtocolRegex,
                    message: 'URL must include protocol - http/https.',
                  },
                  {
                    pattern: webUrlRegex,
                    message: 'This is not a valid URL, please try again',
                  },
                ],
              })(
                <Input
                  style={{ width: 308 }}
                  onBlur={this.onBlurHandler('Website')}
                  id="website"
                  placeholder={'Add company website'}
                />
              )}
            </Form.Item>
          </WebsiteWrapper>
        </PanelSection>
      </>
    );
  };

  renderDeactivatePortal = () => {
    const { companyModelStore } = this.props;
    const consumerUrlLive = extractFieldValue({
      path: CONSUMER_URL_PATH,
      companyModelStore,
    });

    return (
      <>
        <PanelSectionNoWrap marginTop={37}>
          <PanelIcon image={touchIcon} width={64} height={64} />
          <TextSection title="Deactivate your returns portal">
            Your portal is ‘Active’ by default.
            <br />
            By setting your portal to ‘Inactive’, the URL will not
            <br />
            be visible and your customers will not be able to use it.
          </TextSection>
          <RadioGroup
            disabled={companyModelStore.processingEntities.has(
              COMPANY_ENTITIES.DEACTIVATE_PORTAL
            )}
            width={330}
            options={[
              { label: 'Consumer portal active', value: true },
              { label: 'Consumer portal inactive', value: false },
            ]}
            value={consumerUrlLive}
            onChange={this.handleConsumerUrlLiveChange}
          />
        </PanelSectionNoWrap>
      </>
    );
  };

  render() {
    const { hasUnpublishedChanges } = this.state;
    const {
      uiStore: { editingCompanyByAdmin },
      assetManagementStore: { assetsConfig, accentColor, latestPublishedLogo },
      publishStore: { isPublished },
    } = this.props;
    return (
      <Page>
        <Prompt
          when={!IS_DEVELOPMENT && hasUnpublishedChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        {hasUnpublishedChanges && (
          <HeaderAbsoluteErrorMessage largeOffsetRight>
            Form has unpublished changes
          </HeaderAbsoluteErrorMessage>
        )}
        <Menu />
        <Content editingCompanyByAdmin={editingCompanyByAdmin}>
          <PageHeader>
            <PageTitle>Branding</PageTitle>
          </PageHeader>
          <Panel>
            <UploadAssetsComponent
              onMount={this.registerAssetsPublishMethod}
              onChange={this.handleAssetsChange}
              companyName={editingCompanyByAdmin}
              assetsConfig={assetsConfig}
              accentColor={accentColor}
              isPublished={isPublished}
              latestPublishedLogo={latestPublishedLogo}
              pageHasChanges={hasUnpublishedChanges}
            />
            {this.renderWebsiteSection()}
            {this.renderDeactivatePortal()}
          </Panel>
        </Content>
      </Page>
    );
  }

}

export default compose(
  inject(
    'publishStore',
    'uiStore',
    'companyModelStore',
    'assetManagementStore'
  ),
  Form.create({
    onValuesChange(props: Props, values: MerchantDetails) {
      commonActions.setMerchantDetails(values);
    },
  })
)(Branding);
