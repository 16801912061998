import styled from 'styled-components';

export const LeftSection = styled.div`
  width: 480px;
  position: relative;
  padding-top: 100px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-top: 0;
    min-height: calc(100vh - 70px);
  }
`;

export const RightSection = styled.div`
  width: calc(100% - 480px);
  position: relative;
  padding-top: 100px;

  @media only screen and (max-width: 768px) {
    padding-top: 0;
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 170px);

  @media only screen and (max-width: 768px) {
    height: auto;
  }
`;
