// @flow

import stores from 'stores';

const fetchUsers = ({ isPaginated }: { isPaginated: boolean } = {}) => {
  const { organisationId } = stores.authStore.loggedUser;
  const { editingCompanyByAdmin } = stores.uiStore;
  return stores.usersStore.fetch(
    editingCompanyByAdmin || organisationId,
    isPaginated
  );
};

export default fetchUsers;
