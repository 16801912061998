// @flow

import React, { Component } from 'react';
import { Pagination } from 'antd';

import {
  PaginationWrapper,
  PrevButton,
  NextButton,
  PageInfo,
} from './elements';

type Props = {
  onPageChange: (page: number) => void,
  total: number,
  currentPage: number,
  perPage: number,
};

class CustomPagination extends Component<Props> {

  onChange = (page: number) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  nextPageAction = () => {
    const {
      currentPage,
      hasNextPage,
      fetchNextPageData,
      loadingData,
    } = this.props;
    if (this.getPageCount() !== currentPage || !hasNextPage || loadingData)
      return;
    fetchNextPageData();
  };

  controlsRender = (current, type, originalElement) => {
    switch (type) {
      case 'prev':
        return <PrevButton>Previous</PrevButton>;
      case 'next':
        return (
          <NextButton
            disabled={this.props.loadingData}
            onClick={this.nextPageAction}
          >
            Next
          </NextButton>
        );
      default:
        return originalElement;
    }
  };

  getPageCount = () => {
    const { total, perPage } = this.props;
    return Math.ceil(total / perPage);
  };

  render() {
    const {
      total,
      currentPage,
      perPage,
      pagerDisabled,
      hasNextPage,
      loadingData,
    } = this.props;

    return total > 0 ? (
      <PaginationWrapper
        pagerDisabled={pagerDisabled}
        hasNextPage={hasNextPage}
      >
        <Pagination
          onChange={this.onChange}
          showQuickJumper={false}
          defaultPageSize={perPage}
          total={total}
          current={currentPage}
          itemRender={this.controlsRender}
          hideOnSinglePage={!hasNextPage}
          disabled={loadingData}
        />
        {!pagerDisabled && (
          <PageInfo>
            Page {currentPage} of {this.getPageCount()}
          </PageInfo>
        )}
      </PaginationWrapper>
    ) : null;
  }

}

export default CustomPagination;
