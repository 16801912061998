import styled from 'styled-components';
import { isIE } from 'react-device-detect';

export const MerchantSearchWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  max-width: 85%;

  font-size: 14px;
  font-weight: 300;

  .ant-select {
    width: 100%;
    min-width: 600px;
  }

  .ant-select-selection {
    border-right: 0;
    border-radius: 2px 0 0 2px;
    border-color: rgba(151, 151, 151, 0.25);
  }

  .ant-select-selection__rendered {
    margin-left: 45px;
  }

  .ant-select-selection-selected-value {
    ${isIE && 'max-width: 500px'}
  }

  & input {
    &::placeholder {
      color: #2b3034;
      font-size: 14px;
      font-weight: 300;
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 10;

  .anticon {
    font-size: 22px;
    color: #000000;
    opacity: 0.4;
  }
`;
