// @flow

import stores from 'stores';
import { WhiteLabelConstants } from '_common/whitelabelConfig';
import { get } from 'lodash';
import getIsAdminUser from '_common/actions/getIsAdminUser';

export default (blocks: string[]) => {
  if (
    WhiteLabelConstants.IS_ENABLED_TRACK_CHANGES &&
    !getIsAdminUser().isAdmin
  ) {
    const { organisationId, login } = stores.authStore.loggedUser;
    // TODO: As part of global refactoring - need to replace storing company model in multiple stores to one.
    const retailerName =
      get(stores.companyModelStore, 'companyModel.companyName') ||
      organisationId;
    return stores.uiStore.getChanges(blocks, retailerName, login);
  }
};
