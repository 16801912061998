// @flow

import links from '_common/routes/urls';
import Overview from 'merchants/pages/Overview';
import Requests from 'merchants/pages/Requests';

export default [
  {
    path: links.requests,
    component: Requests,
  },
  {
    path: links.overview,
    component: Overview,
  },
];
