// @flow

import React from 'react';
import { HeaderWrapper, Logo } from './elements';

class LocateHeader extends React.Component {

  render() {
    const { logoSrc, ...rest } = this.props;

    return (
      <HeaderWrapper>
        <Logo src={logoSrc} {...rest} />
      </HeaderWrapper>
    );
  }

}

export default LocateHeader;
