// @flow

import type { ResetPasswordConfig } from 'merchants/stores/merchantsStore';
import stores from 'stores';

const staffUpdatePassword = async (config: ResetPasswordConfig) => {
  return stores.merchantStore.staffUpdatePassword(config);
};

export default staffUpdatePassword;
