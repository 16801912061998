// @flow

import { action, computed, observable, runInAction } from 'mobx';
import type {
  Asset,
  AssetManagementConfig,
} from '_common/services/assetManagement';
import assetManagementService from '_common/services/assetManagement';
import commonActions from '_common/actions';
import {
  extractError,
  getAssetsUrlFromBucket,
  isIdempotentErrors,
} from '_common/utils';
import { TrackingBlocks } from '_common/stores/uiStore';
import merchantActions from 'merchants/actions';
import { get } from 'lodash';
import {
  ASSETS_VERSION,
  DEFAULT_ACCENT_COLOR,
  DEFAULT_ASSET_CONFIG,
  INITIAL_ACCENT_COLOR,
} from '_common/constants/assetManagement';
import apiEndpoins from '_common/api/endpoints';
import { WhiteLabelStores } from '_common/whitelabelConfig';

class AssetManagementStore {

  @observable
  assetsConfig: Object;

  @observable
  accentColor = INITIAL_ACCENT_COLOR;

  @observable
  latestUploadedLogo: string | null = null;

  @observable
  latestPublishedLogo: string | null = null;

  @computed
  get logoImageUrl() {
    const logoInAssets = get(this.assetsConfig, 'assets.logoImage');
    return logoInAssets
      ? `${apiEndpoins.ASSETS_URL}/${logoInAssets}`
      : `${window.location.origin}${
          WhiteLabelStores.settingCommunicationsStore.defaultLogoUrl
        }`;
  }

  @computed
  get getAssetsConfigVersion() {
    return get(this.assetsConfig, 'version', ASSETS_VERSION);
  }

  /**
   * Loads assets config for single organisation.
   * @param companyId
   * @returns {Promise<void>}
   */
  @action
  getAssets = async (companyId: string) => {
    let assetsConfig;
    /** trying to get assets config */
    try {
      assetsConfig = await assetManagementService.getConfig(companyId);
    } catch (e) {
      if (e.response.status === 404) {
        /** Trying to create new assets if we cant find existing one. */
        try {
          await this.createAssetConfig(companyId, {});
        } catch (e) {
          /** Config already exists, but looks like it not published yet. */
          if (isIdempotentErrors([{ message: extractError(e) }])) {
            try {
              /** publishing config and using default just to avoid delay with waiting it in bucket. */
              await this.publishAsset(companyId, ASSETS_VERSION);
              assetsConfig = DEFAULT_ASSET_CONFIG;
            } catch (e) {
              console.error('getAssets.publishAsset', e);
            }
          } else {
            console.error('getAssets.createAssetConfig', e);
          }
        }
      }
    } finally {
      runInAction(() => {
        this.assetsConfig = assetsConfig;
        this.accentColor = get(
          assetsConfig,
          'config.css.accentColor',
          DEFAULT_ACCENT_COLOR
        );
      });
    }
  };

  @action
  createAssetConfig = async (
    companyId: string,
    config: AssetManagementConfig
  ) => {
    try {
      /** Creating of new config must be with 1.0 version. */
      return await assetManagementService.createConfig(
        companyId,
        ASSETS_VERSION,
        config
      );
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      return Promise.reject(e);
    }
  };

  @action
  uploadAsset = async (companyId: string, asset: Asset) => {
    try {
      return await assetManagementService.uploadAsset(
        companyId,
        this.getAssetsConfigVersion,
        asset
      );
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      return Promise.reject(e);
    }
  };

  @action
  publishAsset = async (
    companyId: string,
    version: string = this.getAssetsConfigVersion
  ) => {
    try {
      commonActions.getChanges([TrackingBlocks.Branding]);
      const configResp = await assetManagementService.publishAsset(
        companyId,
        version
      );
      await merchantActions.invalidateOrganisationConfig(companyId);

      runInAction(() => {
        if (!configResp.assets) {
          this.assetsConfig = configResp;
        } else {
          const {
            assets: { backgroundImage, logoImage },
          } = configResp;
          this.assetsConfig = {
            ...configResp,
            assets: {
              backgroundImage: getAssetsUrlFromBucket(backgroundImage),
              logoImage: getAssetsUrlFromBucket(logoImage),
            },
          };
        }
        this.onLogoPublish();
      });
      return Promise.resolve();
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      return Promise.reject(e);
    }
  };

  @action
  updateAssetConfig = async (
    companyId: string,
    config: AssetManagementConfig
  ) => {
    try {
      await assetManagementService.updateConfig(
        companyId,
        this.getAssetsConfigVersion,
        config
      );
    } catch (e) {
      commonActions.setApplicationErrorMessage(extractError(e));
      return Promise.reject(e);
    }
  };

  @action
  onLogoPublish = () => {
    runInAction(() => {
      this.latestPublishedLogo = this.latestUploadedLogo;
      this.latestUploadedLogo = null;
    });
  };

  @action
  invalidateAssets = () => {
    this.assetsConfig = {};
    this.accentColor = INITIAL_ACCENT_COLOR;
  };

}

export default AssetManagementStore;
