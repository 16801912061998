// @flow

import stores from 'stores';
import commonActions from '_common/actions';
import {
  WhiteLabelConstants,
  WhiteLabelServices,
} from '_common/whitelabelConfig';
import { get } from 'lodash';
import { ManualErrors } from '_common/constants/apiErrorResponces';
import { extractError } from '_common/utils';

export default async address => {
  const {
    user: { organisationId },
    isMerchant,
  } = commonActions.getIsMerchantUser();

  try {
    const companyId = isMerchant
      ? organisationId
      : stores.uiStore.editingCompanyByAdmin;
    const validationResult = await WhiteLabelServices.validateAddress(
      address,
      get(
        stores.companyModelStore.companyModel,
        `products.${WhiteLabelConstants.PRODUCT_NAME}.customerPayments.merchantChargeAccount`
      ),
      companyId
    );

    if (validationResult.found) {
      return Promise.resolve(validationResult);
    } else {
      return Promise.reject(ManualErrors.ADDRESS_NOT_VALID);
    }
  } catch (e) {
    commonActions.setApplicationErrorMessage(extractError(e));
    return Promise.reject(e);
  }
};
