import React from 'react';
import { chargeAccountFieldRule } from '_common/utils/formUtils';
import {
  validateOnSpecialCharacters,
  validatePhoneNumber,
  validateByPreconditions,
} from '_common/utils';
import { VALIDATION_FIELDS_TYPE } from '_common/constants/validation';
import { AgreementLink, AgreementText } from 'login/pages/elements/Login';
import { AusPostLogo, ButtonWrapper } from './elements';
import {
  authPostCollectAndReturnLogo,
  facebookIcon,
  twitterIcon,
  linkedinIcon,
} from '../_assets';
import {
  getEmailConfigForBarcode,
  getEmailConfigForParcelIsLodged,
  getEmailConfigForDeliveredToWarehouse,
} from '../_utils';
import { FIELD_NAMES } from '../_constants';

const PRODUCT_SELECT_ERR = 'Please select one or both products.';

export default {
  common: {
    carrierLogo: <AusPostLogo />,
    hideUsersListOnRoot: () => true,
    countryCode: 'AU',
    defaultMapCenter: {
      lat: -37.80205068,
      lng: 144.95724385,
    },
  },
  pages: {
    quickStart: {
      colorPickerTitle: 'Accent colours',
    },
    settingsAndCommunications: {
      product: {
        title: 'Select return delivery speed',
        description: 'Dictates the speed of return, defaulted to Parcel Post',
      },
      SPEED_OPTIONS: [
        { label: 'Parcel post', value: 'PR' },
        { label: 'Express post', value: 'XPR' },
      ],
      emailsList: {
        0: {
          name: 'Return requested',
          isDisabled: false,
          isSend: false,
          configFunc: getEmailConfigForBarcode,
        },
        1: {
          name: 'Return on it’s way',
          isDisabled: false,
          isSend: false,
          configFunc: getEmailConfigForParcelIsLodged,
        },
        2: {
          name: 'Return delivered',
          isDisabled: false,
          isSend: false,
          configFunc: getEmailConfigForDeliveredToWarehouse,
        },
      },
      communicationTitleText: (
        <>
          View examples of the emails sent to your customers.
          <br />
          Previews emails will best sent to your email address.
        </>
      ),
    },
    login: {
      loginLogo: <AusPostLogo />,
    },
    signUp: form => {
      const oneOfTwoValidator = (
        rule: Object,
        value: boolean,
        callback: Function
      ) => {
        const { otherField } = rule;
        const otherValue = form.getFieldValue(otherField);
        if (!value && !otherValue) {
          callback(PRODUCT_SELECT_ERR);
        } else {
          callback();
        }
      };
      const checkOtherValidator = (
        rule: Object,
        value: boolean,
        callback: Function
      ) => {
        const { otherField } = rule;
        form.validateFields([otherField], { force: true });
        callback();
      };

      return {
        title: 'Request Collect & Return access',
        subtitle:
          "Fill in and submit the form. We'll then review your request and provide you with your credentials within 3 business days.",
        submit: 'Request access',
        isCollectionsWidgetAndReturnsPortalSubmitted:
          'We’ve received your request to access the Australia Post Returns Portal and Collections widget.',
        isCollectionWidgetOnlySubmitted:
          'We’ve received your request to access the Collections widget.',
        successMessage:
          'We’ve received your request to access the Australia Post Returns Portal.',
        uiBlocksOrder: ['CHECKBOXES', 'INPUTS', 'FOOTER'],
        inputs: [
          {
            id: 'companyName',
            placeholder: 'Enter trading name or brand',
            label: 'Trading name or Brand (this will be used for your URL)*',
            type: 'text',
            tooltip:
              'Trading name will be used for your Returns portal URL extension. Special characters are not allowed, except underscores. Spaces are transformed into underscores e.g. ‘Australia Post’ = `Australia_Post`’',
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please enter a trading name. ',
              },
              {
                validator: validateByPreconditions({
                  // letters, numbers, spaces, and underscores
                  regex: /^[A-Za-z0-9_ ]+$/,
                  message:
                    'Special characters are not allowed, except underscores.',
                }),
              },
            ],
          },
          {
            id: 'contactName',
            placeholder: 'Enter customer name',
            label: 'Customer name*',
            type: 'text',
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please enter a customer name. ',
              },
              {
                validator: validateByPreconditions({
                  // letters, numbers, spaces, hyphens and underscores
                  regex: /^[A-Za-z0-9_ -]+$/,
                  message:
                    'Special Characters are not allowed, except underscores and hyphens',
                }),
              },
            ],
          },
          {
            id: 'legalEntity',
            placeholder: 'Enter company name / legal entity',
            label: 'Company name / legal entity*',
            type: 'text',
            tooltip:
              'Company name / legal entity is the legal name of your business. Please ensure it is correct.',
            validateFirst: true,
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please enter a company name. ',
              },
              {
                validator: (
                  rule: any,
                  value: string,
                  callback: (error?: Error) => void
                ) => {
                  validateOnSpecialCharacters(
                    rule,
                    value,
                    callback,
                    VALIDATION_FIELDS_TYPE.companyName
                  );
                },
              },
            ],
          },

          {
            id: 'phoneNumber',
            placeholder: '+61 491 570 158',
            label: 'Phone number*',
            type: 'text',
            validateFirst: true,
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please enter a contact phone number. ',
              },
              {
                validator: (
                  rule: any,
                  value: string,
                  callback: (error?: Error) => void
                ) => {
                  validatePhoneNumber(rule, value, callback);
                },
              },
              {
                validator: (
                  rule: any,
                  value: string,
                  callback: (error?: Error) => void
                ) => {
                  validateOnSpecialCharacters(rule, value, callback);
                },
              },
            ],
          },
          {
            id: 'emailAddress',
            placeholder: 'yourname@mail.com',
            label: 'Email address*',
            type: 'text',
            rules: [
              {
                required: true,
                message: 'Please enter email address. ',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address. ',
                transform: value => value && value.trim(),
              },
              {
                validator: (
                  rule: any,
                  value: string,
                  callback: (error?: Error) => void
                ) => {
                  validateOnSpecialCharacters(rule, value, callback);
                },
              },
            ],
          },

          {
            id: 'merchantChargeAccount',
            placeholder: '1234567',
            label: 'Charge account number*',
            type: 'text',
            tooltip:
              'Also referred to as your billing account number. This is the reference for your Australia Post account. Please check a recent invoice or contact your Australia Post account manager if you are unsure of your Charge account number.',
            validateFirst: true,
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please enter a charge account number. ',
              },
              {
                ...chargeAccountFieldRule,
              },
              {
                validator: (
                  rule: any,
                  value: string,
                  callback: (error?: Error) => void
                ) => {
                  validateOnSpecialCharacters(rule, value, callback);
                },
              },
            ],
          },
          {
            id: 'locationId',
            placeholder: '12345',
            label: 'Merchant location ID*',
            type: 'text',
            tooltip:
              'A Merchant Location ID (MLID) is a three or five alphanumeric value representing an account number. Please contact your Australia Post account manager if you are unsure of your MLID.',
            validateFirst: true,
            rules: [
              {
                required: true,
                whitespace: true,
                message: 'Please enter a merchant location ID. ',
              },
              {
                message: 'Please enter a valid location ID. ',
                pattern: /^[a-z0-9]{3}$|^[a-z0-9]{5}$/i,
              },
              {
                validator: (
                  rule: any,
                  value: string,
                  callback: (error?: Error) => void
                ) => {
                  validateOnSpecialCharacters(rule, value, callback);
                },
              },
            ],
          },
        ],
        logo: <AusPostLogo />,
        SignupButtonWrapper: ButtonWrapper,
        agreementText: (
          <AgreementText>
            I agree to{' '}
            <AgreementLink
              href="https://auspost.com.au/terms-conditions/collect-and-return-terms-and-conditions"
              target="_blank"
            >
              Terms of Service
            </AgreementLink>{' '}
            and{' '}
            <AgreementLink
              href="https://auspost.com.au/privacy"
              target="_blank"
            >
              Privacy Policy
            </AgreementLink>
          </AgreementText>
        ),
        checkboxes: [
          {
            id: 'customersToUse',
            label: (
              <>
                <span>Returns Portal</span>
                <span>
                  Tick this box if you want us to set up a consumer facing
                  hosted returns portal for you to configure for yourself.
                </span>
              </>
            ),
            rules: [
              {
                validator: oneOfTwoValidator,
                otherField: 'collectionWidget',
              },
            ],
          },
          {
            id: 'collectionWidget',
            label: (
              <>
                <span>Collections Widget or API integration</span>
                <span>
                  Tick this box if you want to add collection points to your
                  shopping cart which allow your customers to select a Parcel
                  Locker, Post Office or Retail Partner as an alternative
                  delivery address for their order.
                </span>
              </>
            ),
            rules: [
              {
                validator: checkOtherValidator,
                otherField: 'customersToUse',
              },
            ],
          },
        ],
        placeholderFontSize: '12px',
      };
    },
    locate: {
      logo: {
        src: authPostCollectAndReturnLogo,
        alt: 'Australia Post Logo',
      },
      header: {
        logoSrc: authPostCollectAndReturnLogo,
        width: 318,
        height: 45,
      },
      footer: {
        termsAndConditions: {
          href:
            'https://auspost.com.au/terms-conditions/general-terms-conditions',
          label: 'Australia Post Terms & Conditions',
        },
        privacy: {
          href: 'https://auspost.com.au/privacy',
          label: 'Australia Post Privacy Policy',
        },
        socialLinks: [
          {
            href: 'https://www.facebook.com/australiapost',
            src: facebookIcon,
            alt: 'facebook',
          },
          {
            href: 'https://twitter.com/auspost',
            src: twitterIcon,
            alt: 'twitter',
          },
          {
            href: 'https://www.linkedin.com/company/australia-post/',
            src: linkedinIcon,
            alt: 'linkedin',
          },
        ],
      },
      distanceUnit: 'km',
      searchPlaceholder:
        'Search for drop off locations by postcode, city, suburb',
    },
    paymentOptionsAndReasonCodes: {
      pageTitle: 'Payment & reason codes',
      pageSectionsOrder: ['PAYMENT_METHOD', 'REASON_CODES', 'FORM_FIELDS'],
      reasonCodesToggleDisabled: false,
      maxInputLength: 50,
      renderTitle: (editIcon, disableIcon) => {
        return (
          <>
            Edit <img src={editIcon} alt="edit action icon" /> or disable the
            Reason codes
            <img src={disableIcon} alt="enable action icon" />. If all Reason
            codes are disabled, the field does not appear in your returns
            portal. Set the toggle to green to indicate reasons that the
            consumer has to pay for.
          </>
        );
      },
    },
    returnsPolicies: {
      getFormFields: isIntegrated =>
        isIntegrated
          ? ['DATE_VALIDATION', 'RAN', 'PHONE_NUMBER', 'TERMS_AND_CONDITIONS']
          : [
              'DATE_VALIDATION',
              'ORDER_ID',
              'RAN',
              'PHONE_NUMBER',
              'TERMS_AND_CONDITIONS',
            ],
      orderIdOnLabelEnabled: false,
    },
    paymentAndOrderDetails: {
      // TODO: Replace with paymentOptionsAndReasonCodes
      pageTitle: 'Payment & Order Details',
      pageSubTitle:
        'Update your Payment and Order Details preferences here, then hit publish at the top to push the changes live.',
      getFormFields: () => [
        'DATE_VALIDATION',
        'ORDER_ID',
        'RAN',
        'REASON_COMMENTS',
        'PHONE_NUMBER',
      ],
      pageSectionsOrder: ['PAYMENT_METHOD', 'REASON_CODES', 'FORM_FIELDS'],
      ranPlaceholder: 'Return authorisation number',
    },
    requestCard: {
      fields: [
        {
          label: 'Company name',
          fieldName: 'companyName',
        },
        {
          label: 'Contact name',
          fieldName: 'contactName',
        },
        {
          label: 'Email address',
          fieldName: 'emailAddress',
        },
        {
          label: 'Phone number',
          fieldName: 'phoneNumber',
        },
        {
          label: 'Merchant location ID',
          fieldName: 'locationId',
        },
        {
          label: 'Charge account number',
          fieldName: 'merchantChargeAccount',
        },
      ],
    },
    merchantCard: {
      deleteMerchantEnabled: true,
    },
    overview: {
      searchFields: [
        { value: FIELD_NAMES.COMPANY_NAME, label: 'Merchant name' },
        { value: FIELD_NAMES.LOCATION_ID, label: 'Merchant location ID' },
        { value: FIELD_NAMES.MERCHANT_CHARGE_ACCOUNT, label: 'Account number' },
      ],
    },
    returnDetails: {
      pageTitle: 'Return details',
    },
  },
};
