// @flow
/* eslint camelcase: 0 */

import { Input, Menu, Pagination } from '_common/components';
import {
  Page,
  PageHeader,
  PageTitle,
  Content,
} from '_common/components/CommonStyledElements';
import {
  Panel,
  PanelSubTitle,
  EmailInputLabel,
  EmailInputWrapper,
  AddUserButton,
} from 'users/pages/elements/userElements';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { Form } from 'antd';
import { compose } from 'recompose';
import { UsersStore } from 'stores';
import UsersActions from 'users/actions';
import {
  extractError,
  formatEmailAddress,
  validateOnSpecialCharacters,
} from '_common/utils';
import { NOTIFICATIONS_DISPLAY_TIME } from '_common/constants/timeout';
import Tick from 'assets/images/tick.svg';
import Amplitude from '_common/utils/amplitude';
import UsersList from 'users/components/UsersList';

type Props = {
  usersStore: UsersStore,
};

type State = {
  isInviteSend: boolean,
  isActionInProcess: boolean,
};

export const USERS_PER_PAGE = 5;

@inject('usersStore')
@observer
class Users extends Component<Props, State> {

  state = {
    isInviteSend: false,
    isActionInProcess: false,
    currentPage: 1,
  };

  componentDidMount() {
    Amplitude.logEvent('page_open', { page_name: 'Users' });
    UsersActions.fetchUsers();
  }

  showTemporalNotification = key => {
    this.setState({
      [key]: true,
    });

    /** hide notification after 3s. */
    setTimeout(() => {
      this.setState({ [key]: false });
    }, NOTIFICATIONS_DISPLAY_TIME);
  };

  sendInvite = (e: Event) => {
    e.preventDefault();
    const {
      form: { validateFields, resetFields, setFields },
    } = this.props;

    validateFields(async (err, { email }) => {
      if (err) {
        return;
      }
      this.setState({ isActionInProcess: true });

      try {
        await UsersActions.sendInvite(formatEmailAddress(email));
        resetFields();
        this.showTemporalNotification('isInviteSend');
      } catch (e) {
        return setFields({
          email: {
            value: email,
            errors: [new Error(extractError(e))],
          },
        });
      } finally {
        this.setState({ isActionInProcess: false });
      }
    });
  };

  setPaginationPage = page => {
    this.setState({ currentPage: page });
  };

  fetchNextUsers = async () => {
    try {
      await UsersActions.fetchUsers({ isPaginated: true });
      this.setState({ currentPage: this.state.currentPage + 1 });
    } catch (error) {
      console.error('error:', error);
    }
  };

  renderForm = () => {
    const { getFieldDecorator, isFieldsTouched } = this.props.form;
    const { isInviteSend, isActionInProcess } = this.state;

    return (
      <Fragment>
        <PageHeader>
          <PageTitle>Users</PageTitle>
        </PageHeader>
        <Panel>
          <PanelSubTitle>Add a new user</PanelSubTitle>
          <Form onSubmit={this.sendInvite}>
            <Form.Item>
              <Fragment>
                <EmailInputLabel htmlFor={`email`}>
                  Email address
                </EmailInputLabel>
                <EmailInputWrapper>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'Please enter a valid email address',
                        transform: value => value && value.trim(),
                      },
                      {
                        validator: (
                          rule: any,
                          value: string,
                          callback: (error?: Error) => void
                        ) => {
                          validateOnSpecialCharacters(rule, value, callback);
                        },
                      },
                    ],
                  })(
                    <Input
                      style={{ width: 740 }}
                      autoComplete="off"
                      type={'text'}
                      id={`email`}
                      placeholder={'Add new users address'}
                    />
                  )}
                  <AddUserButton
                    isInviteSend={isInviteSend}
                    disabled={isActionInProcess || !isFieldsTouched()}
                    htmlType="submit"
                  >
                    {isInviteSend ? (
                      <img src={Tick} alt="" />
                    ) : (
                      <div>Add new user</div>
                    )}
                  </AddUserButton>
                </EmailInputWrapper>
              </Fragment>
            </Form.Item>
          </Form>
        </Panel>
      </Fragment>
    );
  };

  renderList = () => {
    const {
      users,
      hasNextPage,
      usersLoading,
      processingUsersSet,
    } = this.props.usersStore;
    const { currentPage } = this.state;
    const paginatedUsers = users.slice(
      (currentPage - 1) * USERS_PER_PAGE,
      currentPage * USERS_PER_PAGE
    );
    return (
      <Panel>
        <UsersList
          users={paginatedUsers}
          processingUsersSet={processingUsersSet}
        />
        <Pagination
          perPage={USERS_PER_PAGE}
          onPageChange={this.setPaginationPage}
          total={users.length}
          currentPage={currentPage}
          pagerDisabled
          hasNextPage={hasNextPage}
          fetchNextPageData={this.fetchNextUsers}
          loadingData={usersLoading}
        />
      </Panel>
    );
  };

  render() {
    return (
      <Page>
        <Menu />
        <Content>
          {this.renderForm()}
          {this.renderList()}
        </Content>
      </Page>
    );
  }

}
export default compose(Form.create())(Users);
