import styled, { css } from 'styled-components';
import { Switch } from 'antd';

export const StyledSwitch = styled(Switch)`
  && {
    width: 52px;
    height: 26px;
    background-color: #c7c7cc;
    border: none;
    flex-shrink: 0;

    ::after {
      box-sizing: border-box;
      width: 26px;
      height: 26px;
      border: solid 1px #e4e4e4;
      top: 0;
      left: 0;
      box-shadow: none;
    }
    margin-left: 12px;
  }

  &.ant-switch-checked {
    background-color: #38b13a;
    ::after {
      left: 100%;
      margin-left: 0;
    }
  }

  &.ant-switch-disabled {
    opacity: 1;
  }
`;

export const Root = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #131314;
  flex-grow: 1;
  font-family: ${props => props.theme.fonts.book};
  font-size: 14px;
  ${({ borderRight }) => borderRight && `border-right: solid 1px #efefef;`};
  margin: 15px 0 15px 4px;

  .anticon {
    cursor: pointer;
    color: #a2a2a2;
  }
`;

export const Label = styled.div`
  ${props =>
    props.disabled &&
    css`
      color: #c7c7cc;
    `}
`;

export const Stretch = styled.div`
  flex-grow: 1;
`;

export const LeftBorder = styled.div`
  width: 1px;
  height: 32px;
  background-color: #eeeeee;
  margin-right: 15px;
`;
