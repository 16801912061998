// @flow
import { validateOnSpecialCharacters } from '_common/utils';

export default {
  title: 'Login',
  submit: 'Login',
  inputs: [
    {
      id: 'login',
      placeholder: 'Enter email address',
      label: 'Email',
      type: 'text',
      rules: [
        {
          required: true,
          message: 'Please enter a valid email address. ',
        },
        {
          validator: (
            rule: any,
            value: string,
            callback: (error?: Error) => void
          ) => {
            validateOnSpecialCharacters(rule, value, callback);
          },
        },
      ],
    },
    {
      id: 'password',
      placeholder: 'Enter password',
      label: 'Password',
      type: 'password',
      rules: [
        {
          required: true,
          message: 'Please enter your password. ',
        },
      ],
    },
  ],
};
