// @flow
/* eslint camelcase: 0 */

import { Input, Loader } from '_common/components';
import commonActions from '_common/actions';
import { ErrorMessage } from '_common/components/UploadFile/elements';
import links from '_common/routes/urls';
import { extractError, validatePassword } from '_common/utils';
import merchantActions from 'merchants/actions';
import MerchantsStore from 'merchants/stores/merchantsStore';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import {
  ButtonWrapper,
  FormContainer,
  LoginButton,
  LoginInputLabel,
  LoginTitle,
  Wrapper,
} from '../elements/Login';
import { VALIDATION_ERRORS } from '_common/constants/validation';
import Amplitude from '_common/utils/amplitude';

type UrlParams = {
  requestId: string,
  organisationId: string,
  email: string,
};

type Props = RouteComponentProps &
  FormComponentProps & {
    merchantStore: MerchantsStore,
  };

type State = {
  isLoading: boolean,
};

@inject('merchantStore')
@observer
class ResetPassword extends Component<Props, State> {

  /**
   * params parsed from url.
   */
  urlParams: UrlParams = qs.parse(this.props.location.search, {
    ignoreQueryPrefix: true,
  });

  state = {
    isLoading: true,
  };

  async componentDidMount() {
    const {
      history: { push },
    } = this.props;

    Amplitude.logWithoutCheck('page_open', { page_name: 'Setup new password' });
    const { email, organisationId } = this.urlParams;

    if (!email || !organisationId) {
      push(links.rootLogin);
    }

    await commonActions.appLogin();
    this.checkRequestStatus();
  }

  checkRequestStatus = async () => {
    const { requestId, organisationId } = this.urlParams;
    const {
      history: { push },
    } = this.props;
    try {
      const request = await merchantActions.fetchRequest(requestId);

      if (request.status === 'ONBOARDED') {
        push(`/${organisationId}/login`);
      } else {
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.error(e);
      commonActions.setApplicationErrorMessage(extractError(e));
      this.setState({ isLoading: false });
    }
  };

  /**
   * Handle resetting password.
   */
  submit = (e: Event) => {
    e.preventDefault();
    const {
      form: { validateFields, setFieldsValue, getFieldValue },
    } = this.props;

    if (!getFieldValue('login')) {
      setFieldsValue({ login: this.urlParams.email });
    }

    validateFields((err, { password }) => {
      if (!err) {
        merchantActions
          .staffUpdatePassword({ ...this.urlParams, password })
          .then(() => {
            Amplitude.logWithoutCheck('password_setup', {
              user_email: this.urlParams.email,
              company_name: this.urlParams.organisationId,
            });
            if (
              this.props.merchantStore.error === VALIDATION_ERRORS.expiredToken
            )
              return;
            commonActions.logout(this.urlParams.organisationId);
          });
      }
    });
  };

  compareToFirstPassword = (
    _: any,
    value: any,
    callback: (msg?: string) => void
  ) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(VALIDATION_ERRORS.notMatchPasswords);
    } else {
      callback();
    }
  };

  validateToNextPassword = (
    _: any,
    value: string,
    callback: (msg?: string) => void
  ) => {
    if (value && !validatePassword(value)) {
      callback(VALIDATION_ERRORS.invalidPassword);
    } else {
      callback();
    }
  };

  /**
   * Redirect to page of restoring password.
   */
  handleForgotPasswordRedirect = () => {
    const { organisationId } = this.urlParams;
    this.props.history.push(`/${organisationId}/forgot-password`);
  };

  renderErrorWithLink = () => {
    const { organisationId } = this.urlParams;
    return (
      <ErrorMessage>
        Your activation link has expired. Please request a new one by clicking{' '}
        <a
          onClick={this.handleForgotPasswordRedirect}
          href={`/${organisationId}/forgot-password`}
        >
          this link
        </a>
      </ErrorMessage>
    );
  };

  renderLoginForm = () => {
    const {
      form: { getFieldDecorator },
      merchantStore: { isLoading, error },
    } = this.props;
    const renderError =
      error === VALIDATION_ERRORS.expiredToken
        ? this.renderErrorWithLink()
        : error;
    return (
      <Form onSubmit={this.submit}>
        <Form.Item>
          {getFieldDecorator('login', {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Please enter your email',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address. ',
                transform: value => value && value.trim(),
              },
            ],
          })(
            <div>
              <LoginInputLabel htmlFor="loginId">Email</LoginInputLabel>
              <Input
                type="text"
                id="loginId"
                placeholder="Enter your email"
                defaultValue={this.urlParams.email}
              />
            </div>
          )}
        </Form.Item>
        <Form.Item help={VALIDATION_ERRORS.invalidPassword}>
          {getFieldDecorator('password', {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Please enter your password',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(
            <div>
              <LoginInputLabel htmlFor="passwordId">
                Set password
              </LoginInputLabel>
              <Input
                type="password"
                id="passwordId"
                placeholder="Enter password"
              />
            </div>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('passwordTwo', {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Please confirm your password',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(
            <div>
              <LoginInputLabel htmlFor="passwordTwoId">
                Confirm password
              </LoginInputLabel>
              <Input
                type="password"
                id="passwordTwoId"
                placeholder="Enter password"
              />
            </div>
          )}
        </Form.Item>
        {error && <ErrorMessage>{renderError}</ErrorMessage>}
        <ButtonWrapper>
          <LoginButton disabled={isLoading} htmlType="submit">
            Confirm
          </LoginButton>
        </ButtonWrapper>
      </Form>
    );
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Wrapper>
        <FormContainer>
          {isLoading ? (
            <Loader />
          ) : (
            <Fragment>
              <LoginTitle align="left">Set password</LoginTitle>
              {this.renderLoginForm()}
            </Fragment>
          )}
        </FormContainer>
      </Wrapper>
    );
  }

}

export default compose(Form.create())(ResetPassword);
