//@flow
import React, { Component } from 'react';
import { ObservableSet } from 'mobx';
import { observer } from 'mobx-react';

import {
  PanelSubTitle,
  ListWrapper,
  Loading,
} from '../../pages/elements/userElements';
import { ListHeader, ListItem } from './elements';
import UsersActions from 'users/actions';
import Switch from '_common/components/Switch';
import commonActions from '_common/actions';
import type { User } from 'users/services/usersService';

type EnableUserSwitchProps = {
  user: User,
  onChange: () => void,
  disabled?: boolean,
};

class EnableUserSwitch extends Component<EnableUserSwitchProps> {

  handleUserSwitch = e => {
    const {
      user: { login },
      onChange,
    } = this.props;
    onChange(e, login);
  };

  render() {
    const {
      user: { status, login },
      disabled,
    } = this.props;
    const { user } = commonActions.getIsMerchantUser();

    return (
      <>
        {user.login === login ? (
          'You are currently logged in'
        ) : (
          <Switch
            checked={status === 'active'}
            onChange={this.handleUserSwitch}
            disabled={disabled}
            withoutDisabledLabel
          />
        )}
      </>
    );
  }

}

type UsersListProps = {
  user: User,
  processingUsersSet?: ObservableSet<string>,
};

@observer
class UsersList extends Component<UsersListProps> {

  handleUserSuspend = (checked, login) => {
    UsersActions.changeUserSuspendStatus(checked, login);
  };

  render() {
    const { users, processingUsersSet } = this.props;

    return (
      <>
        <PanelSubTitle>Current members</PanelSubTitle>
        {users.length ? (
          <ListWrapper style={{ marginBottom: 22 }}>
            <ListHeader>
              <span>Email address</span> <span>Enable / Disable</span>
            </ListHeader>
            {users.map(user => (
              <ListItem key={user.login}>
                <span>{user.login}</span>
                <EnableUserSwitch
                  disabled={processingUsersSet.has(user.login)}
                  user={user}
                  onChange={this.handleUserSuspend}
                />
              </ListItem>
            ))}
          </ListWrapper>
        ) : (
          <Loading>No members found...</Loading>
        )}
      </>
    );
  }

}

export default UsersList;
