import styled, { css } from 'styled-components';
import {
  PanelLeftSide,
  PanelRightSide,
} from '_common/components/CommonStyledElements';

export const AddressFormWrapper = styled.div`
  ${props =>
    props.isAddressSelected &&
    css`
      background: #f2f9ff;
      padding: 0 20px;
      margin-left: -20px;
      max-width: 408px;
      @media only screen and (max-width: 1480px) {
        max-width: initial;
      }
    `}
`;
export const AddressHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddressFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 107px; /**address icon width + margin*/
  @media only screen and (max-width: 1480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const AddressFieldsLeft = styled(PanelLeftSide)`
  flex-direction: column;
  @media only screen and (max-width: 1480px) {
    width: 360px;
    margin-right: 0;
  }
`;
export const AddressFieldsRight = styled(PanelRightSide)`
  flex-direction: column;
  flex-basis: auto;
  @media only screen and (max-width: 1480px) {
    width: 380px; /**360px + 20px colored background */
  }
`;
