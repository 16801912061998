// @flow

import React from 'react';

import { StyledInput as Input } from './SwitchInputElements';
import type { Option } from '_common/utils/orderDetailsUtils';
import { SaveButton } from 'settings/paymentOptionsAndReasonCodes/components/ReasonCodes/ReasonCodesElements';

type Props = {
  option: Option,
  index: number,
  onDiscard: () => void,
  onSave: (option: Option, index: number) => void,
  maxInputLength: number,
};

type State = {
  value: string,
};

class SwitchInput extends React.Component<Props, State> {

  state = {
    value: this.props.option.label,
  };

  handleChange = (e: SyntheticInputEvent<>) => {
    this.setState({ value: e.target.value });
  };

  handleSearch = () => {
    const { value } = this.state;
    if (!value || !value.trim()) {
      return;
    }
    const { option, index } = this.props;
    const newOption = { ...option, label: value };
    this.setState({ value });
    this.props.onSave(newOption, index);
  };

  render() {
    const { value } = this.state;
    const button = (
      <SaveButton type="primary" onMouseDown={this.handleSearch}>
        Save
      </SaveButton>
    );

    return (
      <Input
        addonAfter={button}
        value={value}
        autoFocus
        onChange={this.handleChange}
        onPressEnter={this.handleSearch}
        onBlur={this.props.onDiscard}
        maxLength={this.props.maxInputLength}
      />
    );
  }

}

export default SwitchInput;
