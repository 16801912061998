// @flow

import * as React from 'react';
import BaseModal from '_common/components/BaseModal';
import {
  QuestionMarkIcon,
  DeleteModalWrapper,
  Text,
  Icon,
  DeletePrimaryButton,
  DeleteSecondaryButton,
} from './elements';
import { ActionButtons } from '../../RequestCard/DeclineConfirmation/elements';
import { iconCircleTick } from 'assets';

type Props = {
  toggle: () => void,
  action: () => void,
  loadingState: boolean,
  isDeleteCompleted: boolean,
};

export default class DeleteConfirmation extends React.Component<Props> {

  renderQuestion = () => {
    const { toggle, action, loadingState } = this.props;

    return (
      <>
        <QuestionMarkIcon>?</QuestionMarkIcon>
        <Text>
          Are you sure you want to permanently delete merchant from the returns
          portal?
        </Text>
        <ActionButtons alignCenter>
          <DeletePrimaryButton disabled={loadingState} onClick={action}>
            Yes
          </DeletePrimaryButton>
          <DeleteSecondaryButton disabled={loadingState} onClick={toggle}>
            No
          </DeleteSecondaryButton>
        </ActionButtons>
      </>
    );
  };

  renderSuccessfulMessage = () => (
    <>
      <Icon icon={iconCircleTick} />
      <Text>Merchant portal deleted</Text>
    </>
  );

  render() {
    const { isDeleteCompleted } = this.props;
    return (
      <BaseModal>
        <DeleteModalWrapper>
          {isDeleteCompleted
            ? this.renderSuccessfulMessage()
            : this.renderQuestion()}
        </DeleteModalWrapper>
      </BaseModal>
    );
  }

}
