// @flow

import { StyledTooltip, TooltipIcon, TooltipWrapper } from './elements';
import React from 'react';

const DEFAULT_TOOLTIP_WIDTH = 375;
class Tooltip extends React.Component<any> {

  render() {
    const {
      drawBorderLeft,
      beforeInput,
      outboundToRight,
      inline,
      tooltipWidth = DEFAULT_TOOLTIP_WIDTH,
    } = this.props;
    return (
      <TooltipWrapper drawBorderLeft={drawBorderLeft} inline={inline}>
        <StyledTooltip {...this.props} tooltipWidth={tooltipWidth}>
          <TooltipIcon
            beforeInput={beforeInput}
            outboundToRight={outboundToRight}
          >
            i
          </TooltipIcon>
        </StyledTooltip>
        {this.props.children}
      </TooltipWrapper>
    );
  }

}

export default Tooltip;
